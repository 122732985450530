import React, { useState, useEffect, createRef } from 'react'
import styled from 'styled-components/macro'
import { times, isEmpty, indexOf, pipe, last } from 'ramda'

import { Spacing } from '../../ui'
import { withFieldError } from '../../wrappers'

const getFirstBlankInput = code =>
  pipe(() => getCodeArray(code), indexOf(null))()

const getCodeArray = code => Object.keys(code).map(item => code[item])

const InputBox = styled.input`
  flex: 1;
  background: ${({ theme }) => theme.color.purpleL3};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: 10px;
  padding: 10px;

  width: 60px;
  height: 60px;

  text-align: center;
  pointer-events: ${({ isFirstBlank }) => (isFirstBlank ? 'all' : 'none')};

  ${({ theme }) => theme.text.t0}

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const CodeVerifyView = ({ onSubmit, meta, input }) => {
  const [refs, setRefs] = useState([])

  useEffect(() => {
    setRefs(times(() => createRef(), 6))
  }, [])

  const onNextField = index => refs[index + 1].current.focus()
  const onPrevField = index => refs[index - 1].current.focus()

  const onChange = (e, index) => {
    e.preventDefault()

    const value = e.target.value
    if (!isEmpty(value)) {
      const valueFinal = value.length > 1 ? last(value) : value
      input.onChange({ ...input.value, [index]: valueFinal })

      if (index < 5) {
        return onNextField(index)
      }

      onSubmit()
    }
  }

  const handleKeyDown = (e, index) => {
    // backspace and empty input
    if (e.keyCode === 8) {
      if (isEmpty(e.target.value)) {
        e.preventDefault()

        if (index > 0) {
          onPrevField(index)
        }
      } else {
        input.onChange({ ...input.value, [index]: null })
      }
    }
  }

  return (
    <div>
      <Spacing direction="row" size="s" stretchChildren>
        {Object.keys(input.value).map((key, index) => (
          <InputBox
            autoFocus={index === 0}
            ref={refs[index]}
            type="number"
            isFirstBlank={getFirstBlankInput(input.value) === index}
            value={input.value[key]}
            onChange={e => onChange(e, index)}
            onKeyDown={e => handleKeyDown(e, index)}
          />
        ))}
      </Spacing>
    </div>
  )
}

export const CodeVerify = withFieldError()(CodeVerifyView)
