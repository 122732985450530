import React, { forwardRef } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components/macro'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import TextareaAutosize from 'react-textarea-autosize'

import { PostMediaControls } from '.'
import { Spacing } from '../../../../../../../components/ui'
import { client } from '../../../../../../../state/apollo'
import { UserGroupForumSearchQuery } from '../../../../../../../state/queries'

const CustomTextarea = styled(TextareaAutosize)`
  ${({ theme }) => theme.text.t1};
  border: 2px solid transparent;
  background: ${({ theme }) => theme.color.white};
  padding-left: 0px;
  width: 100%;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
  }
`

const SuggestionItemWrap = styled.div`
  margin: 0px;
  list-style-type: none;
  background-color: rgb(240, 239, 255);
  display: flex;
  cursor: pointer;
  border: 0.5px solid rgb(147, 137, 255);
  padding: 5px 10px;
  min-width: 100%;
  border-radius: 5px;
  margin-top: 5px;
`

const SuggestionItem = ({ entity: { label } }) => (
  <SuggestionItemWrap>{label}</SuggestionItemWrap>
)
const Loading = () => <SuggestionItemWrap>Loading...</SuggestionItemWrap>

const CustomTextareaComponent = forwardRef(
  ({ finalFormInput, ...props }, ref) => {
    return (
      <CustomTextarea
        {...props}
        onChange={e => {
          props.onChange(e)
          finalFormInput.onChange(e)
        }}
        maxLength={props.maxLength}
        value={props.value}
        ref={ref}
      />
    )
  }
)

const AutoCompleteInput = ({ input, maxLength, tribeId }) => {
  const dollarTrigger = {
    $: {
      dataProvider: async query => {
        if (!query) return []
        const {
          data: { searchForums }
        } = await client.query({
          query: UserGroupForumSearchQuery,
          variables: {
            query,
            tribeId
          },
          fetchPolicy: 'network-only'
        })

        return searchForums
      },
      component: SuggestionItem,
      output: (item, trigger) => `${trigger}${item.value}`
    }
  }

  return (
    <ReactTextareaAutocomplete
      loadingComponent={Loading}
      textAreaComponent={CustomTextareaComponent}
      finalFormInput={input}
      placeholder="Start writing your post..."
      maxLength={maxLength}
      minChar={1}
      trigger={dollarTrigger}
      listStyle={{
        borderRadius: '5px',
        margin: 0,
        padding: 0,
        boxShadow:
          'rgba(9, 30, 66, 0.31) 0px 0px 1px, rgba(9, 30, 66, 0.25) 0px 4px 8px -2px',
        overflow: 'hidden',
        position: 'absolute'
      }}
      loaderStyle={{ position: 'absolute' }}
      itemStyle={{
        listStyle: 'none',
        margin: 0
      }}
    />
  )
}

const NewPlainPost = ({ form, submitting, tribeId, TEXT_MAX_LENGTH }) => (
  <Spacing size="xl">
    <PostMediaControls form={form} submitting={submitting} />
    <Field
      name="title"
      component={AutoCompleteInput}
      maxLength={TEXT_MAX_LENGTH}
      tribeId={tribeId}
    />
  </Spacing>
)

export default NewPlainPost
