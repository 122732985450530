import React from 'react'
import { Field, Form } from 'react-final-form'
import Switch from 'react-ios-switch/lib/Switch'
import styled from 'styled-components/macro'

import { isNil, pipe } from 'ramda'
import { Modal, Spacing, Button, Text, Box, Graphic } from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import theme from '../../theme'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

const HorizontalLine = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.purpleL2};
`

const EarningPreferencesInner = ({ onDismiss, updateMember, params }) => {
  const { earnPreferences } = params

  const onSubmit = values => {
    return updateMember({
      variables: { earnPreferences: values }
    }).then(({ userErrors }) => {
      if (userErrors) return userErrors
      onDismiss()
    })
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="UBDI earning opportunities"
    >
      {() => (
        <ModalWrap>
          <Form
            initialValues={{
              study: !isNil(earnPreferences.study)
                ? earnPreferences.study
                : true,
              ad: !isNil(earnPreferences.ad) ? earnPreferences.ad : true
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
              <>
                <Spacing size="s" align="center">
                  <Graphic name="UbdiIntro" width={400} />
                  <Box>
                    <Spacing size="xl">
                      <Spacing
                        direction="row"
                        align="center"
                        justify="space-between"
                      >
                        <Text t2>
                          Use data to privately match with paid UBDI studies
                        </Text>
                        <Field
                          name="study"
                          render={({ input }) => (
                            <Switch
                              checked={input.value}
                              onChange={input.onChange}
                              onColor={theme.color.main}
                            />
                          )}
                        />
                      </Spacing>
                      <HorizontalLine />
                      <Spacing
                        direction="row"
                        align="center"
                        justify="space-between"
                      >
                        <Text t2>
                          Use my data to show ads relevant to my traits
                        </Text>
                        <Field
                          name="ad"
                          render={({ input }) => (
                            <Switch
                              checked={input.value}
                              onChange={input.onChange}
                              onColor={theme.color.main}
                            />
                          )}
                        />
                      </Spacing>
                    </Spacing>
                  </Box>
                </Spacing>
                <Modal.Footer>
                  <Spacing direction="row" justify="flex-end" align="center">
                    <Button onClick={handleSubmit} block disabled={submitting}>
                      {submitting ? 'Saving...' : 'Save Earning Preferences'}
                    </Button>
                  </Spacing>
                </Modal.Footer>
              </>
            )}
          />
        </ModalWrap>
      )}
    </Modal>
  )
}

export const EarningPreferences = pipe(
  withMutation('updateMember'),
  withMutation('verifyPhoneNumber')
)(EarningPreferencesInner)
