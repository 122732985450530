import { propOr } from 'ramda'
import React from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components/macro'

import {
  Modal,
  Spacing,
  Text,
  Button,
  DataSourceGraphic
} from '../../components/ui'
import { TribeRowCard } from '../../screens/Home/components'
import { DataSourceQuery } from '../../state/queries'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

export const AvailableTribes = ({ onDismiss, params = {} }) => {
  const { tribes, dataSourceId } = params

  const { data: dataSourceData, loading } = useQuery(DataSourceQuery, {
    variables: { id: dataSourceId }
  })

  if (loading) return null

  const onRowClick = ({ tribe }) => {
    const win = window.open(`/tribes/${tribe.id}`, '_blank')
    win.focus()
  }

  const { name } = propOr({}, 'dataSource', dataSourceData)

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Available Tribes"
    >
      {({ onDismiss }) => (
        <ModalWrap>
          <Spacing size="l">
            <Spacing size="l" align="center" justify="center">
              <DataSourceGraphic id={dataSourceId} size={100} />
              <Text h1>{name}</Text>
              <Text t1>{`You have successfuly linked ${name}!`}</Text>
            </Spacing>
            <Text h6>Available Tribes to join after linking:</Text>
            <Spacing>
              {tribes.map((tribe, i) => (
                <TribeRowCard
                  key={tribe.id + i}
                  tribe={tribe}
                  isRowClickable
                  onRowClick={() => onRowClick({ tribe })}
                  outline
                />
              ))}
            </Spacing>
          </Spacing>
          <Modal.Footer>
            <Spacing direction="row" justify="flex-end" align="center">
              <Button outline onClick={onDismiss}>
                Done
              </Button>
            </Spacing>
          </Modal.Footer>
        </ModalWrap>
      )}
    </Modal>
  )
}
