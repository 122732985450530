import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { AudienceSchemaQuery, UserQuery } from '../../state/queries'
import { TraitEntry } from '../TraitEntry'
import { Spacing } from '../ui'
import { path, pathOr, prop, uniq } from 'ramda'
import { HorizontalPillFilter } from '../HorizontalPillFilter'
import { withMutation } from '../wrappers'

export const TraitsEntryView = ({
  updateMember,
  disableCategories = false,
  showOnlyUniqueIds,
  selectedCategory
}) => {
  const { data, loading } = useQuery(AudienceSchemaQuery)
  const audienceSchema = pathOr([], ['audienceSchema'], data)

  const { data: userData } = useQuery(UserQuery)
  const tags = pathOr([], ['currentUser', 'member', 'tags'], userData)

  const allQuestions = audienceSchema
    .reduce(
      (acc, { uniqueId, children }) => [
        ...acc,
        ...children.map(child => ({ ...child, parent: uniqueId }))
      ],
      []
    )
    .filter(({ uniqueId }) => {
      if (showOnlyUniqueIds && showOnlyUniqueIds.length > 0) {
        return !!showOnlyUniqueIds.find(id => id.indexOf(uniqueId) >= 0)
      } else return true
    })

  const allCategoriesIds = uniq(allQuestions.map(prop('parent'))).map(value => {
    const category = audienceSchema.find(({ uniqueId }) => uniqueId === value)
    return {
      value,
      label: category.label
    }
  })

  const [selectedOption, setSelectedOption] = useState(selectedCategory)
  useEffect(() => {
    if (!disableCategories) {
      !selectedOption && setSelectedOption(path([0, 'value'], allCategoriesIds))
    }
  }, [allCategoriesIds, selectedOption, disableCategories])

  const selectedCategoryQuestions = selectedOption
    ? allQuestions.filter(({ parent }) => parent === selectedOption)
    : allQuestions

  const onSubmit = values => {
    const memberTags = Object.keys(values).map(uniqueId => ({
      uniqueId,
      values: values[uniqueId]
    }))

    return updateMember({ variables: { tags: memberTags } })
  }

  if (loading) return null

  const formTags = tags.reduce(
    (acc, { uniqueId, values }) => ({ ...acc, [uniqueId]: values }),
    {}
  )

  return (
    <Form initialValues={formTags} onSubmit={onSubmit} mutators={arrayMutators}>
      {({ form, handleSubmit }) => (
        <Spacing>
          {!disableCategories && (
            <HorizontalPillFilter
              options={allCategoriesIds}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}
          <Spacing size="s">
            {selectedCategoryQuestions.map(question => (
              <TraitEntry
                key={question.uniqueId}
                {...question}
                form={form}
                handleSubmit={handleSubmit}
              />
            ))}
          </Spacing>
        </Spacing>
      )}
    </Form>
  )
}

export const TraitsEntry = withMutation('updateMember')(TraitsEntryView)
