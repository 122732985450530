import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Spacing, Text } from '../../../../../components/ui'
import { InlineTags } from '../../../../Home/components/CommunityPosts/components'
import { FormatNumber } from '../../../../../util'

export const FitnessLeaderboardCard = ({ data, index, tribeId }) => {
  const { member, steps } = data

  const { tags, colors, publicTagCategories, memberId } = JSON.parse(member)

  return (
    <Spacing size="s">
      <Spacing size="l" direction="row" align="center">
        <Text h6>{index + 1}.</Text>
        <Link
          style={{ color: 'inherit' }}
          to={`/profile/${memberId}/${tribeId}`}
        >
          <InlineTags
            avatarId={`${memberId}-${tribeId}-${index}`}
            colors={colors}
            tags={tags}
            publicTagCategories={publicTagCategories}
            member={member}
            numberOfTags={3}
          />
        </Link>
      </Spacing>
      <EntriesContent>
        <EntryWrap backgroundColor="green">
          Steps: <FormatNumber>{parseInt(steps)}</FormatNumber>
        </EntryWrap>
      </EntriesContent>
    </Spacing>
  )
}

const EntriesContent = styled.div`
  padding-left: ${({ theme }) => theme.padding.xl};
`

const EntryWrap = styled.div`
  ${({ theme }) => theme.text.h7}
  background: ${({ theme, backgroundColor }) => theme.color[backgroundColor]};
  color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.s}`};

  border-radius: 4px;
  display: inline;
`
