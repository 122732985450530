import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { Input, Text } from '../../ui'
import { isEmpty, pipe, find, prop, propEq } from 'ramda'

const DropdownWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0px;
  overflow: hidden;
`

const DropdownItemWrapper = styled.div`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  cursor: pointer;

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      background-color: ${theme.color.purpleL3};
    `};

  &:hover {
    background-color: ${({ theme }) => theme.color.purpleL3};
  }
`
const DropdownItemWrapperEmpty = styled.div`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  cursor: pointer;
  opacity: 0.5;
  background: ${({ theme }) => theme.color.greyL1};
`

const NUMBER_OF_DROPDOWN_ITEMS = 3

export const SearchDropdown = ({
  input,
  meta,
  label,
  graphic,
  className,
  options,
  getSuggestions
}) => {
  const [searchText, setSearchText] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (isEmpty(input.value)) return

    const fromOptions = code =>
      pipe(find(propEq('code', code)), prop('name'))(options)

    setSearchText(fromOptions(input.value))
  }, [input.value, options])

  const onSelect = code => input.onChange(code)

  const suggestions = getSuggestions(searchText, options).splice(
    0,
    input.value ? 1 : NUMBER_OF_DROPDOWN_ITEMS
  )

  const handleKeyDown = e => {
    // enter
    if (e.keyCode === 13 && !isEmpty(suggestions)) {
      e.preventDefault()

      return onSelect(suggestions[selectedIndex].code)
    }

    // arrow up
    if (e.keyCode === 38 && !isEmpty(suggestions)) {
      e.preventDefault()

      if (selectedIndex === 0)
        return setSelectedIndex(NUMBER_OF_DROPDOWN_ITEMS - 1)

      setSelectedIndex(selectedIndex - 1)
    }

    // arrow down
    if (e.keyCode === 40 && !isEmpty(suggestions)) {
      e.preventDefault()

      if (selectedIndex === NUMBER_OF_DROPDOWN_ITEMS - 1)
        return setSelectedIndex(0)

      setSelectedIndex(selectedIndex + 1)
    }
  }

  return (
    <div>
      <Input
        className={className}
        block
        autoFocus
        meta={meta}
        input={{
          value: searchText,
          onChange: evt => setSearchText(evt.target.value)
        }}
        label={label}
        graphic={graphic}
        onKeyDown={handleKeyDown}
      />
      <DropdownWrapper>
        {isEmpty(suggestions) ? (
          <DropdownItemWrapperEmpty onClick={() => {}}>
            <Text t3 color="dark">
              Not found.
            </Text>
          </DropdownItemWrapperEmpty>
        ) : (
          suggestions.map(({ code, callingCode, name, emoji }, i) => (
            <DropdownItemWrapper
              key={code}
              isSelected={selectedIndex === i}
              onClick={() => onSelect(code)}
            >
              <Text t2>{`${emoji}  (+${callingCode})  ${name}`}</Text>
            </DropdownItemWrapper>
          ))
        )}
      </DropdownWrapper>
    </div>
  )
}
