import React from 'react'
import styled from 'styled-components/macro'

import {
  Text,
  Spacing,
  LocalStorageNotice,
  Button,
  DataSourceGraphic
} from '../../../components/ui'
import { SourceInstructions } from '../components'
import { Dropzone } from '../../../components/Dropzone'

import SyncStateIcon from '../../../components/ui/SyncStateIcon'
import { LocalStorage, Sources } from '../../../constants'
import { DataStreamWrapper } from './DataStreamWrapper'
import { DataSourceLinker } from './DataSourceLinker'

const InstructionsWrapper = styled.div`
  width: 75%;
  margin: auto;
`

const UnlinkButton = styled.div`
  cursor: pointer;
`

const TextWrap = styled.div`
  max-width: 380px;
`

export const DataSourceSpace = props => {
  return (
    <DataSourceLinker {...props}>
      {({
        name,
        hasCustomLink,
        status,
        dataSource,
        onSync,
        showUnlink,
        onUnlink,
        onStartLink,
        onFilesChange,
        apiProviderName,
        apiProvider,
        isDisabled
      }) => (
        <Spacing size="l" stretched>
          <LocalStorageNotice noticeId={LocalStorage.dataSourcesNotice} />
          <DataStreamWrapper>
            <Spacing size="xl" align="center" justify="center">
              <Spacing size="l" align="center" justify="center">
                <DataSourceGraphic id={dataSource.id} size={127} />
                <Text h1>{name}</Text>
              </Spacing>
              {isDisabled && (
                <InstructionsWrapper>
                  <Text h5 center color="main">
                    This data source is currently only linkable on our mobile
                    app
                  </Text>
                </InstructionsWrapper>
              )}
              {hasCustomLink ? (
                <LocalUploadAction
                  status={status}
                  dataSource={dataSource}
                  onFilesChange={onFilesChange}
                  onSync={onSync}
                  apiProviderName={apiProviderName}
                  apiProvider={apiProvider}
                />
              ) : (
                <LinkButton
                  status={status}
                  dataSource={dataSource}
                  onStartLink={onStartLink}
                  onSync={onSync}
                  disabled={isDisabled}
                />
              )}
              {showUnlink && onUnlink && (
                <UnlinkButton onClick={() => onUnlink(dataSource.id)}>
                  <Text h6 color="grey">
                    Unlink your account
                  </Text>
                </UnlinkButton>
              )}
            </Spacing>
          </DataStreamWrapper>
        </Spacing>
      )}
    </DataSourceLinker>
  )
}

const LocalUploadAction = ({
  status,
  dataSource: { id: dataSourceId, name },
  onFilesChange,
  onSync,
  apiProvider,
  apiProviderName
}) => {
  const isPending = status === Sources.statuses.pending
  const isResync = status === Sources.statuses['needs-resync']

  const showStateIcon = !isPending && !isResync

  const hideInstructions = [
    Sources.statuses.completed,
    Sources.statuses['needs-resync']
  ].includes(status)

  const isDisabled = [
    Sources.statuses.running,
    Sources.statuses.completed,
    Sources.statuses['needs-resync']
  ].includes(status)

  const getButtonText = () => {
    switch (status) {
      case Sources.statuses.pending:
        return `Upload ${name} ${apiProviderName} file`
      case Sources.statuses.running:
        return 'Uploading...'
      case Sources.statuses.failed:
        return `Retry uploading your ${name} ${apiProviderName} file`
      case Sources.statuses.completed:
      case Sources.statuses['needs-resync']:
        return 'Account Linked'
      default:
        return ''
    }
  }

  return (
    <Spacing size="xl" align="center" justify="center">
      <Dropzone
        onFilesChange={onFilesChange}
        onFilesError={onFilesChange}
        disabled={isDisabled}
        accepts={apiProvider.supportedExtensions}
      >
        <Button
          rounded
          status={status}
          outline={!isPending}
          disabled={isDisabled}
        >
          <Spacing direction="row" size="s" align="center">
            {showStateIcon && (
              <SyncStateIcon syncState={status} size={20} height={20} />
            )}
            <Text h6 color={isPending ? 'white' : 'black'}>
              {getButtonText()}
            </Text>
          </Spacing>
        </Button>
      </Dropzone>
      {status === Sources.statuses['needs-resync'] && (
        <Button
          rounded
          block
          onClick={() => onSync()}
          graphic="Sync"
          graphicColor="white"
          graphicSize={20}
        >
          Start Sync
        </Button>
      )}
      {!hideInstructions && (
        <InstructionsWrapper>
          <SourceInstructions dataSourceId={dataSourceId} />
        </InstructionsWrapper>
      )}
    </Spacing>
  )
}

const LinkButton = ({
  status,
  dataSource: { name },
  onStartLink,
  onSync,
  disabled
}) => {
  const isPending = status === Sources.statuses.pending
  const isResync = status === Sources.statuses['needs-resync']

  const showStateIcon = !isPending && !isResync

  const isDisabled =
    disabled ||
    [
      Sources.statuses.running,
      Sources.statuses.completed,
      Sources.statuses['needs-resync']
    ].includes(status)

  const getButtonText = () => {
    switch (status) {
      case Sources.statuses.pending:
        return `Link your ${name} account`
      case Sources.statuses.running:
        return 'Linking...'
      case Sources.statuses.failed:
        return `Retry linking your ${name} account`
      case Sources.statuses.completed:
      case Sources.statuses['needs-resync']:
        return 'Account Linked'
      default:
        return ''
    }
  }

  return (
    <Spacing size="xl" align="center" justify="center">
      <Button
        rounded
        status={status}
        onClick={onStartLink}
        disabled={isDisabled}
        outline={!isPending}
      >
        <Spacing direction="row" size="s" align="center" justify="center">
          {showStateIcon && (
            <SyncStateIcon syncState={status} size={20} height={20} />
          )}
          <Text h6 color={isPending ? 'white' : 'black'}>
            {getButtonText()}
          </Text>
        </Spacing>
      </Button>
      {status === Sources.statuses['needs-resync'] && (
        <Spacing size="l">
          <TextWrap>
            <Text t2 center>
              Based on our security architecture, if you switch browsers or
              computer, you need to re-sync to post data
            </Text>
          </TextWrap>
          <Button
            rounded
            block
            onClick={() => onSync()}
            graphic="Sync"
            graphicColor="white"
            graphicSize={20}
          >
            Start Sync
          </Button>
        </Spacing>
      )}
    </Spacing>
  )
}
