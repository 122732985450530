import React from 'react'
import { flatten, pathOr, pipe } from 'ramda'

import { Spacing } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { useDataSourcesStatuses } from '../../../hooks'
import { getDataSources } from '../util'
import { DataSourceLink } from './DataSourceLink'

const TribeSourcesListInner = ({
  dataSources,
  linkDataSource,
  unlinkDataSource,
  data,
  linkedDataSources
}) => {
  const {
    sourceStatuses,
    setSourceStatus,
    onSync,
    onCompletedLink,
    onUnlinkSource
  } = useDataSourcesStatuses({
    linkDataSource,
    unlinkDataSource
  })

  const edges = pathOr([], ['allDataSources', 'edges'], data)

  const tribeSources = flatten(dataSources).map(dataSourceId =>
    edges.find(({ node }) => node.id === dataSourceId)
  )
  const dataSourcesToRender = getDataSources(tribeSources, linkedDataSources)

  return (
    <Spacing size="s">
      {dataSourcesToRender.map(dataSource => (
        <DataSourceLink
          key={dataSource.id}
          dataSource={dataSource}
          onCompletedLink={onCompletedLink}
          onUnlink={onUnlinkSource}
          status={sourceStatuses[dataSource.id]}
          setSourceStatus={setSourceStatus}
          onSync={onSync}
        />
      ))}
    </Spacing>
  )
}

export const TribeSourcesList = pipe(
  withMutation('linkDataSource'),
  withMutation('unlinkDataSource')
)(TribeSourcesListInner)
