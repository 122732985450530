import gql from 'graphql-tag'
import { EntryFragment, CompetitionFragment } from '../fragments'

export const CompetitionLeaderboardQuery = gql`
  query CompetitionLeaderboardQuery($id: String!) {
    competitionLeaderboard(id: $id) {
      id
      ...Entry
    }
  }
  ${EntryFragment}
`

export const CompetitionDataLeaderboardQuery = gql`
  query CompetitionDataLeaderboardQuery($id: String!) {
    competitionDataLeaderboard(id: $id) {
      totalEntries
      positionInLeaderboard
      results {
        key
        value
      }
    }
  }
`

export const CompetitionQuery = gql`
  query CompetitionQuery($id: String!) {
    competition(id: $id) {
      id
      ...Competition
    }
  }
  ${CompetitionFragment}
`

export const LastActiveCompetitionQuery = gql`
  query LastActiveCompetitionQuery($tribeId: String!) {
    lastActiveTribeCompetition(tribeId: $tribeId) {
      id
      ...Competition
    }
  }
  ${CompetitionFragment}
`
