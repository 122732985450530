import axios from 'axios'

const { REACT_APP_INSIGHTS_WAREHOUSE_URL } = process.env

export const uploadData = async ({ data, token }) => {
  try {
    return await axios({
      method: 'POST',
      url: REACT_APP_INSIGHTS_WAREHOUSE_URL + '/upload',
      headers: { Authorization: `Bearer ${token}` },
      data
    })
  } catch (error) {
    throw new Error(`Error uploading data: ${JSON.stringify(error.response)}`)
  }
}

export const setUploadCompleted = async ({ data, token }) => {
  try {
    return await axios({
      method: 'POST',
      url: REACT_APP_INSIGHTS_WAREHOUSE_URL + '/setUploadCompleted',
      headers: { Authorization: `Bearer ${token}` },
      data
    })
  } catch (error) {
    console.log('Error uploading data to Warehouse: ', error)
  }
}

export const getWarehouseUploadStatuses = async ({ token }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: REACT_APP_INSIGHTS_WAREHOUSE_URL + '/getUploadStatuses',
      headers: { Authorization: `Bearer ${token}` }
    })
    return data
  } catch (error) {
    throw new Error(
      `Error getting upload statuses from warehouse: ${JSON.stringify(
        error.response
      )}`
    )
  }
}

export const uploadSurvey = async ({ data, token }) => {
  try {
    return await axios({
      method: 'POST',
      url: REACT_APP_INSIGHTS_WAREHOUSE_URL + '/uploadSurvey',
      headers: { Authorization: `Bearer ${token}` },
      data
    })
  } catch (error) {
    console.log('Error uploading survey to Warehouse: ', error.response)
  }
}
