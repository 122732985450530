import React, { useState, useEffect, useRef } from 'react'
import { isEmpty, pathOr } from 'ramda'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import { Spacing, Box, RenderFromQuery } from '../../components/ui'
import {
  CommunityPollFilteredQuery,
  CommunityPollQuery
} from '../../state/queries'
import {
  Poll,
  PostCard,
  PostInfo
} from '../Home/components/CommunityPosts/components'
import { Field, Form } from 'react-final-form'
import {
  CommentForm,
  FilterResults,
  PostComments,
  FilterTagsLegend
} from './components'
import { EmptyScreenTemplate } from '../Home/components/EmptyScreenTemplate'

const Wrapper = styled.div`
  position: relative;
`

const Container = styled.div`
  padding: ${({ theme }) => `${theme.padding.xl} 0px`};
  width: 40%;
  height: 100%;
  margin: auto;
`

const BackButton = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;

  ${({ theme }) => theme.text.h6};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  color: ${({ theme }) => theme.color.main};
  background: ${({ theme }) => theme.color.white};
  border-radius: 35px;
  border: 1px solid ${({ theme }) => theme.color.main};
  cursor: pointer;
`

const StyledBoxUpper = styled(Box)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`

const StyledBoxLower = styled(Box)`
  border-top-width: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`

const CommentFormWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`

const CommentFormBox = styled.div`
  margin: auto;
  width: 40%;
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
`
const initialValues = { tags: { and: [] } }

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
const scrollToTop = () => window.scrollTo(0, 0)

const PostScreenInner = ({
  match: { params: { postId } } = {},
  location,
  history: { goBack }
}) => {
  const [selectedResultsTags, setSelectedResultTags] = useState([])
  const [replyingToCommentId, setReplyingToCommentId] = useState()
  const scrollToComments = pathOr(
    false,
    ['state', 'scrollToComments'],
    location
  )
  const CommentsRef = useRef()

  const { data, loading } = useQuery(CommunityPollQuery, {
    variables: { id: postId }
  })

  useEffect(() => {
    if (loading) return

    if (scrollToComments) scrollToRef(CommentsRef)
    else scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (loading) return null

  const post = pathOr({}, ['communityPoll'], data)
  const hasVoted = pathOr(false, ['poll', 'voted'], post)
  const hasPoll = pathOr(false, ['poll'], post)
  const tribe = pathOr(false, ['tribe'], post)

  if (isEmpty(post)) {
    return <EmptyScreenTemplate />
  }

  const renderPoll = ({ post }) =>
    hasPoll && (
      <Poll
        poll={post.poll}
        tribe={tribe}
        selectedResultsTags={selectedResultsTags}
      />
    )

  return (
    <Wrapper>
      <BackButton onClick={goBack}>{`< Back to posts`}</BackButton>
      <Container>
        <Form
          onSubmit={() => {}}
          initialValues={initialValues}
          render={({ values: { tags } }) => (
            <StyledBoxUpper size="xl" bordered noShadow>
              <Spacing size="l" kurton>
                <PostCard condensed post={post} />
                {hasVoted ? (
                  <Field
                    name="tags.and"
                    tribe={tribe}
                    render={fieldProps => (
                      <FilterResults {...fieldProps}>
                        {props => (
                          <FilterTagsLegend
                            {...props}
                            selectedResultsTags={selectedResultsTags}
                            setSelectedResultTags={setSelectedResultTags}
                          />
                        )}
                      </FilterResults>
                    )}
                  />
                ) : null}
                {hasPoll && (
                  <RenderFromQuery
                    query={CommunityPollFilteredQuery}
                    variables={{ id: postId, tags }}
                    renderData={({ communityPoll: post }) =>
                      renderPoll({ post })
                    }
                    renderLoading={() => renderPoll({ post })}
                  />
                )}
                <PostInfo post={post} />
              </Spacing>
            </StyledBoxUpper>
          )}
        />
        <StyledBoxLower size="xl" bordered noShadow>
          <PostComments
            {...post}
            setReplyingToCommentId={setReplyingToCommentId}
          />
        </StyledBoxLower>
      </Container>
      <CommentFormWrapper ref={CommentsRef}>
        <CommentFormBox>
          <CommentForm
            id={postId}
            commentId={replyingToCommentId}
            tribeId={tribe.id}
            onReplyDimiss={() => setReplyingToCommentId('')}
          />
        </CommentFormBox>
      </CommentFormWrapper>
    </Wrapper>
  )
}

export const PostScreen = PostScreenInner
