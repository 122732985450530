import React, { createRef, useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import { path, pathOr } from 'ramda'
import styled from 'styled-components/macro'

import { Spacing, Text } from '../../components/ui'
import { PublicMemberQuery, UserMetaQuery } from '../../state/queries'
import { ProfileHeader, PublicPostsManagement } from './components'
import { CommunityPosts } from '../Home/components'
import { getDataSourceOptinIds } from './util'
import { DataStreamSections } from './components/DataStreamSections'
import { PrivateTraitsSection } from './components/PrivateTraitsSection'
import { EmptyScreenTemplate } from '../Home/components/EmptyScreenTemplate'

const useMemberQuery = ({ id, tribeId, profileUsername }) => {
  const variables = {
    ...(id ? { id } : {}),
    ...(profileUsername ? { profileUsername } : {}),
    ...(tribeId ? { tribeId } : {})
  }

  const { data, loading } = useQuery(PublicMemberQuery, {
    variables: variables
  })

  const member = pathOr(
    path(['publicMember'], data),
    ['currentUser', 'member'],
    data
  )

  return { member, loading }
}

export const ProfileScreen = ({
  match: {
    params: { memberId, memberProfileUsername, tribeId: tribeIdFromUrl }
  }
}) => {
  const ProfileRef = createRef()
  const history = useHistory()
  const [headerOffset, setHeaderOffset] = useState(0)
  const { data: currentUserData, loading: loadingUser } = useQuery(
    UserMetaQuery
  )
  const currentMember = pathOr({}, ['currentUser', 'member'], currentUserData)

  const tribeId = tribeIdFromUrl

  const { member: profileMember, loading } = useMemberQuery({
    id: memberId,
    tribeId,
    history,
    profileUsername: memberProfileUsername
  })

  useEffect(() => {
    if (!ProfileRef.current) return

    setHeaderOffset(ProfileRef.current.clientHeight - 60) // height - absolute offset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProfileRef])

  if (loading || loadingUser) return null

  if (!profileMember) {
    return (
      <EmptyScreenTemplate>
        <Text h2>
          Oops! Seems like who you are looking for doesn't exist any more.
        </Text>
      </EmptyScreenTemplate>
    )
  }

  const isOwnProfile = currentMember.id === profileMember.id

  const optins = getDataSourceOptinIds(profileMember)

  const leftSideStreams = optins.slice(0, optins.length / 2)
  const rightSideStreams = optins.slice(optins.length / 2)

  return (
    <Background coverPhotoUrl={profileMember.coverPhotoUrl}>
      <Spacing size="xl">
        <ProfileHeader
          ref={ProfileRef}
          member={profileMember}
          tribeId={tribeId}
          isOwnProfile={isOwnProfile}
          currentUser={currentUserData.currentUser}
        />
        <Container>
          <LeftPart>
            <DataStreamSections
              dataStreamOptions={leftSideStreams}
              member={profileMember}
              isOwnProfile={isOwnProfile}
            />
          </LeftPart>
          <ContentWrapper headerOffset={headerOffset}>
            <CenterPart>
              <SpacingWrap>
                {isOwnProfile && (
                  <PublicPostsManagement member={profileMember} />
                )}
                <PrivateTraitsSection
                  member={profileMember}
                  isOwnProfile={isOwnProfile}
                />
                <CommunityPosts
                  memberId={profileMember.id}
                  tribeId={tribeId}
                  profilePosts
                />
              </SpacingWrap>
            </CenterPart>
          </ContentWrapper>
          <RightPart>
            <DataStreamSections
              dataStreamOptions={rightSideStreams}
              member={profileMember}
              isOwnProfile={isOwnProfile}
              showEmptyState={rightSideStreams.length === 0}
            />
          </RightPart>
        </Container>
      </Spacing>
    </Background>
  )
}

const Background = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  ${({ coverPhotoUrl }) =>
    coverPhotoUrl ? `background-image: url(${coverPhotoUrl});` : ''};
`

const ContentWrapper = styled.div`
  width: 40%;
  margin: auto;
  margin-top: ${({ headerOffset }) => `${headerOffset}px`};
  padding-bottom: ${({ theme }) => theme.padding.xxl};
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftPart = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    ` 0px 75px ${theme.padding.xl} ${theme.padding.xl}`};
`

const CenterPart = styled.div`
  display: flex;
  flex: 2;
  padding: ${({ theme }) => theme.padding.xl} 0;
`

const RightPart = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    ` 0px ${theme.padding.xl} ${theme.padding.xl} 75px`};
`

const SpacingWrap = styled(Spacing)`
  flex: 1;
`
