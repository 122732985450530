import gql from 'graphql-tag'

export const PublicMemberFragment = gql`
  fragment PublicMember on PublicMember {
    id
    publicProfileSourceOptIns {
      dataSourceId
      accountIds
    }
    profileColor
    profileUsername
    isCustomUsername
    profileDescription
    arePostsPublic
    profileImage {
      thumbnail
    }
    followingCount
    followersCount
    isFollowedByMe

    totalPosts
    totalComments
    coverPhotoUrl
    tribeSettings {
      id
      tribeId
      publicTags
    }
  }
`
