import React from 'react'
import styled from 'styled-components/macro'
import { Text } from '..'

const LabelWrapper = styled.label`
  cursor: pointer;
  display: flex;
  ${({ hideBox }) => hideBox && `justify-content: center;`}
  align-items: center;
  border-radius: 10px;
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.m}`};
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, isChecked }) =>
      isChecked ? theme.color.main : theme.color.purpleL2};
  ${({ stretched }) => stretched && `justify-content:  space-between;`}
  ${({ reversed }) => reversed && `flex-direction:  row-reverse;`}
  ${({ labelProps }) => labelProps && `${labelProps}`};
`
const Box = styled.div`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  margin-right: ${props => props.theme.padding.s};
  border-radius: ${({ theme, isRadio }) =>
    isRadio ? theme.borderRadius.large : theme.borderRadius.small};
  background-color: ${props =>
    props.checked ? props.theme.color.main : props.theme.color.purpleL2};
  transition: background-color ${props => props.theme.animationSpeed.normal};
  ${({ checkboxProps }) => checkboxProps && `${checkboxProps}`};
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const Checkbox = ({
  label,
  input,
  textProps,
  labelProps,
  checkboxProps,
  hideBox = false,
  justBox = false,
  isRadio = false,
  stretched = false,
  reversed = false
}) => {
  if (justBox) {
    return (
      <LabelWrapper hideBox={hideBox} isChecked={input.checked}>
        <div>
          <CheckboxInput {...input} type="checkbox" />
          {!hideBox && (
            <Box
              checked={input.checked}
              isRadio={isRadio}
              checkboxProps={checkboxProps}
            />
          )}
        </div>
      </LabelWrapper>
    )
  }

  return (
    <LabelWrapper
      hideBox={hideBox}
      isChecked={input.checked}
      stretched={stretched}
      reversed={reversed}
      labelProps={labelProps}
    >
      <div>
        <CheckboxInput {...input} type="checkbox" />
        {!hideBox && (
          <Box
            checked={input.checked}
            isRadio={isRadio}
            checkboxProps={checkboxProps}
          />
        )}
      </div>
      <Text
        inline
        color={input.checked ? 'main' : 'dark'}
        {...(textProps || { h6: true })}
      >
        {label}
      </Text>
    </LabelWrapper>
  )
}
