import React from 'react'
import styled from 'styled-components/macro'

import { getTagValue } from '../../../state/selectors'
import { Tag } from '../../../components/ui'

const TagWrap = styled.div`
  padding-bottom: ${({ theme }) => theme.padding.xs};
  padding-right: ${({ theme }) => theme.padding.s};
`

export const ProfileTagsAudience = ({ children, profileSchema, ...props }) => {
  const value = getTagValue(children, profileSchema)
  if (!value) return null

  return (
    <TagWrap>
      <Tag {...props}>{value}</Tag>
    </TagWrap>
  )
}
