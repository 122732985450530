import React from 'react'
import styled from 'styled-components/macro'
import { filter, pipe, find, has, lte, length, is } from 'ramda'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { Input, Spacing, Text } from '../../../../../../../components/ui'
import { PollVoteOption, PostMediaControls } from '.'

const GIFS_LIMIT = 2 // per poll

const CustomInput = styled(Input)`
  ${({ theme }) => theme.text.h3};
  border: 2px solid transparent;
  background: ${({ theme }) => theme.color.white};
  padding-left: 0px;
  width: 100%;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
  }
`

const AddNewButtonWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  border-radius: 10px;
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.l}`};
  cursor: pointer;
`

const NewPollPost = ({ form, submitting, choices }) => (
  <Spacing size="l">
    <PostMediaControls form={form} submitting={submitting} />
    <Field
      name="title"
      component={CustomInput}
      placeholder="Enter your question"
      multiline={true}
      meta={{}}
      disabled={submitting}
    />
    <FieldArray
      name="choices"
      render={({ fields }) => {
        return (
          <Spacing>
            {fields.length > 0 && (
              <Spacing>
                {fields.map((name, index) => (
                  <Field
                    key={`${name}-${index}`}
                    name={name}
                    component={PollVoteOption}
                    placeholder={`Enter answer option #${index + 1}`}
                    onFieldRemove={() => !submitting && fields.remove(index)}
                    autoFocus
                    disabled={submitting}
                    withGiphy
                    disableGiphyInput={shouldDisableGiphyInput(choices, index)}
                  />
                ))}
              </Spacing>
            )}
            {showAddMore(choices) && (
              <AddNewButtonWrapper onClick={() => !submitting && fields.push()}>
                <Text h6 color="grey">
                  + Add new answer option
                </Text>
              </AddNewButtonWrapper>
            )}
          </Spacing>
        )
      }}
    />
  </Spacing>
)

const showAddMore = choices =>
  !(checkIfGifAdded(choices) && choices.length >= 2)

const checkIfGifAdded = choices => !!find(choice => hasGifUrl(choice))(choices)

const hasGifUrl = choice => is(Object, choice) && has('gifUrl', choice)

const gifsLimitReached = pipe(filter(hasGifUrl), length, withGifs =>
  lte(GIFS_LIMIT, withGifs)
)

const shouldDisableGiphyInput = (choices, currentIndex) => {
  const hasReachedGifLimit = gifsLimitReached(choices)
  if (hasReachedGifLimit) {
    // if reached limit enable just already selected ones
    return !hasGifUrl(choices[currentIndex])
  }

  return hasReachedGifLimit
}

export default NewPollPost
