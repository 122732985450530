import React, { PureComponent } from 'react'
import styled from 'styled-components/macro'
import { Route, Switch } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'
import { head, last, pathOr, pipe, split } from 'ramda'

import * as StepsComponents from './steps'
import {
  Graphic,
  Spacing,
  Text,
  ProgressBar,
  OnboardingContextBox
} from '../../components/ui'
import { LogoBackgroundScreen } from '../components/LogoBackgroundScreen'
import { hasToken } from '../../util'

const StepLoadingWrap = styled.div`
  margin-top: ${({ theme }) => theme.padding.xxxl};
`

const HorizontalLine = styled.div`
  border: 1px solid #dbd8ff;
`

const ContentHeader = styled.div`
  padding: 0 40px 15px 40px;
  margin-top: -20px;
`

const BackWrapper = styled.div`
  cursor: pointer;
`

const ProgressWrapper = styled.div`
  margin: 90px 0px;
  width: 35%;
  @media only screen and (max-width: 767px) {
    width: 90%;
  }
`

// Animations
const RouteContainer = posed.div()

// Steps
const steps = [
  ['username', 'StepUsername', 'Create Account'],
  ['password', 'StepPassword', 'Set a password'],
  ['country', 'StepCountry', 'Select Country'],
  ['tos', 'StepTOS', 'Terms of Service']
]

export class SignUpScreen extends PureComponent {
  static defaultProps = {
    steps
  }

  componentDidMount() {
    const { location, history } = this.props

    const shouldAllowTOS = pathOr(false, ['state', 'allowOnlyTOS'], location)

    if (shouldAllowTOS) {
      return history.replace(location.pathname)
    }

    if (!this._stepIds.includes(getRouteKey(location.pathname))) {
      history.replace(this._getStepUrl(head(this._stepIds)))
    }

    this.checkIsAuthenticated(history)
  }

  checkIsAuthenticated(history) {
    if (hasToken()) {
      history.push('/')
    }
  }

  render() {
    const { location } = this.props

    return (
      <div>
        <PoseGroup preEnterPose="preEnter">
          <RouteContainer key={location.pathname}>
            <Switch location={location}>
              {this._renderSteps()}
              <Route render={() => <div>Invalid step</div>} />
            </Switch>
          </RouteContainer>
        </PoseGroup>
        <StepLoadingWrap></StepLoadingWrap>
      </div>
    )
  }

  _renderSteps = () => {
    const wizardProps = {
      onNextStep: this._onNextStep,
      onPrevStep: this._onPrevStep
    }

    const { match, steps } = this.props

    return steps.map(([stepId, stepComponent, stepLabel]) => {
      const StepComponent = StepsComponents[stepComponent]

      return (
        <Route
          path={`${match.url}/${stepId}`}
          render={props => (
            <LogoBackgroundScreen>
              <OnboardingContextBox>
                <ContentHeader>
                  <Spacing direction="row" size="xs" align="center">
                    <BackWrapper onClick={() => this._onPrevStep()}>
                      <Graphic
                        name="ChevronLeft"
                        width={20}
                        height={13}
                        themeColor="main"
                      />
                    </BackWrapper>
                    <Text h4>{stepLabel}</Text>
                  </Spacing>
                </ContentHeader>
                <HorizontalLine />
                <StepComponent {...props} {...wizardProps} />
              </OnboardingContextBox>
              <ProgressWrapper>
                <ProgressBar
                  current={this.currentStepIndex + 1}
                  total={this.totalSteps}
                />
              </ProgressWrapper>
            </LogoBackgroundScreen>
          )}
          key={stepId}
        />
      )
    })
  }

  _goToStepIndex = stepIndex => () => {
    const {
      steps,
      history: { push }
    } = this.props

    if (stepIndex < 0) return push('/signin')
    if (stepIndex === steps.length) return this._onComplete()

    push(this._getStepUrl(steps[stepIndex][0]))
  }

  _onPrevStep = () => this._goToStepIndex(this.prevStepIndex)()

  _onNextStep = () => this._goToStepIndex(this.nextStepIndex)()

  _getStepUrl = stepId => `${this.props.match.url}/${stepId}`

  _onComplete = () => this.props.history.push('/')

  get _stepIds() {
    return this.props.steps.map(([id]) => id)
  }

  get nextStepIndex() {
    return this.currentStepIndex + 1
  }

  get prevStepIndex() {
    return this.currentStepIndex - 1
  }

  get currentStepIndex() {
    const { location } = this.props
    return this._stepIds.indexOf(getRouteKey(location.pathname))
  }

  get totalSteps() {
    return this.props.steps.length
  }
}

const getRouteKey = pipe(split('/'), last)
