import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { dropLast, append } from 'ramda'

import ModalsContext from './context'
import * as AllModals from './_all'
import { hasToken } from '../util'
import { useLocalStorageState } from 'react-storage-hooks'
import { LocalStorage } from '../constants'

export const ModalsProvider = ({ children }) => {
  const [isModalDismissed, setIsModalDismissed] = useLocalStorageState(
    LocalStorage.onboardingStepsModal,
    null
  )
  const [nowOpen, setOpen] = useState([])
  const location = useLocation()

  const closeModal = () => setOpen(dropLast(1))

  useEffect(() => {
    closeModal()
    if (hasToken() && !isModalDismissed) {
      setOpen(
        append({ id: 'OnboardingSteps', params: { setIsModalDismissed } })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <ModalsContext.Provider
      value={{
        nowOpen: nowOpen,
        openModal: (id, params) => setOpen(append({ id, params })),
        closeModal
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export const Modals = () => {
  return (
    <ModalsContext.Consumer>
      {({ nowOpen, closeModal }) =>
        nowOpen.map(({ id, params }) => {
          const ModalComponent = AllModals[id]
          return (
            <ModalComponent key={id} params={params} onDismiss={closeModal} />
          )
        })
      }
    </ModalsContext.Consumer>
  )
}
