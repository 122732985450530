import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import ndlProvider from '../../../../providers/ndlProvider'
import { Spacing, Text } from '../../../../components/ui'

const QUOTES_PROVIDER = 'tiingo'
export const RealInvestorForumSummary = ({ forum }) => {
  const [forumData, setForumData] = useState(null)

  useEffect(() => {
    const getForumDataAsync = async () => {
      const [{ data }] = await ndlProvider.getExternalApi({
        provider: QUOTES_PROVIDER,
        payload: { symbol: forum.value }
      })

      setForumData(data[0])
    }

    getForumDataAsync()
  }, [forum.value])

  return (
    <Spacing size="s">
      <Spacing direction="horizontal" size="xs">
        <Text t2 brandBlack>
          {forum.label} (${forum.value})
        </Text>
        <Text t2>-</Text>
        <Text t2>Updated {format(new Date(), 'MMM dd, yyyy h:mm aaa')}</Text>
      </Spacing>
      {forumData && <Text h2>${forumData.last_price * 1}</Text>}
    </Spacing>
  )
}
