import React from 'react'
import { graphql } from 'react-apollo'
import { includes, isNil, isEmpty, pipe } from 'ramda'
import styled, { useTheme, withTheme } from 'styled-components'

import {
  getQuestionLevelId,
  getTagValue,
  retrofitAudienceSchema
} from '../../../../../state/selectors'
import { Tag, Graphic, Text, Spacing } from '../../../../../components/ui'
import { AudienceSchemaQuery } from '../../../../../state/queries'
import { Avatar } from '../../../../../components/Avatar'

const SHOW_TAGS_NUMBER_DEFAULT = 10

const TextWrapper = styled.div`
  padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.s}`};
  background-color: ${({ theme }) => theme.color.purpleL3};
  border-radius: ${({ theme }) => theme.borderRadius.base};
`

const renderContent = ({
  member,
  publicTagsToRender,
  profileSchema,
  shouldShowTags,
  hideEmptyState
}) => {
  if (member && member.isCustomUsername) {
    return <Text h6>{member.profileUsername}</Text>
  }
  if (shouldShowTags) {
    return (
      <Spacing direction="row" size="xs">
        {publicTagsToRender.map((tag, index) => {
          const tagValue = getTagValue(tag, profileSchema)

          return <Tag key={`tag-${index}`}>{tagValue}</Tag>
        })}
      </Spacing>
    )
  }
  if (!hideEmptyState) {
    return (
      <TextWrapper>
        <Text h7>No public traits</Text>
      </TextWrapper>
    )
  }
}

const InlineTagsLoading = () => {
  const theme = useTheme()

  return (
    <Spacing direction="row" size="xs" align="center">
      <Graphic
        name="AvatarIncognito"
        size={26}
        color={theme.color.grey}
        avatarId="loading"
      />
      <Tag backgroundColor={theme.color.greyL2} textColor={theme.color.dark1}>
        ...
      </Tag>
    </Spacing>
  )
}

const InlineTags = ({
  avatarId,
  theme,
  tags = [],
  customTags = [],
  publicTagCategories = [],
  profileSchema,
  showAllTags = false,
  numberOfTags,
  member,
  hideEmptyState
}) => {
  const numberOfTagsFinal = numberOfTags || SHOW_TAGS_NUMBER_DEFAULT

  if (!tags) {
    return null
  }

  const publicTags = tags.filter(tag => {
    const maybeAge = parseInt(tag, 10)
    if (maybeAge) {
      return includes('general_age', publicTagCategories)
    }
    return includes(getQuestionLevelId(tag), publicTagCategories)
  })

  const publicTagsToRender = showAllTags
    ? publicTags
    : publicTags.length > numberOfTagsFinal
    ? publicTags.slice(0, numberOfTagsFinal)
    : publicTags

  const tagsCountTotal = publicTags.length - publicTagsToRender.length

  const shouldShowTags =
    !isNil(publicTagsToRender) && !isEmpty(publicTagsToRender)

  if (isNil(profileSchema.flattened) || isEmpty(profileSchema.flattened)) {
    return <InlineTagsLoading />
  }

  return (
    <Spacing direction="row" size="s" align="center">
      <Spacing direction="row" size="s" align="center">
        <Avatar member={member} avatarId={avatarId} />
        {!isEmpty(customTags) && (
          <Spacing direction="row" size="xs">
            {customTags
              .filter(i => !!i)
              .map(({ value, backgroundColor, textColor }, index) => (
                <Tag
                  key={`customTag-${index}`}
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                  selected
                >
                  {value}
                </Tag>
              ))}
          </Spacing>
        )}
        {renderContent({
          member,
          publicTagsToRender,
          profileSchema,
          shouldShowTags,
          hideEmptyState
        })}
      </Spacing>
      {shouldShowTags && tagsCountTotal > 0 && !numberOfTags && (
        <Text h6 color={theme.color.dark1}>
          + {tagsCountTotal}
        </Text>
      )}
    </Spacing>
  )
}

InlineTags.loading = InlineTagsLoading

export default pipe(
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema }),
  withTheme
)(InlineTags)
