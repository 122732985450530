import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Helmet } from 'react-helmet'

import {
  Graphic,
  Spacing,
  Text,
  VideoPlayer
} from '../../../../../components/ui'
import theme from '../../../../../theme'

const ExternalUrlWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
`

const ExternalUrlContentWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.m};
`

const StyledImage = styled.img`
  height: ${({ ogImage }) => (ogImage ? `270px` : `350px`)};
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
  ${({ ogImage }) =>
    ogImage &&
    css`
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    `}
`

const EmbededWrap = styled.div`
  .tiktok-embed {
    max-width: 705px !important;
  }
  .twitter-tweet-rendered {
    margin: auto;
  }
`
const PostMedia = ({ externalUrlData, uploadedImage, videoUrl }) => {
  const getMediaContent = () => {
    if (externalUrlData) {
      const { url, shortUrl, ogImage, ogTitle, embedCode } = externalUrlData

      if (embedCode) {
        return <EmbededPost embedCode={embedCode} shortUrl={shortUrl} />
      }
      return (
        <ExternalUrlWrapper onClick={() => window.open(url)}>
          {ogImage && <StyledImage src={ogImage} ogImage />}
          <ExternalUrlContentWrapper>
            <Spacing>
              {ogTitle && <Text h5>{ogTitle}</Text>}
              <Spacing direction="row" size="xs">
                <Graphic name="Link" themeColor={theme.color.main} size={20} />
                <Text t3 color="main">
                  {shortUrl}
                </Text>
              </Spacing>
            </Spacing>
          </ExternalUrlContentWrapper>
        </ExternalUrlWrapper>
      )
    }
    if (uploadedImage) {
      return (
        <div onClick={() => window.open(uploadedImage.large)}>
          <StyledImage src={uploadedImage.large} />
        </div>
      )
    }

    if (videoUrl) {
      return <VideoPlayer url={videoUrl} />
    }

    return null
  }

  return getMediaContent()
}

export default PostMedia

const EmbededPost = ({ embedCode, shortUrl }) => {
  const scriptSrc = getScript(shortUrl)

  return (
    <>
      <Helmet>
        <script id="ttEmbedder" type="text/javascript" async src={scriptSrc} />
      </Helmet>
      <EmbededWrap dangerouslySetInnerHTML={{ __html: embedCode }} />
    </>
  )
}

const getScript = shortUrl => {
  if (shortUrl.includes('tiktok.com')) {
    return 'https://www.tiktok.com/embed.js'
  } else if (shortUrl.includes('twitter.com')) {
    return 'https://platform.twitter.com/widgets.js'
  }
  return ''
}
