import { range } from 'ramda'

export const FILTER_AGES = {
  '13-22': '13-22',
  '23-29': '23-29',
  '30-40': '30-40',
  '41-55': '41-55',
  '56+': '56-101'
}

// Accepts age range string (e.g. "23-29") and returns calendar array of ages (string) for that range (e.g. ["1991", "1992",..., "1998"])
export const getCalendarAges = rangeString => {
  const [rangeFirst, rangesecond] = rangeString
    .split('-')
    .map(i => parseInt(i, 10))

  const currentYear = new Date().getFullYear()

  return range(currentYear - rangesecond, currentYear - rangeFirst + 1).map(i =>
    i.toString()
  )
}

// Accepts calendar age (e.g. 1994) and returns age range (e.g. "23-29")
export const getAgeRange = calendarAge => {
  const currentYear = new Date().getFullYear()
  const diff = currentYear - calendarAge

  const ageRanges = Object.entries(FILTER_AGES)
    .map(([label, value]) => {
      const [rangeFirst, rangeSecond] = value
        .split('-')
        .map(i => parseInt(i, 10))

      if (diff >= rangeFirst && diff <= rangeSecond) return label
      return false
    })
    .filter(Boolean)

  return ageRanges[0]
}
