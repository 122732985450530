import { isEmpty, isNil, match, pipe } from 'ramda'

export const getImage = ({ media }) => {
  if (!isNil(media) && !isEmpty(media) && isImg(media)) {
    return {
      src: media.data,
      x: 0,
      y: 0,
      height: 1,
      width: 1
    }
  }

  return null
}

export const isImg = ({ type }) =>
  pipe(
    match(/\/(gif|jpe?g|tiff|png|webp|bmp)/i),
    matches => !isEmpty(matches)
  )(type || '')
