import React from 'react'
import { Button, Spacing, Text } from '../../../../components/ui'

import { DataStreamWrapper } from '../../components/DataStreamWrapper'

export const EmptyState = ({ refetch }) => (
  <DataStreamWrapper>
    <Spacing size="xl" align="center">
      <Text center t1>
        There is currently no data to display
      </Text>
      <Button
        outline
        onClick={refetch}
        graphic="Sync"
        graphicColor="main"
        graphicSize={20}
      >
        Try syncing again
      </Button>
    </Spacing>
  </DataStreamWrapper>
)
