import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { pipe } from 'ramda'
import { Form, Field } from 'react-final-form'

import {
  Text,
  Button,
  Spacing,
  Input,
  SubmitButton,
  OnboardingContextBox
} from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { User } from '../../constants'
import { LogoBackgroundScreen } from '../components/LogoBackgroundScreen'
import { generateRandomString, hasToken } from '../../util'

const { REACT_APP_APPLE_SIGNIN_REDIRECT } = process.env

const ButtonWrap = styled.div`
  width: 33%;
  @media only screen and (max-width: 767px) {
    width: 100%;
    &:nth-child(2) {
      padding-top: 20px;
    }
  }
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const LowerPart = styled.div`
  border-top: 1px solid #dbd8ff;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
// const AppleButton = styled(Button)`
//   background: black;
//   border: 1px solid black;
// `

const FooterWrapper = styled.div`
  margin-top: 50px;
`

const ContentWrapper = styled.div`
  padding: 0 40px;
`

const AppleButtonWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
`

const checkIsAuthenticated = push => {
  if (hasToken()) {
    push('/')
  }
}

const SignInScreenInner = ({ history: { push }, logIn, signUp }) => {
  useEffect(() => {
    checkIsAuthenticated(push)
  }, [push])

  const state = generateRandomString(32)

  useEffect(() => {
    const onSignInSuccess = data => {
      const { authorization, user } = data.detail

      const { id_token: appleJWT, state: returnedState } = authorization
      const { email, name: fullName } = user || {}

      if (state !== returnedState) return

      const variables = {
        appleJWT,
        email,
        fullName: fullName
          ? [fullName.firstName, fullName.lastName]
              .filter(s => !!s)
              .join(' ') || null
          : null
      }

      return signUp({ variables }).then(
        async ({ errors, userErrors, data }) => {
          if (!userErrors && data && data.signUp) {
            localStorage.setItem(User.AUTH_TOKEN, data.signUp.token)

            if (!data.signUp.user.member.tosAccepted) {
              return push('/signup/tos', { allowOnlyTOS: true })
            }

            return push('/')
          }

          return userErrors || errors
        }
      )
    }

    const initApple = () => {
      window.AppleID.auth.init({
        clientId: 'com.ubdi.app', // This is the service ID we created.
        scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
        redirectURI: REACT_APP_APPLE_SIGNIN_REDIRECT, // As registered along with our service ID
        state, // Any string of your choice that you may use for some logic. It's optional and you may omit it.
        usePopup: true // Important if we want to capture the data apple sends on the client side.
      })

      //Listen for authorization success
      document.addEventListener('AppleIDSignInOnSuccess', onSignInSuccess)
    }

    initApple()

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', onSignInSuccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = values =>
    logIn({ variables: values }).then(async ({ errors, userErrors, data }) => {
      if (!userErrors && data && data.logIn) {
        localStorage.setItem(User.AUTH_TOKEN, data.logIn.token)
        push('/')
      }

      return userErrors || errors
    })

  return (
    <LogoBackgroundScreen>
      <OnboardingContextBox>
        <ContentWrapper>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, ...formProps }) => (
              <form onSubmit={handleSubmit}>
                <Spacing size="xl">
                  <Spacing size="l">
                    <Field
                      component={Input}
                      name="username"
                      label="Username"
                      block
                    />
                    <Field
                      component={Input}
                      name="password"
                      label="Password"
                      type="password"
                      block
                      securedTextEntry
                    />
                  </Spacing>

                  <ButtonsWrapper>
                    <ButtonWrap>
                      <Button
                        onClick={() => push('/forgot-password')}
                        link
                        transparent
                      >
                        <Text t2 color="main">
                          Forgot Password?
                        </Text>
                      </Button>
                    </ButtonWrap>
                    <ButtonWrap>
                      <SubmitButton block {...formProps}>
                        Log In
                      </SubmitButton>
                    </ButtonWrap>
                  </ButtonsWrapper>
                  <AppleButtonWrapper
                    id="appleid-signin"
                    className="signin-button"
                    data-color="black"
                    data-type="sign in"
                  />
                  <LowerPart>
                    <Spacing direction="row" size="xs">
                      <Text t1>Don't have an account? </Text>
                      <Button link transparent onClick={() => push('/signup')}>
                        <Text t1 color="main" bold>
                          Sign up now
                        </Text>
                      </Button>
                    </Spacing>
                  </LowerPart>
                </Spacing>
              </form>
            )}
          </Form>
        </ContentWrapper>
      </OnboardingContextBox>
      <FooterWrapper>
        <Button
          onClick={() =>
            window.location.assign('https://portal.ubdi.com/onboarding/email')
          }
          link
          transparent
        >
          <Text h4 color="main" bold>
            Create UBDI Business account
          </Text>
        </Button>
      </FooterWrapper>
    </LogoBackgroundScreen>
  )
}

export const SignInScreen = pipe(
  withMutation('logIn'),
  withMutation('signUp')
)(SignInScreenInner)
