import React from 'react'
import { map } from 'ramda'
import { Spacing, Text } from '../../../../components/ui'
import { CategoryCard } from './CategoryCard'
import styled from 'styled-components/macro'

const CategoryItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const Item = styled.div`
  flex-basis: 50%;
  padding: ${({ theme }) => theme.padding.xs};
`
export const CategoriesOverview = ({ categories }) => (
  <Spacing>
    <Text h5>Spending by categories</Text>

    <CategoryItems>
      {map((category, index) => (
        <Item key={`${category.label}-${index}`}>
          <CategoryCard category={category} />
        </Item>
      ))(categories)}
    </CategoryItems>
  </Spacing>
)
