import React from 'react'
import { Text } from '../../../components/ui'

export const sourcesInstructions = {
  syncAccounts: [
    <>
      Open{' '}
      <Text t2 color="main" bold inline>
        UBDI
      </Text>{' '}
      mobile app and login to your account
    </>,
    <>
      Navigate to{' '}
      <Text t2 color="main" bold inline>
        Data Bank
      </Text>{' '}
      screen
    </>,
    <>
      Scroll to the bottom of the screen and press{' '}
      <Text t2 color="main" bold inline>
        Sync Accounts
      </Text>{' '}
      button
    </>,
    <>
      Launch{' '}
      <Text t2 color="main" bold inline>
        QR Code Scanner
      </Text>{' '}
      and scan this QR Code
    </>
  ],
  linkedin: [
    <>
      Click here to{' '}
      <a
        href="https://www.linkedin.com/psettings/member-data"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text t2 color="main" inline>
          go to LinkedIn Member Data
        </Text>
      </a>
    </>,
    'Select "Download larger data archive"',
    'Press "Request Archive"',
    'Once you receive an email from LinkedIn that your archive is ready, download and unzip it',
    <>
      Upload the{' '}
      <Text t2 bold inline>
        'Ad_Targeting.csv'
      </Text>{' '}
      file and submit to continue
    </>
  ],
  netflix: [
    <>
      Click here to{' '}
      <a
        href="https://www.netflix.com/viewingactivity"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text t2 color="main" inline>
          login to Netflix
        </Text>
      </a>{' '}
      and access the{' '}
      <Text t2 bold inline>
        Viewing Activity
      </Text>{' '}
      page
    </>,
    <>
      Scroll to the bottom of the page and select{' '}
      <Text t2 bold inline>
        Download All
      </Text>
    </>,
    'Once your data is ready, download it',
    'Upload the file and submit to continue'
  ],
  amazon: [
    <>
      Click here to{' '}
      <a
        href="https://www.amazon.com/gp/b2b/reports?ref_=ya_d_l_order_reports&"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text t2 color="main" inline>
          login to Amazon
        </Text>
      </a>{' '}
      and see your{' '}
      <Text t2 bold inline>
        Order History
      </Text>
    </>,
    <>
      Select a time period that will be used to help you match and earn, select{' '}
      <Text t2 bold inline>
        Items
      </Text>{' '}
      and click{' '}
      <Text t2 bold inline>
        Request Report
      </Text>
    </>,
    <>
      Once the report is generated, click{' '}
      <Text t2 bold inline>
        Download
      </Text>
    </>,
    'Upload the file and submit to continue'
  ]
}
