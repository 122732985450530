import { propOr } from 'ramda'
import React from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components'
import { CommunityPostCard } from '../../components/CommunityPostCard'
import {
  ColorizedHeader,
  RenderFromQuery,
  Spacing,
  Text
} from '../../components/ui'
import { TribeForums } from '../../constants'
import {
  AllCommunityPollsQuery,
  UserGroupWithMemberQuery
} from '../../state/queries'
import theme from '../../theme'
import { DataFeed } from '../TribeDetails/components/DataFeed'
import { InvestorForumChart, RealInvestorForumChart } from './components/Chart'
import {
  InvestorForumSummary,
  RealInvestorForumSummary
} from './components/Summary'

const TribeDetailsWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.padding.xl};
`

const CenterPart = styled.div`
  display: flex;
  flex: 2;
  padding: ${({ theme }) => theme.padding.xl} 75px;
`

const Image = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const HeaderContentWrapper = styled(Spacing)`
  margin-left: 0;
`
const getGradient = gradient => {
  if (gradient && gradient.length > 0) {
    return `linear-gradient(-50.39deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`
  }
  return theme.gradient.gradient1
}

const renderForumSummary = ({ tribeId, forum }) => {
  switch (tribeId) {
    case TribeForums.Investors:
      return <InvestorForumSummary forum={forum} />
    case TribeForums.RealInvestors:
      return <RealInvestorForumSummary forum={forum} />
    default:
      return null
  }
}

const renderForumChart = ({ tribeId, forum, userGroup }) => {
  switch (tribeId) {
    case TribeForums.Investors:
      return (
        <InvestorForumChart
          forum={forum}
          tribeId={tribeId}
          userGroup={userGroup}
        />
      )
    case TribeForums.RealInvestors:
      return (
        <RealInvestorForumChart
          forum={forum}
          tribeId={tribeId}
          userGroup={userGroup}
        />
      )
    default:
      return null
  }
}

export const TribeForumScreen = ({
  match: { params: { tribeId, forumValue } } = {}
}) => {
  return (
    <RenderFromQuery
      query={UserGroupWithMemberQuery}
      variables={{ id: tribeId }}
      renderData={data => (
        <TribeForumComponent
          data={data}
          forumValue={forumValue}
          tribeId={tribeId}
        />
      )}
    />
  )
}

const TribeForumComponent = ({ data, forumValue, tribeId }) => {
  const { userGroup } = data
  const forum = userGroup.forums.find(({ value }) => value === forumValue)

  const { data: allCommunityPollsData } = useQuery(AllCommunityPollsQuery, {
    variables: { containingWords: [forum.value, forum.label] }
  })

  const communityPosts = propOr([], 'allCommunityPolls', allCommunityPollsData)

  const renderDataFeed = () => {
    return (
      <DataFeed
        tribeId={tribeId}
        dataQuery={forum.dataQuery}
        publicDataStreamId={userGroup.publicDataStreamId}
      />
    )
  }

  return (
    <>
      <ColorizedHeader background={getGradient(userGroup.gradient)}>
        <HeaderContentWrapper
          direction="row"
          size="l"
          align="center"
          justify="center"
        >
          <Image src={userGroup.logoUrl} />
          <Text h2 color="white" center>
            {userGroup.name}
          </Text>
        </HeaderContentWrapper>
      </ColorizedHeader>
      <TribeDetailsWrapper>
        <div style={{ flex: 1 }}></div>
        <CenterPart>
          <Spacing stretched>
            {renderForumSummary({ forum, tribeId })}
            {renderForumChart({ tribeId, forum, userGroup })}
            {communityPosts.map(post => (
              <CommunityPostCard key={`forum-${post.id}`} post={post} />
            ))}
          </Spacing>
        </CenterPart>
        <div style={{ flex: 1 }}>{renderDataFeed()}</div>
      </TribeDetailsWrapper>
    </>
  )
}
