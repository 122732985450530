import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { is } from 'ramda'
import pluralize from 'pluralize'

import { Button, Spacing, Text } from '../../../../../../components/ui'
import { ShareButton } from '../../../components'
import { DataBitWrap } from '../../DataCardBits'
import { formatLocaleNumber } from '../../../../../../util'
import { TradeCard } from '.'

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`

const TextWrap = styled.div`
  flex: 5;
`
const ShareButtonWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
`

const CardsWrap = styled.div`
  background: white;
  padding: ${({ theme }) => theme.padding.m};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-shadow: 0px 6px 8px rgba(111, 98, 255, 0.06);
  border-radius: 10px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const ExpandButtonWrap = styled.div`
  height: 30px;
  display: flex;
`

const PortfolioCard = ({ data, objectTypeId, onShare, viewOnly = false }) => {
  const {
    symbol,
    currency,
    name,
    quantity,
    averageprice,
    trades,
    quote,
    holding
  } = data

  const [isExpanded, setIsExpanded] = useState(false)
  const onToggleExpanded = () => setIsExpanded(!isExpanded)

  const renderQuantity = (quantity, symbol) => {
    if (quantity === 0) return 'Not holding'

    return `${quantity < 0 ? 'Short' : 'Long'} ${quantity} ${pluralize(
      symbol ? 'stock' : 'coin',
      quantity
    )}`
  }

  const cashSpent = quantity * averageprice
  const netLiquidationValue = quantity * quote
  const unrealizedPL = netLiquidationValue - cashSpent

  return (
    <CardsWrap disabled={viewOnly}>
      <Spacing>
        <ContentWrap>
          <TextWrap>
            <Text h4 semiBold>
              {name} ({symbol || currency})
            </Text>
          </TextWrap>
          {onShare && (
            <ShareButtonWrap>
              <ShareButton onClick={() => onShare({ data, objectTypeId })} />
            </ShareButtonWrap>
          )}
        </ContentWrap>
        <Spacing direction="row" size="xsmall">
          <DataBitWrap>
            <Text h6 color="darkBlue">
              {renderQuantity(quantity, symbol)}
            </Text>
          </DataBitWrap>
          {!isNaN(holding.cash) && (
            <DataBitWrap>
              <Text h6 color="darkBlue">
                Realized p/l:{' '}
                {formatLocaleNumber({
                  number: holding.cash,
                  currency: 'USD'
                })}
              </Text>
            </DataBitWrap>
          )}
          {!isNaN(unrealizedPL) && quantity !== 0 && (
            <DataBitWrap>
              <Text h6 color="darkBlue">
                Unrealized p/l:{' '}
                {formatLocaleNumber({
                  number: unrealizedPL,
                  currency: 'USD'
                })}{' '}
                {unrealizedPL > 0 ? (
                  <span role="img" aria-label="money-bag">
                    💰
                  </span>
                ) : (
                  <span role="img" aria-label="chart_with_downwards_trend">
                    📉
                  </span>
                )}
              </Text>
            </DataBitWrap>
          )}
        </Spacing>
        {isExpanded && (
          <TradesList
            trades={trades}
            symbol={symbol}
            objectTypeId={202}
            onShare={onShare}
          />
        )}
        {trades.length > 0 && (
          <ExpandButtonWrap>
            <Button
              block
              rounded
              outline
              link
              onClick={onToggleExpanded}
              graphic={isExpanded ? 'ChevronUp' : 'ChevronDown'}
              graphicSize={15}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </ExpandButtonWrap>
        )}
      </Spacing>
    </CardsWrap>
  )
}

const TradesList = ({ trades, symbol, onShare, objectTypeId }) => {
  const parsedTrades = is(String, trades) ? JSON.parse(trades) : trades
  return (
    <Spacing>
      <Text h5>Trades</Text>
      <Spacing separated>
        {parsedTrades.map(trade => (
          <TradeCard
            key={trade.createddate}
            data={{ ...trade, symbol }}
            hideSymbol
            {...(onShare
              ? {
                  onShare: () =>
                    onShare({ data: { ...trade, symbol }, objectTypeId })
                }
              : {})}
          />
        ))}
      </Spacing>
    </Spacing>
  )
}

export default PortfolioCard
