import React from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components/macro'

import { UserQuery } from '../../state/queries'

import { WalletSection } from './components/WalletSection'
import { OpportunitiesSection } from './components/OpportunitiesSection'
import { ExternalSurveys } from './components/ExternalSurveys'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.padding.xl};
`

const LeftPart = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.padding.xl} 0px ${theme.padding.xl} ${theme.padding.s}`};
`

const CenterPart = styled.div`
  display: flex;
  flex: 2;
  padding: ${({ theme }) => theme.padding.xl} 75px;
`

const RightPart = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: ${({ theme }) => `${theme.padding.xl} 0 ${theme.padding.xl} 0px`};
`

export const EarnScreen = () => {
  const { data: userData, loading } = useQuery(UserQuery)

  if (loading) return null

  return (
    <Container>
      <LeftPart>
        <WalletSection userData={userData} />
      </LeftPart>
      <CenterPart>
        <OpportunitiesSection />
      </CenterPart>
      <RightPart>
        <ExternalSurveys userData={userData} />
      </RightPart>
    </Container>
  )
}
