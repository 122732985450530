import React from 'react'
import styled from 'styled-components'
import { Spacing, Text } from '../../../components/ui'
import { FeeInfo } from './FeeInfo'

export const AdCard = ({ id, name, compensation, ad }) => {
  const onAdClick = () => {
    const win = window.open(
      `/adclaim/${id}`,
      'Loading',
      'toolbar=no, menubar=no, width=600, height=800, top=100, left=100'
    )
    win && win.focus()
  }
  return (
    <AdCardWrap background="white" onClick={onAdClick}>
      {ad.imageUrl && <Image src={ad.imageUrl} />}
      <ContentWrap>
        <Spacing size="s">
          <Text h6 color="dark">
            {name}
          </Text>
          <Spacing direction="row" align="center" justify="space-between">
            <Spacing size="xs" direction="row" align="center">
              <Text h7 color="main">
                {ad.hostUrl}
              </Text>
              <Text h7 color="dark1">
                ·
              </Text>
              <Text h7 color="dark1">
                Sponsored
              </Text>
            </Spacing>
            <FeeInfo lightBg={true} {...compensation} />
          </Spacing>
        </Spacing>
      </ContentWrap>
    </AdCardWrap>
  )
}

const AdCardWrap = styled.div`
  cursor: pointer;
  background: ${({ background }) => background};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: ${({ theme }) => theme.borderRadius.base};
`
const ContentWrap = styled.div`
  padding: ${({ theme }) => theme.padding.l};
`
const Image = styled.img`
  display: block;
  width: 100%;
  max-height: 215px;
  object-fit: cover;
  object-position: 0 0;
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.base} ${theme.borderRadius.base} 0 0 `};
`
