import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components/macro'
import { pathOr, pipe } from 'ramda'

import { Spacing, Text } from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { UserQuery } from '../../state/queries'
import { Notifications } from './components/Notifications'
import { UserInfo } from './components/UserInfo'
import { UserSettings } from './components/UserSettings'
import { TribesManagement } from './components/TribesManagement'
import { AccountInfo } from './components/AccountInfo'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 100px;
`
const TitleWrapper = styled.div`
  padding: 30px 0 0 130px;
`

const LeftPart = styled.div`
  display: flex;
  flex: 3;
  padding: ${({ theme }) => `0 ${theme.padding.xl}`};
`

const LeftPartContent = styled.div`
  display: flex;
  flex: 1;
  padding: 45px 40px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
`

const RightPart = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: ${({ theme }) => theme.padding.xl};
`

const UserWrapper = styled.div`
  margin-top: -30px;
`

const SpacingWrap = styled(Spacing)`
  flex: 1;
`

const UserAccountScreenInner = ({ seenAllNotifications }) => {
  const { data: { currentUser } = {}, loading, refetch } = useQuery(UserQuery)
  const [screenToShow, setScreenToShow] = useState('account')

  useEffect(() => {
    refetch()

    return () => seenAllNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return null
  const notifications = pathOr([], ['member', 'notifications'], currentUser)

  const renderContent = () => {
    switch (screenToShow) {
      case 'account':
        return <AccountInfo notifications={notifications} />
      case 'tribes':
        return <TribesManagement notifications={notifications} />
      default:
        return <Notifications notifications={notifications} />
    }
  }

  return (
    <Spacing size="l">
      <TitleWrapper>
        <Text h0>My account</Text>
      </TitleWrapper>
      <ContentWrapper>
        <LeftPart>
          <LeftPartContent>{renderContent()}</LeftPartContent>
        </LeftPart>

        <RightPart>
          <UserWrapper>
            <SpacingWrap size="xl">
              <UserInfo currentUser={currentUser} />
              <UserSettings
                setScreenToShow={setScreenToShow}
                screenToShow={screenToShow}
              />
            </SpacingWrap>
          </UserWrapper>
        </RightPart>
      </ContentWrapper>
    </Spacing>
  )
}

export const UserAccountScreen = pipe(withMutation('seenAllNotifications'))(
  UserAccountScreenInner
)
