import { useQuery } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { identity, times } from 'ramda'
import InfiniteScroll from 'react-infinite-scroller'

import { Box, Button, Spacing, Text } from '../../../components/ui'
import { DataStreamResultsQuery } from '../../../state/queries'
import { InlineTags } from '../../Home/components/CommunityPosts/components'
import { getQueryResult } from '../util'
import { LoadingBlanket } from '../../DataBank/DataStream/components'
import { TribeLeaderboardCard } from './'

const LIMIT = 5

const TribeLeaderboard = ({ tribe }) => {
  const [page, setPage] = useState(1)

  const dataLimit = page * LIMIT

  const { data, loading } = useQuery(DataStreamResultsQuery, {
    variables: {
      dataStreamId: tribe.publicDataStreamId,
      first: 200,
      isLeaderboard: true
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  })

  const results = getQueryResult(
    (data && data.dataStreamResults) || [],
    `${tribe.leaderboardDataStreamId}`
  )

  return (
    <Box size="xl" noShadow bordered>
      <Spacing size="l">
        <Text h5>Leaderboard</Text>
        {loading ? (
          <TribeLeaderboardLoading />
        ) : (
          <InfiniteScroll
            key="data-stream-leaderboard"
            hasMore={page > 1 && dataLimit < results.length}
            loadMore={page => setPage(page + 1)}
            pageStart={1}
            loader={
              <div style={{ paddingTop: 10 }}>
                <LoadingBlanket key={page} />
              </div>
            }
          >
            <Spacing size="xl" separated>
              {results.slice(0, dataLimit).map((result, index) => (
                <TribeLeaderboardCard
                  data={result}
                  index={index}
                  tribeId={tribe.id}
                />
              ))}
            </Spacing>
          </InfiniteScroll>
        )}
        {page === 1 && (
          <Button
            outline
            disabled={loading}
            rounded
            block
            onClick={() => setPage(2)}
          >
            View all
          </Button>
        )}
      </Spacing>
    </Box>
  )
}

const TribeLeaderboardLoading = () => (
  <Spacing size="xl" separated>
    {times(identity, 5).map(i => (
      <Spacing size="s">
        <Spacing size="l" direction="row" align="center">
          <Text h6>{i + 1}.</Text>
          <InlineTags.loading />
        </Spacing>
        <div style={{ paddingLeft: 25 }}>
          <div
            style={{
              width: '35%',
              height: 18,
              backgroundColor: '#DBD8FF',
              borderRadius: 5
            }}
          />
        </div>
      </Spacing>
    ))}
  </Spacing>
)

TribeLeaderboard.loading = TribeLeaderboardLoading

export default TribeLeaderboard
