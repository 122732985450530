import React from 'react'
import { useLocalStorageState } from 'react-storage-hooks'
import styled from 'styled-components'
import { Box, Graphic, Spacing, Text } from '.'
import { Notice as NoticeText } from '../../constants'

const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.color.purpleL3};
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
`

const CloseButton = styled.div`
  cursor: pointer;
`

export const DismissableNotice = ({ notice }) => {
  const [isNoticeDismissed, setIsNoticeDismissed] = useLocalStorageState(
    notice,
    null
  )

  if (isNoticeDismissed) return null

  return (
    <StyledBox size="xl" bordered noShadow>
      <Spacing direction="row" justify="space-between">
        <Spacing size="s">
          <Text h2>{NoticeText[notice].title}</Text>
          <Text t3>{NoticeText[notice].text}</Text>
        </Spacing>
        <CloseButton onClick={() => setIsNoticeDismissed(true)}>
          <Graphic name="X" size="22" themeColor="main" />
        </CloseButton>
      </Spacing>
    </StyledBox>
  )
}
