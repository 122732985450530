import React from 'react'
import styled from 'styled-components'
import { Graphic, Spacing, Text } from '../../../../../components/ui'

const ButtonWrap = styled.div`
  padding: ${({ theme }) =>
    ` ${theme.padding.xs} ${theme.padding.s} ${theme.padding.xs} ${theme.padding.xs}`};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.color.purpleL4};
  }
  margin: ${({ theme }) => `0 ${theme.padding.s} ${theme.padding.s} 0`};
`

const ImageWrap = styled.img`
  display: block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
`
const ItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const TribeSocialMedia = ({ tribe: { socialMedia = [] } }) => {
  return (
    <Spacing size="l">
      <Text h6>UBDI on social</Text>
      <ItemsWrap>
        {socialMedia.map((socialMediaItem, i) => (
          <SocialMediaItem
            key={`social-media-${i}`}
            socialMediaItem={socialMediaItem}
          />
        ))}
      </ItemsWrap>
    </Spacing>
  )
}

const SocialMediaItem = ({ socialMediaItem: { label, url, logoUrl } }) => {
  const onButtonClick = () => window.open(url)

  const GraphicComponent = ({ size }) =>
    logoUrl ? (
      <ImageWrap src={logoUrl} size={size} />
    ) : (
      <Graphic name={label} size={size} />
    )

  return (
    <ButtonWrap onClick={onButtonClick}>
      <Spacing direction="row" align="center" size="s">
        <GraphicComponent size={24} />
        <Text h6 bold>
          {label}
        </Text>
      </Spacing>
    </ButtonWrap>
  )
}
