import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Text, Graphic, Button, Spacing } from '../../../components/ui'

const width = window.innerWidth

const HeaderWrapper = styled.div`
  position: absolute;
  top: -80px;
`
const SkipWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 80px;
  width: 130px;
`

const HeadingWrapper = styled.div`
  position: absolute;
  top: 80px;
`
const GraphicWrapper = styled.div`
  position: absolute;
  top: 180px;
`
const TextHeaderWrapper = styled.div`
  position: absolute;
  width: 450px;
  top: 460px;
`
const TextWrapper = styled.div`
  position: absolute;
  width: 700px;
  top: 550px;
`
const ButtonWrapper = styled.div`
  position: absolute;
  width: 440px;
  top: 690px;
`

export class Onboarding2 extends Component {
  state = {
    login: true, // switch between Login and SignUp
    email: '',
    password: '',
    name: '',
  }

  render() {
    const { history } = this.props
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HeaderWrapper>
          <Graphic name="Header" width={width} />
        </HeaderWrapper>
        <SkipWrapper>
          <Button
            block
            style={{
              borderWidth: 0,
              backgroundColor: 'rgba(225, 225, 225, 0.2)',
              fontWeight: 'bold',
            }}
          >
            Skip
          </Button>
        </SkipWrapper>
        <HeadingWrapper>
          <Text
            color="white"
            bold
            style={{ fontSize: 50, fontFamily: 'Poppins' }}
          >
            How it Works
          </Text>
        </HeadingWrapper>
        <GraphicWrapper>
          <Graphic name="OnboardingDataBank" width={width * 0.32} />
        </GraphicWrapper>
        <TextHeaderWrapper>
          <Text center bold style={{ fontFamily: 'poppins', fontSize: 24 }}>
            Create your private data bank by linking your data sources
          </Text>
        </TextHeaderWrapper>
        <TextWrapper>
          <Text center style={{ fontFamily: 'rubik' }}>
            Our architecture gives you the only key to your encrypted data bank
            so whether it be earning money from a brand, letting your data
            compete in a challenge, or viewing others opinions on polls, you
            pick how your data is used and shared at all times
          </Text>
        </TextWrapper>
        <ButtonWrapper>
          <Spacing direction="row">
            <Button
              outline
              block
              style={{ width: '220px' }}
              onClick={() => history.push('./one')}
            >
              Previous
            </Button>
            <Button
              outline
              block
              style={{ width: '220px' }}
              onClick={() => history.push('./three')}
            >
              Next
            </Button>
          </Spacing>
        </ButtonWrapper>
      </div>
    )
  }
}
