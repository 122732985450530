import gql from 'graphql-tag'

import {
  TransactionFragment,
  OpportunityFragment,
  OptInFragment
} from '../fragments'

export const AllTransactionsQuery = gql`
  query AllTransactions {
    allTransactions {
      edges {
        node {
          id
          ...Transaction
          redeemedOpportunities {
            redeemedMonths
            firstTime
            opportunity {
              id
              ...Opportunity
            }
          }
        }
      }
    }
  }
  ${OpportunityFragment}
  ${TransactionFragment}
`

export const AllTransactionsAndAllOptInsQuery = gql`
  query AllTransactionsAndAllOptInsQuery {
    allTransactions {
      edges {
        node {
          id
          ...Transaction
        }
      }
    }
    allOptIns {
      id
      ...OptIn
    }
  }
  ${TransactionFragment}
  ${OptInFragment}
`

export const AllTransactionsAndAllOptInsExtendedQuery = gql`
  query allTransactionsAndAllOptInsExtendedQuery {
    allTransactions {
      edges {
        node {
          id
          ...Transaction
          redeemedOpportunities {
            opportunity {
              id
              ...Opportunity
            }
            firstTime
            redeemedMonths
          }
        }
      }
    }
    allOptIns {
      id
      date
      opportunity {
        id
        ...Opportunity
      }
    }
  }
  ${TransactionFragment}
  ${OpportunityFragment}
`

export const TransactionQuery = gql`
  query Transaction($transactionId: String!) {
    transaction(id: $transactionId) {
      id
      ...Transaction
      redeemedOpportunities {
        redeemedMonths
        firstTime
        opportunity {
          id
          ...Opportunity
        }
      }
    }
  }
  ${TransactionFragment}
  ${OpportunityFragment}
`
