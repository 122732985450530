/* eslint-disable jsx-a11y/no-access-key */
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import UnsplashReact, { InsertIntoApplicationUploader } from 'unsplash-react'

import { Modal, Spacing, Button } from '../../components/ui'
import { withMutation } from '../../components/wrappers'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

export const PickCoverPhoto = withMutation('updateMember')(
  ({ onDismiss, updateMember }) => {
    const [selectedCover, setSelectedCover] = useState()

    const onCoverSave = () => {
      updateMember({ variables: { coverPhotoUrl: selectedCover } }).then(
        onDismiss
      )
    }

    const onCoverPick = coverPhotoUrl => {
      setSelectedCover(coverPhotoUrl)
    }

    const onRemove = () => {
      updateMember({ variables: { coverPhotoUrl: '' } }).then(onDismiss)
    }

    return (
      <Modal
        isOpen
        onDismiss={onDismiss}
        onRequestClose={onDismiss}
        title="Pick new cover photo"
      >
        {({ onDismiss }) => (
          <ModalWrap>
            <div style={{ height: '500px' }}>
              <UnsplashReact
                accessKey="j7zsWhwu3dtlVTKosPvtiLZFpGRP8Nm1rrkaq4mXVJA"
                onFinishedUploading={onCoverPick}
                photoRatio={16 / 9}
                preferredSize={{ width: 1920, height: 1080 }}
                Uploader={InsertIntoApplicationUploader}
              />
            </div>
            <Modal.Footer>
              <Spacing
                direction="row"
                justify="space-between"
                align="center"
                stretched
              >
                <Button outline onClick={onRemove} themeColor="redNegative">
                  Remove
                </Button>
                <Spacing direction="row" justify="flex-end" align="center">
                  <Button outline onClick={onDismiss}>
                    Cancel
                  </Button>
                  <Button onClick={onCoverSave} disabled={!selectedCover}>
                    Save
                  </Button>
                </Spacing>
              </Spacing>
            </Modal.Footer>
          </ModalWrap>
        )}
      </Modal>
    )
  }
)
