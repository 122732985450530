import React from 'react'
import styled from 'styled-components/macro'
import { useQuery } from 'react-apollo'
import { prop, pathOr } from 'ramda'
import { DataSourceQuery } from '../../../state/queries'
import { getAllAccountsFromLocalStorage } from '../../../util'

import streams from './streams'

const DEFAULT_STREAM_TEMPLATE = 'standard'

const STREAM_TEMPLATE_SCHEMA = {
  yodlee: { template: 'finance' },
  appleHealth: { template: 'appleHealth' },
  robinhood: { template: 'portfolio' }
}

const StreamWrap = styled.div`
  flex: 1;
`

const DataStream = ({
  dataSourceId,
  hideInsights = false,
  onShare,
  ...props
}) => {
  const { data, loading } = useQuery(DataSourceQuery, {
    variables: { id: dataSourceId }
  })

  const accountIds = getAllAccountsFromLocalStorage(dataSourceId).map(
    prop('accountId')
  )

  const streamTemplate = pathOr(
    DEFAULT_STREAM_TEMPLATE,
    [dataSourceId, 'template'],
    STREAM_TEMPLATE_SCHEMA
  )

  const Stream = streams[streamTemplate]

  if (loading) return null

  return (
    <StreamWrap>
      <Stream
        key={dataSourceId}
        accountIds={accountIds}
        dataSource={data.dataSource}
        onShare={onShare}
        hideInsights={hideInsights}
        {...props}
      />
    </StreamWrap>
  )
}

export default DataStream
