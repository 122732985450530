import React from 'react'
import { map, pipe, filter } from 'ramda'
import { PieChart } from 'react-minimal-pie-chart'
import { getCurrencySymbol } from '../streams/util/financeHelpers'

export const FinancePieChart = ({ categories, total, currency }) => {
  const sections = pipe(
    map(category => ({
      value: category.totalAmount,
      color: category.color
    })),
    filter(item => item.value > 0)
  )(categories)

  return (
    <PieChart
      data={sections}
      lineWidth={13}
      paddingAngle={3}
      animate
      totalValue={total}
      label={() => getCurrencySymbol(Math.round(total), currency)}
      labelStyle={{
        fontSize: '13px',
        fontFamily: 'Poppins',
        fill: '#494949'
      }}
      labelPosition={0}
    />
  )
}
