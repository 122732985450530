import { gql } from 'apollo-boost'

export const SignedS3UrlQuery = gql`
  query GetSignedS3Url(
    $key: String!
    $contentType: String!
    $extension: String
    $platform: String
  ) {
    getSignedS3Url(
      key: $key
      contentType: $contentType
      extension: $extension
      platform: $platform
    ) {
      response
      responseUrl
    }
  }
`
