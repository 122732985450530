import React from 'react'
import { graphql } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks'
import { pathOr, pipe, prop, uniq } from 'ramda'

import {
  AudienceDataVerifiedTagsQuery,
  AudienceSchemaQuery,
  UserQuery
} from '../../state/queries'
import { Spacing } from '../ui'
import { withMutation } from '../wrappers'
import { TraitCategory } from './components/TraitCategory'
import { retrofitAudienceSchema } from '../../state/selectors'
import { getAnsweredQuestions } from './util'

export const TraitsCategoriesInner = ({
  profileSchema,
  data: { audienceDataVerifiedTags }
}) => {
  const { data: userData, loading } = useQuery(UserQuery)

  const audienceSchema = pathOr([], ['original'], profileSchema)
  const userTags = pathOr([], ['currentUser', 'member', 'tags'], userData)

  const allQuestions = audienceSchema.reduce(
    (acc, { uniqueId, children }) => [
      ...acc,
      ...children.map(child => ({ ...child, parent: uniqueId }))
    ],
    []
  )

  const categoriesToRender = uniq(allQuestions.map(prop('parent'))).map(
    value => {
      const category = audienceSchema.find(({ uniqueId }) => uniqueId === value)
      return {
        ...category,
        value,
        label: category.label
      }
    }
  )

  if (
    loading ||
    !profileSchema ||
    profileSchema.loading ||
    profileSchema.original.length === 0
  ) {
    return null
  }

  const tags = userTags.reduce(
    (acc, { uniqueId, values }) => ({ ...acc, [uniqueId]: values }),
    {}
  )
  const answeredQuestions = getAnsweredQuestions(tags, profileSchema)

  return (
    <Spacing size="s">
      {categoriesToRender.map(category => {
        return (
          <TraitCategory
            key={category.value}
            tags={tags}
            category={category}
            answeredQuestions={answeredQuestions}
            audienceDataVerifiedTags={audienceDataVerifiedTags}
            profileSchema={profileSchema}
          />
        )
      })}
    </Spacing>
  )
}

export const TraitsCategories = pipe(
  withMutation('updateMember'),
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema }),
  graphql(AudienceDataVerifiedTagsQuery)
)(TraitsCategoriesInner)
