import React, { PureComponent } from 'react'
import styled from 'styled-components/macro'
import { Route, Switch } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'
import { head, last, pipe, split } from 'ramda'

import { StepLoading } from './components/StepLoading'

import * as StepsComponents from './steps'

// Animations
const RouteContainer = posed.div()

const StepLoadingWrapper = styled.div`
  position: absolute;
  width: 220px;
  top: 650px;
`

// Steps
const steps = [
  ['one', 'OnboardingOne'],
  ['two', 'Onboarding2'],
  ['three', 'Onboarding3'],
  ['four', 'Onboarding4'],
  ['five', 'Onboarding5'],
  ['six', 'Onboarding6']
]

export class OnboardingScreen extends PureComponent {
  static defaultProps = {
    steps
  }

  componentDidMount() {
    const { location, history } = this.props

    if (!this._stepIds.includes(getRouteKey(location.pathname)))
      history.replace(this._getStepUrl(head(this._stepIds)))
  }

  render() {
    const { location, steps } = this.props

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <PoseGroup preEnterPose="preEnter">
          <RouteContainer key={location.pathname}>
            <Switch location={location}>
              {this._renderSteps()}
              <Route render={() => <div>Invalid step</div>} />
            </Switch>
          </RouteContainer>
        </PoseGroup>
        <StepLoadingWrapper>
          <StepLoading
            total={steps.length}
            current={this.currentStepIndex}
            onStepChange={this._goToStepIndex}
          />
        </StepLoadingWrapper>
      </div>
    )
  }

  _renderSteps() {
    const { match, steps } = this.props
    return steps.map(([stepId, stepComponent]) => {
      const StepComponent = StepsComponents[stepComponent]
      return (
        <Route
          path={`${match.url}/${stepId}`}
          render={props => <StepComponent {...props} />}
          key={stepId}
        />
      )
    })
  }

  _goToStepIndex = stepIndex => () => {
    const {
      steps,
      history: { push }
    } = this.props

    if (stepIndex < 0) return
    if (stepIndex === steps.length) return this._onComplete()

    push(this._getStepUrl(steps[stepIndex][0]))
  }

  _getStepUrl = stepId => `${this.props.match.url}/${stepId}`

  _onComplete = () => this.props.history.push('/signin')

  get _stepIds() {
    return this.props.steps.map(([id, component]) => id)
  }

  get currentStepIndex() {
    const { location } = this.props
    return this._stepIds.indexOf(getRouteKey(location.pathname))
  }
}

const getRouteKey = pipe(split('/'), last)
