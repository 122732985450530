import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, Text } from '../../../../components/ui'
import { DataStreamWrapper } from '../../components/DataStreamWrapper'

const Wrapper = styled.div`
  text-align: center;
`

const StyledImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
`

export const LoadingBlanket = ({
  text,
  animationSource = require('../../../../components/ui/Graphic/animations/dot-loader.gif')
}) => {
  return (
    <Wrapper>
      <DataStreamWrapper>
        <Spacing>
          <StyledImage src={animationSource} />
          {text && (
            <Text t1 center>
              {text}
            </Text>
          )}
        </Spacing>
      </DataStreamWrapper>
    </Wrapper>
  )
}
