import { pipe, prop, sortBy } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Spacing, Text } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { NotificationCard } from './NotificationCard'
import { NotificationPreferences } from './NotificationPreferences'

const NotificationsWrapper = styled.div`
  flex: 1;
`

const ListWrap = styled.div`
  padding-top: ${({ theme }) => theme.padding.m};
`

const ButtonWrap = styled(Button)`
  height: 41px;
  width: 137px;
  padding: 10px 25px;
`

const EmptyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.padding.xxxl};
`

const TextWrap = styled(Text)`
  color: ${({ theme }) => theme.color.dark};
  opacity: 0.5;
`

const NotificationsInner = ({ notifications, clearAllNotifications }) => {
  const [
    showNotificationPreferences,
    setShowNotificationPreferences
  ] = useState(false)

  const onClearAllNotifications = () =>
    clearAllNotifications().then(({ userErrors }) => {
      if (userErrors) return userErrors
    })

  const sortedNotifications = sortBy(prop('seen'))(notifications)

  const renderContent = () => {
    if (notifications.length === 0) {
      return (
        <EmptyWrap>
          <TextWrap t1>No new notifications</TextWrap>
        </EmptyWrap>
      )
    }
    return (
      <Spacing size="l">
        {sortedNotifications.map(notification => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
      </Spacing>
    )
  }

  return (
    <NotificationsWrapper>
      {!showNotificationPreferences ? (
        <Spacing size="xl">
          <Spacing direction="row" justify="space-between" align="center">
            <Text h2>Notifications ({notifications.length})</Text>
            <Spacing direction="row" size="s">
              <ButtonWrap
                outline
                onClick={() => setShowNotificationPreferences(true)}
              >
                Preferences
              </ButtonWrap>
              <ButtonWrap onClick={onClearAllNotifications}>
                Clear All
              </ButtonWrap>
            </Spacing>
          </Spacing>
          <ListWrap>{renderContent()}</ListWrap>
        </Spacing>
      ) : (
        <NotificationPreferences
          setShowNotificationPreferences={setShowNotificationPreferences}
        />
      )}
    </NotificationsWrapper>
  )
}

export const Notifications = pipe(withMutation('clearAllNotifications'))(
  NotificationsInner
)
