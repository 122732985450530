import {
  filter,
  find,
  intersection,
  isNil,
  map,
  pathEq,
  pipe,
  prop,
  propOr,
  reduce,
  sortBy,
  uniq,
  unnest
} from 'ramda'
import { getAvailableServiceProviders } from '../../state/local/serviceProviders'

export const getMissingSourcesAndTagsForTribe = ({
  tags,
  audienceDataVerifiedTags,
  tribe,
  linkedDataSources = [] // in case when you dont need missingSources
}) => {
  const tribeTags = audienceDataVerifiedTags.filter(({ uniqueId }) =>
    uniqueId.startsWith(tribe.id)
  )

  const allTribeSources = uniq(
    unnest(
      tribeTags.map(({ queries }) => queries.map(query => query.dataSourceIds))
    )
  )

  const missingSources = allTribeSources.filter(
    sources =>
      !sources.find(source => isSourceLinked(source, linkedDataSources))
  )

  const missingTags = findMissingTags(tags, tribe.requiredTags)

  return { missingTags, missingSources, allTribeSources }
}

export const findMissingTags = (tags, requiredTags = []) => {
  const userTags = tags
    .filter(({ values }) => values && values.length > 0)
    .map(prop('uniqueId'))

  if (!requiredTags) return []

  return requiredTags.filter(
    tag => !userTags.find(userTag => userTag.includes(tag))
  )
}

export const isSourceLinked = (id, sources) =>
  pipe(
    find(pathEq(['dataSource', 'id'], id)),
    linked => !isNil(linked)
  )(sources)

export const getDataSources = (sources, membersLinkedDataSources) =>
  pipe(
    map(({ node }) => ({
      ...node,
      availableServiceProviders: getAvailableServiceProviders(node.id),
      linked: isSourceLinked(node.id, membersLinkedDataSources)
    })),
    filter(({ availableServiceProviders }) => {
      return availableServiceProviders && availableServiceProviders.length > 0
    }),
    sortBy(prop('name')),
    sortBy(prop('linked'))
  )(sources)

export const getQuery = queryId => find(({ query: { id } }) => id === queryId)

export const getQueryResult = (opportunityResults = [], queryId) => {
  return pipe(
    getQuery(queryId),
    propOr([], 'results'),
    map(reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}))
  )(opportunityResults)
}

const findCurrentTribeSettings = (currentUser, tribeId) => {
  if (currentUser.member && currentUser.member.tribeSettings) {
    const tribeSettings = find(tribe => tribe.tribeId === tribeId)(
      currentUser.member.tribeSettings
    )
    return tribeSettings
  }
  return null
}

export const getMemberPublicTags = (currentUser, tribeId) => {
  const tribeSettings = findCurrentTribeSettings(currentUser, tribeId)
  if (tribeSettings) {
    return tribeSettings.publicTags
  }
  return []
}

const containsAtLeastOneOfTags = tags => tag =>
  tags[tag.uniqueId] &&
  tag.values &&
  intersection(tag.values, tags[tag.uniqueId]).length > 0

export const canJoinTribe = ({ tags, requiredTagValues = [] }) => {
  if (!requiredTagValues) return true
  const matchingRequiredTags = requiredTagValues.filter(
    containsAtLeastOneOfTags(tags)
  )
  return matchingRequiredTags.length === requiredTagValues.length
}
