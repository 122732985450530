import { gql } from 'apollo-boost'

export const DataStreamResultsQuery = gql`
  query dataStreamResults(
    $dataStreamId: String!
    $first: Int
    $after: Int
    $variables: [GenericVariableInput]
    $isLeaderboard: Boolean
    $dataQuery: DataQueryInput
  ) {
    dataStreamResults(
      dataStreamId: $dataStreamId
      variables: $variables
      after: $after
      first: $first
      isLeaderboard: $isLeaderboard
      dataQuery: $dataQuery
    ) {
      dataStreamId
      results {
        key
        value
      }
      query {
        id
        sql
        objectTypeId
      }
    }
  }
`
