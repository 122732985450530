import React from 'react'
import Lottie from 'lottie-web-react'

import { Graphic, Text, Spacing } from '../../ui'

const RUNNING_ANIMATION_PATH = require('../../../animations/sync-running-completed.json')
const RUNNING_INITIAL_ANIMATION_PATH = require('../../../animations/sync-running-initial.json')

const SyncStateIcon = ({ syncState, size = 34, height }) => {
  switch (syncState) {
    case 'completed':
      return <CompletedAnimation size={size} height={height} />
    case 'failed':
      return <Graphic name="SyncStateFailed" size={size} height={height} />
    case 'running':
      return <RunningAnimation size={size} height={height} />
    case 'needs-resync':
      return (
        <Spacing direction="row" size="s" align="center">
          <Text h7 color="greyL1">
            sync
          </Text>
          <Graphic
            name="Sync"
            size={size - 5}
            height={height - 5}
            themeColor="grey"
          />
        </Spacing>
      )
    default:
      console.log(`Unknown digi.me Sync State: ${syncState}`)
      return null
  }
}

const RunningAnimation = ({ size, height }) => {
  const source = RUNNING_INITIAL_ANIMATION_PATH

  return (
    <div style={{ width: size, height }}>
      <Lottie
        options={{
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: source
        }}
        playSegments={{ segments: [0, 10], forceFlag: true }}
        playingState="play"
      />
    </div>
  )
}

const CompletedAnimation = ({ size, height }) => {
  const source = RUNNING_ANIMATION_PATH

  return (
    <div style={{ width: size, height }}>
      <Lottie
        options={{
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: source
        }}
        playingState="play"
      />
    </div>
  )
}

export default SyncStateIcon
