import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroller'
import { isEmpty, propOr } from 'ramda'

import { Box, Graphic, Spacing, Text } from '../../../../components/ui'
import { AllCommunityPollsQuery } from '../../../../state/queries'
import { CommunityPostCard } from '../../../../components/CommunityPostCard'
import { CreatePost } from './components'
import { CommunityStatistics } from '../../components'

const LIMIT = 10

const defaultFetchVariables = {
  first: LIMIT,
  withCompetitions: true
}

export const CommunityPosts = ({
  tribeId,
  memberId,
  viewOnly = false,
  profilePosts = false
}) => {
  const [hasReceivedEmptyList, setHasReceivedEmptyList] = useState(false)
  const fetchVariables = {
    ...defaultFetchVariables,
    ...(tribeId ? { tribeId } : {}),
    ...(memberId ? { memberId } : {})
  }

  const { data: allCommunityPollsData, loading, fetchMore } = useQuery(
    AllCommunityPollsQuery,
    {
      variables: { ...fetchVariables }
    }
  )

  if (loading) return renderLoading(profilePosts)

  const data = propOr([], 'allCommunityPolls', allCommunityPollsData)

  const onEndReached = () => {
    if (loading || hasReceivedEmptyList) return

    fetchMore({
      variables: {
        ...fetchVariables,
        after: data.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          allCommunityPolls: [
            ...prev.allCommunityPolls,
            ...fetchMoreResult.allCommunityPolls
          ]
        })
      }
    }).then(({ data }) => {
      const allCommunityPolls = propOr([], 'allCommunityPolls', data)
      if (isEmpty(allCommunityPolls)) setHasReceivedEmptyList(true)
    })
  }

  if (isEmpty(data)) return renderEmpty()

  return (
    <Spacing size="l" stretched>
      <InfiniteScroll
        hasMore={!hasReceivedEmptyList}
        loadMore={onEndReached}
        loader={<Loader key="loader" />}
        key="posts"
      >
        <Spacing size="s">
          {data.map(post => (
            <CommunityPostCard key={post.id} post={post} viewOnly={viewOnly} />
          ))}
        </Spacing>
      </InfiniteScroll>
    </Spacing>
  )
}

const Wrapper = styled.div`
  padding: 20px 10px;
  text-align: center;
`

const Loader = () => (
  <Wrapper>
    <Graphic name="Loading" width={150} height={60} themeColor="main" />
  </Wrapper>
)

const renderEmpty = () => (
  <Box size="l" noShadow bordered>
    <Text t3 center>
      No posts to show. :(
    </Text>
  </Box>
)

const renderLoading = profilePosts => (
  <Spacing size="l" stretched>
    <CommunityStatistics />
    {!profilePosts && <CreatePost />}
    <Loader />
  </Spacing>
)
