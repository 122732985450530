import { gql } from 'apollo-boost'
import { DataSourceFragment } from './DataSource'

export const MemberFragment = gql`
  fragment Member on Member {
    id
    referralCode
    paymentDashboardUrl
    hyperwallet {
      id
      kycHash
      verificationStatus
    }
    uphold {
      email
    }
    completedOpportunities
    tosAccepted
    totalUsd
    totalUbdi
    referralCode
    referralLink
    coverPhotoUrl
    subscribedNotificationTopics {
      newEarnOpportunity
      newFollowers
      newPosts
      liveCommunityPoll
      commentCommunityPoll
      reactCommunityPoll
      voteCommunityPoll
      replyCommentCommunityPoll
    }
    tribeSettings {
      id
      tribeId
      publicTags
    }
    notifications {
      id
      createdAt
      subject
      contentUrl
      seen
    }
    optedInDataStreams
    linkedDataSources {
      disabled
      dateLinked
      dataSource {
        id
        ...DataSource
      }
      accounts {
        providerId
        accountId
      }
    }
    tags {
      uniqueId
      values
    }
    finalAccountSyncDone {
      webapp
    }
    pollfishConsent
    optedInDataStreams
    profileColor
    profileUsername
    isCustomUsername
    profileDescription
    profileImage {
      thumbnail
    }
    totalComments
    totalPosts
    publicTags
    arePostsPublic
    followingCount
    followersCount
    publicProfileSourceOptIns {
      dataSourceId
      accountIds
    }
    referralStats {
      earned {
        totalUsd
        totalUbdi
      }
      reward {
        totalUsd
        totalUbdi
      }
      maxUsers
      pendingUsers
      completedUsers
      hasClaimedReferral
      hasRedeemedReferral
    }
    earnPreferences {
      study
      ad
    }
  }
  ${DataSourceFragment}
`
