import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import theme from '../../../theme'

export const Box = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme, size }) => theme.padding[size]};

  background-color: ${({ theme, backgroundColor }) =>
    theme.color[backgroundColor]};

  ${({ theme, bordered }) =>
    bordered &&
    css`
      border: 1px solid ${theme.color.purpleL2};
    `};

  ${({ theme, noShadow }) =>
    !noShadow &&
    css`
      box-shadow: ${theme.boxShadow.large};
    `};

  ${({ link }) =>
    link &&
    css`
      cursor: pointer;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const propOptions = {
  size: Object.keys(theme.padding),
  backgroundColor: Object.keys(theme.color)
}

Box.defaultProps = {
  size: 'l',
  backgroundColor: 'white',
  noShadow: false
}

Box.propTypes = {
  size: PropTypes.oneOf(propOptions.size),
  backgroundColor: PropTypes.oneOf(propOptions.backgroundColor),
  noShadow: PropTypes.bool
}
