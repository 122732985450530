const numberWithCommas = x => {
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatLocaleNumber = ({ number, currency }) => {
  const options = {
    minimumFractionDigits: currency === 'USD' ? 2 : 0
  }

  try {
    return new Intl.NumberFormat(undefined, options).format(number)
  } catch (e) {
    return numberWithCommas(number)
  }
}

export const FormatNumber = ({ children, ...props }) => {
  if (typeof children !== 'number') {
    throw new Error(
      `Provided child for Number component is not number: ${children}`
    )
  }

  const localeNumber = formatLocaleNumber({
    number: children,
    currency: props.currency
  })

  const getPrefix = () => {
    if (props.prefix) return prefix
    if (props.currency) return currencyMap[props.currency]
    return null
  }

  const currencyMap = {
    USD: '$'
  }

  const prefix = getPrefix()

  return prefix ? prefix + localeNumber : localeNumber
}
