import { gql } from 'apollo-boost'

export const PollFragment = gql`
  fragment Poll on Poll {
    id
    question
    options {
      id
      choice {
        text
        gifUrl
      }
      voted
      votesPerCategory(tags: { and: [] }) {
        uniqueId
        count
      }
      totalVotes
    }
    voted
    totalVotes
  }
`
