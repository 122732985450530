import { parse, getUnixTime, isValid } from 'date-fns'
import { difference, equals } from 'ramda'

const isInputInvalid = (firstRow) => {
  const expectedInput = ['Title', 'Date']

  if (!equals(expectedInput, firstRow)) {
    return difference(expectedInput, firstRow)
  }

  return false
}

const convertIntoObject = () => (row) => {
  /*
    4311 Watch History

    name -- [0]
    createddate -- [1]
  */

  const parseSeriesName = (rawName) => {
    const [name, season, episode] = rawName.split(': ')

    if (name && season && episode) {
      return {
        name,
        season,
        episode,
        originalname: rawName,
      }
    }

    return { originalname: rawName, name: rawName }
  }

  const parseRawDate = (rawDate) => {
    const possibleFormats = ['M/d/yy', 'dd/MM/yyyy', 'd/M/y']
    return possibleFormats
      .map((possibleFormat) => parse(rawDate, possibleFormat, new Date()))
      .filter(isValid)[0]
  }

  const [rawName, rawCreatedDate] = row
  const createddate = getUnixTime(parseRawDate(rawCreatedDate)) * 1000

  return {
    ...parseSeriesName(rawName),
    createddate,
  }
}

export default {
  isInputInvalid,
  convertIntoObject,
}
