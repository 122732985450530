import React from 'react'
import styled from 'styled-components'
import { pipe, path, isNil } from 'ramda'
import { Button, Text } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { useModal } from '../../../hooks'
import { SetupUphold } from './SetupUphold'

const renderUpholdNotice = ({ openModal, currentUser, initPayout }) => {
  const upholdEmail = path(['member', 'uphold', 'email'], currentUser)
  const hyperwalletId = path(['member', 'hyperwallet', 'id'], currentUser)
  const totalUsd = path(['member', 'totalUsd'], currentUser)

  const showUpholdNotice = isNil(upholdEmail) && isNil(hyperwalletId)

  if (showUpholdNotice) {
    return <SetupUphold />
  }
  return (
    <ButtonWrap
      block
      onClick={() => openModal('CashWithdraw', { currentUser, initPayout })}
      disabled={totalUsd === 0}
    >
      <Text h6 color="main" center>
        Withdraw Cash
      </Text>
    </ButtonWrap>
  )
}

const WalletNoticeInner = ({
  showUphold = true,
  currentUser,
  initPayout,
  connectUphold
}) => {
  const { openModal } = useModal()

  if (showUphold) {
    return renderUpholdNotice({
      openModal,
      currentUser,
      initPayout,
      connectUphold
    })
  }

  return null
}

export const WalletNotice = pipe(withMutation('initPayout'))(WalletNoticeInner)

const ButtonWrap = styled(Button)`
  height: 34px;
  padding: ${({ theme }) => `0 ${theme.padding.l}`};
  border: ${({ theme }) => theme.color.main} 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  background: ${({ theme }) => theme.color.white};
`
