import React, { useState } from 'react'
import styled from 'styled-components'
import { Text } from '../Text'

export const Tabs = ({ children, forceShowHeader = false }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const tabsChildren = children.filter(i => !!i)

  const hasMultipleTabs = tabsChildren.length > 1
  return (
    <Wrap>
      {(hasMultipleTabs || forceShowHeader) && (
        <TabsHeader>
          {tabsChildren.map(({ props }, i) => {
            const isSelected = i === selectedTabIndex
            return (
              <TabHeader
                key={props.title}
                onClick={() => setSelectedTabIndex(i)}
                isSelected={isSelected}
              >
                <Text h5 color={isSelected ? 'main' : 'purpleL1'} center>
                  {props.title}
                </Text>
              </TabHeader>
            )
          })}
        </TabsHeader>
      )}
      {hasMultipleTabs ? tabsChildren[selectedTabIndex] : tabsChildren}
    </Wrap>
  )
}

Tabs.Tab = ({ children }) => {
  return children
}

const Wrap = styled.div`
  flex: 1;
`

const TabsHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.padding.xl};
  flex: 1;
`

const TabHeader = styled.div`
  flex: 1;
  cursor: pointer;
  padding: ${({ theme }) => theme.padding.m};
  border-bottom: 1px solid
    ${({ theme, isSelected }) => theme.color[isSelected ? 'main' : 'purpleL2']};
  transition: border 0.2s ease-in-out;
`
