import axios from 'axios'
import { isEmpty, isNil } from 'ramda'

import { SignedS3UrlQuery } from '../../state/queries'
import { client } from '../../state/apollo'
import { isImg } from './photo'

export const getVideo = async ({ media }) => {
  if (!isNil(media) && !isEmpty(media) && !isImg(media)) {
    try {
      const {
        data: { getSignedS3Url }
      } = await client.query({
        query: SignedS3UrlQuery,
        variables: {
          key: 'communityPolls/video',
          contentType: 'multipart/form-data',
          extension: 'mp4',
          platform: 'webapp'
        },
        fetchPolicy: 'network-only'
      })

      const { response, responseUrl } = getSignedS3Url

      await uploadVideo({
        video: media,
        signedUrl: JSON.parse(response)
      })

      return responseUrl
    } catch (error) {
      console.log('Error uploading video: ', error)
      throw error
    }
  }

  return null
}

export const uploadVideo = ({ video, signedUrl }) =>
  new Promise((resolve, reject) => {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      const response = axios.put(signedUrl, video.file, options)
      resolve(response)
    } catch (error) {
      console.log('Error uploading to signed url:', error.response.data)
      reject(error)
    }
  })
