import React from 'react'
import { pathOr, propOr } from 'ramda'
import styled from 'styled-components/macro'
import { format, differenceInCalendarDays } from 'date-fns'
import pluralize from 'pluralize'

import { Formats } from '../../../../../constants'
import { Spacing, Text } from '../../../../../components/ui'
import { InfoPillWrap } from './InfoPillWrap'
import { VoteButtons } from './VoteButtons'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { CompetitionDataLeaderboardQuery } from '../../../../../state/queries'
import { getNumberWithSufix } from '../../../../../util/numberSuffix'

const StyledImage = styled.img`
  height: 30px;
  width: 30px;
  object-fit: contain;
`

const PostInfo = ({
  post: {
    id,
    createdAt,
    totalComments,
    tribe: { logoUrl: tribeLogoUrl },
    poll,
    competition,
    reactions,
    viewCount
  },
  condensed = false
}) => {
  const { dataStreamId, id: competitionId } = competition || {}
  const history = useHistory()

  const { data } = useQuery(CompetitionDataLeaderboardQuery, {
    variables: { id: competitionId },
    skip: !dataStreamId || !condensed
  })

  const totalVotes = propOr(0, 'totalVotes', poll)
  const totalEntries = dataStreamId
    ? pathOr(0, ['competitionDataLeaderboard', 'totalEntries'], data)
    : propOr(0, 'totalEntries', competition)

  return (
    <Spacing size="s">
      <Spacing direction="row" justify="space-between" align="center">
        <Spacing direction="row" align="center" size="s">
          <InfoPillWrap
            graphicName="Views"
            graphicSize={18}
            onClick={() =>
              history.push(`/${competition ? 'competition' : 'post'}/${id}`, {
                scrollToComments: false
              })
            }
          >
            {getNumberWithSufix(viewCount)}
          </InfoPillWrap>
          <VoteButtons id={id} reactions={reactions} />
          {(poll || (competition && (condensed || !dataStreamId))) && (
            <InfoPillWrap
              graphicName={competition ? 'Entry' : 'Answers'}
              graphicColor="#08E696"
              onClick={() =>
                history.push(`/${competition ? 'competition' : 'post'}/${id}`)
              }
            >
              {competition ? totalEntries : totalVotes}
            </InfoPillWrap>
          )}
          <InfoPillWrap
            graphicName="Comments"
            graphicColor="#FFB36D"
            onClick={() =>
              history.push(`/${competition ? 'competition' : 'post'}/${id}`, {
                scrollToComments: true
              })
            }
          >
            {totalComments}
          </InfoPillWrap>
        </Spacing>
        <Spacing direction="row" align="center">
          {createdAt && (
            <Text t2 brandBlack>
              {competition
                ? getDurationInfo(competition.duration)
                : format(new Date(parseInt(createdAt)), Formats.dates.imperial)}
            </Text>
          )}

          <StyledImage src={tribeLogoUrl} resizeMode="contain" />
        </Spacing>
      </Spacing>
    </Spacing>
  )
}

const getDurationInfo = duration => {
  const numberOfDays = differenceInCalendarDays(
    new Date(parseInt(duration.endDate)),
    new Date()
  )

  if (numberOfDays < 0)
    return `Created at ${format(
      new Date(parseInt(duration.startDate)),
      Formats.dates.imperial
    )}`

  return `${numberOfDays} ${pluralize('day', numberOfDays)} left`
}

export default PostInfo
