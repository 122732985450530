import React, { useContext, useEffect } from 'react'
import { formatLocaleNumber } from '../../../../util'

import { prop, isEmpty, isNil, propOr } from 'ramda'
import pluralize from 'pluralize'
import styled from 'styled-components/macro'
import { Text } from '../../../../components/ui'
import { getDataBits } from '../util'
import humanizeDuration from 'humanize-duration'
import { convertUnit } from '../streams/util/convertUnit'
import { format } from 'date-fns'
import { Formats } from '../../../../constants'
import {
  PriceContext,
  PRICE_PROVIDERS
} from '../../../../contexts/priceContext'

const WrapBits = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const DataBitWrap = styled.div`
  border-radius: 5px;

  background-color: ${({ theme, color }) => theme.color[color || 'lightBlue']};
  padding: ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.m};

  margin-right: ${({ theme }) => theme.padding.s};
  margin-bottom: ${({ theme }) => theme.padding.s};
`

const getSpotifyDurationHumanized = value =>
  humanizeDuration(value, {
    language: 'shortEn',
    languages: {
      shortEn: {
        h: () => ':',
        m: () => ':',
        s: () => ''
      }
    },
    spacer: '',
    serialComma: false,
    conjunction: ' ',
    round: true
  }).replace(/\s/g, '')

const renderValueUnit = ({ value, fieldMapping, dataSourceId, fields }) => {
  const bestUnit = !isNil(fieldMapping.unit)
    ? convertUnit(value, fieldMapping.unit)
    : `${fieldMapping.displayName} ${value}`

  switch (fieldMapping.name) {
    case 'duration':
      switch (dataSourceId) {
        case 'spotify':
          return getSpotifyDurationHumanized(value)

        default:
          return humanizeDuration(value * 1000, {
            units: ['h', 'm'],
            round: true
          })
      }

    case 'elevationgain':
      return `${bestUnit} elevation`
    case 'basetype': {
      switch (dataSourceId) {
        case 'coinbase':
        case 'binance':
        case 'robinhood':
        case 'gemini':
        case 'uphold':
          return value === 'CREDIT' ? 'Buy' : 'Sell'
        default:
          return ''
      }
    }
    case 'likecount':
    case 'resharecount':
    case 'dislikecount':
    case 'viewcount':
      return `${formatLocaleNumber({ number: parseInt(value) })} ${pluralize(
        fieldMapping.unit,
        value
      )}`

    case 'itemtotal':
    case 'currency':
    case 'paymentinstrument':
      return value

    case 'steps':
      return `${formatLocaleNumber({ number: parseInt(value) })} ${
        fieldMapping.unit
      }`

    case 'activitycalories':
      return `${formatLocaleNumber({ number: parseFloat(value).toFixed(2) })} ${
        fieldMapping.unit
      }`

    case 'heartpoints':
      return `${parseInt(value)} ${fieldMapping.unit}`

    case 'veryactiveminutes':
      return humanizeDuration(value * 60 * 1000, {
        units: ['h', 'm'],
        round: true
      })
    case 'releasedate':
      return `${fieldMapping.displayName}: ${format(
        new Date(value),
        Formats.dates.medium
      )}`
    case 'explicit':
      return value ? 'Explicit' : ''
    default:
      return bestUnit
  }
}

export const NO_OF_DATABITS_DEFAULT = 5

export const DataCardBits = ({
  fields,
  objectType,
  dataSourceId,
  dataSeparator,
  isExpanded
}) => {
  const valuableBits = getDataBits({ fields, objectType }).filter(prop('value'))

  return (
    <WrapBits>
      {!isEmpty(valuableBits) ? (
        valuableBits.map(({ value, fieldMapping }) => {
          const bitsValues = dataSeparator
            ? value.split(dataSeparator)
            : [value]

          const dataBitsValues = isExpanded
            ? bitsValues
            : bitsValues.splice(0, NO_OF_DATABITS_DEFAULT)

          return dataBitsValues.map((dataBitValue, index) => (
            <DataBit
              key={`${fieldMapping.name}-${index}`}
              objectTypeId={objectType.id}
              fieldMapping={fieldMapping}
              value={dataBitValue}
              fields={fields}
              dataSourceId={dataSourceId}
            />
          ))
        })
      ) : (
        <DataBit noData>No data</DataBit>
      )}
    </WrapBits>
  )
}

export const DataBit = ({
  noData,
  objectTypeId,
  fieldMapping,
  value,
  fields,
  dataSourceId
}) => {
  if (noData) {
    return (
      <DataBitWrap>
        <Text h6>No data</Text>
      </DataBitWrap>
    )
  }

  if (
    objectTypeId === 201 &&
    fieldMapping.name === 'currency' &&
    (fields.basetype === 'CREDIT' || fields.side === 'buy')
  ) {
    return <DataBitWithCryptoPrice {...fields} />
  }

  return (
    <DataBitWrap>
      <Text h6 color={!noData ? 'darkBlue' : undefined}>
        {renderValueUnit({
          value,
          fieldMapping,
          dataSourceId,
          fields
        })}
      </Text>
    </DataBitWrap>
  )
}

const DataBitWithCryptoPrice = ({ currency, amount, nativeamount, type }) => {
  const { fetchPrice, prices } = useContext(PriceContext)
  const ticker = `${currency}USDT`

  useEffect(() => {
    fetchPrice(ticker, PRICE_PROVIDERS.binance)
  }, [ticker, fetchPrice])

  if (type === 'send') return null

  const price = propOr(0, ticker, prices)
  const currentValue = amount * price
  const nativeAmount = parseFloat(nativeamount)

  const percentageChange = ((currentValue - nativeAmount) / nativeAmount) * 100
  const color = percentageChange <= 0 ? 'redNegative' : 'green'
  const roi = percentageChange.toFixed(2)

  if (!roi || isNaN(roi)) return null
  return (
    <DataBitWrap color={color}>
      <Text h6 color="white">
        ROI: {roi}%
      </Text>
    </DataBitWrap>
  )
}
