import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { filter, map, pathOr, pipe, prop } from 'ramda'
import styled from 'styled-components'
import { Text } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { AllUserGroupsWithMemberQuery } from '../../../state/queries'
import { checkIsTribeMember } from '../../Home/components/Tribes/util'
import { TribeCard } from './TribeCard'
import { TribeSettings } from '../../../components/TribeSettings'

const ManagementWrapper = styled.div`
  flex: 1;
`

const EmptyWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const TribesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ManagementInner = () => {
  const [editTribe, setEditTribe] = useState()
  const { data, loading } = useQuery(AllUserGroupsWithMemberQuery)

  if (loading) return null

  const allTribes = pipe(
    pathOr([], ['allUserGroups', 'edges']),
    map(prop('node'))
  )(data)

  const member = pathOr({}, ['currentUser', 'member'])(data)

  const tribesToRender = filter(({ id }) =>
    checkIsTribeMember({ tribeId: id, member })
  )(allTribes)

  const onTribeSettings = tribe => setEditTribe(tribe)

  if (!tribesToRender || tribesToRender.length === 0) {
    return (
      <EmptyWrap>
        <Text h3>You don't have any tribes</Text>
      </EmptyWrap>
    )
  }
  return (
    <ManagementWrapper>
      {editTribe ? (
        <TribeSettings
          tribe={editTribe}
          showHeader
          setEditTribe={setEditTribe}
        />
      ) : (
        <TribesWrap>
          {tribesToRender.map(tribe => (
            <TribeCard
              key={tribe.id}
              tribe={tribe}
              onTribeSettings={onTribeSettings}
            />
          ))}
        </TribesWrap>
      )}
    </ManagementWrapper>
  )
}

export const TribesManagement = pipe(withMutation('clearAllNotifications'))(
  ManagementInner
)
