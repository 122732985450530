import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import theme from '../../../theme'
import { Box, Graphic, Spacing, Text } from '../../../components/ui'
import { FeeInfo } from './FeeInfo'

export const StudyCard = ({
  id,
  name,
  shortDescription,
  compensation,
  isJoinable, // Checks if you meet minimum criteria to join
  reach,
  gradientBg = theme.gradient.gradient1,
  inverse,
  hideFeeInfo
}) => {
  const { push } = useHistory()

  const backgroundBg = inverse ? ['#fff', '#fff'] : gradientBg
  const textColor = inverse ? 'dark' : 'white'

  const onStudyClick = () => push(`/earn/${id}`)

  return (
    <Box style={{ padding: 0 }}>
      <StudyCardWrap background={backgroundBg} onClick={onStudyClick}>
        <Spacing size="s">
          <Spacing direction="row" justify="space-between">
            <Text h2 color="white">
              {name}
            </Text>
            {!isJoinable && (
              <Graphic name="Lock" size={40} color="transparent" />
            )}
          </Spacing>
          <Spacing size="l">
            {shortDescription && (
              <Text t3 color={textColor}>
                {shortDescription}
              </Text>
            )}
            <Spacing direction="row" justify="space-between" align="center">
              {!hideFeeInfo && <FeeInfo lightBg={inverse} {...compensation} />}
              <Spacing direction="row" size="s" align="center">
                <Graphic name="Users" size={20} />
                {reach && (
                  <Text t3 color={textColor}>
                    {Math.max(reach.maxPermitted - reach.reached, 0)} spots left
                  </Text>
                )}
              </Spacing>
            </Spacing>
          </Spacing>
        </Spacing>
      </StudyCardWrap>
    </Box>
  )
}

const StudyCardWrap = styled.div`
  cursor: pointer;
  background: ${({ background }) => background};
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
  border-radius: ${({ theme }) => theme.borderRadius.base};
`
