import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { format, fromUnixTime, parseISO } from 'date-fns'
import { find, prop, propOr, isEmpty, pathOr, includes, path } from 'ramda'
import styled, { css } from 'styled-components/macro'

import { Spacing, Text } from '../../../../../components/ui'
import { DataSchemaQuery } from '../../../../../state/queries'
import { DataCardBits } from '../../components'
import { LinkedInCard, TradeCard, PortfolioCard } from './components'
import Formats from '../../../../../constants/Formats'
import { ShareButton } from '../ShareButton'
import { RichContent } from '../RichContent'

const DAY_AS_TITLE = ['fitbit', 'googleFit']
const NO_DATA_CARD_BITS = [1] // sources with no additional data below content

const STREAM_CARD_SCHEMA = {
  300: {
    title: 'activityname'
  },
  4311: {
    title: 'originalname'
  },
  201: {
    title: 'amount'
  },
  202: {
    title: 'quantity'
  },
  2: {
    title: 'text'
  },
  4321: {
    title: 'productname'
  },
  403: {
    title: 'title'
  },
  406: {
    title: 'name'
  },
  1: {
    title: 'name'
  }
}

const CardsWrap = styled.div`
  background: white;
  padding: ${({ theme }) => theme.padding.m};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-shadow: 0px 6px 8px rgba(111, 98, 255, 0.06);
  border-radius: 10px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const CardWrapper = styled.div`
  cursor: ${({ hasUrl }) => hasUrl && 'pointer'};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const DataStreamCard = props => {
  switch (props.objectTypeId) {
    case 4404:
      return <LinkedInCard {...props} />
    case 202:
      return <TradeCard {...props} />
    case 203:
      return <PortfolioCard {...props} />
    case 406:
    case 403:
    case 1:
      return <CardWithUrl {...props} />
    default:
      return <DataStreamCardDefault {...props} />
  }
}

const DataStreamCardDefault = ({
  objectTypeId,
  data,
  dataSourceId,
  onShare,
  isPublic,
  viewOnly = false
}) => {
  const cardSchema = STREAM_CARD_SCHEMA[objectTypeId]
  const titleProp = prop('title', cardSchema)
  const title = propOr('Untitled', titleProp, data)

  const { data: dataSchemaData } = useQuery(DataSchemaQuery, {})
  const objectTypes = pathOr([], ['dataSchema', 'objectTypes'], dataSchemaData)

  if (isEmpty(objectTypes)) return null
  const objectType = find(({ id }) => id === objectTypeId)(objectTypes)

  const dateCreated = data.date
    ? parseISO(data.date)
    : fromUnixTime(data.createddate / 1000)

  return (
    <CardsWrap disabled={viewOnly}>
      <Spacing size="s">
        <Spacing direction="row" justify="space-between">
          <Text t4>
            {format(new Date(dateCreated), Formats.dates.dateTime)}
          </Text>
          {onShare && (
            <ShareButton onClick={() => onShare({ data, objectTypeId })} />
          )}
        </Spacing>

        {renderActivityTitle({
          dataSourceId,
          title,
          dateCreated,
          objectTypeId,
          data
        })}

        {!isPublic && <RichContent data={data} objectTypeId={objectTypeId} />}
        {!includes(objectTypeId, NO_DATA_CARD_BITS) && (
          <DataCardBits
            fields={data}
            objectType={objectType}
            dataSourceId={dataSourceId}
          />
        )}
      </Spacing>
    </CardsWrap>
  )
}

const CardWithUrl = ({ viewOnly, ...props }) => {
  const url = createUrl(props)
  const onCardClick = () => url && window.open(url)

  return (
    <CardWrapper disabled={viewOnly} hasUrl={!!url} onClick={onCardClick}>
      <DataStreamCardDefault {...props} />
    </CardWrapper>
  )
}

const createUrl = props => {
  const { objectTypeId, onShare } = props
  if (onShare) return null

  const trackId = path(['data', 'id'], props)
  const externaluri = path(['data', 'externaluri'], props)
  if (!trackId && !externaluri) return null

  switch (objectTypeId) {
    case 406: {
      return externaluri
        ? externaluri
        : `https://open.spotify.com/track/${trackId}`
    }

    case 403: {
      return externaluri
        ? externaluri
        : `https://www.youtube.com/watch?v=${trackId}`
    }
    case 1: {
      return path(['data', 'permalink'], props)
    }
    default:
      return
  }
}

const renderActivityTitle = ({
  dataSourceId,
  title,
  dateCreated,
  objectTypeId,
  data
}) => {
  switch (objectTypeId) {
    case 201:
      return (
        <Text h5 bold>
          {data.basetype === 'CREDIT' ? 'Bought' : 'Sold'} {data.amount}{' '}
          {data.currency}
          {data.nativecurrency && data.nativeamount
            ? `@ ${data.nativecurrency} ${data.nativeamount}`
            : ''}
        </Text>
      )
    default:
      return (
        <Text h5 bold>
          {includes(dataSourceId, DAY_AS_TITLE)
            ? format(dateCreated, 'EEEE')
            : title}
        </Text>
      )
  }
}
