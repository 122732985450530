import { gql } from 'apollo-boost'

export default gql`
  mutation RequestPasswordChage($usernameOrPhoneNumber: String!) {
    requestPasswordChange(usernameOrPhoneNumber: $usernameOrPhoneNumber) {
      success
      errors {
        key
        message
      }
    }
  }
`
