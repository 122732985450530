import React from 'react'
import styled from 'styled-components'

import { Box, Graphic, Spacing, Text } from '../../../components/ui'
import { TypeButton } from './TypeButton'

export const StudyAttribute = ({
  text,
  buttonText,
  buttonColor,
  onClick,
  hideInfoIcon
}) => (
  <Box>
    <InfoWrap onClick={onClick}>
      {!hideInfoIcon && <GraphicWrap name="Info" size={18} />}
    </InfoWrap>

    <Spacing align="center">
      <Text t0>{text}</Text>
      <TypeButton
        onClick={onClick}
        text={buttonText}
        backgroundColor={buttonColor}
      />
    </Spacing>
  </Box>
)

const InfoWrap = styled.div`
  position: relative;
  height: 30px;
`

const GraphicWrap = styled(Graphic)`
  position: absolute;
  right: 0px;
`
