import { gql } from 'apollo-boost'

import { UserFragment } from '../fragments'

export default gql`
  mutation UpdateUser(
    $username: String
    $oldPassword: String
    $newPassword: String
    $phoneNumber: String
    $email: String
    $country: String
    $tosAccepted: Boolean
  ) {
    updateUser(
      username: $username
      oldPassword: $oldPassword
      newPassword: $newPassword
      phoneNumber: $phoneNumber
      email: $email
      country: $country
      tosAccepted: $tosAccepted
    ) {
      currentUser {
        id
        ...User
      }
      query {
        statistics {
          member {
            memberDataPoints
            memberLinked
            memberOrdinalNumber
            memberReferred
            totalLinked
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
  ${UserFragment}
`
