import gql from 'graphql-tag'

const mutation = gql`
  mutation StartDataRequest(
    $opportunityId: String!
    $shortId: String!
    $kickoffs: [KickoffRequest!]
  ) {
    startDataRequest(
      opportunityId: $opportunityId
      shortId: $shortId
      kickoffs: $kickoffs
    ) {
      opportunity {
        id
        currentMemberDataRequest {
          cumulativeStatus
          shortId
          opportunityId
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export default mutation
