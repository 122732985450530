import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components/macro'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './state/apollo'
import { Modals, ModalsProvider } from './modals'

// Screens
import {
  QRCodeScreen,
  OAuthLoadingScreen,
  SignUpScreen,
  SignInScreen,
  OnboardingScreen,
  DataBankScreen,
  ForgetPassword,
  HomeScreen,
  TribeDetailsScreen,
  PostScreen,
  EarnScreen,
  EarnDetailsScreen,
  TribeForumScreen,
  ProfileScreen,
  UserAccountScreen,
  CompetitionScreen
} from './screens'

// Stying
import 'normalize.css'
import { GlobalStyles } from './components/GlobalStyles'
import theme from './theme'

import { hasToken } from './util'
import { DataSync } from './components/DataSync'
import { AppHeader } from './components/AppHeader'

// eslint-disable-next-line no-unused-vars
import { Blob } from 'blob-polyfill'
import { PriceContextWrapper, TribeContextWrapper } from './contexts'
import { AdClaimScreen } from './screens/AdClaim'

const BodyWrapper = styled.div`
  background: ${({ theme }) => theme.color.purpleL4};
`

function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles />
        <Router>
          <ApolloProvider client={client}>
            <TribeContextWrapper>
              <PriceContextWrapper>
                <ModalsProvider>
                  <BodyWrapper>
                    <AppHeader />
                    <DataSync />
                    <Switch>
                      <Route path="/qr" component={QRCodeScreen} />
                      <Route
                        path="/oauthloading"
                        component={OAuthLoadingScreen}
                      />

                      <Route path="/adclaim/:id" component={AdClaimScreen} />
                      <Route path="/onboarding" component={OnboardingScreen} />
                      <Route path="/signup" component={SignUpScreen} />
                      <Route path="/signin" component={SignInScreen} />
                      <Route
                        path="/forgot-password"
                        component={ForgetPassword}
                      />

                      <Route
                        path="*"
                        render={props =>
                          hasToken() ? (
                            <Main {...props} />
                          ) : (
                            <Redirect to="/signin" />
                          )
                        }
                      />
                    </Switch>
                  </BodyWrapper>
                  <Modals />
                </ModalsProvider>
              </PriceContextWrapper>
            </TribeContextWrapper>
          </ApolloProvider>
        </Router>
      </React.Fragment>
    </ThemeProvider>
  )
}

// Authenticated state
const Main = () => {
  return (
    <Switch>
      <Route path="/databank/:dataSourceId?" component={DataBankScreen} />

      <Route path="/tribes/:tribeId/:forumValue" component={TribeForumScreen} />
      <Route path="/tribes/:tribeId" component={TribeDetailsScreen} />

      <Route path="/earn/:opportunityId" component={EarnDetailsScreen} />
      <Route path="/earn" component={EarnScreen} />

      <Route path="/post/:postId" component={PostScreen} />
      <Route path="/competition/:postId" component={CompetitionScreen} />

      <Route path="/u/:memberProfileUsername" component={ProfileScreen} />
      <Route path="/profile/:memberId?/:tribeId?" component={ProfileScreen} />
      <Route path="/account" component={UserAccountScreen} />

      <Route path="/" component={HomeScreen} exact />
    </Switch>
  )
}

export default App
