import React from 'react'
import { useQuery } from 'react-apollo'
import { Link } from 'react-router-dom'
import { Spacing, Text } from '../../../../../components/ui'
import { MostActiveMembersQuery } from '../../../../../state/queries'
import { FollowButton } from '../../../../Profile/components/FollowButton'
import { UBDI_TRIBE_ID } from '../../../../TribeDetails/_utils'
import { InlineTags } from '../../CommunityPosts/components'
import { getUbdiTribeSettings } from '../util'

export const DiscoverMembers = () => {
  const { data: { mostActiveMembers } = {}, loading } = useQuery(
    MostActiveMembersQuery,
    {
      variables: {
        tribeId: UBDI_TRIBE_ID
      }
    }
  )
  if (loading || !mostActiveMembers) {
    return null
  }

  return (
    <Spacing size="xl">
      <Text h6>Discover members</Text>
      <Spacing size="s">
        {mostActiveMembers.map((member, index) => {
          const ubdiTribeSettings = getUbdiTribeSettings({ member })

          return (
            <Spacing
              direction="row"
              justify="space-between"
              align="center"
              key={`${member.id}-${index}`}
            >
              <Link
                style={{ color: 'inherit' }}
                to={`/profile/${member.id}/${UBDI_TRIBE_ID}`}
              >
                <InlineTags
                  avatarId={member.id}
                  tags={member.publicTags}
                  publicTagCategories={ubdiTribeSettings.publicTags}
                  numberOfTags={3}
                  member={member}
                />
              </Link>
              <FollowButton member={member} />
            </Spacing>
          )
        })}
      </Spacing>
    </Spacing>
  )
}
