export const ALWAYS_VISIBLE_TAGS = []

export const tribeColorSchema = [
  ['#B7B0FF', '#B7B0FF'],
  ['#73AAFD', '#73AAFD'],
  ['#47DCA6', '#47DCA6'],
  ['#F67777', '#F67777'],
  ['#F1BF3E', '#F1BF3E'],
  ['#DC7CEC', '#DC7CEC'],
  ['#ADADAD', '#ADADAD']
]
