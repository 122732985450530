import React from 'react'
import { pipe } from 'ramda'
import styled from 'styled-components'
import { withMutation } from '../../../components/wrappers'

const ButtonWrapper = styled.div`
  ${({ theme }) => theme.text.h6};

  border-radius: 37px;
  background: ${({ theme, isFollowedByMe }) =>
    isFollowedByMe ? theme.color.white : theme.color.main};
  color: ${({ theme, isFollowedByMe }) =>
    !isFollowedByMe ? theme.color.white : theme.color.main};
  padding: ${({ theme }) => `12px ${theme.padding.l}`};
  border: 1px solid ${({ theme }) => theme.color.main};

  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
`

const FollowButtonInner = ({
  member,
  isOwnProfile,
  followMember,
  unfollowMember
}) => {
  const onFollow = () =>
    followMember({
      variables: {
        targetMemberId: member.id
      }
    })

  const onUnfollow = () =>
    unfollowMember({
      variables: {
        targetMemberId: member.id
      }
    })
  return (
    !isOwnProfile && (
      <ButtonWrapper
        isFollowedByMe={member.isFollowedByMe}
        onClick={member.isFollowedByMe ? onUnfollow : onFollow}
      >
        {member.isFollowedByMe ? 'Following' : 'Follow'}
      </ButtonWrapper>
    )
  )
}

export const FollowButton = pipe(
  withMutation('followMember'),
  withMutation('unfollowMember')
)(FollowButtonInner)
