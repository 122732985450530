import { gql } from 'apollo-boost'

import { PublicMemberFragment, UserFragment } from '../fragments'
export const UserQuery = gql`
  query UserQuery {
    currentUser {
      id
      ...User
    }
  }
  ${UserFragment}
`

export const UserWithSourcesQuery = gql`
  query UserWithSourcesQuery {
    currentUser {
      id
      ...User
      linkedDataSources {
        dataSource {
          id
          name
        }
        dateLinked
      }
    }
  }
  ${UserFragment}
`

export const ReferralsQuery = gql`
  query Referrals {
    currentUser {
      id
      linkedDataSources {
        dataSource {
          id
          name
        }
        dateLinked
      }
      member {
        id
        referralCode
        referralLink
        uphold {
          email
          status
        }
        referralStats {
          earned {
            totalUsd
            totalUbdi
          }
          reward {
            totalUsd
            totalUbdi
          }
          maxUsers
          pendingUsers
          completedUsers
          hasClaimedReferral
          hasRedeemedReferral
        }
      }
    }
  }
`

export const PublicMemberQuery = gql`
  query PublicMember($id: String, $profileUsername: String, $tribeId: String) {
    publicMember(id: $id, profileUsername: $profileUsername) {
      id
      ...PublicMember
      publicTags(tribeId: $tribeId)
    }
  }
  ${PublicMemberFragment}
`

// NOTE: This query is exempted from Token-check-redirect in apollo
export const UserMetaQuery = gql`
  query UserMetaQuery {
    currentUser {
      id
      email
      username
      country
      hasPassword
      tosAccepted
      member {
        id
      }
    }
  }
`

export const MostActiveMembersQuery = gql`
  query MostActiveMembersQuery($tribeId: String!) {
    mostActiveMembers {
      id
      ...PublicMember
      publicTags(tribeId: $tribeId)
    }
  }
  ${PublicMemberFragment}
`
