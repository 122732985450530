import React from 'react'
import styled from 'styled-components/macro'
import posed, { PoseGroup } from 'react-pose'
import { times } from 'ramda'

const Wrapper = styled.div`
  display: flex;
`

const StepWrap = styled.div`
  background: ${({ theme }) => theme.color.purpleL3};
  width: 30px;
  height: 2px;
  position: relative;
  margin-right: ${({ theme }) => theme.padding.xs};

  transition: all 0.05s ease-in-out;

  &:hover {
    height: 4px;
  }
`

const FilledStep = posed(
  styled.div`
    position: absolute;
    width: 0;
    height: 100%;
    background: ${({ theme }) => theme.color.main};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    cursor: pointer;
  `
)({
  enter: { width: '100%' },
  exit: { width: 0 }
})

export const StepLoading = ({ total, current}) => (
  <Wrapper>
    {times(
      i => (
        <StepWrap key={i}>
          <PoseGroup>
            {i <= current && <FilledStep  key={i} />}
          </PoseGroup>
        </StepWrap>
      ),
      total
    )}
  </Wrapper>
)
