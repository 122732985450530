import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEmpty, pathOr } from 'ramda'
import { lighten } from 'polished'
import {
  Button,
  DataSourceGraphic,
  Graphic,
  Spacing,
  Text
} from '../../../components/ui'
import { useDataStream } from '../../../hooks'
import { DataStreamCard } from '../../DataBank/DataStream/components'
import {
  ALL_ITEMS_STREAM,
  STREAMS_SCHEMA
} from '../../DataBank/DataStream/streams/_config'
import { hasDateKey } from '../../DataBank/DataStream/util'
import { getAccountIds, getPublicDataSource } from '../util'

export const DataStream = ({ member, dataSource, accountIds }) => {
  const [selectedDataSourceId, setSelectedDataSourceId] = useState(
    dataSource.id
  )

  const [loadItems, setLoadItems] = useState(3)

  const sourceStreamDefinition = pathOr(
    STREAMS_SCHEMA[selectedDataSourceId],
    [selectedDataSourceId, 'publicStreams'],
    STREAMS_SCHEMA
  )

  const streamDefinition =
    sourceStreamDefinition &&
    sourceStreamDefinition.find(
      ({ dataStreamId }) => dataStreamId === ALL_ITEMS_STREAM
    )

  useEffect(() => {
    if (member) {
      const publicDataSource = getPublicDataSource({
        publicOptIns: member.publicProfileSourceOptIns,
        selectedDataSourceId
      })

      if (
        !publicDataSource &&
        member.publicProfileSourceOptIns &&
        member.publicProfileSourceOptIns.length > 0
      ) {
        setSelectedDataSourceId(
          member.publicProfileSourceOptIns[0].dataSourceId
        )
      }
    }
  }, [selectedDataSourceId, member])

  const { streamCards, loading, error, refetch } = useDataStream({
    accountIds:
      accountIds ||
      getAccountIds({
        publicOptIns: member.publicProfileSourceOptIns,
        selectedDataSourceId
      }),
    dataQueries: [streamDefinition],
    isPublicRead: true
  })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSourceId])
  return (
    <Spacing size="l">
      <PillWrap background={dataSource.baseColor}>
        <Spacing direction="row" align="center" justify="center">
          <DataSourceGraphic id={selectedDataSourceId} size={30} />
          <Text h6 color="darkBlue">
            {dataSource.name}
          </Text>
        </Spacing>
      </PillWrap>
      {selectedDataSourceId
        ? loading
          ? renderLoading()
          : error
          ? renderError()
          : renderContent({
              streamCards,
              dataSourceId: selectedDataSourceId,
              refetch,
              loadItems,
              setLoadItems
            })
        : null}
    </Spacing>
  )
}

const renderContent = ({
  streamCards,
  dataSourceId,
  refetch,
  loadItems,
  setLoadItems
}) => {
  const renderableItems = streamCards[ALL_ITEMS_STREAM]
    ? streamCards[ALL_ITEMS_STREAM].slice(0, loadItems)
    : []

  const hasDataToShow =
    renderableItems &&
    renderableItems.length > 0 &&
    !isEmpty(renderableItems[0].data) &&
    hasDateKey(renderableItems[0].data)

  const onSeeMore = () => {
    setLoadItems(loadItems + 5)
  }

  const isButtonVisible =
    renderableItems &&
    streamCards[ALL_ITEMS_STREAM] &&
    renderableItems.length < streamCards[ALL_ITEMS_STREAM].length

  return hasDataToShow ? (
    <Spacing size="l">
      <Spacing size="s">
        {renderableItems.map((item, index) => (
          <DataStreamCard
            key={`${item.data.id}_${index}`}
            objectTypeId={item.objectTypeId}
            dataSourceId={dataSourceId}
            data={item.data}
          />
        ))}
      </Spacing>
      {isButtonVisible && (
        <ButtonWrap block outline rounded onClick={() => onSeeMore()}>
          See more
        </ButtonWrap>
      )}
    </Spacing>
  ) : (
    renderEmpty({ refetch })
  )
}

const renderLoading = () => (
  <GraphicWrap>
    <Graphic name="Loading" width={100} height={60} themeColor="main" />
  </GraphicWrap>
)

const renderError = () => (
  <EmptyProfileSection>
    <Text t3 center>
      There was an error loading data for this stream
    </Text>
  </EmptyProfileSection>
)

const renderEmpty = ({ refetch }) => (
  <EmptyProfileSection>
    <Spacing align="center" size="s">
      <Text t3 center>
        No data
      </Text>
      <RefetchTextWrap h6 center onClick={() => refetch()}>
        Try to refetch
      </RefetchTextWrap>
    </Spacing>
  </EmptyProfileSection>
)

export const EmptyProfileSection = styled.div`
  background: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.xl};
  border-radius: 10px;
`

const GraphicWrap = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.xl};
  border-radius: 10px;
`

const PillWrap = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.padding.s} ${theme.padding.l} ${theme.padding.s} ${theme.padding.m}`};
  border-radius: 35px;
  background: ${({ theme }) => theme.color.lightBlue};
  border: ${({ theme }) => theme.color.lightBlue} 1px solid;

  cursor: pointer;
  &:hover {
    background: ${({ theme }) => lighten(0.015, theme.color.lightBlue)};
  }
`
const RefetchTextWrap = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.main};
  &:hover {
    color: ${({ theme }) => lighten(0.015, theme.color.main)};
  }
`
const ButtonWrap = styled(Button)`
  padding: ${({ theme }) => theme.padding.s};
`
