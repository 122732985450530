import React from 'react'
import { path } from 'ramda'
import { useQuery } from '@apollo/react-hooks'

import { CommunityPollQuery } from '../../../../../state/queries'
import { Box, Spacing } from '../../../../../components/ui'
import { PostInfo, PostMedia, InlineTags, Poll } from '.'
import { DataStreamCard } from '../../../../DataBank/DataStream/components'
import { Link } from 'react-router-dom'
import { TribeAwareTitleWithLinks } from './TribeAwareTitleWithLinks'

const PostCard = ({ condensed, viewOnly, post }) => {
  const {
    id: postId,
    title,
    externalUrlData,
    videoUrl,
    uploadedImage,
    tribe,
    tags,
    colors,
    publicTagCategories,
    postData,
    publicMember
  } = post

  const { data: pollData } = useQuery(CommunityPollQuery, {
    variables: { id: postId }
  })

  const poll = path(['communityPoll', 'poll'], pollData)
  const publicMemberId = path(['publicMember', 'id'], post)
  const hasSomeMedia = externalUrlData || uploadedImage || videoUrl

  return (
    <Box
      size={condensed ? 'xxs' : 'xl'}
      bordered={!condensed}
      noShadow
      disabled={viewOnly}
    >
      <Spacing size="l">
        <Link
          style={{ color: 'inherit' }}
          to={`/profile/${publicMemberId}/${tribe.id}`}
        >
          <InlineTags
            avatarId={postId}
            colors={colors}
            tags={tags}
            publicTagCategories={publicTagCategories}
            member={publicMember}
          />
        </Link>
        <Link to={`/post/${postId}`}>
          <TribeAwareTitleWithLinks poll={poll} tribe={tribe}>
            {title}
          </TribeAwareTitleWithLinks>
        </Link>
        {hasSomeMedia && (
          <PostMedia
            externalUrlData={externalUrlData}
            uploadedImage={uploadedImage}
            videoUrl={videoUrl}
            postId={postId}
          />
        )}
        {poll && !condensed && (
          <Poll poll={poll} tribe={tribe} showSubmitOnSelect />
        )}
        {postData && (
          <DataStreamCard
            objectTypeId={postData.objectTypeId}
            dataSourceId={postData.dataSourceId}
            data={JSON.parse(postData.data)}
          />
        )}
        {!condensed && <PostInfo post={post} />}
      </Spacing>
    </Box>
  )
}

export default PostCard
