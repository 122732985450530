import { keys } from 'ramda'

export const getVariants = (Query, filterVariants = []) => {
  const possibleVariants = Query.possibleVariants

  if (!possibleVariants) return [{ query: Query }]

  return keys(possibleVariants)
    .filter(variant => {
      if (filterVariants.length === 0) return true
      return filterVariants.includes(variant)
    })
    .map(key => ({
      query: Query,
      variables: possibleVariants[key]
    }))
}
