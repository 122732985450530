import { gql } from 'apollo-boost'
import { PollFragment } from '../fragments/Poll'
import { UserGroupFragment } from '../fragments/UserGroup'
import { CommunityPollCommentFragment } from '../fragments/CommunityPollComment'
import {
  CompetitionCondensedFragment,
  CompetitionFragment
} from '../fragments/Competition'

export const CommunityPollCardFragment = gql`
  fragment CommunityPollCard on CommunityPoll {
    id
    createdAt
    title
    reactions {
      votes
      currentMemberReaction
    }
    tribe {
      ...UserGroup
    }
    totalComments
    viewCount
    status
    videoUrl
    uploadedImage {
      thumbnail
      default
      large
    }
    colors
    publicTagCategories
    tags
    externalUrlData {
      url
      shortUrl
      ogImage
      ogTitle
      embedCode
    }
    poll {
      id
      voted
      totalVotes
    }
    competition {
      id
      ...Competition
    }
    postData {
      objectTypeId
      dataSourceId
      data
    }
    publicMember {
      id
      profileUsername
      isCustomUsername
      profileColor
      profileImage {
        thumbnail
      }
    }
  }

  ${CompetitionCondensedFragment}
  ${UserGroupFragment}
`

export const CommunityPollFragment = gql`
  fragment CommunityPoll on CommunityPoll {
    id
    createdAt
    title
    reactions {
      votes
      currentMemberReaction
    }
    comments {
      id
      ...CommunityPollComment
      comments {
        id
        ...CommunityPollComment
      }
    }
    totalComments
    viewCount
    tribe {
      ...UserGroup
    }
    status
    videoUrl
    uploadedImage {
      thumbnail
      default
      large
    }
    externalUrlData {
      url
      shortUrl
      ogImage
      ogTitle
      embedCode
    }
    poll {
      id
      ...Poll
    }
    competition {
      id
      ...Competition
    }
    postData {
      objectTypeId
      dataSourceId
      data
    }
    colors
    publicTagCategories
    tags
    publicMember {
      id
      profileColor
      profileUsername
      isCustomUsername
    }
  }
  ${PollFragment}
  ${CompetitionFragment}
  ${UserGroupFragment}
  ${CommunityPollCommentFragment}
`
