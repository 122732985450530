import React from 'react'
import { pipe, prop, includes, filter, map, pathOr, reduce } from 'ramda'
import { graphql } from 'react-apollo'

import { Text } from '../../ui'
import { getTagValues, retrofitAudienceSchema } from '../../../state/selectors'
import {
  AudienceDataVerifiedTagsQuery,
  AudienceSchemaQuery
} from '../../../state/queries'
import styled from 'styled-components'
import { TagValue } from './TagValue'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ProfileTagValues = ({
  tagCategories,
  currentUser,
  profileSchema,
  data: { audienceDataVerifiedTags }
}) => {
  const userTags = pathOr([], ['member', 'tags'], currentUser)

  const tags = reduce((acc, value) => {
    return {
      ...acc,
      [value.uniqueId]: value.values
    }
  }, [])(userTags)

  if (profileSchema.flattened.length === 0 || !audienceDataVerifiedTags) {
    return null
  }

  const tagsToList = tagCategories
    .map(uniqueId => ({
      uniqueId,
      tagValues: getTagValues(tags, profileSchema, uniqueId)
    }))
    .filter(tag => !!tag.tagValues)

  if (tagsToList.length === 0) {
    return (
      <Text t2 brandBlack>
        You have no tags visible.
      </Text>
    )
  }

  return (
    <Container>
      {tagsToList.map(({ uniqueId, tagValues }) => {
        const verifiedTagIds = pipe(
          filter(tag => includes(uniqueId, tag.uniqueId)),
          map(prop('uniqueId'))
        )(audienceDataVerifiedTags)

        const isDataVerified = includes(uniqueId, verifiedTagIds)

        return (
          <TagValue
            key={uniqueId}
            uniqueId={uniqueId}
            profileSchema={profileSchema}
            tags={tags}
            isDataVerified={isDataVerified}
            tagValues={tagValues}
          />
        )
      })}
    </Container>
  )
}

export default pipe(
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema }),
  graphql(AudienceDataVerifiedTagsQuery)
)(ProfileTagValues)
