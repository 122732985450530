import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import { Back } from '../Back'

const ColorizedHeaderWrapper = styled.div`
  display: flex;
  background: ${props => props.background || 'white'};

  padding: ${({ theme }) =>
    `${theme.padding.l} 70px ${theme.padding.l} ${theme.padding.xl}`};
`

const ColorizedHeaderInner = ({
  backRouteName,
  children,
  background,
  coverPhotoUrl
}) => (
  <ColorizedHeaderWrapper background={background} coverPhotoUrl={coverPhotoUrl}>
    <Back backRouteName={backRouteName} color="white" />
    <div style={{ flex: 1 }}>{children}</div>
  </ColorizedHeaderWrapper>
)

export const ColorizedHeader = withRouter(ColorizedHeaderInner)
