import React, { useState } from 'react'
import styled from 'styled-components/macro'
import arrayMutators from 'final-form-arrays'

import { Box, Text, Spacing, Button } from '../../../../../../components/ui'
import { TribeSelect } from '../../../Tribes/components/TribeSelect'
import { Form } from 'react-final-form'
import {
  filter,
  find,
  has,
  includes,
  is,
  isEmpty,
  isNil,
  omit,
  pipe,
  propOr,
  reject,
  slice
} from 'ramda'
import { NewDataPost, NewPlainPost, NewPollPost } from './components'
import {
  createCommunityPollValidator,
  newPlainPostValidator
} from '../../../../../../util/validators'
import { withMutation } from '../../../../../../components/wrappers'
import { getImage, getPostData, getVideo } from '../../../../../../util'

const CONTROLS = ['Post', 'Poll', 'Data']
const CONTROLS_WITH_TEXT_LIMIT = ['Post', 'Data']
const TEXT_MAX_LENGTH = 500
const GIFS_LIMIT = 2 // per poll

const getPostTypeValidator = ({ selectedControl, variables }) => {
  switch (selectedControl) {
    case 'Poll':
      return createCommunityPollValidator(variables)
    case 'Post':
    case 'Data':
      return newPlainPostValidator(variables)
    default:
      return null
  }
}

const CreatePost = ({ createCommunityPoll, currentTribe }) => {
  const [selectedControl, setSelectedControl] = useState('Post')
  const [tribeId, setTribeId] = useState(currentTribe ? currentTribe.id : null)
  const [emptyState, setEmptyState] = useState(true)

  const renderPostTypeContent = props => {
    switch (selectedControl) {
      case 'Post':
        return <NewPlainPost key="plain" {...props} tribeId={tribeId} />
      case 'Poll':
        return <NewPollPost key="poll" {...props} />
      case 'Data':
        return <NewDataPost key="data" {...props} tribeId={tribeId} />
      default:
        return null
    }
  }

  const onSubmit = async variables => {
    const image = getImage(variables)
    const videoSrc = await getVideo({
      media: variables.media
    })

    const choices = getChoices(variables)
    const postData = getPostData(variables)

    const values = pipe(
      omit(['media', 'tribe', 'choices', 'postData']),
      filter(i => !isNil(i))
    )(variables)

    return createCommunityPoll({
      variables: {
        ...values,
        tribeId: variables.tribe.id,
        ...(postData ? { postData } : {}),
        ...(choices ? { choices } : {}),
        ...(image ? { image } : {}),
        ...(videoSrc ? { videoSrc } : {})
      },
      refetchQueries: ['AllCommunityPolls']
    }).then(({ userErrors }) => {
      if (userErrors) {
        return userErrors
      }

      setSelectedControl('')
    })
  }

  return (
    <StyledBox size="l" bordered noShadow>
      <Spacing size="s">
        <Spacing
          size="xs"
          direction="row"
          align="center"
          justify="space-between"
        >
          <Spacing size="s" direction="row">
            <Text h3>+</Text>
            <Text h3>Create a new post</Text>
          </Spacing>
          <Spacing direction="row" size="s">
            {CONTROLS.map(control => (
              <ControlButton
                key={control}
                selected={selectedControl === control}
                onClick={() => setSelectedControl(control)}
              >
                {control}
              </ControlButton>
            ))}
          </Spacing>
        </Spacing>
        {!isEmpty(selectedControl) && (
          <Form
            mutators={arrayMutators}
            initialValues={{
              tribe: currentTribe
            }}
            onSubmit={onSubmit}
            validate={variables =>
              getPostTypeValidator({ variables, selectedControl })
            }
            render={({
              handleSubmit,
              form,
              submitting,
              invalid,
              values: formValues
            }) => {
              const textLength = propOr('', 'title', formValues).length
              const choices = propOr([], 'choices', formValues)

              return (
                <Spacing size="l">
                  <TribeSelect
                    onSelectTribe={tribe => setTribeId(tribe.id)}
                    selectedControl={selectedControl}
                    setEmptyState={setEmptyState}
                  />
                  {!emptyState && (
                    <Spacing size="xl">
                      {renderPostTypeContent({
                        form,
                        submitting,
                        textLength,
                        choices,
                        TEXT_MAX_LENGTH
                      })}

                      <FooterWrapper>
                        <FooterLeft>
                          {includes(
                            selectedControl,
                            CONTROLS_WITH_TEXT_LIMIT
                          ) && (
                            <Text t3 color="grey">
                              {`${textLength} / ${TEXT_MAX_LENGTH} characters`}
                            </Text>
                          )}
                        </FooterLeft>
                        <FooterRight>
                          <Spacing size="l" direction="row" stretchChildren>
                            <Button
                              block
                              outline
                              onClick={() => {
                                form.reset()
                                setSelectedControl('')
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              block
                              onClick={handleSubmit}
                              disabled={submitting || invalid}
                            >
                              Submit
                            </Button>
                          </Spacing>
                        </FooterRight>
                      </FooterWrapper>
                    </Spacing>
                  )}
                </Spacing>
              )
            }}
          />
        )}
      </Spacing>
    </StyledBox>
  )
}

const hasGifUrl = choice => is(Object, choice) && has('gifUrl', choice)

const checkIfGifAdded = choices => !!find(choice => hasGifUrl(choice))(choices)

const getChoices = ({ choices }) => {
  if (isNil(choices) || isEmpty(choices)) return null

  if (checkIfGifAdded(choices)) {
    return slice(0, GIFS_LIMIT, reject(isNil, choices))
  }
  return reject(isNil, choices)
}

const StyledBox = styled(Box)`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
`

const ControlButton = styled.button`
  background: ${({ theme, selected }) =>
    selected ? theme.color.white : theme.color.lightBlue};

  border: 2px solid
    ${({ theme, selected }) =>
      selected ? theme.color.secondary : theme.color.lightBlue};
  color: ${({ theme }) => theme.color.darkBlue};
  border-radius: 60px;
  padding: 13px 25px;
  ${({ theme }) => theme.text.h6};
  outline: none;
  cursor: pointer;
`

const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const FooterLeft = styled.div`
  width: 40%;
`
const FooterRight = styled.div`
  width: 60%;
`

export default pipe(withMutation('createCommunityPoll'))(CreatePost)
