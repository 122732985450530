import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import pluralize from 'pluralize'

import { Button, Spacing, Text } from '../../../../../components/ui'
import { TribeSettings } from '../../../../../components/TribeSettings'

const TribeRowCardInner = ({
  tribe,
  total,
  onRowClick,
  outline = false,
  settings,
  isRowClickable,
  showCounter
}) => {
  const { name, logoUrl, membersCount } = tribe
  const [showTribeSettings, setShowTribeSetting] = useState(false)

  const renderRightSide = () => {
    if (settings) {
      return (
        <SettingsButtonWrap
          outline
          rounded
          onClick={() => setShowTribeSetting(!showTribeSettings)}
        >
          {`${showTribeSettings ? 'Close' : 'Settings'} `}
        </SettingsButtonWrap>
      )
    }
    if (showCounter) {
      return total ? (
        <PillWrap>
          <Text h6 color="white">
            {total}
          </Text>
        </PillWrap>
      ) : null
    }
    return (
      <ButtonWrapper>
        <Text h6 center color="white">
          + Join
        </Text>
      </ButtonWrapper>
    )
  }

  return (
    <Container
      onClick={onRowClick}
      outline={outline}
      isRowClickable={isRowClickable}
    >
      <Spacing
        size="xs"
        align="center"
        direction="row"
        justify="space-between"
        stretched
      >
        <Spacing direction="row" size="l" align="center">
          <StyledImage src={logoUrl} />
          <Spacing size="xxs">
            <Text h4>{name}</Text>
            <Text t4 color="grey">
              {membersCount} {pluralize('member', membersCount)}
            </Text>
          </Spacing>
        </Spacing>
        {renderRightSide()}
      </Spacing>
      {showTribeSettings && (
        <TribeSettings
          tribe={tribe}
          setShowTribeSetting={setShowTribeSetting}
        />
      )}
    </Container>
  )
}

const TribeRowCardLoading = () => {
  return (
    <PlaceholderWrapper>
      <Spacing
        size="xs"
        align="center"
        direction="row"
        justify="space-between"
        stretched
      >
        <Spacing direction="row" size="l" align="center">
          <StyledImagePlaceholder />
          <TextPlaceholder />
        </Spacing>
        <PillWrapPlaceholder />
      </Spacing>
    </PlaceholderWrapper>
  )
}

const TribeRowCard = withRouter(TribeRowCardInner)
TribeRowCard.loading = TribeRowCardLoading

export default TribeRowCard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.base};

  ${({ theme, outline }) =>
    outline && `padding: ${theme.padding.s} ${theme.padding.m};`}

  &:hover {
    background: ${({ isRowClickable, theme }) =>
      isRowClickable && theme.color.purpleL3};
  }
  background: ${({ outline, theme }) =>
    outline ? theme.color.white : theme.color.transparent};
  border: 1px solid
    ${({ outline, theme }) =>
      outline ? theme.color.purpleL2 : theme.color.transparent};
  box-shadow: ${({ outline, theme }) => outline && theme.boxShadow.default};
`
const StyledImage = styled.img`
  width: 32px;
  height: 32px;
`

const PillWrap = styled.div`
  padding: 4px 12px;
  background: ${({ theme }) => theme.color.purpleL1};
  border-radius: 40px;
`

const ButtonWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  background: ${({ theme }) => theme.color.main};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
`

const SettingsButtonWrap = styled(Button)`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.xl}`};
  background-color: ${({ theme }) => theme.color.transparent};
`

const TextPlaceholder = styled.div`
  height: 25px;
  width: 100px;
  border-radius: 10px;
  background: ${({ theme }) => theme.color.purpleL2};
`

const StyledImagePlaceholder = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background: ${({ theme }) => theme.color.purpleL2};
`

const PillWrapPlaceholder = styled(PillWrap)`
  width: 30px;
  height: 25px;
`

const PlaceholderWrapper = styled.div`
  margin: 3px;
`
