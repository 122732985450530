import { gql } from 'apollo-boost'
import { EntryFragment } from '../fragments/Entry'

export const CompetitionFragment = gql`
  fragment Competition on Competition {
    id
    title
    subtitle
    tribeId
    dataStreamId
    entries {
      id
      ...Entry
    }
    duration {
      startDate
      endDate
    }
    totalEntries
    positionInLeaderboard
    memberAllowedToSubmit
    isCompetitionFinished
  }
  ${EntryFragment}
`

export const CompetitionCondensedFragment = gql`
  fragment Competition on Competition {
    id
    title
    subtitle
    tribeId
    dataStreamId
    duration {
      startDate
      endDate
    }
    totalEntries
    memberAllowedToSubmit
    isCompetitionFinished
  }
`
