import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroller'
import {
  flatten,
  isEmpty,
  map,
  pathOr,
  pipe,
  prop,
  propOr,
  splitEvery,
  uniq
} from 'ramda'

import { Box, Graphic, Spacing, Text } from '../../../../../components/ui'
import {
  AllCommunityPollsQuery,
  AllFollowingCurrentMemberLeanQuery,
  DataSourcesQuery
} from '../../../../../state/queries'
import { InlineTags } from './'
import ndlProvider from '../../../../../providers/ndlProvider'
import { DataStream } from '../../../../Profile/components/DataStream'
import { CommunityPostCard } from '../../../../../components/CommunityPostCard'

const LIMIT = 10

const defaultFetchVariables = {
  first: LIMIT,
  withCompetitions: true
}

const FollowingPostsAndData = ({ tribeId, memberId, viewOnly = false }) => {
  const [sortedSyncs, setSortedSyncs] = useState([])
  const [hasReceivedEmptyList, setHasReceivedEmptyList] = useState(false)
  const fetchVariables = {
    ...defaultFetchVariables,
    ...(tribeId ? { tribeId } : {}),
    ...(memberId ? { memberId } : {}),
    justFollowingMembers: true
  }

  const { data: allCommunityPollsData, loading, fetchMore } = useQuery(
    AllCommunityPollsQuery,
    {
      variables: { ...fetchVariables }
    }
  )

  const { data: allFollowingData, loading: allFollowingLoading } = useQuery(
    AllFollowingCurrentMemberLeanQuery
  )

  const { data: allDataSourcesData } = useQuery(DataSourcesQuery)
  const allSourcesEdges = pathOr(
    [],
    ['allDataSources', 'edges'],
    allDataSourcesData
  )
  const allSources = allSourcesEdges.map(prop('node'))

  const allFollowing = propOr([], 'allFollowing', allFollowingData)

  // Get a sorted list of last synced data streams for accounts being followed
  useEffect(() => {
    if (allFollowing.length > 0) {
      async function getLatestSyncedAccounts() {
        const allNdlAccounts = pipe(
          map(prop('publicProfileSourceOptIns')),
          flatten,
          map(prop('accountIds')),
          flatten
        )(allFollowing)

        const lastSortedSyncs = await ndlProvider.getPublicMetadata({
          metric: 'last_sync',
          accountIds: allNdlAccounts
        })

        setSortedSyncs(
          uniq(
            map(({ accountId, dataSourceId }) => ({ accountId, dataSourceId }))(
              lastSortedSyncs
            )
          )
        )

        console.log({ lastSortedSyncs, allFollowing })
      }

      getLatestSyncedAccounts()
    }
  }, [allFollowing, allFollowing.length])

  if (loading || allFollowingLoading) return <Loader />

  const data = propOr([], 'allCommunityPolls', allCommunityPollsData)

  const onEndReached = () => {
    if (loading || hasReceivedEmptyList) return

    fetchMore({
      variables: {
        ...fetchVariables,
        after: data.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          allCommunityPolls: [
            ...prev.allCommunityPolls,
            ...fetchMoreResult.allCommunityPolls
          ]
        })
      }
    }).then(({ data }) => {
      const allCommunityPolls = propOr([], 'allCommunityPolls', data)
      if (isEmpty(allCommunityPolls)) setHasReceivedEmptyList(true)
    })
  }

  if (isEmpty(data))
    return (
      <Box size="l" noShadow bordered>
        <Text t3 center>
          Currently, there are no posts to show.
        </Text>
      </Box>
    )

  const splitPosts = splitEvery(2, data)

  return (
    <Spacing size="xl" stretched>
      <InfiniteScroll
        hasMore={!hasReceivedEmptyList}
        loadMore={onEndReached}
        loader={<Loader key="loader" />}
        key="posts"
      >
        <Spacing size="s">
          {splitPosts.map((posts, i) => (
            <Spacing size="s" key={`spacing-item-${i}`}>
              <Spacing size="s">
                {posts.map(post => (
                  <CommunityPostCard
                    key={post.id}
                    post={post}
                    viewOnly={viewOnly}
                  />
                ))}
              </Spacing>
              <FollowingDataStream
                key={`following-data-${i}`}
                index={i}
                sortedSyncs={sortedSyncs}
                allSources={allSources}
                allFollowing={allFollowing}
              />
            </Spacing>
          ))}
        </Spacing>
      </InfiniteScroll>
    </Spacing>
  )
}

const FollowingDataStream = ({
  index,
  sortedSyncs,
  allSources,
  allFollowing
}) => {
  const sync = sortedSyncs[index]

  const accountIds = useMemo(() => {
    if (!sync) return []
    return [sync.accountId]
  }, [sync])

  if (!sync) return null

  const dataSource = allSources.find(({ id }) => id === sync.dataSourceId)
  const member = allFollowing.find(({ publicProfileSourceOptIns }) =>
    publicProfileSourceOptIns.find(({ accountIds }) =>
      accountIds.includes(sync.accountId)
    )
  )

  return (
    <Box>
      <Spacing>
        <InlineTags member={member} />
        <DataStream
          member={member}
          accountIds={accountIds}
          dataSource={dataSource}
        />
      </Spacing>
    </Box>
  )
}

const Wrapper = styled.div`
  padding: 20px 10px;
  text-align: center;
`

const Loader = () => (
  <Wrapper>
    <Graphic name="Loading" width={150} height={60} themeColor="main" />
  </Wrapper>
)

export default FollowingPostsAndData
