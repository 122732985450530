import React from 'react'
import styled from 'styled-components'
import { Button, Spacing, Text } from '../../../components/ui'
import { TypeButton } from '../../../screens/EarnDetails/components/TypeButton'

export const DataStore = ({ onClick = () => {} }) => {
  return (
    <DataStoreWrap>
      <Spacing size="xxl" align="center">
        <Spacing size="xl">
          <Spacing size="l">
            <TypeButton text="UBDI Server" />
            <Text t1>
              This means that the data you send will be stored only on UBDI
              servers and will not be forwarded anywhere else
            </Text>
          </Spacing>
          <Spacing size="l">
            <TypeButton text="Company Server" backgroundColor="#A1AAB7" />
            <Text t1>
              This means that the data you send will be aggregated and stored on
              a server outside of UBDI
            </Text>
          </Spacing>
        </Spacing>
        <Button style={{ width: 200 }} onClick={onClick}>
          <Text h4 color="white">
            Got it
          </Text>
        </Button>
      </Spacing>
    </DataStoreWrap>
  )
}

const DataStoreWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xxl}`};
`
