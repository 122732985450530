import React, { useState } from 'react'
import styled from 'styled-components'
import {
  reverse,
  map,
  prop,
  pipe,
  range,
  keys,
  find,
  toPairs,
  filter
} from 'ramda'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'

import {
  Button,
  CheckList,
  Input,
  RadioList,
  SelectDropdown,
  Spacing,
  TagGraphic,
  Text
} from '../ui'
import { LocationFinder } from './LocationFinder'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  box-shadow: ${({ theme }) => theme.boxShadow.default};
`

const SmallButton = styled(Button)`
  padding: 5px 8px;
`

const RoundButton = styled(Button)`
  padding: 8px 20px;
  border-radius: 40px;
`

const ExpandHeaderButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.m}`};
  flex-grow: 1;
`
const AnswersWrap = styled.div`
  max-height: 220px;
  overflow-y: auto;
`

const TraitEntryValueWrap = styled.div`
  margin: ${({ theme }) => theme.padding.m};
  padding-top: ${({ theme }) => theme.padding.m};
  margin-top: 0;
  border-top: ${({ theme }) => theme.color.greyL1} 1px solid;
`

const checkIsDirty = ({ dirtyFields, uniqueId }) =>
  !!pipe(
    keys,
    find(id => id.includes(uniqueId))
  )(dirtyFields)

const checkIsFilled = ({ values, uniqueId }) => {
  return !!pipe(
    toPairs,
    filter(item => item[0].includes(uniqueId) && item[1].length > 0),
    map(item => {
      return {
        key: item[0],
        values: item[1]
      }
    }),
    find(({ key }) => key.includes(uniqueId))
  )(values)
}

export const TraitEntry = ({
  question,
  label,
  uniqueId,
  handleSubmit,
  form,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(current => !current)

  const { dirtyFields, values } = form.getState()
  const dirty = checkIsDirty({ dirtyFields, uniqueId })

  const onRemove = () => {
    if (uniqueId === 'general_location') {
      form.change('general_location_city', [])
      form.change('general_location_country', [])
      form.change('general_location_state', [])
      form.change('general_location_zip', [])
    } else {
      form.change(uniqueId, [])
    }
    handleSubmit()
  }

  return (
    <Wrap>
      <ExpandHeaderButton expanded={expanded} onClick={toggleExpanded}>
        <Spacing direction="row" align="center" justify="space-between">
          <Spacing direction="row" align="center">
            <TagGraphic uniqueId={uniqueId} />
            <Text>{label}</Text>
          </Spacing>
          {expanded ? (
            <RoundButton outline>Close</RoundButton>
          ) : checkIsFilled({ values, uniqueId }) ? (
            <RoundButton outline>Edit</RoundButton>
          ) : (
            <RoundButton>Add</RoundButton>
          )}
        </Spacing>
      </ExpandHeaderButton>
      {expanded && (
        <TraitEntryValueWrap>
          <Spacing>
            <TraitEntryValue uniqueId={uniqueId} form={form} {...props} />
            <Spacing direction="row" justify="flex-end">
              {dirty ? (
                <Button onClick={handleSubmit}>Save trait</Button>
              ) : (
                checkIsFilled({ values, uniqueId }) && (
                  <Button outline onClick={onRemove}>
                    Remove trait
                  </Button>
                )
              )}
            </Spacing>
          </Spacing>
        </TraitEntryValueWrap>
      )}
    </Wrap>
  )
}

const TraitEntryValue = ({
  canEdit,
  multi,
  children,
  uniqueId,
  custom,
  subfields,
  form
}) => {
  const singleFieldProps = {
    format: values => values && values[0],
    parse: value => [value]
  }

  if (canEdit === false) {
    return (
      <Field
        name={uniqueId}
        render={({ input: { value } }) => (
          <Text t0>
            {children
              .filter(({ uniqueId }) => value.includes(uniqueId))
              .map(prop('label'))
              .join(',')}
          </Text>
        )}
      />
    )
  }

  if (custom) {
    switch (custom) {
      case 'age_picker': {
        const ages = pipe(
          range(1900),
          reverse,
          map(year => ({
            label: year.toString(),
            value: year.toString()
          }))
        )(new Date().getFullYear() - 12)

        return (
          <Field
            name={uniqueId}
            options={ages}
            component={SelectDropdown}
            label="Select year of birth"
          />
        )
      }

      case 'location_finder': {
        return (
          <LocationFinder
            subfields={subfields}
            form={form}
            singleFieldProps={singleFieldProps}
          />
        )
      }

      case 'text': {
        if (!multi) {
          return (
            <Field
              name={uniqueId}
              component={Input}
              block
              {...singleFieldProps}
              autoFocus
            />
          )
        }

        return (
          <FieldArray name={uniqueId}>
            {({ fields }) => (
              <Spacing>
                <Spacing>
                  {fields.map((name, index) => (
                    <Spacing
                      key={name}
                      direction="row"
                      align="center"
                      justify="space-between"
                    >
                      <Field
                        key={name}
                        name={name}
                        label={`Custom tag #${index + 1}`}
                        component={Input}
                        autoFocus={index === 0}
                      />
                      <SmallButton onClick={() => fields.remove(index)} outline>
                        <Text h7>remove</Text>
                      </SmallButton>
                    </Spacing>
                  ))}
                </Spacing>
                <SmallButton onClick={() => fields.push()} outline>
                  {!fields.value ? '+ Add' : '+ Add more'}
                </SmallButton>
              </Spacing>
            )}
          </FieldArray>
        )
      }
      default:
        return null
    }
  }

  if (multi) {
    return (
      <AnswersWrap>
        <CheckList
          uniqueId={uniqueId}
          options={children.map(({ uniqueId, inputLabel, label, ...rest }) => ({
            value: uniqueId,
            label: inputLabel || label,
            ...rest
          }))}
        />
      </AnswersWrap>
    )
  }

  return (
    <AnswersWrap>
      <RadioList
        uniqueId={uniqueId}
        options={children.map(({ uniqueId, inputLabel, label, ...rest }) => ({
          value: uniqueId,
          label: inputLabel || label,
          ...rest
        }))}
      />
    </AnswersWrap>
  )
}
