import React from 'react'
import { pipe, propOr, map, any, complement, isNil, path, pathOr } from 'ramda'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useQuery } from '@apollo/react-hooks'

import {
  RadioList,
  Spacing,
  GifRadioList,
  Notice
} from '../../../../../components/ui'
import { withMutation } from '../../../../../components/wrappers'
import { PollResults } from '.'
import { UserMetaQuery, UserQuery } from '../../../../../state/queries'
import { getSpecificTagCategoriesValues } from '../../../../../state/selectors'

const fieldId = 'pollFieldId'
const Poll = ({
  poll,
  pollVote,
  tribe,
  showSubmitOnSelect = false,
  ...props
}) => {
  const { error: isUnauthorizedUser, loading } = useQuery(UserMetaQuery)
  const { data: currentUserData } = useQuery(UserQuery, {
    skip: loading || isUnauthorizedUser
  })

  const userTags = pathOr(
    [],
    ['currentUser', 'member', 'tags'],
    currentUserData
  )

  const filterCategories = propOr([], 'filterCategories', tribe)
  const hasVoted = propOr(false, 'voted', poll)

  const tribeSpecificTags = getSpecificTagCategoriesValues(
    userTags,
    filterCategories
  )
  const options = propOr([], 'options', poll)

  const hasGifs = pipe(
    map(path(['choice', 'gifUrl'])),
    any(complement(isNil))
  )(options)

  const onVote = values =>
    pollVote({
      variables: {
        pollId: poll.id,
        options: values[fieldId],
        tags: tribeSpecificTags
      }
    })

  if (hasVoted || isUnauthorizedUser) {
    return (
      <Spacing>
        <PollResults poll={poll} {...props} />
        {isUnauthorizedUser && (
          <Notice>Sign up or Log in to cast your vote in the poll</Notice>
        )}
      </Spacing>
    )
  }

  return (
    <Form mutators={arrayMutators} onSubmit={onVote} validate={validate}>
      {({ handleSubmit }) => (
        <Spacing>
          {hasGifs ? (
            <GifRadioList
              uniqueId={fieldId}
              options={options.map(({ choice, id }) => ({
                choice,
                value: id
              }))}
              onChange={handleSubmit}
              noShadow
            />
          ) : (
            <RadioList
              uniqueId={fieldId}
              options={options.map(({ choice: { text }, id }) => ({
                label: text,
                value: id
              }))}
              size="xs"
              noShadow
              hideBox
              onChange={handleSubmit}
            />
          )}
        </Spacing>
      )}
    </Form>
  )
}

const validate = values => {
  if (!values[fieldId] || !values[fieldId].length) {
    return { [fieldId]: 'Must select one option' }
  }
}

export default pipe(withMutation('pollVote'))(Poll)
