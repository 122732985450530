import { gql } from 'apollo-boost'
import {
  CommunityPollFragment,
  CommunityPollCardFragment
} from '../fragments/CommunityPoll'
import { UserGroupFragment } from '../fragments/UserGroup'
import { CommunityPollCommentFragment } from '../fragments/CommunityPollComment'

export const AllCommunityPollsQuery = gql`
  query AllCommunityPolls(
    $tribeId: String
    $status: String
    $first: Int
    $after: Int
    $withCompetitions: Boolean
    $memberId: String
    $justFollowingMembers: Boolean
    $containingWords: [String]
  ) {
    allCommunityPolls(
      tribeId: $tribeId
      status: $status
      after: $after
      first: $first
      withCompetitions: $withCompetitions
      memberId: $memberId
      justFollowingMembers: $justFollowingMembers
      containingWords: $containingWords
    ) {
      id
      ...CommunityPollCard
    }
  }

  ${CommunityPollCardFragment}
`

export const CommunityPollQuery = gql`
  query CommunityPoll($id: String!) {
    communityPoll(id: $id) {
      id
      ...CommunityPoll
    }
  }
  ${CommunityPollFragment}
`

export const CommunityPollFilteredQuery = gql`
  query CommunityPollFiltered($id: String!, $tags: VotesTagFilter) {
    communityPoll(id: $id) {
      id
      createdAt
      title
      reactions {
        votes
        currentMemberReaction
      }
      comments {
        id
        ...CommunityPollComment
        comments {
          id
          ...CommunityPollComment
        }
      }
      totalComments
      viewCount
      tribe {
        ...UserGroup
      }
      status
      videoUrl
      uploadedImage {
        thumbnail
        default
        large
      }
      externalUrlData {
        url
        shortUrl
        ogImage
        ogTitle
        embedCode
      }
      poll {
        id
        question
        options {
          id
          choice {
            text
            gifUrl
          }
          voted
          votesPerCategory(tags: $tags) {
            uniqueId
            count
          }
          totalVotes
        }
        voted
        totalVotes
      }
    }
  }
  ${UserGroupFragment}
  ${CommunityPollCommentFragment}
`
