import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import ReactModal from 'react-modal'

import { Text, Graphic } from '../../../components/ui'
import theme from '../../../theme'

ReactModal.setAppElement('#root')

const customStyles = {
  content: {
    minWidth: '500px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.borderRadius.large,
    padding: 0,
    maxHeight: '90%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 100
  }
}

const getModalStyles = ({ fullScreen }) => {
  if (fullScreen) {
    return {
      ...customStyles,
      content: {
        ...customStyles.content,
        minHeight: '95%',
        minWidth: '95%'
      }
    }
  }
  return customStyles
}

const ModalHeader = styled.div`
  background: ${({ theme }) => theme.color.purpleL4};
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
`

const ModalBody = styled.div`
  padding: ${({ theme, noPadding }) => noPadding ? 0 : `${theme.padding.xl} ${theme.padding.xl} 0`};
`

const ModalFooter = styled.div`
  background: ${({ theme }) => theme.color.white};
  margin-top: ${({ theme }) => theme.padding.xl};
  padding: ${({ theme }) => theme.padding.xl} 0;
  border-top: 1px solid ${({ theme }) => theme.color.greyL2};
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
`

const Close = styled.a`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.purpleL3};
  cursor: pointer;
  color: ${({ theme }) => theme.color.main};

  &:hover {
    background: ${({ theme }) => theme.color.main};
    color: #fff;
  }
`

export const Modal = ({ children, title, footer, onDismiss, noPadding, ...props  }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => (document.body.style.overflow = 'unset')
  }, [])

  return (
    <ReactModal {...props} style={getModalStyles(props)}>
      <ModalHeader>
        <Text h5>{title}</Text>
        <Close onClick={onDismiss}>
          <Graphic name="X" size={12} />
        </Close>
      </ModalHeader>
      <ModalBody noPadding={noPadding}>{children({ onDismiss })}</ModalBody>
    </ReactModal>
  )
}

Modal.Footer = ModalFooter
