import React from 'react'
import { graphql } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks'
import { filter, includes, length, map, pathOr, pipe, reduce } from 'ramda'
import styled from 'styled-components'
import { Graphic, Spacing } from '../../../components/ui'
import { AudienceSchemaQuery, UserQuery } from '../../../state/queries'
import { retrofitAudienceSchema } from '../../../state/selectors'
import { StatsCard } from './StatsCard'

const EXCLUDED_TAGS = ['system_groups', 'revoked_system_groups', 'dataverified']

const isTagExcluded = uniqueId =>
  !!pipe(
    map(tag => includes(tag, uniqueId)),
    filter(Boolean),
    length
  )(EXCLUDED_TAGS)

const StatsInner = ({ sources, profileSchema }) => {
  const { data, loading } = useQuery(UserQuery)

  if (loading || profileSchema.original.length === 0) {
    return (
      <LoadingWrapper>
        <Graphic name="Loading" width={150} height={60} themeColor="main" />
      </LoadingWrapper>
    )
  }

  const linkedDataSources = pathOr(
    [],
    ['currentUser', 'member', 'linkedDataSources'],
    data
  )

  const userTags = pipe(
    pathOr([], ['currentUser', 'member', 'tags']),
    filter(({ uniqueId }) => !isTagExcluded(uniqueId))
  )(data)

  const totalTags = reduce((acc, value) => {
    if (value.uniqueId !== 'custom') {
      return acc + value.children.length
    }
    return acc
  }, 0)(profileSchema.original)

  const STATS = [
    {
      label: 'linked sources',
      value: linkedDataSources.length,
      limit: sources.length
    },
    {
      label: 'added traits',
      value: userTags.length,
      limit: totalTags
    },
    {
      label: 'overall score',
      value: `${Math.round(
        ((linkedDataSources.length + userTags.length) /
          (sources.length + totalTags)) *
          100
      )}%`
    }
  ]

  return (
    <Spacing direction="row" size="s" stretchChildren>
      {STATS.map(stat => (
        <StatsCard key={stat.label} {...stat} />
      ))}
    </Spacing>
  )
}

export const Stats = pipe(
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema })
)(StatsInner)

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`
