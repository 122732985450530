import { useQuery } from '@apollo/react-hooks'
import { filter, map, isEmpty, pipe, pathOr, isNil } from 'ramda'
import { EligibleOpportunitiesQuery } from '../state/queries'
import { getAllAccountsFromLocalStorage } from '../util'

export const useMatchedOpportunities = (query, config) => {
  const accounts = getAllAccountsFromLocalStorage()

  const {
    data: allOpportunitesData,
    loading: allOpportunitiesLoading,
    refetch,
    ...apolloPropsAllOpportunities
  } = useQuery(query, config)

  const allOpportunitesEdges = pathOr(
    [],
    ['allOpportunities', 'edges'],
    allOpportunitesData
  )

  const opportunityIds = pipe(
    map(pathOr(false, ['node', 'id'])),
    filter(Boolean)
  )(allOpportunitesEdges)

  const { data, loading: allEligibleOpportunitesLoading } = useQuery(
    EligibleOpportunitiesQuery,
    {
      skip: isEmpty(opportunityIds) || isNil(accounts),
      pollInterval: 10000, // 10 sec
      variables: { opportunityIds, shortIds: [], accounts } // shortIds is DEPRECATED (now from API) but is required in schema
    }
  )

  const allOpportunities = pathOr([], ['allEligibleOpportunities'], data)

  return {
    ...apolloPropsAllOpportunities,
    refetch,
    loading: allOpportunitiesLoading || allEligibleOpportunitesLoading,
    allOpportunities
  }
}
