import gql from 'graphql-tag'

export default gql`
  mutation VerifyPhoneNumber($code: String!) {
    verifyPhoneNumber(code: $code) {
      success
      query {
        currentUser {
          id
          verified
        }
      }
      errors {
        key
        message
      }
    }
  }
`
