import React from 'react'
import styled from 'styled-components'
import { FormatNumber } from '../../../util'
import { Spacing } from '../Spacing'
import { Text } from '../Text'

const GradientWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.m};
  border-radius: 10px;
  background: ${({ gradient, theme }) =>
    gradient ? gradient : theme.gradient.gradient1};
`

export const GradientCard = ({
  title,
  value,
  gradient,
  textColor = 'white'
}) => (
  <GradientWrapper gradient={gradient}>
    <Spacing size="m">
      <Text t3 color={textColor}>
        {title}
      </Text>
      <Text h2 color={textColor}>
        <FormatNumber currency={title}>{parseFloat(value)}</FormatNumber>
      </Text>
    </Spacing>
  </GradientWrapper>
)
