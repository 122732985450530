import React from 'react'
import { Field } from 'react-final-form'
import { graphql } from 'react-apollo'
import { pipe, isEmpty, isNil } from 'ramda'
import styled from 'styled-components/macro'

import { DataStreamCard } from '../../../../../../DataBank/DataStream/components'
import {
  Box,
  Graphic,
  Input,
  Spacing,
  Text
} from '../../../../../../../components/ui'
import { AudienceDataVerifiedTagsWithDataSchemaQuery } from '../../../../../../../state/queries'
import { getTribeDataSources } from '../../../../../../../state/selectors'
import TribeSourcesList from '../../../../Tribes/components/TribeSourceList'
import { useModal } from '../../../../../../../hooks/useModal'

const CustomInput = styled(Input)`
  ${({ theme }) => theme.text.t1};
  border: 2px solid transparent;
  background: ${({ theme }) => theme.color.white};
  padding-left: 0px;
  width: 100%;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
  }
`

const BoxTextColor = styled.div`
  margin: auto;
  width: 70%;
`

const XButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  cursor: pointer;
`
const XButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid ${({ theme }) => theme.color.dark1};
  width: 28px;
  height: 28px;
`

const NewDataPost = ({
  tribeId,
  TEXT_MAX_LENGTH,
  submitting,
  data: { audienceDataVerifiedTags }
}) => {
  const { openModal } = useModal()

  const maybeRenderTribeContent = ({ postData, onChange }) => {
    const { dataSourceId, data = {} } = postData

    if (!tribeId) {
      return (
        <Box noShadow size="xl" backgroundColor="purpleL3">
          <BoxTextColor>
            <Text t1 center>
              {postData
                ? 'Please select a tribe to which you wish to post this data to'
                : 'Please select a tribe to see the data sources you can post your data from'}
            </Text>
          </BoxTextColor>
        </Box>
      )
    }

    if (!dataSourceId) {
      const allTribeSources = getTribeDataSources({
        audienceDataVerifiedTags,
        tribeId
      })

      return (
        <TribeSourcesList
          dataSources={allTribeSources}
          onDataSourceLinkedClick={dataSource => {
            onChange({ ...postData, dataSourceId: dataSource.id })
            openModal('ShareDataStream', {
              dataSource,
              onShare: ({ data, objectTypeId }) =>
                onChange({
                  dataSourceId: dataSource.id,
                  data,
                  objectTypeId
                }),
              onShareDismiss: () =>
                onChange({
                  dataSourceId: null
                })
            })
          }}
        />
      )
    }

    if (dataSourceId && isEmpty(data)) {
      return <Text t3>Waiting for selected data to share...</Text>
    }

    return null
  }

  return (
    <Field
      name="postData"
      render={({ input: { value: postData, onChange } }) => (
        <Spacing>
          {maybeRenderTribeContent({ postData, onChange })}
          {postData.dataSourceId && !isNil(postData.data) && (
            <Spacing size="xl">
              <Spacing direction="row" size="xs" justify="space-between">
                <Text h6>Your data activity</Text>
                <XButton onClick={() => !submitting && onChange({})}>
                  <XButtonWrap>
                    <Graphic name="X" size={12} themeColor="dark1" />
                  </XButtonWrap>
                </XButton>
              </Spacing>
              <DataStreamCard
                key
                objectTypeId={postData.objectTypeId}
                dataSourceId={postData.dataSourceId}
                data={postData.data}
              />
              <Field
                name="title"
                component={CustomInput}
                placeholder="Start writing your post..."
                meta={{}}
                disabled={submitting}
                maxLength={TEXT_MAX_LENGTH}
                textarea
              />
            </Spacing>
          )}
        </Spacing>
      )}
    />
  )
}

export default pipe(graphql(AudienceDataVerifiedTagsWithDataSchemaQuery))(
  NewDataPost
)
