import React, { useState } from 'react'
import { equals, pipe, propOr } from 'ramda'
import styled from 'styled-components/macro'
import { graphql } from 'react-apollo'
import { Spacing } from '../../../components/ui'
import {
  getTagFromProfileSchema,
  retrofitAudienceSchema
} from '../../../state/selectors'
import { AudienceSchemaQuery } from '../../../state/queries'
import FilterCategory from './FilterCategory'

const FilterCategoryWrap = styled.div`
  padding-top: ${({ theme }) => theme.padding.s};
  cursor: pointer;
`

const NO_FILTER_CATEGORY = {
  uniqueId: 'no-results'
}

const FilterResultsInner = ({ input, profileSchema, tribe, children }) => {
  const [expandedCategory, setExpandedCategory] = useState(NO_FILTER_CATEGORY)

  const resetExpandedCategory = () => {
    setExpandedCategory(NO_FILTER_CATEGORY)
    return input.onChange([])
  }

  const onSetExpandCategory = tag => {
    if (!tag) {
      return resetExpandedCategory()
    }

    setExpandedCategory(tag)
    input.onChange([{ uniqueId: tag.uniqueId, values: [] }])
  }

  if (!tribe || !tribe.filterCategories || profileSchema.loading) return null

  const categories = [NO_FILTER_CATEGORY.uniqueId, ...tribe.filterCategories]

  return (
    <Spacing size="s">
      <Spacing size="s" direction="row" wrap>
        {categories.map(uniqueId => {
          const isExpandedCategory = equals(
            uniqueId,
            propOr('', 'uniqueId', expandedCategory)
          )

          return (
            <FilterCategoryWrap key={uniqueId}>
              <FilterCategory
                tag={getTagFromProfileSchema(profileSchema, uniqueId)}
                setExpandedCategory={onSetExpandCategory}
                isExpandedCategory={isExpandedCategory}
              />
            </FilterCategoryWrap>
          )
        })}
      </Spacing>
      {expandedCategory !== NO_FILTER_CATEGORY
        ? children({ category: expandedCategory, input, resetExpandedCategory })
        : null}
    </Spacing>
  )
}

export default pipe(
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema })
)(FilterResultsInner)
