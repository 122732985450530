import {
  prop,
  pipe,
  map,
  filter,
  sortBy,
  isEmpty,
  find,
  isNil,
  propEq,
  pathEq,
  reduce,
  uniq,
  unnest
} from 'ramda'
import { getAvailableServiceProviders } from '../local/serviceProviders'

export const getSelectedDataSource = (sources, dataSourceId) =>
  find(propEq('id', dataSourceId))(sources)

export const isSourceLinked = (id, sources) =>
  pipe(
    find(pathEq(['dataSource', 'id'], id)),
    linked => !isNil(linked) && linked.accounts && linked.accounts.length > 0
  )(sources)

export const getInitialSourceStatuses = reduce(
  (acc, { id, linked }) => ({
    ...acc,
    [id]: !linked ? 'pending' : 'completed'
  }),
  {}
)

export const filterAndSortDataSources = (sources, membersLinkedDataSources) =>
  pipe(
    map(({ node }) => ({
      ...node,
      availableServiceProviders: getAvailableServiceProviders(node.id),
      linked: isSourceLinked(node.id, membersLinkedDataSources)
    })),
    filter(
      ({ availableServiceProviders }) => !isEmpty(availableServiceProviders)
    ),
    sortBy(prop('linked'))
  )(sources)

export const getTribeDataSources = ({
  audienceDataVerifiedTags = [],
  tribeId
}) => {
  const tribeTags = audienceDataVerifiedTags.filter(({ uniqueId }) =>
    uniqueId.startsWith(tribeId)
  )

  return uniq(
    unnest(
      tribeTags.map(({ queries }) => queries.map(query => query.dataSourceIds))
    )
  )
}
