import React from 'react'
import { Spacing } from '../../../components/ui'
import { EmptyFollowerList } from './EmptyFollowerList'
import { FollowersListItem } from './FollowersListItem'

export const FollowersList = ({ data, currentUser, emptyText }) => {
  if (!data || data.length === 0) {
    return <EmptyFollowerList text={emptyText} />
  }
  return (
    <Spacing size="s">
      {data.map((item, index) => {
        const isLastItem = !!(index === data.length - 1)
        return (
          <FollowersListItem
            key={`${item.id}_${index}`}
            item={item}
            currentUser={currentUser}
            isLastItem={isLastItem}
          />
        )
      })}
    </Spacing>
  )
}
