import gql from 'graphql-tag'

const mutation = gql`
  mutation ConnectUphold($code: String!) {
    connectUphold(code: $code) {
      success
      query {
        currentUser {
          id
          member {
            id
            uphold {
              email
            }
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export default mutation
