import React, { useState } from 'react'
import { isEmpty, map } from 'ramda'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components/macro'

import { Graphic, Spacing, Text } from '../../../components/ui'
import { CommentCard } from '.'

const CommentReplyWrapper = styled.div`
  padding-left: ${({ theme }) => theme.padding.xl};
`

const EmptyComments = styled.div`
  padding-bottom: 50px;
`

const LIMIT = 5

const PostComments = ({
  id,
  comments,
  totalComments,
  setReplyingToCommentId,
  selectedComment
}) => {
  const [page, setPage] = useState(1)

  const dataLimit = page * LIMIT
  const hasComments = !!comments.length

  return (
    <Spacing size="l">
      <Spacing size="xxs" direction="row">
        <Text h5>Comments </Text>
        <Text h5 color="grey">
          ({totalComments})
        </Text>
      </Spacing>
      {hasComments ? (
        <InfiniteScroll
          style={{ paddingBottom: 100 }}
          hasMore={dataLimit < comments.length}
          loadMore={setPage}
          pageStart={1}
          loader={<Loader key="loader" />}
        >
          <Spacing size="s">
            {comments.slice(0, dataLimit).map(comment => (
              <Spacing size="s" key={comment.id}>
                <CommentCard
                  comment={comment}
                  communityPollId={id}
                  onCommentReply={() => setReplyingToCommentId(comment.id)}
                  isSelected={comment.id === selectedComment}
                />
                {!isEmpty(comment.comments) && (
                  <CommentReplyWrapper>
                    <Spacing size="s">
                      {map(commentReply => (
                        <CommentCard
                          key={commentReply.id}
                          comment={commentReply}
                          communityPollId={id}
                          parentCommentId={comment.id}
                          onCommentReply={() =>
                            setReplyingToCommentId(comment.id)
                          }
                          isSelected={commentReply.id === selectedComment}
                        />
                      ))(comment.comments)}
                    </Spacing>
                  </CommentReplyWrapper>
                )}
              </Spacing>
            ))}
          </Spacing>
        </InfiniteScroll>
      ) : (
        <EmptyComments />
      )}
    </Spacing>
  )
}
export default PostComments

const Wrapper = styled.div`
  padding: 20px 10px;
  text-align: center;
`

const Loader = () => (
  <Wrapper>
    <Graphic name="Loading" width={150} height={60} themeColor="main" />
  </Wrapper>
)
