export const notificationOptions = [
  {
    label: 'New Followers',
    name: 'newFollowers'
  },
  {
    label: 'New Posts',
    name: 'newPosts'
  },
  {
    label: 'Published Poll',
    name: 'liveCommunityPoll'
  },
  {
    label: 'Poll Comments',
    name: 'commentCommunityPoll'
  },
  {
    label: 'Poll Comment Replies',
    name: 'replyCommentCommunityPoll'
  },
  {
    label: 'Poll Reactions',
    name: 'reactCommunityPoll'
  },
  {
    label: 'Poll Votes',
    name: 'voteCommunityPoll'
  }
]
