import { isEmpty, isNil } from 'ramda'
import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, Text } from '../../../components/ui'
import { CompetitionEntry } from './'

export const CompetitionEntriesList = ({ post }) => {
  const {
    competition,
    id: communityPollId,
    competition: { entries }
  } = post

  if (isNil(entries) || isEmpty(entries)) {
    return (
      <EmptyWrap>
        <TextWrap>
          <Text color="dark1" h6>
            There are no any competition entries yet.
          </Text>
        </TextWrap>
      </EmptyWrap>
    )
  }
  return (
    <HorizontalScroll>
      <Spacing direction="row" stretchChildren size="s">
        {entries.map(entry => (
          <CompetitionEntry
            key={entry.id}
            entry={entry}
            competition={competition}
            communityPollId={communityPollId}
          />
        ))}
      </Spacing>
    </HorizontalScroll>
  )
}

const EmptyWrap = styled.div`
  display: flex;
  flex: 1;
  height: 170px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.purpleL3};
  border-radius: 10px;
  padding: ${({ theme }) => `0px ${theme.padding.xl}`};
`

const TextWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const HorizontalScroll = styled.div`
  display: flex;
  overflow: scroll;
  padding: 0px ${({ theme }) => theme.padding.xs};
  padding-bottom: ${({ theme }) => theme.padding.m};
`
