import React from 'react'

import { Text } from '../../ui'
import { useQuery } from '@apollo/react-hooks'

export const RenderFromQuery = ({
  renderError = defaultRenderError,
  renderLoading = defaultRenderLoading,
  renderEmpty = defaultRenderEmpty,
  emptyValidator = defaultEmptyValidator,
  renderData,
  query,
  ...queryProps
}) => {
  if (!query) throw new Error('No Query provided')

  const { error, loading, data, ...rest } = useQuery(query, { ...queryProps })

  if (error) {
    return renderError(error)
  }

  if (loading) {
    return renderLoading()
  }

  if (emptyValidator(data)) {
    return renderEmpty()
  }

  return renderData(data, { loading, ...rest })
}

const defaultEmptyValidator = (data) => {
  const firstQueryData = data[Object.keys(data)[0]]
  return (
    firstQueryData && firstQueryData.edges && firstQueryData.edges.length === 0
  )
}

const defaultRenderEmpty = () => <div>No results.</div>

const defaultRenderLoading = () => null

const defaultRenderError = (error) => (
  <Text color="redNegative">
    {error.message}
    {error.networkError && `Network Error: ${error.networkError.toString()}`}
  </Text>
)
