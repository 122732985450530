/*
  Component for loading and styling custom SVG graphics

  Flow for adding new graphic:
    1) Go to SVGOMG and minify the SVG
    2) Paste minified SVG into https://www.smooth-code.com/open-source/svgr/playground/
    3) Paste the component into ./graphics/YourGraphic.svg
    4) Export the created component from ./graphics/index.js
*/

import React from 'react'
import { path, pipe, equals, type } from 'ramda'
import styled from 'styled-components/macro'

import theme from '../../../theme'

import graphics from './graphics'
import pngs from './pngs'

const isArray = pipe(type, equals('Array'))

const getGraphic = (name, source) => {
  const graphic = path(isArray(name) ? name : [name])(source)

  return graphic
}

export const Graphic = ({
  name,
  png,
  size,
  width,
  height,
  themeColor,
  color,
  src,
  ...props
}) => {
  if (png) {
    const graphic = getGraphic(name, pngs)
    if (!graphic) {
      console.error(`Missing PNG ${name}`)
      return null
    }

    return (
      <Image
        src={src || graphic}
        width={width || size}
        height={height || size}
      />
    )
  }

  const RenderGraphic = getGraphic(name, graphics)
  if (!RenderGraphic) {
    console.error(`Missing PNG ${name}`)
    return null
  }

  return (
    <RenderGraphic
      {...props}
      width={width || size}
      height={height || size}
      color={color || (themeColor && theme.color[themeColor])}
    />
  )
}

const Image = styled.img.attrs(({ src, width, height }) => ({
  src: src,
  width: `${width ? `${width}px` : '100%'}`,
  height: `${height ? `${height}px` : '100%'}`
}))`
  object-fit: contain;
`
