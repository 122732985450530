import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import pluralize from 'pluralize'
import { pathOr, prop } from 'ramda'

import { getAgeRange } from '../../../util'
import { Spacing, TagGraphic, Text } from '../../ui'
import { getTagValue, getTagValues } from '../../../state/selectors'
import { TagCountQuery } from '../../../state/queries/TagCount'

const TextWrap = styled(Text)`
  color: ${({ isDataVerified, theme }) =>
    isDataVerified ? theme.color.darkBlue : theme.color.main};
`

const LabelWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.m}`};
  background-color: ${({ isDataVerified, theme }) =>
    isDataVerified ? theme.color.lightBlue : theme.color.purpleL3};
  border-radius: 5px;
  margin: ${({ theme }) => `0 ${theme.padding.m} ${theme.padding.xs} 0`};
`
const TagIconWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.m}`};
  background-color: ${({ isDataVerified, theme }) =>
    isDataVerified ? theme.color.lightBlue : theme.color.purpleL3};
  border-radius: 10px;
`

export const TagValue = ({
  profileSchema,
  isDataVerified,
  tagValues = [],
  tags,
  uniqueId,
  tag,
  showIcon,
  showStats
}) => {
  const renderTagValue = (tagValues, uniqueId) => {
    if (uniqueId === 'general_location') {
      const country = getTagValues(
        tags,
        profileSchema,
        'general_location_country'
      )
      const city = getTagValues(tags, profileSchema, 'general_location_city')

      return [city && city[0], country && country[0]]
        .filter(Boolean)
        .map(prop('label'))
        .join(', ')
    } else if (uniqueId === 'general_age') {
      const yearOfBirth = parseInt(tagValues[0].label)

      return getAgeRange(yearOfBirth)
    }

    return tagValues.map(prop('label')).join(', ')
  }

  if (showIcon) {
    const value = getTagValue(tag.uniqueId, profileSchema)

    return (
      <TagIconWrap isDataVerified={isDataVerified}>
        <Spacing direction="row" align="center" justify="space-between">
          <Spacing size="m" direction="row" align="center">
            <TagGraphic size={32} uniqueId={uniqueId} />
            <TextWrap h6 isDataVerified={isDataVerified} bold>
              {tagValues.length > 1
                ? pathOr(value, ['label'], tag)
                : renderTagValue(tagValues, uniqueId)}
            </TextWrap>
          </Spacing>
          {showStats && <Stats categoryId={uniqueId} value={tag.uniqueId} />}
        </Spacing>
      </TagIconWrap>
    )
  }

  return (
    <LabelWrap isDataVerified={isDataVerified}>
      <TextWrap h7 isDataVerified={isDataVerified} bold>
        {renderTagValue(tagValues, uniqueId)}
      </TextWrap>
    </LabelWrap>
  )
}

const Stats = ({ categoryId, value }) => {
  const { data: { tagCount } = {}, loading } = useQuery(TagCountQuery, {
    variables: { categoryId, value }
  })

  if (loading) {
    return null
  }

  return (
    <Spacing size="xxs" alignItems="center" justify="center">
      <Text h6 bold center numberOfLines={1}>
        {tagCount.count.toLocaleString()}
      </Text>
      <Text t4>{pluralize('member', tagCount.count)}</Text>
    </Spacing>
  )
}
