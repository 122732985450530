import React, { useEffect } from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components/macro'

import { Modal } from '../../components/ui'
import { UBDI_TRIBE_ID } from '../../screens/TribeDetails/_utils'
import { AllFollowingQuery } from '../../state/queries'
import { FollowersList } from '../Followers/components/FollowersList'

const ModalWrap = styled.div`
  width: 600px;
  min-height: 400px;
  position: relative;
  max-height: 750px;
  height: auto;
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.padding.l};
`

export const Following = ({ onDismiss, params = {} }) => {
  const { member, currentUser } = params
  const { data: { allFollowing } = {}, loading, refetch } = useQuery(
    AllFollowingQuery,
    {
      variables: {
        followerMemberId: member.id,
        tribeId: UBDI_TRIBE_ID
      }
    }
  )

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return null

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      title={`Following (${allFollowing.length})`}
    >
      {() => (
        <ModalWrap>
          <FollowersList
            data={allFollowing}
            currentUser={currentUser}
            emptyText="Not following anyone yet."
          />
        </ModalWrap>
      )}
    </Modal>
  )
}
