import { findIndex, propEq } from 'ramda'
export const TAG_COLOR = [
  '#6B82FC',
  '#FFB36D',
  '#F26C9C',
  '#46B385',
  '#D16E58',
  '#62B4FF',
  '#FF6A61',
  '#AF6CF2',
  '#7DD653',
  '#7195F2'
]

export const getSelectedTagColor = (uniqueId, votes) => {
  const tagIndex = findIndex(propEq('uniqueId', uniqueId))(votes)
  return TAG_COLOR[tagIndex % 5]
}
