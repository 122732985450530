import { pipe } from 'ramda'
import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import {
  Back,
  Button,
  Checkbox,
  RenderFromQuery,
  Spacing,
  Text
} from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { UserQuery } from '../../../state/queries'
import theme from '../../../theme'
import { notificationOptions } from '../constants'

const ArrowWrap = styled.div`
  &:hover {
    opacity: 0.8;
  }
`

const ListWrap = styled.div`
  display: block;
  text-align: right;
`

const NotificationPreferencesInner = ({
  setShowNotificationPreferences,
  updateNotificationSubscriptions
}) => {
  const onSave = variables =>
    updateNotificationSubscriptions({ variables }).then(
      async ({ errors, userErrors, data }) => {
        if (data && data.updateNotificationSubscriptions.success)
          return setShowNotificationPreferences(false)
        return errors || userErrors
      }
    )

  return (
    <Spacing size="xxl">
      <Spacing direction="row" size="l" align="center">
        <ArrowWrap onClick={() => setShowNotificationPreferences(false)}>
          <Back
            backRouteName="account"
            background="white"
            borderColor={theme.color.main}
          />
        </ArrowWrap>
        <Text h2>Notification Preferences</Text>
      </Spacing>
      <Text t0>Select the items you want to be notified about.</Text>
      <RenderFromQuery
        query={UserQuery}
        renderData={({
          currentUser: {
            member: { subscribedNotificationTopics }
          }
        }) => (
          <Form
            onSubmit={onSave}
            initialValues={{ ...subscribedNotificationTopics }}
            render={({ handleSubmit, invalid, submitting }) => (
              <ListWrap>
                <Spacing size="xl">
                  <Spacing size="l">
                    {notificationOptions.map(option => (
                      <Field
                        key={option.name}
                        component={Checkbox}
                        type="checkbox"
                        label={option.label}
                        name={option.name}
                        stretched
                        reversed
                        isRadio
                        textProps={{ t1: true }}
                        labelProps={`padding: 20px;`}
                        checkboxProps={`height: 31px; width: 31px;`}
                      />
                    ))}
                  </Spacing>
                  <Button
                    disabled={invalid || submitting}
                    onClick={handleSubmit}
                  >
                    Save Preferences
                  </Button>
                </Spacing>
              </ListWrap>
            )}
          />
        )}
      />
    </Spacing>
  )
}

export const NotificationPreferences = pipe(
  withMutation('updateNotificationSubscriptions')
)(NotificationPreferencesInner)
