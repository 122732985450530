import { isEmpty, isNil } from 'ramda'

export const getPostData = ({ postData }) => {
  if (isNil(postData) || isEmpty(postData)) return null

  return {
    ...postData,
    data: JSON.stringify(postData.data)
  }
}
