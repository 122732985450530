import React, { createContext, useState } from 'react'
import styled from 'styled-components/macro'
import { filter, append, map } from 'ramda'
import posed, { PoseGroup } from 'react-pose'
import { Notice } from '../../components/ui'

export const ToastsContext = createContext([])

const defaultParams = {
  secondsToLive: 10,
}

const generateId = () => Math.random().toString(36).substring(7)

export const ToastsProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const hideToast = (id) => setToasts(filter((toast) => id !== toast.id))

  const startTimer = (id) =>
    setToasts(
      map((toast) => {
        if (toast.id === id) {
          return {
            ...toast,
            timerId: setTimeout(
              () => hideToast(toast.id),
              toast.secondsToLive * 1000
            ),
          }
        } else {
          return toast
        }
      })
    )

  const stopTimer = (id) =>
    setToasts(
      map((toast) => {
        if (toast.id === id) {
          clearInterval(toast.timerId)
          return {
            ...toast,
            timerId: null,
          }
        } else {
          return toast
        }
      })
    )

  const showToast = (params) => {
    const toast = {
      id: generateId(),
      ...defaultParams,
      ...params,
    }

    setToasts(append(toast))
    startTimer(toast.id)
  }

  return (
    <ToastsContext.Provider
      value={{
        toasts,
        showToast,
        hideToast,
        startTimer,
        stopTimer,
      }}
    >
      {children}
    </ToastsContext.Provider>
  )
}

const ToastsWrapper = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.padding.xxl};
  right: ${({ theme }) => theme.padding.xxl};
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  pointer-events: none;
  width: 300px;
  margin-bottom: -${({ theme }) => theme.padding.l};
`

const NoticeWrapper = posed(styled.div`
  margin-bottom: ${({ theme }) => theme.padding.l};
  pointer-events: initial;
  > ${Notice} {
    box-shadow: ${({ theme }) => theme.boxShadow.large};
  }
`)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      opacity: {
        duration: 200,
      },
      default: {
        type: 'spring',
        damping: 50,
        duration: 300,
        stiffness: 200,
      },
    },
  },
  exit: {
    x: 200,
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 50,
      duration: 500,
      stiffness: 200,
    },
  },
})

export const Toasts = () => {
  return (
    <ToastsContext.Consumer>
      {({ toasts, hideToast, startTimer, stopTimer }) => (
        <ToastsWrapper>
          <PoseGroup>
            {toasts.map(({ id, title, text, type }) => (
              <NoticeWrapper
                key={id}
                onMouseOver={() => stopTimer(id)}
                onMouseOut={() => startTimer(id)}
              >
                <Notice
                  {...{ [type]: true }}
                  title={title}
                  onClose={() => hideToast(id)}
                >
                  {text}
                </Notice>
              </NoticeWrapper>
            ))}
          </PoseGroup>
        </ToastsWrapper>
      )}
    </ToastsContext.Consumer>
  )
}
