import React, { useEffect, useState } from 'react'
import { map, path, pathOr } from 'ramda'

import { Spacing } from '../../../../components/ui'
import {
  DataInsights,
  LoadingBlanket,
  DataStreamCards,
  DataStreamHeader
} from '../components'

import { groupTradesByHoldings, CRYPTO_QUOTE_CURRENCY } from './util'
import { useDataStream } from '../../../../hooks/useDataStream'
import ndlProvider from '../../../../providers/ndlProvider'
import { DataStreamWrapper } from '../../components/DataStreamWrapper'
import { formatLocaleNumber } from '../../../../util'

const QUOTES_PROVIDER = 'robinhood'

const PortfolioStream = ({
  accountIds,
  dataSource,
  hideInsights,
  onShare,
  ...props
}) => {
  const [fetching, setFetching] = useState(false)

  const [quotes, setQuotes] = useState({})

  const ALL_TRADES_STREAM = 'allTradesStream'
  const ALL_POSITIONS_STREAM = 'allPositionsStream'
  const ALL_CURRENCY_TRADES_STREAM = 'allCurrencyTradesStream'
  const ALL_SYMBOLS_STREAM = 'allSymbolsStream'
  const ALL_CURRENCIES_STREAM = 'allCurrenciesStream'

  const { streamCards, loading } = useDataStream({
    accountIds,
    dataQueries: [
      {
        objectTypeId: 203,
        sql: 'select * from ?',
        dataStreamId: ALL_POSITIONS_STREAM
      },
      {
        objectTypeId: 202,
        sql: 'select * from ? where symbol is not null and state = "filled"',
        dataStreamId: ALL_TRADES_STREAM
      },
      {
        objectTypeId: 202,
        sql: 'select * from ? where currency is not null and state = "filled"',
        dataStreamId: ALL_CURRENCY_TRADES_STREAM
      },
      {
        objectTypeId: 202,
        sql:
          'select distinct(symbol) as uniquesymbol from ? where symbol is not null and state = "filled"',
        dataStreamId: ALL_SYMBOLS_STREAM
      },
      {
        objectTypeId: 202,
        sql:
          'select distinct(currency) as uniquecurrency from ? where currency is not null and state = "filled"',
        dataStreamId: ALL_CURRENCIES_STREAM
      }
    ]
  })

  const symbols = map(
    path(['data', 'uniquesymbol']),
    pathOr([], [ALL_SYMBOLS_STREAM], streamCards)
  )

  const currencies = map(
    path(['data', 'uniquecurrency']),
    pathOr([], [ALL_CURRENCIES_STREAM], streamCards)
  )

  const allPositions = map(
    path(['data']),
    pathOr([], [ALL_POSITIONS_STREAM], streamCards)
  )

  const allTrades = map(
    path(['data']),
    pathOr([], [ALL_TRADES_STREAM], streamCards)
  )

  const allCurrencyTrades = map(
    path(['data']),
    pathOr([], [ALL_CURRENCY_TRADES_STREAM], streamCards)
  )

  useEffect(
    () => {
      async function fetchSymbolQuotes() {
        if (fetching) return
        if (symbols.length === 0 && currencies.length === 0) return

        setFetching(true)

        // Fetch all the symbol quotes
        const quotes = await ndlProvider.getExternalApi({
          accountId: accountIds[0],
          provider: QUOTES_PROVIDER,
          payload: { symbols, currencies }
        })

        quotes &&
          setQuotes(
            quotes.reduce(
              (acc, { symbol, last_price }) => ({
                ...acc,
                [symbol]: last_price
              }),
              {}
            )
          )

        setFetching(false)
      }

      fetchSymbolQuotes()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [symbols.length, currencies.length]
  )

  const tradesByHolding = groupTradesByHoldings([
    ...allTrades,
    ...allCurrencyTrades
  ])

  const streamData = allPositions
    .sort((a, b) => b.quantity - a.quantity)
    .map(item => {
      const holding = pathOr(
        {},
        [item.symbol || item.currency],
        tradesByHolding
      )
      const trades = pathOr([], ['trades'], holding)
      const quote = quotes[item.symbol || item.currency]

      return { objectTypeId: 203, data: { ...item, holding, trades, quote } }
    })

  const insightsData = allPositions.reduce(
    ({ total, largest, largestSymbol }, position) => {
      const { symbol, currency, quantity } = position
      const price = quotes[symbol || `${currency}${CRYPTO_QUOTE_CURRENCY}`]
      const nlv = !isNaN(price) ? quantity * price : 0

      return {
        total: total + nlv,
        largest: largest < nlv ? nlv : largest,
        largestSymbol: largest < nlv ? symbol || currency : largestSymbol
      }
    },
    { total: 0, largest: 0 }
  )

  const insightsStreamCards = {
    accountValue: [
      {
        label: 'Account Value',
        data: {
          nlv: `$${formatLocaleNumber({
            number: insightsData.total,
            currency: 'USD'
          })}`
        }
      }
    ],
    largest: [
      {
        label: 'Largest Holding',
        data: {
          nlv: `${insightsData.largestSymbol} $${formatLocaleNumber({
            number: insightsData.largest,
            currency: 'USD'
          })}`
        }
      }
    ]
  }

  const renderContent = () => {
    if (fetching || loading) {
      return (
        <LoadingBlanket text="Please wait while your data is being synced" />
      )
    }

    return hideInsights ? (
      <DataStreamCards
        data={streamData}
        dataSourceId={dataSource.id}
        onShare={onShare}
        {...props}
      />
    ) : (
      <DataStreamWrapper>
        <Spacing>
          <DataInsights
            streamCards={insightsStreamCards}
            dataSource={dataSource}
          />
          <DataStreamCards
            data={streamData}
            dataSourceId={dataSource.id}
            onShare={onShare}
            {...props}
          />
        </Spacing>
      </DataStreamWrapper>
    )
  }

  return hideInsights ? (
    renderContent()
  ) : (
    <Spacing>
      {!hideInsights && <DataStreamHeader dataSource={dataSource} />}
      {renderContent()}
    </Spacing>
  )
}

export default PortfolioStream
