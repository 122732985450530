import React from 'react'
import { Graphic, Spacing, Text } from '../../../components/ui'
import { DismissableNotice } from '../../../components/ui/DismissableNotice'
import { LocalStorage } from '../../../constants'
import { DataStreamWrapper } from './DataStreamWrapper'

export const DataSourceSpaceEmpty = () => {
  return (
    <Spacing size="xl" stretched>
      <DismissableNotice notice={LocalStorage.dataSourceNotSellingNotice} />
      <DataStreamWrapper>
        <Spacing size="xxl" align="center" justify="center">
          <Graphic name="Enter" size={127} />
          <Text h1>Click on a data source</Text>
        </Spacing>
      </DataStreamWrapper>
    </Spacing>
  )
}
