import React from 'react'
import { includes, pathOr } from 'ramda'
import styled, { css } from 'styled-components/macro'
import { Spacing, Text, DataSourceGraphic } from '../../../components/ui'
import SyncStateIcon from '../../../components/ui/SyncStateIcon'
import { getAvailableServiceProviders } from '../../../state/local/serviceProviders'

const Wrapper = styled.div`
  ${({ isSelected, theme }) => {
    const borderWidth = isSelected ? 2 : 1
    const borderColor = isSelected ? theme.color.main : theme.color.purpleL2

    const padding = isSelected
      ? `${parseInt(theme.padding.m) - 1}px`
      : theme.padding.m

    return css`
      background: ${theme.color.white};
      border: ${borderWidth}px solid ${borderColor};
      box-sizing: border-box;
      border-radius: 10px;
      padding: ${padding};
      cursor: pointer;
      display: flex;
      box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
    `
  }}
`

const IconWrap = styled.div`
  position: relative;
`
const StateIconWrap = styled.div`
  position: absolute;
  right: -5px;
  top -5px;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

const ButtonWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  background: ${({ theme, white }) =>
    white ? theme.color.white : theme.color.main};
  border: 1px solid ${({ theme }) => theme.color.main};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};

  ${({ disabled }) => disabled && `opacity: 0.3`};
`

const defaultRenderAction = ({ status, id }) => {
  const serviceProviders = getAvailableServiceProviders(id)
  const isImport = !!(serviceProviders[0].id === 'localNdl')
  const isDisabled = includes(
    id,
    pathOr([], ['0', 'disabledSources'], serviceProviders)
  )

  if (status === 'pending') {
    return (
      <ButtonWrapper disabled={isDisabled}>
        <Text h6 color="white">
          {isImport ? 'Import' : 'Link'}
        </Text>
      </ButtonWrapper>
    )
  }
  if (status === 'completed') {
    return (
      <ButtonWrapper white disabled={isDisabled}>
        <Text h6 color="main">
          View
        </Text>
      </ButtonWrapper>
    )
  }

  return <SyncStateIcon syncState={status} />
}

export const DataSourceCard = ({
  id,
  name,
  status,
  isSelected,
  setSelectedSourceId = () => {},
  renderAction = defaultRenderAction
}) => {
  return (
    <Wrapper isSelected={isSelected} onClick={() => setSelectedSourceId(id)}>
      <ContentWrapper>
        <Spacing direction="row" align="center">
          {renderGraphic({ status, id })}
          <Text h4 white>
            {name}
          </Text>
        </Spacing>
        {renderAction({ id, status })}
      </ContentWrapper>
    </Wrapper>
  )
}

const renderGraphic = ({ status, id }) =>
  status === 'completed' ? (
    <IconWrap>
      <StateIconWrap>
        <SyncStateIcon syncState="completed" size={15} height={15} />
      </StateIconWrap>

      <DataSourceGraphic id={id} size={32} />
    </IconWrap>
  ) : (
    <DataSourceGraphic id={id} size={32} />
  )
