import React, { useState } from 'react'
import { pathOr } from 'ramda'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components/macro'
import arrayMutators from 'final-form-arrays'

import {
  Modal,
  Spacing,
  Button,
  Text,
  Input,
  Graphic,
  RadioList
} from '../../components/ui'
import { Field, Form } from 'react-final-form'
import { DataSourceQuery } from '../../state/queries'
import NDLProvider from '../../providers/ndlProvider'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 500px;
  position: relative;
`

const Wrap = styled.div``

export const LinkSourceLogin = ({
  onDismiss,
  params: { dataSourceId, accountId, onComplete, registerAccountPromise }
}) => {
  const [mfaRequired, setMfaRequired] = useState(false)
  const [challengeRequired, setChallengeRequired] = useState(false)
  const [challenge, setChallenge] = useState(false)
  const { data } = useQuery(DataSourceQuery, {
    variables: { id: dataSourceId }
  })
  const dataSource = pathOr({}, ['dataSource'], data)

  const onUserPassSubmit = async (
    { username, password, mfa_code, challenge_type, challenge_response },
    form
  ) => {
    await registerAccountPromise
    const login = NDLProvider.performLogin({
      dataSourceId,
      accountId,
      username,
      password,
      mfa_code,
      challenge_type: challenge_type && challenge_type[0],
      challenge_id: challenge && challenge.id,
      challenge_response
    })

    login.then(response => {
      if (response.error) {
        form.change('mfa_code', null)
      }

      const {
        mfa_required,
        accept_challenge_types,
        status,
        challenge
      } = response

      setMfaRequired(mfa_required)
      setChallengeRequired(accept_challenge_types)
      setChallenge(challenge)

      if (status === NDLProvider.config.NDL_STATUSES.linked) {
        onDismiss()
        onComplete(response)
      }
    })

    return login.then(response => response.error || response)
  }

  return (
    <Modal
      title={`Link ${dataSource.name}`}
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
    >
      {() => (
        <ModalWrap>
          <Spacing align="center">
            {dataSource.name && (
              <Graphic
                name={'Source' + dataSource.name.replace(/ /g, '')}
                size={127}
              />
            )}
            <Text center h2>
              Link your {dataSource.name} account
            </Text>
          </Spacing>
          <Wrap>
            <Form onSubmit={onUserPassSubmit} mutators={arrayMutators}>
              {({ handleSubmit, submitting, submitError }) => (
                <Spacing>
                  <Spacing size="s">
                    <div>
                      <Spacing>
                        <Text t1>
                          Enter your Robinhood login credentials below. We never
                          store your credentials, and we use SSL encryption to
                          perform the authentication with Robinhood.
                        </Text>

                        <Field
                          block
                          component={Input}
                          name="username"
                          label="Username"
                        />
                        <Field
                          block
                          component={Input}
                          name="password"
                          label="Password"
                          securedTextEntry
                        />
                        {mfaRequired && (
                          <Field
                            block
                            component={Input}
                            name="mfa_code"
                            label="Multi-factor Authentication Code"
                            placeholder="Enter MFA Code"
                          />
                        )}
                        {challengeRequired && (
                          <Spacing size="s">
                            <Text t1>
                              Where do you want to receive your authorization
                              code?
                            </Text>
                            <RadioList
                              uniqueId="challenge_type"
                              options={Object.keys(challengeRequired).map(
                                value => ({
                                  value,
                                  label: challengeRequired[value]
                                })
                              )}
                              onChange={() => handleSubmit()}
                            />
                          </Spacing>
                        )}
                        {challenge && (
                          <Field
                            block
                            component={Input}
                            name="challenge_response"
                            label="Multi-factor Authentication Code"
                            placeholder="Enter MFA Code"
                          />
                        )}
                        {submitError && <Text redNegative>{submitError}</Text>}
                        <Spacing align="flex-end">
                          <Button
                            onClick={handleSubmit}
                            full
                            disabled={submitting}
                          >
                            {submitting ? 'Logging in...' : 'Log in'}
                          </Button>
                        </Spacing>
                      </Spacing>
                    </div>
                  </Spacing>
                </Spacing>
              )}
            </Form>
          </Wrap>
        </ModalWrap>
      )}
    </Modal>
  )
}
