import React from 'react'
import styled from 'styled-components/macro'
import ReactPlayer from 'react-player'

const StyledVideo = styled(ReactPlayer)`
  height: 350px !important;
  width: 100% !important;
  border-radius: 5px;
  overflow: hidden;
  object-fit: cover;
`

export const VideoPlayer = ({ url, controls = true }) => (
  <StyledVideo url={url} controls={controls} />
)
