import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { pathOr } from 'ramda'
import { Box, Spacing, Text } from '../../../components/ui'
import { UserWithSourcesQuery } from '../../../state/queries'
import { CommunityStatistics } from '../../Home/components'
import Opportunities from './Opportunities'

export const OpportunitiesSection = () => {
  const { data: userData, loading: loadingUserData } = useQuery(
    UserWithSourcesQuery
  )

  if (loadingUserData) {
    return null
  }

  const currentUser = pathOr({}, ['currentUser'])(userData)

  return (
    <Spacing size="xl">
      <CommunityStatistics />
      <Spacing>
        <Text h4>UBDI earning opportunities</Text>

        <Box size="xxl">
          <Spacing size="xl">
            <Opportunities
              variant="earn"
              showEditPreference={false}
              currentUser={currentUser}
            />
          </Spacing>
        </Box>
      </Spacing>
    </Spacing>
  )
}
