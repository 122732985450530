import { includes, isEmpty } from 'ramda'

export const formatLocaleNumber = ({ number, currency }) => {
  const options = {
    minimumFractionDigits: currency === 'USD' ? 2 : 0
  }

  try {
    return new Intl.NumberFormat(undefined, options).format(number)
  } catch (e) {
    return numberWithCommas(number)
  }
}

const numberWithCommas = x => {
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

const getAccountFromLocalStorage = dataSourceId =>
  Object.keys(localStorage).reduce((acc, itemKey) => {
    if (!isEmpty(acc) || !includes('account', itemKey)) return acc

    try {
      const { dataSourceId: localStorageDataSourceId } = JSON.parse(
        localStorage[itemKey]
      )

      if (includes(dataSourceId, localStorageDataSourceId))
        return { account: itemKey, val: localStorage[itemKey] }

      return acc
    } catch (e) {
      localStorage.removeItem(itemKey)
      return acc
    }
  }, {})

export const removeAllAccountsFromLocalStorage = dataSourceId => {
  const accounts = getAllAccountsFromLocalStorage(dataSourceId)

  return accounts.forEach(({ accountId }) =>
    localStorage.removeItem(`account_${accountId}`)
  )
}

export const getAllAccountsFromLocalStorage = dataSourceId =>
  Object.keys(localStorage)
    .map(itemKey => {
      if (!includes('account', itemKey)) return false

      const {
        encryptionKey: encKey,
        dataSourceId: localStorageDataSourceId
      } = JSON.parse(localStorage[itemKey])

      if (!encKey) return false

      if (!dataSourceId || includes(dataSourceId, localStorageDataSourceId)) {
        const [, accountId] = itemKey.split('_')

        return { dataSourceId: localStorageDataSourceId, accountId, encKey }
      }
      return false
    })
    .filter(Boolean)

export const getCredentialsFromLocalStorage = dataSourceId => {
  const credentials = getAccountFromLocalStorage(dataSourceId)
  if (isEmpty(credentials)) return false

  const { account, val } = credentials

  const [, accountId] = account.split('_')
  const { encryptionKey: encKey } = JSON.parse(val)

  return { accountId, encKey }
}
