import React from 'react'
import styled from 'styled-components'
import { Box, Spacing, Text } from '../../../components/ui'

export const StatsCard = ({ label, value, limit }) => {
  return (
    <StatsWrapper>
      <Spacing size="s" align="center">
        <Spacing direction="row" size="xs" align="center">
          <Text h4>{value}</Text>
          {limit && (
            <Spacing direction="row" size="xs" align="center">
              <Text h4 color="purpleL2">
                /
              </Text>
              <Text h4>{limit}</Text>
            </Spacing>
          )}
        </Spacing>

        <Text t4>{label}</Text>
      </Spacing>
    </StatsWrapper>
  )
}

const StatsWrapper = styled(Box)`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.s}`};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: 10px;
`
