import React from 'react'
import { withMutation } from '../../../../../components/wrappers'
import { getOptimisticReactCommunityPoll } from '../../../../../state/mutations/ReactCommunityPoll'
import { InfoPillWrap } from './InfoPillWrap'

export const VoteButtons = ({ reactions, id, disabled }) => {
  const { votes, currentMemberReaction } = reactions

  return (
    <VoteButton
      operation="inc"
      id={id}
      voted={currentMemberReaction}
      votes={votes}
      disabled={disabled}
    />
  )
}

const VoteButton = withMutation('reactCommunityPoll')(
  ({ id, operation, reactCommunityPoll, voted, votes }) => {
    const onVote = () => {
      reactCommunityPoll({
        variables: { id, operation },
        optimisticResponse: getOptimisticReactCommunityPoll({
          id,
          votes,
          voted,
          operation
        })
      })
    }

    return (
      <InfoPillWrap
        graphicName={voted ? 'Heart' : 'HeartOutline'}
        graphicColor="#EC5B5B"
        onClick={onVote}
      >
        {votes}
      </InfoPillWrap>
    )
  }
)
