import React from 'react'
import { pathOr, pipe } from 'ramda'
import { graphql } from 'react-apollo'

import { Box, Spacing, Text, ProgressBar } from '../../../components/ui'
import { StatisticsQuery } from '../../../state/queries'
import { FormatNumber } from '../../../util/formatters'

const MEMBERS_GOAL = 150000

const CommunityStatisticsInner = ({ data }) => {
  const totalUsers = pathOr(0, ['statistics', 'member', 'totalUsers'], data)

  return (
    <Box size="xl" bordered noShadow>
      <Spacing size="l">
        <Text t3>
          We've built this community so you can start earning 70% of the revenue
          your data generates on our site (& eventually others) but we need your
          help to get enough people to compete!
        </Text>
        <Spacing size="s">
          <Spacing
            size="xs"
            direction="row"
            justify="space-between"
            align="center"
          >
            <Spacing size="xs" direction="row" align="center">
              <Text h5>
                <FormatNumber>{totalUsers}</FormatNumber>
              </Text>
              <Text h6 color="grey">
                current members
              </Text>
            </Spacing>
            <Spacing size="xs" direction="row" align="center">
              <Text h6 color="grey">
                our goal is
              </Text>
              <Text h5>
                <FormatNumber>{MEMBERS_GOAL}</FormatNumber>
              </Text>
            </Spacing>
          </Spacing>
          <ProgressBar
            current={totalUsers}
            total={MEMBERS_GOAL}
            showLabel={false}
            barThemeColor="green"
          />
        </Spacing>
      </Spacing>
    </Box>
  )
}

export const CommunityStatistics = pipe(graphql(StatisticsQuery))(
  CommunityStatisticsInner
)
