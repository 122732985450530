import React from 'react'
import { Spacing, ProgressBar, Button, Text } from '../../../components/ui'
import theme from '../../../theme'

export const ActionButtons = ({
  handleSubmit,
  hasSubmittedRequired,
  submitting,
  currentQuestion,
  totalQuestions,
  setCurrentQuestion
}) => {
  const onPrevQuestion = () => {
    if (currentQuestion === 0) return

    setCurrentQuestion(currentQuestion - 1)
  }

  const onNextQuestion = handleSubmit => {
    if (currentQuestion === totalQuestions - 1) {
      handleSubmit()
      return
    }

    setCurrentQuestion(currentQuestion + 1)
  }

  return (
    <Spacing size="l" stretched>
      <ProgressBar
        current={currentQuestion}
        total={totalQuestions}
        color={theme.color.main}
        showLabel={false}
      />
      <Spacing direction="row" size="s" align="center" justify="center">
        <Text h5 center>
          {`${currentQuestion}`}
        </Text>
        <Text color="grey">/</Text>
        <Text t2>{` ${totalQuestions} answered`}</Text>
      </Spacing>
      <Spacing direction="row" stretchChildren>
        <Button
          block
          outline
          onClick={onPrevQuestion}
          disabled={currentQuestion === 0}
        >
          Previous
        </Button>

        {submitting ? (
          <Button block text="Sending..." disabled />
        ) : (
          <Button
            block
            onClick={() => onNextQuestion(handleSubmit)}
            disabled={!hasSubmittedRequired}
          >
            Next
          </Button>
        )}
      </Spacing>
    </Spacing>
  )
}
