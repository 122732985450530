import { gql } from 'apollo-boost'

import { PollFragment } from '../fragments/Poll'
const mutation = gql`
  mutation PollVote($pollId: String!, $options: [String!]!, $tags: [String!]) {
    pollVote(pollId: $pollId, options: $options, tags: $tags) {
      poll {
        id
        ...Poll
      }
      errors {
        key
        message
      }
    }
  }
  ${PollFragment}
`

export default mutation
