import React from 'react'
import styled from 'styled-components'
import { parse } from 'query-string'
import { pipe } from 'ramda'

import { Button, Text } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { useModal } from '../../../hooks'
import { generateRandomString } from '../../../util'

const ButtonWrap = styled(Button)`
  height: 34px;
  padding: ${({ theme }) => `0 ${theme.padding.l}`};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
`

const SetupUpholdInner = ({ connectUphold }) => {
  const { openModal } = useModal()

  const onSetupUphold = () => {
    const upholdUri = generateUpholdUri()

    window.open(
      upholdUri,
      'Loading',
      'toolbar=no, menubar=no, width=600, height=800, top=100, left=100'
    )

    const onMessageReceived = async event => {
      console.log('Received event', event)
      // Security check if message is coming from Auth URL
      if (event.origin !== process.env.REACT_APP_AUTH_URL) return

      const { data: redirectUrl } = event
      if (redirectUrl) {
        window.removeEventListener('message', onMessageReceived)

        const search = redirectUrl.split('?')
        const hash = redirectUrl.split('#')
        const urlInfo = search[1] || hash[1]

        const { code } = parse(urlInfo)

        connectUphold({ variables: { code } })
          .then(
            ({
              data: {
                connectUphold: { success }
              }
            }) => {
              openModal('UpholdAuthIntro')
            }
          )
          .catch(() => {})
      }
    }

    window.addEventListener('message', onMessageReceived, false)
  }

  const generateUpholdUri = () => {
    const oauthState = generateRandomString(10)
    return `${process.env.REACT_APP_UPHOLD_URL}/authorize/${process.env.REACT_APP_UPHOLD_CLIENT_ID}?state=${oauthState}&scope=user:read&intention=signup`
  }

  return (
    <ButtonWrap block onClick={onSetupUphold}>
      <Text h6 color="white" center>
        Setup Uphold
      </Text>
    </ButtonWrap>
  )
}

export const SetupUphold = pipe(withMutation('connectUphold'))(SetupUpholdInner)
