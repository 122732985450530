import {
  difference,
  filter,
  flatten,
  map,
  pathOr,
  pipe,
  prop,
  propEq
} from 'ramda'

export const getUnansweredQuestions = (tags, profileSchema) =>
  difference(
    getAllAnswerableQuestions(profileSchema.original),
    getAnsweredQuestions(tags, profileSchema)
  )

const getAllAnswerableQuestions = pipe(
  map(prop('children')),
  flatten,
  filter(tag => tag.selfReported && tag.uniqueId !== 'custom_text'),
  map(prop('uniqueId'))
)

export const getAnsweredQuestions = (tags, profileSchema) => {
  return pipe(
    pathOr([], ['original']),
    map(prop('children')),
    flatten,
    filter(tag => {
      if (tag.subfields) {
        return (
          tag.subfields
            .map(subfield => isQuestionAnswered(tags, subfield.uniqueId))
            .filter(item => !item).length === 0
        )
      }

      return isQuestionAnswered(tags, tag.uniqueId)
    }),
    map(prop('uniqueId'))
  )(profileSchema)
}

const isQuestionAnswered = (tags, uniqueId) => {
  if (!tags || !tags[uniqueId]) {
    return false
  }

  if (tags[uniqueId].length === 0) {
    return false
  }

  return true
}

export const getCategorySchema = uniqueId => schema => {
  return (schema.length > 0 && schema.find(propEq('uniqueId', uniqueId))) || {}
}
