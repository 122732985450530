import React, { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { isEmpty, pathOr, propOr } from 'ramda'
import { Field } from 'react-final-form'
import { Carousel } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'

import { Graphic, Spacing } from '../../ui'
import { Input } from '../Input'
import { useQuery } from '@apollo/react-hooks'
import { AudienceDataVerifiedTagsWithDataSchemaQuery } from '../../../state/queries'
import { getTribeDataSources } from '../../../state/selectors'
import { useModal } from '../../../hooks'
import { TribeSourceCarousel } from '../../../screens/Home/components/Tribes/components'
import { DataStreamCard } from '../../../screens/DataBank/DataStream/components'

const { REACT_APP_GIPHY_API_KEY } = process.env

const gf = new GiphyFetch(REACT_APP_GIPHY_API_KEY)

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 15px;
`

const GifImageWrapper = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
`

const StyledGifImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const GifControlItem = styled.div`
  padding-left: 15px;
  cursor: pointer;
`

const XControlItem = styled.div`
  border: 2px solid ${({ theme }) => theme.color.dark1};
  border-radius: 100px;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledInput = styled(Input)`
  ${({ theme }) => theme.text.h6};
  background: ${({ theme }) => theme.color.white};
  border: 2px solid ${({ theme }) => theme.color.transparent};
  margin: 6px 0px;

  width: 100%;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
  }
`

const SearchInput = styled(Input)`
  ${({ theme }) => theme.text.t3};
  background: ${({ theme }) => theme.color.white};
  border: 2px solid ${({ theme }) => theme.color.transparent};
  margin: 6px 0px;

  width: 100%;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
  }
`

const CarouselWrapper = styled.div`
  max-width: ${({ width }) => `${width}px`};
`

export const GifInput = ({ input, className, ...props }) => {
  const {
    meta: { dirty },
    onRemove,
    tribeId,
    withPostData = false,
    disableGiphyInput = false
  } = props

  const [showGifPicker, setShowGifPicker] = useState(false)
  const [showDataPicker, setShowDataPicker] = useState(false)
  const [searchGif, setSearchGif] = useState('')

  const WrapperRef = useRef()
  const { openModal } = useModal()

  const { data } = useQuery(AudienceDataVerifiedTagsWithDataSchemaQuery, {
    skip: !showDataPicker
  })

  const audienceDataVerifiedTags = propOr([], 'audienceDataVerifiedTags', data)
  const postData = pathOr(false, ['value', 'postData'], input)
  const gifUrl = pathOr(null, ['value', 'gifUrl'], input)

  const shouldDisableGiphyInput = disableGiphyInput || postData.data
  const shouldDisableDataInput = gifUrl || !withPostData

  const allTribeSources = getTribeDataSources({
    audienceDataVerifiedTags,
    tribeId
  })

  const fetchGifs = offset =>
    isEmpty(searchGif)
      ? gf.trending({ offset, limit: 10 })
      : gf.search(searchGif, { offset, limit: 10 })

  const onSetGif = gif => {
    const gifUrl = pathOr(null, ['images', 'fixed_height', 'url'], gif)

    input.onChange({ ...input.value, gifUrl })
    setShowGifPicker(false)
  }

  const onGifRemove = () => {
    if (onRemove) return onRemove()

    input.onChange({ text: input.value.text })
  }

  return (
    <Spacing stretched>
      {showDataPicker && (
        <TribeSourceCarousel
          dataSources={allTribeSources}
          onDataSourceLinkedClick={dataSource => {
            input.onChange({
              postData: { dataSourceId: dataSource.id }
            })
            openModal('ShareDataStream', {
              dataSource,
              onShare: ({ data, objectTypeId }) => {
                input.onChange({
                  postData: {
                    dataSourceId: dataSource.id,
                    data,
                    objectTypeId
                  }
                })
                setShowDataPicker(false)
              },
              onShareDismiss: () =>
                input.onChange({
                  postData: {
                    dataSourceId: null
                  }
                })
            })
          }}
        />
      )}
      <Wrapper ref={WrapperRef}>
        <div style={{ flex: 1 }}>
          <Spacing>
            {gifUrl && (
              <GifImageWrapper>
                <StyledGifImage src={gifUrl} />
              </GifImageWrapper>
            )}
            {postData.data && (
              <DataStreamCard
                key
                objectTypeId={postData.objectTypeId}
                dataSourceId={postData.dataSourceId}
                data={postData.data}
              />
            )}
            {!showGifPicker ? (
              <Field
                name={`${input.name}.text`}
                component={StyledInput}
                {...props}
                meta={{}}
                className={className}
              />
            ) : (
              <SearchInput
                meta={{}}
                placeholder="Type to search gifs..."
                input={{
                  value: searchGif,
                  onChange: e => {
                    e.preventDefault()
                    setSearchGif(e.target.value)
                  }
                }}
              />
            )}
          </Spacing>
        </div>
        <ControlsWrapper>
          {!gifUrl && !shouldDisableGiphyInput && (
            <GifControlItem
              onClick={() => {
                setShowGifPicker(!showGifPicker)
                setShowDataPicker(false)
              }}
            >
              <Graphic name="Gif" size={30} />
            </GifControlItem>
          )}
          {!postData && !shouldDisableDataInput && (
            <GifControlItem
              onClick={() => {
                setShowDataPicker(!showDataPicker)
                setShowGifPicker(false)
              }}
            >
              <Graphic name="Data" size={40} themeColor="main" />
            </GifControlItem>
          )}
          {dirty && (
            <GifControlItem>
              <XControlItem onClick={onGifRemove}>
                <Graphic name="X" size={12} themeColor="dark1" />
              </XControlItem>
            </GifControlItem>
          )}
        </ControlsWrapper>
      </Wrapper>
      {showGifPicker && (
        <CarouselWrapper width={WrapperRef.current.offsetWidth}>
          <Carousel
            key={searchGif}
            fetchGifs={fetchGifs}
            gifHeight={150}
            gutter={6}
            onGifClick={(gif, e) => {
              e.preventDefault()
              onSetGif(gif)
            }}
          />
        </CarouselWrapper>
      )}
    </Spacing>
  )
}
