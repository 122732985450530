import { useEffect } from 'react'

import firebase from 'firebase/app'
import 'firebase/messaging'
import { useMutation } from 'react-apollo'
import { updateMember } from '../state/mutations'

// this key is generated thru FCM console (web push certificate)
// https://console.firebase.google.com/u/1/project/ubdi-pushnotifications/settings/cloudmessaging/ios:com.ubdi
const vapidKey =
  'BPzw9VWdQJD0UrpFkORcUJ5C97ks4zp3yswx4_AmyrmGtLB7ctUcz9RQbEdFA1hG2Q9LJ68PaE5KNW5Qw6LdvWc'

export const usePushNotifications = () => {
  const [updateMemberMutation] = useMutation(updateMember)

  useEffect(() => {
    const run = async () => {
      try {
        const messaging = firebase.messaging()
        const fcmWebToken = await messaging.getToken({ vapidKey })

        if (fcmWebToken) {
          updateMemberMutation({ variables: { fcmWebToken } })
        } else {
          // Show permission request.
          console.log(
            'No registration token available. Request permission to generate one.'
          )
          // Show permission UI.
        }
      } catch (e) {
        console.log('Failed obtaining token for push messaging', e)
      }
    }

    run()
  }, [updateMemberMutation])
}
