import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import {
  Button,
  Graphic,
  Input,
  Spacing
} from '../../../../../../../components/ui'
import styled from 'styled-components/macro'
import { isEmpty, pathOr, propOr } from 'ramda'
import PostMedia from '../../PostMedia'
import { useQuery } from '@apollo/react-hooks'
import { GetURLMetaQuery } from '../../../../../../../state/queries'
import { UploadMedia } from '.'

const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const XButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  cursor: pointer;
`
const XButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid ${({ theme }) => theme.color.dark1};
  width: 28px;
  height: 28px;
`

const StyledButton = styled(Button)`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
`

const PostMediaControls = ({ form, submitting }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(-1)
  const [externalUrlOgData, setExternalUrlOgData] = useState({})

  const externalUrlValue = pathOr(
    '',
    ['values', 'externalUrl'],
    form.getState()
  )

  const { data, loading } = useQuery(GetURLMetaQuery, {
    variables: { url: externalUrlValue },
    skip: isEmpty(externalUrlValue)
  })

  useEffect(() => {
    if (isEmpty(externalUrlValue) && !isEmpty(externalUrlOgData))
      return setExternalUrlOgData({})

    if (loading) return

    const urlMetaData = propOr({}, 'getURLMeta', data)

    setExternalUrlOgData({ ...urlMetaData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, externalUrlValue])

  const onTabSelect = ({ tabIndex, form }) => {
    if (tabIndex === -1) {
      form.change('externalUrl', null)
      form.change('media', null)
    } else if (tabIndex === 0) {
      form.change('media', null)
    } else {
      form.change('externalUrl', null)
    }

    setCurrentTabIndex(tabIndex)
  }

  const renderControlesContent = () => {
    switch (currentTabIndex) {
      case 0: {
        return (
          <Spacing size="m">
            <Field
              name="externalUrl"
              component={Input}
              label="Enter your link"
              disabled={submitting}
              block
            />
            {!isEmpty(externalUrlOgData) && (
              <PostMedia externalUrlData={externalUrlOgData} />
            )}
          </Spacing>
        )
      }
      case 1: {
        return <Field name="media" component={UploadMedia} />
      }
      default:
        return null
    }
  }

  return (
    <Spacing size="l">
      <ContentWrap>
        <Spacing
          direction="row"
          size="s"
          justify="space-between"
          align="center"
          stretched
        >
          <Spacing direction="row" size="s">
            <StyledButton
              outline={currentTabIndex === 0}
              rounded
              onClick={() => onTabSelect({ tabIndex: 0, form })}
              disabled={submitting}
            >
              + Add link
            </StyledButton>
            <StyledButton
              rounded
              outline={currentTabIndex === 1}
              onClick={() => onTabSelect({ tabIndex: 1, form })}
              disabled={submitting}
            >
              + Add media
            </StyledButton>
          </Spacing>

          {currentTabIndex !== -1 ? (
            <XButton
              onClick={() => !submitting && onTabSelect({ tabIndex: -1, form })}
            >
              <XButtonWrap>
                <Graphic name="X" size={12} themeColor="dark1" />
              </XButtonWrap>
            </XButton>
          ) : (
            <></>
          )}
        </Spacing>
      </ContentWrap>
      {renderControlesContent()}
    </Spacing>
  )
}

export default PostMediaControls
