import React, { useEffect, forwardRef, useRef } from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import {
  times,
  join,
  equals,
  includes,
  range,
  keys,
  pipe,
  sortBy,
  toPairs,
  map,
  prop,
} from 'ramda'
import { Text, Spacing, Graphic, Box } from '../../components/ui'
import { Form, Field, FormSpy } from 'react-final-form'
import { ExtensionTokenQuery } from '../../state/queries'
import { useLazyQuery } from '@apollo/react-hooks'

const TOKEN_LENGTH = 6
const excludedCharacters = [69, 91, 189, 187, 188, 190] // characters allowed in type number

const Wrapper = styled.div`
  position: relative;
  width: 500px;
  margin: auto;
  margin-top: 50px;
`

const GraphicWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Input = styled.input`
  width: 58px;
  height: 60px;
  background-color: ${({ theme }) => theme.color.purpleL3};
  border-radius: 10px;
  text-align: center;
  font-size: 31px;
  color: ${({ theme }) => theme.color.dark};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.color.main};
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`

const DigitInput = forwardRef((props, ref) => (
  <Input type="number" ref={ref} {...props} {...props.input} />
))

const CodeInputForm = () => {
  const { push } = useHistory()
  const codeFields = useRef(times(() => null, TOKEN_LENGTH))

  const [
    getExtensionToken,
    { loading, called, error, data },
  ] = useLazyQuery(ExtensionTokenQuery, { fetchPolicy: 'network-only' })
  const extensionToken = prop('extensionToken', data)
  const errors = prop('errors', extensionToken)
  const userErrors = (error && error.graphQLErrors) || errors

  useEffect(() => {
    codeFields.current[0].focus()
  }, [])

  useEffect(() => {
    extensionToken && !userErrors && push(`/${extensionToken.urlCode}`)
  }, [extensionToken, push, userErrors])

  const onSubmit = (digits) => {
    const urlCode = pipe(
      toPairs,
      sortBy(prop(0)),
      map(prop('1')),
      join('')
    )(digits)
    getExtensionToken({ variables: { urlCode } })
  }

  const handleKeyDown = (e) => {
    if (includes(e.keyCode, excludedCharacters)) {
      e.preventDefault()
    }

    // backspace, arrow left and right, tab
    if (e.target.value && ![8, 37, 39, 9, 13].includes(e.keyCode)) {
      e.preventDefault()
    }
  }

  const handleKeyUp = (e, i, digits) => {
    if (i > 0 && equals(e.keyCode, 8)) {
      codeFields.current[i - 1].focus()
    }

    if (i < 5 && includes(e.keyCode, range(48, 58))) {
      codeFields.current[i + 1].focus()
    }
  }

  const onType = (handleSubmit) => ({ values }) => {
    if (keys(values).length > 5) {
      handleSubmit()
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            onChange={onType(handleSubmit)}
            subscription={{ values: true }}
          />
          <Spacing size="xxl">
            <Spacing size="s" direction="row">
              {codeFields.current.map((_, i) => (
                <Field
                  name={`code_${i}`}
                  key={i}
                  min={0}
                  onKeyUp={(e) => handleKeyUp(e, i, values)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  render={(props) => (
                    <DigitInput
                      {...props}
                      ref={(ref) => {
                        codeFields.current[i] = ref
                      }}
                    />
                  )}
                />
              ))}
            </Spacing>

            {called && loading && (
              <GraphicWrapper>
                <Graphic name="Spinner" themeColor="main" size={32} />
              </GraphicWrapper>
            )}

            {userErrors && (
              <Text t3 color="red" center bold>
                {userErrors.map(({ message }, i) => (
                  <span key={i}>{message}</span>
                ))}
              </Text>
            )}
          </Spacing>
          <button type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </Form>
  )
}

export const OneTimeScreen = () => (
  <Wrapper>
    <Box size="xxl">
      <Spacing size="xxl">
        <GraphicWrapper>
          <Graphic name="Logo" size={125} height={35} />
        </GraphicWrapper>
        <Spacing size="xl">
          <Text t1>
            Please enter the 6-digit code you see on your screen inside the UBDI
            mobile app to continue
          </Text>
          <CodeInputForm />
        </Spacing>
      </Spacing>
    </Box>
  </Wrapper>
)
