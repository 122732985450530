export default {
  dates: {
    short: 'dd.MM.yy',
    medium: 'dd MMM yyyy',
    imperial: 'MM/d/y',
    hyperwallet: 'yyyy-MM-dd',
    dateTime: 'dd MMM yyyy - HH:mm',
    dayName: 'EEEE'
  },
  time: {
    short: 'HH:mm',
    long: 'H:mm:ss X'
  }
}
