import { useEffect } from 'react'
import { useQuery } from 'react-apollo'
import { DataSchemaQuery } from '../../state/queries'
import { syncAccounts } from '../../util/dataSources'

export const DataSync = () => {
  const { data: { dataSchema } = {} } = useQuery(DataSchemaQuery, {})

  useEffect(() => {
    if (dataSchema) {
      syncAccounts({ dataSchema })
    }
  }, [dataSchema])

  return null
}
