import React from 'react'
import { Field } from 'react-final-form'

import { CheckList, Input, RadioList, Spacing } from '../../../components/ui'
import { SURVEY_QUESTION_TYPES } from '../constants'

export const SurveyOptions = ({ name, choices, type }) => {
  const getRenderComponent = props => {
    switch (type) {
      case SURVEY_QUESTION_TYPES.singleChoice: {
        return <RadioList {...props} />
      }
      case SURVEY_QUESTION_TYPES.multipleChoice: {
        return <CheckList {...props} />
      }
      case SURVEY_QUESTION_TYPES.shortInput: {
        return (
          <Spacing>
            <Field
              name={props.uniqueId}
              component={Input}
              label="Type your answer"
              block
              {...props}
            />
          </Spacing>
        )
      }
      case SURVEY_QUESTION_TYPES.longInput: {
        return (
          <Field
            {...props}
            name={props.uniqueId}
            component={Input}
            textarea
            block
            label="Type your answer"
          />
        )
      }
      default:
        return null
    }
  }

  const options = choices
    ? choices.map(({ label, ...rest }) => ({
        value: label,
        label: label,
        ...rest
      }))
    : []

  return getRenderComponent({ uniqueId: name, options })
}
