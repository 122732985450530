import React from 'react'
import styled from 'styled-components/macro'
import { NavLink, withRouter } from 'react-router-dom'
import { pipe, path } from 'ramda'
import { useQuery } from '@apollo/react-hooks'

import { Text, Graphic, Spacing, Button } from '../ui'
import { User } from '../../constants'
import { UserQuery, UserMetaQuery } from '../../state/queries'
import { Avatar } from '../Avatar'

const HeaderWrap = styled.div`
  position: sticky;
  top: 0;
  background: white;
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.xl}`};
  padding-bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.purpleL2};
  height: 60px;
  z-index: 10;
`

const UserInfoWrapper = styled.div`
  background: ${({ theme }) => theme.color.purpleL3};
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-sizing: border-box;
  border-radius: 50px;
  padding: 5px 15px 5px 7px;
  overflow: hidden;
  cursor: pointer;
`

const SessionButtonWrapper = styled.div`
  background: ${({ theme }) => theme.color.red};
  border-radius: 50px;
  padding: ${({ theme }) => `11px ${theme.padding.l} `};
  cursor: pointer;
`

const HeaderLink = styled(NavLink)`
  display: inline-block;
  padding: ${({ theme }) =>
    `0 ${theme.padding.l} ${theme.padding.xs} ${theme.padding.l}`};

  &.active {
    border-bottom: ${({ theme }) => `2px solid ${theme.color.main}`};
    color: ${({ theme }) => `${theme.color.main}`};
  }
`

const TextWrap = styled(Text)`
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  letter-spacing: -0.2px;

  color: ${({ isActive, theme }) =>
    `${isActive ? theme.color.main : theme.color.dark}`};
`

const Logo = styled.a`
  display: flex;
  margin-bottom: ${({ theme }) => `${theme.padding.s}`};
`

const RoutesWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const UserWrapper = styled.div`
  padding-bottom: 10px;
`

export const AppHeader = pipe(withRouter)(props => {
  const { error, loading } = useQuery(UserMetaQuery)
  if (loading) return <HeaderView loading {...props} />

  if (error) {
    return <HeaderView {...props} />
  }

  return <AuthenticatedAppHeader {...props} />
})

const AuthenticatedAppHeader = ({ history, location }) => {
  const { data: userData, loading: loadingUserData, refetch } = useQuery(
    UserQuery
  )

  const username = path(['currentUser', 'username'], userData)
  const member = path(['currentUser', 'member'], userData)

  const onEndSession = () => {
    localStorage.removeItem(User.AUTH_TOKEN)
    refetch()
    history.push('/signin')
  }

  return (
    <HeaderView
      loading={loadingUserData}
      location={location}
      history={history}
      username={username}
      member={member}
      onEndSession={onEndSession}
    />
  )
}

const HeaderView = ({
  location,
  history,
  username,
  onEndSession,
  member,
  loading
}) => {
  const links = [
    {
      to: '/',
      title: 'Home',
      icon: 'Home'
    },
    { to: '/earn', title: 'Earn', icon: 'Earn' },
    { to: '/databank', title: 'Data Bank', icon: 'DataBank' },
    {
      to: member
        ? member.profileUsername && member.isCustomUsername
          ? `/u/${member.profileUsername}`
          : `/profile/${member.id}`
        : '',
      title: 'Profile',
      icon: 'Profile'
    }
  ]
  return (
    <HeaderWrap>
      <Spacing direction="row" align="center" stretched stretchChildren>
        <Logo href="/">
          <Graphic name="Logo" width={40} />
        </Logo>

        <RoutesWrapper>
          <Spacing direction="row" size="xl">
            {links.map(({ title, to, icon }) => {
              const [, rootPath] = location.pathname.split('/')
              const isActive = `/${rootPath}` === to

              return (
                <HeaderLink key={title} to={to} exact>
                  <Graphic
                    name={`${icon}${isActive ? 'Selected' : ''}`}
                    width={23}
                  />
                  <TextWrap isActive={isActive}>{title}</TextWrap>
                </HeaderLink>
              )
            })}
          </Spacing>
        </RoutesWrapper>

        <UserWrapper>
          {loading ? (
            <Spacing direction="row" align="center" justify="flex-end">
              <Graphic name="Loading" height={30} themeColor="main" />
            </Spacing>
          ) : username && member ? (
            <Spacing direction="row" size="l" align="center" justify="flex-end">
              <UserInfoWrapper onClick={() => history.push('/account')}>
                <Spacing direction="row" align="center">
                  <Avatar
                    member={member}
                    avatarId={`header_${member.id || 'member'}`}
                  />
                  <Text h6 ellipsis maxWidth={150}>
                    {username || 'Loading...'}
                  </Text>
                </Spacing>
              </UserInfoWrapper>
              <SessionButtonWrapper onClick={onEndSession}>
                <Text h6 color="white">
                  End Session
                </Text>
              </SessionButtonWrapper>
            </Spacing>
          ) : (
            <Spacing direction="row" align="center" justify="flex-end">
              <Button
                size="small"
                outline
                onClick={() => history.push('/signin')}
              >
                Log in
              </Button>
              <Button size="small" onClick={() => history.push('/signup')}>
                Sign up
              </Button>
            </Spacing>
          )}
        </UserWrapper>
      </Spacing>
    </HeaderWrap>
  )
}
