import gql from 'graphql-tag'
import {
  AllTransactionsQuery,
  UserQuery,
  RedeemableOpportunitiesQuery,
  RedeemedOpportunitiesQuery
} from '../queries'
import { getVariants } from '../queries/util/getVariants'

const mutation = gql`
  mutation Redeem(
    $opportunityId: String
    $response: String!
    $digimeAccounts: [String!]
  ) {
    redeem(
      opportunityId: $opportunityId
      response: $response
      digimeAccounts: $digimeAccounts
    ) {
      success
      query {
        currentUser {
          id
          member {
            id
            totalUbdi
            totalUsd
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

mutation.refetchQueries = [
  ...getVariants(RedeemableOpportunitiesQuery),
  ...getVariants(RedeemedOpportunitiesQuery),
  { query: AllTransactionsQuery },
  { query: UserQuery }
]

export default mutation
