import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Text, Graphic, Button, Spacing } from '../../../components/ui'

const width = window.innerWidth

const HeaderWrapper = styled.div`
  position: absolute;
  top: -80px;
`

const HeadingWrapper = styled.div`
  position: absolute;
  top: 80px;
`
const GraphicWrapper = styled.div`
  position: absolute;
  top: 200px;
`
const ButtonWrapper = styled.div`
  position: absolute;
  width: 440px;
  top: 690px;
`

export class Onboarding6 extends Component {
  state = {
    login: true, // switch between Login and SignUp
    email: '',
    password: '',
    name: '',
  }

  render() {
    const { history } = this.props
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HeaderWrapper>
          <Graphic name="Header" width={width} />
        </HeaderWrapper>
        <HeadingWrapper>
          <Text
            color="white"
            bold
            style={{ fontSize: 50, fontFamily: 'Poppins' }}
          >
            The Bottom Line
          </Text>
        </HeadingWrapper>
        <GraphicWrapper>
          <Graphic name="OnboardingBottomLine" width={width * 0.6} />
        </GraphicWrapper>

        <ButtonWrapper>
          <Spacing direction="row">
            <Button
              outline
              block
              style={{ width: '220px' }}
              onClick={() => history.push('./five')}
            >
              Previous
            </Button>
            <Button
              block
              style={{ width: '220px' }}
              onClick={() => history.push('../auth')}
            >
              Get Started
            </Button>
          </Spacing>
        </ButtonWrapper>
      </div>
    )
  }
}
