import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { path } from 'ramda'
import { v4 as uuidv4 } from 'uuid'
import { useQuery } from '@apollo/react-hooks'
import QRCode from 'qrcode.react'

import { Text, Spacing, Graphic } from '../../components/ui'

import { CheckSessionExchangeQuery } from '../../state/queries/SessionExchangeQueries'
import { User } from '../../constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const UpperPart = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.color.purpleL3};
`

const LowerPart = styled.div`
  flex: 2;
  background: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const GraphicWrapperContainer = styled.div`
  position: relative;
  top: -50px;
`

const ContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 50px 50px 50px;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  border-radius: 20px;
  width: 400px;
  top: -150px;
`

export const QRCodeScreen = ({ history }) => {
  const [sessionId, setSessionId] = useState(null)
  useEffect(() => {
    setSessionId(uuidv4())
  }, [])

  const { data, loading } = useQuery(CheckSessionExchangeQuery, {
    variables: { sessionId },
    skip: !sessionId,
    pollInterval: 1000
  })

  const maybeSetSession = data => {
    const session = path(['checkSession', 'session'], data)

    if (!session) return

    localStorage.setItem(User.AUTH_TOKEN, session.memberJWT)
    return history.push(`/databank/${session.dataSourceId}`)
  }

  if (!sessionId || loading) return null
  maybeSetSession(data)

  return (
    <Container>
      <UpperPart />
      <LowerPart>
        <ContentWrapper>
          <GraphicWrapperContainer>
            <Graphic name="Logo" size={150} />
          </GraphicWrapperContainer>
          <Spacing size="xxl" align="center">
            <QRCode
              value={sessionId}
              size={200}
              bgColor={'#ffffff'}
              fgColor={'#000000'}
              level={'M'}
              includeMargin={false}
              renderAs={'svg'}
            />
            <Text t0 center>
              Scan the QR code with the UBDI app to start
            </Text>
          </Spacing>
        </ContentWrapper>
      </LowerPart>
    </Container>
  )
}
