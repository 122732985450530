import axios from 'axios'
import jose from 'node-jose'
import randomBytes from 'randombytes'

export const generateEncryptionKey = () =>
  new Promise((resolve, reject) => {
    randomBytes(32, async (err, bytes) => {
      if (!err) {
        const hexKey = new Buffer.from(bytes, 'base64').toString('hex')
        resolve(hexKey)
      }
      reject(err)
    })
  })

export const generateCollectorToken = async (
  transportKeyUrl,
  { accountId, encryptionKey }
) => {
  const encryptPayload = new Buffer.from(
    JSON.stringify({ accountId, encryptionKey })
  )

  const pubKey = await obtainTransportKey(transportKeyUrl)
  const JWKKey = await jose.JWK.asKey(pubKey)

  return new Promise(resolve => {
    jose.JWE.createEncrypt({ format: 'compact' }, JWKKey.toJSON())
      .update(encryptPayload)
      .final()
      .then(result => resolve(result))
  })
}

export const obtainTransportKey = async url => {
  try {
    const { data } = await axios({
      method: 'GET',
      url
    })
    return data
  } catch (error) {
    console.log('Error obtaining transport pubkeys: ', error.response)
  }
}
