import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { ExtensionTokenQuery } from '../../state/queries'
import { getServeableInputDataSources } from './utils'
import { Spacing, Graphic, Text, Button, Box } from '../../components/ui'
import { UploadCard } from './components'
import { getWarehouseUploadStatuses } from '../../services/warehouse'
import { prop } from 'ramda'

const Wrapper = styled.div`
  width: 500px;
  margin: auto;
  margin-top: 50px;
`

const GraphicWrapper = styled.div`
  justify-content: center;
  display: flex;
`

export const SourcesListScreen = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [uploadsCompleted, setUploadsCompleted] = useState(0)
  const [warehouseUploadStatuses, setWarehouseUploadStatuses] = useState([])

  const incUploadsCompleted = useCallback(
    () => setUploadsCompleted((uploadStatuses) => uploadStatuses + 1),
    [setUploadsCompleted]
  )

  const { urlCode } = useParams()
  const { loading, error, data } = useQuery(ExtensionTokenQuery, {
    variables: { urlCode },
    fetchPolicy: 'network-only',
  })

  const extensionToken = prop('extensionToken', data)
  const errors = prop('errors', extensionToken)
  const userErrors = (error && error.graphQLErrors) || errors

  useEffect(() => {
    if (!extensionToken || !extensionToken.warehouseJWT) return

    getWarehouseUploadStatuses({
      token: extensionToken.warehouseJWT,
    })
      .then((data) => {
        setWarehouseUploadStatuses(data)
      })
      .finally(() => setIsLoading(false))
  }, [extensionToken])

  if (loading) return renderLoading()

  if (userErrors) {
    return (
      <Wrapper>
        <Box>
          {userErrors.map(({ message }, i) => (
            <Text t3 color="red" center bold>
              {message}
            </Text>
          ))}
        </Box>
      </Wrapper>
    )
  }

  const { opportunity, warehouseJWT } = extensionToken

  const serveableInputDataSources = getServeableInputDataSources(
    opportunity.inputDataSources
  )

  const onSubmit = () => setIsSubmitted(true)

  return !isSubmitted ? (
    <Wrapper>
      <Box size="xxl">
        <Spacing size="xxl">
          <GraphicWrapper>
            <Graphic name="Logo" size={125} height={35} />
          </GraphicWrapper>
          <Spacing size="xl">
            <Text h2 center>
              {opportunity.name}
            </Text>
            <Text t1 center>
              Please upload the following files in order to continue the study.
              After files are uploaded, you will complete the study on your
              mobile device.
            </Text>
            {isLoading ? (
              <GraphicWrapper>
                <Graphic name="Spinner" size={50} themeColor="main" />
              </GraphicWrapper>
            ) : (
              serveableInputDataSources.map((inputDataSource) => {
                const preferredProvider =
                  inputDataSource.availableServiceProviders[0] // no algorithm for figuring out the preferred now, can be changed later
                return (
                  <UploadCard
                    key={inputDataSource.query.objectTypeId}
                    inputDataSource={inputDataSource}
                    provider={preferredProvider}
                    warehouseJWT={warehouseJWT}
                    warehouseUploadStatuses={warehouseUploadStatuses}
                    incUploadsCompleted={incUploadsCompleted}
                  />
                )
              })
            )}
          </Spacing>
          <Button
            onClick={onSubmit}
            block
            disabled={uploadsCompleted !== serveableInputDataSources.length}
          >
            <Text h4 color="white">
              Submit
            </Text>
          </Button>
        </Spacing>
      </Box>
    </Wrapper>
  ) : (
    <Wrapper>
      <Box>
        <Spacing size="xxl">
          <GraphicWrapper>
            <Graphic name="Logo" size={125} height={35} />
          </GraphicWrapper>
          <GraphicWrapper>
            <Graphic name="HumanULogoWithCircles" size={215} height={130} />
          </GraphicWrapper>
          <Spacing size="xl">
            <Text h2 center>
              Great, you’re good to go
            </Text>
            <Text t1 center>
              You have successfully completed this step of the process. Please
              continue with the study on your mobile device
            </Text>
          </Spacing>
        </Spacing>
      </Box>
    </Wrapper>
  )
}

const renderLoading = () => (
  <Wrapper>
    <Box size="xxl">
      <GraphicWrapper>
        <Graphic name="Spinner" size={50} themeColor="main" />
      </GraphicWrapper>
    </Box>
  </Wrapper>
)
