import React, { useState } from 'react'
import { pathOr } from 'ramda'
import { Field } from 'react-final-form'
import { Button, Input, Spacing } from '../ui'

const getGeocodeAPI = ({ latitude, longitude }) =>
  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude}+${longitude}&key=AIzaSyD0MH7592402UtwS0XxzET0Syt46dbt2fA`

export const LocationFinder = ({ subfields, form, singleFieldProps }) => {
  const [findingLocation, setFindingLocation] = useState(false)

  const handleLocation = async coords => {
    const data = await fetch(getGeocodeAPI(coords))

    if (data.ok) {
      const reverse = await data.json()

      if (reverse.status === 'OK') {
        const locationData = getReverseResult(reverse)

        form.change('general_location_city', [
          extractLocationData(locationData, 'locality')
        ])
        form.change('general_location_country', [
          extractLocationData(locationData, 'country')
        ])
        form.change('general_location_state', [
          extractLocationData(locationData, 'administrative_area_level_1')
        ])
        form.change('general_location_zip', [
          extractLocationData(locationData, 'postal_code')
        ])
      }
    }
    setFindingLocation(false)
  }
  return (
    <Spacing separated>
      <Button
        block
        bordered
        disabled={findingLocation}
        onClick={() => {
          setFindingLocation(true)

          navigator.geolocation.getCurrentPosition(({ coords }) => {
            if (!coords) {
              setFindingLocation(false)
              form.change('general_location_city', ['Unknown'])
              form.change('general_location_country', ['Unknown'])
              form.change('general_location_state', ['Unknown'])
              form.change('general_location_zip', ['Unknown'])
            } else {
              handleLocation(coords)
            }
          })
        }}
      >
        {!findingLocation ? 'Find my location' : 'Getting location....'}
      </Button>
      <Spacing>
        {subfields.map(({ uniqueId, label }) => {
          return (
            <Spacing size="xsmall" key={uniqueId}>
              <Field
                component={Input}
                label={label}
                block
                readOnly
                name={uniqueId}
                {...singleFieldProps}
              />
            </Spacing>
          )
        })}
      </Spacing>
    </Spacing>
  )
}

const getReverseResult = pathOr({}, ['results', 0, 'address_components'])

const extractLocationData = (locationData, type) => {
  const result = locationData.find(x => x.types.includes(type))
  if (result) {
    return result.long_name
  }
}
