import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, Button } from '../../components/ui'
import { useDataSourcesStatuses } from '../../hooks'
import { STREAMS_SCHEMA } from './DataStream/streams/_config'

import { getSelectedDataSource } from '../../state/selectors'

import DataStream from './DataStream'
import { DataSourceCard, DataSourceSpace } from './components'
import { TraitsCategories } from '../../components/TraitsCategories'
import { Stats } from './components/Stats'

const Container = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.padding.xl} ${theme.padding.m}`};
  background: ${({ theme }) => theme.color.purpleL4};
`

const LeftPart = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.padding.xl}  0px ${theme.padding.xl} ${theme.padding.xl} `};
`

const CenterPart = styled.div`
  display: flex;
  flex: 2;
  padding: ${({ theme }) => theme.padding.xl};
`

const RightPart = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.padding.xl} ${theme.padding.xl} ${theme.padding.xl} 0px`};
`

export const DataBankScreen = ({
  history,
  match: { params: { dataSourceId } } = {}
}) => {
  const {
    sourceStatuses,
    sources,
    setSourceStatus,
    onSync,
    onCompletedLink,
    onUnlinkSource
  } = useDataSourcesStatuses()

  const setSelectedSourceId = dataSourceId => {
    history.push(`/databank/${dataSourceId}`)
  }

  const selectedSource = getSelectedDataSource(sources, dataSourceId)

  const dataStreamAvailable =
    sourceStatuses[dataSourceId] === 'completed' &&
    !!STREAMS_SCHEMA[dataSourceId]

  return (
    <Container>
      <LeftPart>
        <Spacing size="xl" stretched>
          <Stats sources={sources} />
          <Spacing size="s" stretched>
            <Button
              outline
              block
              onClick={() => onSync()}
              graphicSize={20}
              graphic="Sync"
            >
              Sync with mobile
            </Button>
            {sources.map(source => (
              <DataSourceCard
                key={source.id}
                {...source}
                isSelected={source.id === dataSourceId}
                setSelectedSourceId={setSelectedSourceId}
                status={sourceStatuses[source.id]}
              />
            ))}
          </Spacing>
        </Spacing>
      </LeftPart>
      <CenterPart>
        {dataStreamAvailable ? (
          <DataStream dataSourceId={selectedSource.id} />
        ) : (
          <DataSourceSpace
            dataSource={selectedSource}
            onCompletedLink={onCompletedLink}
            onUnlink={onUnlinkSource}
            status={sourceStatuses[dataSourceId]}
            setSourceStatus={setSourceStatus}
            onSync={onSync}
          />
        )}
      </CenterPart>
      <RightPart>
        <TraitsCategories />
      </RightPart>
    </Container>
  )
}
