import React from 'react'
import styled from 'styled-components/macro'
import { PostInfo } from '.'
import { Box, Graphic, Spacing, Text } from '../../../../../components/ui'
import { Link } from 'react-router-dom'

const Container = styled.div`
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-top: 2px solid #ff3636;
`
const BookmarkWrap = styled.div`
  position: absolute;
  right: 20px;
  top: 0px;
`

// A lot of Competition functionalities are missing here, just Card UI
const CompetitionCard = ({ condensed, post, viewOnly }) => {
  const {
    id: postId,
    competition: { title, subtitle }
  } = post

  return (
    <Container>
      <Box size="xl" noShadow disabled={viewOnly} bordered={!condensed}>
        <BookmarkWrap>
          <Graphic name="Bookmark" size={20} />
        </BookmarkWrap>
        <Spacing size="l">
          <Spacing>
            <Spacing direction="row" align="center">
              <Graphic name="Cup" size={16} />
              <Text h6 color="main">
                {subtitle}
              </Text>
            </Spacing>
            <Link to={`/competition/${postId}`}>
              <Text h4 bold>
                {title}
              </Text>
            </Link>
          </Spacing>
          {!condensed && <PostInfo post={post} />}
        </Spacing>
      </Box>
    </Container>
  )
}

export default CompetitionCard
