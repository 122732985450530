import React from 'react'
import styled from 'styled-components'
import { VideoPlayer } from '../../../../components/ui'

const Image = styled.img`
  display: block;
  height: 250px;
  width: 100%;
  margin: auto;
  object-fit: cover;
`

export const RichContent = ({ data, objectTypeId }) => {
  switch (objectTypeId) {
    case 406:
      return <VideoThumbnail thumbnail={data.cover} />
    case 403:
      return <VideoThumbnail thumbnail={data.thumbnail} />
    case 1:
      return <InstagramCard data={data} />
    default:
      return null
  }
}

const VideoThumbnail = ({ thumbnail }) => <Image src={thumbnail} />

const InstagramCard = ({ data }) => {
  switch (data.mediatype) {
    case 'VIDEO':
      return <VideoPlayer url={data.imagefileurl} />

    // 'IMAGE' and 'CAROUSEL_ALBUM'
    default:
      return <Image src={data.imagefileurl} />
  }
}
