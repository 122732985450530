import { gql } from 'apollo-boost'
import { CommunityPollCommentFragment } from '../fragments'

const mutation = gql`
  mutation LikeCommunityPollComment(
    $id: String!
    $commentId: String!
    $replyCommentId: String
  ) {
    likeCommunityPollComment(
      id: $id
      commentId: $commentId
      replyCommentId: $replyCommentId
    ) {
      errors {
        key
        message
      }
      communityPoll {
        id
        comments {
          id
          ...CommunityPollComment
          comments {
            id
            ...CommunityPollComment
          }
        }
      }
    }
  }
  ${CommunityPollCommentFragment}
`

export const getOptimisticCommentLike = ({ comment }) => {
  return {
    __typename: 'Mutation',
    likeCommunityPollComment: {
      __typename: 'CommunityPollLikeCommentPayload',
      comment: {
        __typename: 'CommunityPollComment',
        ...comment,
        liked: !comment.liked,
        likes: comment.liked ? -1 : 1
      },
      errors: null,
      communityPoll: null
    }
  }
}

export default mutation
