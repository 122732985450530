import React, { useEffect, useState } from 'react'
import { format, parse } from 'date-fns'
import {
  equals,
  filter,
  find,
  head,
  isEmpty,
  map,
  path,
  pathOr,
  pipe,
  reduce,
  sort,
  uniq
} from 'ramda'
import styled from 'styled-components/macro'
import { Spacing, Dropdown } from '../../../../components/ui'
import { useDataStream } from '../../../../hooks/useDataStream'
import { DataStreamWrapper } from '../../components/DataStreamWrapper'
import { DataStreamHeader } from '../components'
import { CategoriesOverview } from '../components/CategoriesOverview'
import { EmptyState } from '../components/EmptyState'
import { LoadingBlanket } from '../components/LoadingBlanket'
import { FinancePieChart } from '../components/FinancePieChart'
import { TransactionsList } from '../components/TransactionsList'
import { CATEGORIES, getTotalAmount } from './util/financeHelpers'
import { STREAMS_SCHEMA } from './_config'

const dateFormat = 'MMMM, yyyy'

const ChartWrapper = styled.div`
  flex: 1;
  margin: 60px 15px 0 45px;
`

const Wrap = styled.div`
  width: auto;
  min-width: 200px;
`

const getCategoriesFromStream = pipe(
  map(({ data }) => {
    return {
      ...data,
      label: data.masterCategory
    }
  })
)

const isMetaDataLoaded = ({ metaDataStreamCards }) => {
  const currenciesObtained = path(
    ['availableCurrenciesStream', '0', 'data', 'cnt'],
    metaDataStreamCards
  )

  const monthsObtained = path(
    ['availableMonthsStream', '0', 'data'],
    metaDataStreamCards
  )
  return currenciesObtained > 0 && !isEmpty(monthsObtained)
}

const isDataLoaded = ({ streamCards }) => {
  const categories = path(
    ['categoryTransactionsStream', '0', 'data'],
    streamCards
  )
  const transactions = pathOr([], ['allItemsStream'], streamCards)
  return transactions.length > 0 && !isEmpty(categories)
}

const FinanceStream = ({
  accountIds,
  dataSource,
  onShare,
  hideInsights,
  ...props
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [selectedDate, setSelectedDate] = useState()

  const dataQueries = STREAMS_SCHEMA[dataSource.id].data({
    selectedCurrency: selectedCurrency && selectedCurrency.label,
    selectedDate: selectedDate && selectedDate.label
  })

  const { streamCards, loading, refetch } = useDataStream({
    accountIds,
    dataQueries
  })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedCurrency])

  const metaDataQueries = STREAMS_SCHEMA[dataSource.id].metadata
  const {
    streamCards: metaDataStreamCards,
    loading: metaDataLoading
  } = useDataStream({
    accountIds,
    dataQueries: metaDataQueries
  })

  const availableMonths = pipe(
    map(path(['data', 'period'])),
    filter(Boolean),
    map(date => parse(date, 'MMM yyyy', new Date())),
    map(date => format(date, dateFormat)),
    sort(
      (a, b) =>
        parse(b, dateFormat, new Date()) - parse(a, dateFormat, new Date())
    ),
    map(item => ({ label: item })),
    uniq
  )(metaDataStreamCards.availableMonthsStream || [])

  const availableCurrencies = pipe(
    map(path(['data', 'currency'])),
    filter(Boolean),
    map(item => ({ label: item })),
    uniq
  )(metaDataStreamCards.availableCurrenciesStream || [])

  useEffect(() => {
    const selectedDate = head(availableMonths)
    setSelectedDate(selectedDate)

    const selectedCurrency = head(availableCurrencies)
    setSelectedCurrency(selectedCurrency)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaDataStreamCards])

  const getCategoriesToRender = reduce(
    (acc, value) =>
      acc.concat([
        {
          ...value,
          ...find(({ label }) => equals(label, value.label))(
            getCategoriesFromStream(streamCards.categoryTransactionsStream)
          ),
          currency: selectedCurrency.label
        }
      ]),
    []
  )

  const isContentLoading = loading || metaDataLoading

  const isContentEmpty = !(
    isMetaDataLoaded({ metaDataStreamCards }) && isDataLoaded({ streamCards })
  )

  const renderPieChart = () => {
    if (isContentLoading || isContentEmpty) {
      return null
    }

    return (
      <DataStreamWrapper>
        <FinancePieChart
          categories={getCategoriesToRender(CATEGORIES)}
          total={getTotalAmount(getCategoriesToRender(CATEGORIES))}
          currency={selectedCurrency.label}
        />
      </DataStreamWrapper>
    )
  }

  const renderContent = () => {
    if (isContentLoading) {
      return <LoadingBlanket />
    }

    if (isContentEmpty) {
      return (
        <Spacing>
          <EmptyState refetch={refetch} />
        </Spacing>
      )
    }

    return hideInsights ? (
      <TransactionsList
        data={streamCards.allItemsStream}
        dataSourceId={dataSource.id}
        onShare={onShare}
      />
    ) : (
      <DataStreamWrapper>
        <Spacing size="xl">
          <Spacing direction="row" justify="space-between">
            <Wrap>
              <Dropdown
                onSelect={setSelectedDate}
                selectedOption={selectedDate}
                options={availableMonths}
                hideImage
              />
            </Wrap>
            {availableCurrencies.length > 1 && (
              <Wrap>
                <Dropdown
                  onSelect={setSelectedCurrency}
                  selectedOption={selectedCurrency}
                  options={availableCurrencies}
                  hideImage
                />
              </Wrap>
            )}
          </Spacing>
          <CategoriesOverview categories={getCategoriesToRender(CATEGORIES)} />
          <TransactionsList
            data={streamCards.allItemsStream}
            dataSourceId={dataSource.id}
            onShare={onShare}
            {...props}
          />
        </Spacing>
      </DataStreamWrapper>
    )
  }

  return hideInsights ? (
    renderContent()
  ) : (
    <>
      <Spacing>
        {!hideInsights && <DataStreamHeader dataSource={dataSource} />}
        {renderContent()}
      </Spacing>
      <ChartWrapper>{renderPieChart()}</ChartWrapper>
    </>
  )
}

export default FinanceStream
