import React from 'react'
import styled from 'styled-components/macro'
import {
  SubmitButton,
  RenderFromQuery,
  Text,
  Spacing,
  Button
} from '../../../components/ui'
import { Form } from 'react-final-form'
import { pipe } from 'ramda'
import { withMutation } from '../../../components/wrappers'
import { UserMetaQuery } from '../../../state/queries/UserQueries'
import { Documents, Links } from '../../../constants'
import { StepWrapper } from './StepWrapper'
import { ButtonWrapper } from './ButtonWrapper'

const SectionBox = styled.div`
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.l}`};
  background: ${({ theme }) => theme.color.white};

  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-sizing: border-box;

  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  border-radius: 10px;
`

const SectionsWrapper = styled.div`
  width: 95%;
  margin: auto;
`

const StepTOSInner = ({ updateUser, onNextStep }) => {
  const onSubmit = tosAccepted => () => {
    if (tosAccepted) {
      return onNextStep()
    }

    return updateUser({ variables: { tosAccepted: true } }).then(
      async ({ errors, userErrors, data }) => {
        if (!userErrors && data && data.updateUser) {
          onNextStep()
        }
        return userErrors || errors
      }
    )
  }

  const { sections, heading } = Documents.tos

  return (
    <RenderFromQuery
      query={UserMetaQuery}
      renderData={({ currentUser: { tosAccepted } }) => (
        <StepWrapper>
          <Form onSubmit={onSubmit(tosAccepted)}>
            {({ handleSubmit, ...formProps }) => (
              <>
                <SectionsWrapper>
                  <Spacing size="xl">
                    <SectionBox>
                      <Spacing size="l">
                        <Text t2 bold>
                          {heading.title}
                        </Text>
                        <Text t4>{heading.tagline}</Text>
                        <Button
                          outline
                          block
                          onClick={() =>
                            window.open(Links.TermsOfService, '_blank')
                          }
                        >
                          Read Full Document
                        </Button>
                      </Spacing>
                    </SectionBox>

                    <SectionsWrapper>
                      <Spacing size="xl">
                        {sections.map(({ title, short }) => (
                          <SectionBox key={title}>
                            <Spacing size="l">
                              <Text t2 bold>
                                {title}
                              </Text>
                              <Text t4 color="grey">
                                {short}
                              </Text>
                            </Spacing>
                          </SectionBox>
                        ))}
                      </Spacing>
                    </SectionsWrapper>
                  </Spacing>
                  <ButtonWrapper>
                    {tosAccepted ? (
                      <Button
                        onClick={handleSubmit}
                        outline
                        block
                        graphic="Checkmark"
                        graphicSize={15}
                      >
                        Accepted
                      </Button>
                    ) : (
                      <SubmitButton onClick={handleSubmit} block {...formProps}>
                        Next
                      </SubmitButton>
                    )}
                  </ButtonWrapper>
                </SectionsWrapper>
              </>
            )}
          </Form>
        </StepWrapper>
      )}
    />
  )
}

export const StepTOS = pipe(withMutation('updateUser'))(StepTOSInner)
