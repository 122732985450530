import React, { useState, useEffect } from 'react'

import { format, subDays } from 'date-fns'
import { useQuery } from 'react-apollo'
import ndlProvider from '../../../../providers/ndlProvider'
import { DataStreamResultsQuery } from '../../../../state/queries'
import { getQueryResult } from '../../../TribeDetails/util'
import { getDayHoldings } from '../../../TribeDetails/_utils'
import { TribeForumChart } from '../../../TribeDetails/components'
import theme from '../../../../theme'

const QUOTES_PROVIDER = 'tiingo'
export const RealInvestorForumChart = ({ forum, userGroup }) => {
  // History price calculation
  const [tickerHistory, setTickerHistory] = useState([])
  useEffect(() => {
    async function getHistory() {
      const [{ data }] = await ndlProvider.getExternalApi({
        provider: QUOTES_PROVIDER,
        payload: {
          symbol: forum.value,
          endDate: format(new Date(), 'yyyy-M-d'),
          startDate: format(subDays(new Date(), 180), 'yyyy-M-d')
        }
      })

      setTickerHistory(data)
    }

    getHistory()
  }, [forum.value])

  const { data, loading } = useQuery(DataStreamResultsQuery, {
    variables: {
      dataStreamId: userGroup.publicDataStreamId,
      first: 200
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  })

  if (loading) return null

  const trades = getQueryResult(data.dataStreamResults, forum.dataStreamQueryId)

  const dayHoldings = getDayHoldings({ dates: tickerHistory, trades })

  const areaData = tickerHistory.map(({ date, last_price }) => ({
    time: date,
    value: last_price
  }))

  return areaData.length > 0 ? (
    <TribeForumChart
      backgroundColor={theme.color.purpleL4}
      height={300}
      width={650}
      areaData={areaData}
      lineData={dayHoldings}
    />
  ) : null
}
