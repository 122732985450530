import gql from 'graphql-tag'

import { AllTransactionsQuery } from '../queries'

const mutation = gql`
  mutation InitPayout($total: Float) {
    initPayout(total: $total) {
      success
      query {
        currentUser {
          id
          member {
            id
            totalUsd
            totalUbdi
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

mutation.refetchQueries = [{ query: AllTransactionsQuery }]

export default mutation
