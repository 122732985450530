import { path } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { Modal, Spacing, Text, Button } from '../../components/ui'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`
const BoxWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.l};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
`

export const CashWithdraw = ({ onDismiss, params = {} }) => {
  const { title, initPayout, currentUser } = params

  const totalUsd = path(['member', 'totalUsd'], currentUser)

  const [success, setSucces] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const onPayout = () => {
    setSubmitting(true)
    return initPayout().then(({ userErrors }) => {
      setSubmitting(false)

      if (userErrors) {
        setSucces(false)
        return userErrors
      }
      return setSucces(true)
    })
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title={title}
    >
      {({ onDismiss }) => (
        <ModalWrap>
          {!success ? (
            <Spacing>
              <Spacing
                size="xxl"
                direction="row"
                align="center"
                justify="center"
              >
                <Spacing size="xxs">
                  <Text h2>${totalUsd}</Text>
                  <Text h4>Available</Text>
                </Spacing>

                <Button onClick={onPayout} outline disabled={submitting}>
                  {submitting ? 'Confirming...' : 'Request payout'}
                </Button>
              </Spacing>
              <BoxWrapper>
                <Text t2 center>
                  Please note that, at this moment, you are making a request for
                  a payout. Allow up to 2 days for our team to review it, and
                  our payment processor to release the payment.
                </Text>
              </BoxWrapper>
            </Spacing>
          ) : (
            <Spacing size="xs">
              <Text t2 center>
                Payout request has been submitted!
              </Text>
              <Text t2 center>
                Once confirmed, you will receive money into your Uphold wallet.
              </Text>
            </Spacing>
          )}
          <Modal.Footer>
            <Spacing direction="row" justify="flex-end" align="center">
              <Button outline onClick={onDismiss}>
                {success ? 'Done' : 'Cancel'}
              </Button>
            </Spacing>
          </Modal.Footer>
        </ModalWrap>
      )}
    </Modal>
  )
}
