import React from 'react'
import styled from 'styled-components'

import { Button } from '../../../components/ui'
import { useModal } from '../../../hooks'

const ButtonWrap = styled(Button)`
  height: 34px;
  padding: ${({ theme }) => `0 ${theme.padding.l}`};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
`

export const UnverifiedNotice = ({ currentUser }) => {
  const { openModal } = useModal()
  const onVerify = () => openModal('VerifyPhoneNumber', { currentUser })

  return (
    <ButtonWrap onClick={onVerify} block>
      Verify number
    </ButtonWrap>
  )
}
