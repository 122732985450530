import React from 'react'
import styled from 'styled-components'
import { Graphic, Spacing, Text } from '../../../components/ui'

const OPTIONS = [
  { id: 'account', label: 'Account', icon: 'Account' },
  { id: 'tribes', label: 'My tribes', icon: 'AccountMultiple' },
  { id: 'notifications', label: 'Notifications', icon: 'Notifications' }
]

const UserInfoWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  border-radius: 10px;
  overflow: hidden;
`

const GraphicWrap = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(137, 127, 255, 0.07);
  border-radius: 50%;
`
const LabelWrap = styled(Text)`
  color: ${({ theme }) => theme.color.dark};
  font-weight: bold;
`

const OptionWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.xl}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.purpleL2};
  cursor: pointer;

  ${props => !props.selected},
  &:hover {
    background: ${({ theme }) => theme.color.purple};

    ${GraphicWrap} {
      background: ${({ theme }) => theme.color.white};
    }

    ${LabelWrap} {
      color: ${({ theme }) => theme.color.white};
    }
  }
`

const TitleWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.purpleL2};
`

export const UserSettings = ({ screenToShow, setScreenToShow }) => {
  return (
    <UserInfoWrapper>
      <TitleWrap>
        <Text h3>Settings</Text>
      </TitleWrap>
      {OPTIONS.map(({ label, id, icon }) => (
        <OptionWrap
          key={id}
          onClick={() => setScreenToShow(id)}
          selected={id === screenToShow}
        >
          <Spacing direction="row" align="center">
            <GraphicWrap>
              <Graphic name={icon} size={15} />
            </GraphicWrap>
            <LabelWrap t2>{label}</LabelWrap>
          </Spacing>
        </OptionWrap>
      ))}
    </UserInfoWrapper>
  )
}
