import { gql } from 'apollo-boost'

import { DataSourceFragment, UsersDataSourceFragment } from '../fragments'

export const DataSourceQuery = gql`
  query DataSource($id: String!) {
    dataSource(id: $id) {
      id
      name
      availableObjectTypes {
        id
        name
      }
      isMultipleLinkable
      serviceGroupId
      category {
        id
      }
      baseColor
    }
  }
`

export const DataSourcesQuery = gql`
  query AllDataSources {
    allDataSources {
      edges {
        node {
          id
          ...DataSource
        }
      }
    }

    currentUser {
      id
      username
      ...UsersDataSource
    }
  }
  ${DataSourceFragment}
  ${UsersDataSourceFragment}
`

export const OnlyDataSourcesQuery = gql`
  query OnlyAllDataSources {
    allDataSources {
      edges {
        node {
          id
          ...DataSource
        }
      }
    }
  }
  ${DataSourceFragment}
`
