const theme = {
  color: {
    main: '#6F62FF',
    secondary: '#8BCEFF',
    green: '#12B78F',
    red: '#EC5B5B',
    redNegative: '#E75C5C',
    dark: '#494949',
    darkBlue: '#3D99DD',
    lightBlue: '#ECF7FF',
    darkViolet: '#150D73',
    transparent: '#0000',

    purple: '#9389FF',
    purpleL1: '#B7B0FF',
    purpleL2: '#DBD8FF',
    purpleL3: '#F5F4FF',
    purpleL4: '#FBFAFF',

    grey: '#B2B2B2',
    greyL1: '#D9D9D9',
    greyL2: '#F2F2F2',
    white: '#FFFFFF',
    semiTransparentWhite: 'rgba(255, 255, 255, 0.5)',

    dark1: '#A3A3A3',
    dark2: '#D9D9D9',

    yellow: '#F3AA3B'
  },
  gradient: {
    gradient1:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.35) 100%), linear-gradient(243.31deg, #613AFF 0%, #5BBAFF 95.66%);',
    gradient2:
      'linear-gradient(180deg, rgba(240, 240, 240, 0.5) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(285.32deg, #C2B2FF -22.63%, #77C6FF 138.61%);',
    gradient3: 'linear-gradient(31.84deg, #7A53BB 0%, #FF8777 100%);',
    purpleGradient: 'linear-gradient(180deg, #9389FF 0%, #6F62FF 100%);',
    greenGradient: 'linear-gradient(118.79deg, #75FFD6 0%, #1CC99F 100%);',
    blueGradient: 'linear-gradient(131.58deg, #9DD6FF 18.97%, #4FA2EE 95.42%);'
  },
  text: {
    h0: `
      font-size: 54px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    h1: `
      font-size: 34px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    h2: `
      font-size: 28px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    h3: `
      font-size: 20px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    h4: `
      font-size: 18px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    h5: `
      font-size: 16px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    h6: `
      font-size: 14px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    h7: `
      font-size: 12px;
      font-weight: bold;
      font-family: 'Poppins';
    `,
    t0: `
      font-size: 22px;
      font-weight: normal;
      font-family: 'Rubik';
    `,
    t1: `
      font-size: 18px;
      font-weight: normal;
      font-family: 'Rubik';
    `,
    t2: `
      font-size: 16px;
      font-weight: normal;
      font-family: 'Rubik';
    `,
    t3: `
      font-size: 14px;
      font-weight: normal;
      font-family: 'Rubik';
    `,
    t4: `
      font-size: 12px;
      font-weight: normal;
      font-family: 'Rubik';
    `,
    t5: `
      font-size: 12px;
      font-weight: normal;
      font-family: 'Rubik';
      letter-spacing: -0.2px;
    `,
    formLabel: `
      font-family: Poppins;
      font-weight: bold;
      font-size: 11px;
      letter-spacing: -0.2px;
    `
  },
  padding: {
    xxs: '1px',
    xs: '5px',
    s: '10px',
    m: '15px',
    l: '20px',
    xl: '30px',
    xxl: '50px',
    xxxl: '80px',
    xxxxl: '100px',
    xxxxxl: '150px'
  },
  borderRadius: {
    small: '3px',
    base: '7px',
    large: '15px',
    pill: '95px'
  },
  boxShadow: {
    default: '0px 6px 8px rgba(111, 98, 255, 0.06)',
    large: '0px 12px 30px rgba(42, 37, 97, 0.1)'
  },
  animationSpeed: {
    slow: '0.2s',
    normal: '0.1s',
    fast: '0.05s'
  },
  animationTextSpeed: {
    slow: 30, // in ms between letters
    normal: 20,
    fast: 10
  }
}

export default theme
