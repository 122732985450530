import React from 'react'
import styled from 'styled-components/macro'

import { Modal } from '../../components/ui'
import { DataStore } from './components/DataStore'
import { DataTypes } from './components/DataTypes'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 450px;
  position: relative;
`

const types = {
  dataType: 'What are data types?',
  dataStore: 'Where is my data stored?'
}

export const InsightsDetails = ({ onDismiss, params }) => {
  const { type } = params
  const title = type === 'dataType' ? types.dataType : types.dataStore

  const renderContent = () => {
    if (type === 'dataType') {
      return <DataTypes />
    }
    return <DataStore onClick={onDismiss} />
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title={title}
    >
      {() => <ModalWrap>{renderContent()}</ModalWrap>}
    </Modal>
  )
}
