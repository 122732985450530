export default {
  PrivacyPolicy: 'https://www.ubdi.com/legal/privacy-policy',
  TermsOfService: 'https://www.ubdi.com/legal/terms-of-service',
  DataUsage: 'https://www.ubdi.com/datausage',
  UbdiAppStore: 'https://apps.apple.com/us/app/ubdi/id1479859273',
  UbdiPlayStore:
    'https://play.google.com/store/apps/details?id=com.mobileubdiapp',
  Pollfish: 'https://wss.pollfish.com/v2/device/register/true?dontencrypt=true',
  UBDI: 'https://ubdi.com'
}
