import ApolloClient from 'apollo-boost'
import { User } from '../constants'
import { getToken } from '../util'

const { REACT_APP_API_URL } = process.env

const EXCLUDED_QUERIES_FROM_AUTH = ['UserMetaQuery']
const AUTH_ERRORS = ['U01', 'U02'] // U01 NotAuthorized, U02 TokenInvalid

export const onApolloError = errors => {
  const { graphQLErrors, networkError, operation } = errors

  // example of error handling code
  if (graphQLErrors) {
    // Handle top level errors
    graphQLErrors.map(err => {
      const { code, message } = err

      // Auth
      if (AUTH_ERRORS.includes(code)) {
        if (!EXCLUDED_QUERIES_FROM_AUTH.includes(operation.operationName)) {
          return handleInvalidOrMissingToken()
        }
        return false
      }

      console.log(`[GraphQL error]: Code: ${code}, Message: ${message}`)
      return false
    })
  } else if (networkError) {
    console.warn('There seems to be an issue with your network connectivity.')
    console.log(`[Network error]:  ${networkError}`)
  }
}

export const client = new ApolloClient({
  uri: REACT_APP_API_URL,
  request: operation => {
    const token = getToken()

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  },
  onError: onApolloError
})

const handleInvalidOrMissingToken = () => {
  localStorage.removeItem(User.AUTH_TOKEN)
  window.location = '/signin'
}
