import gql from 'graphql-tag'

export const OptInFragment = gql`
  fragment OptIn on OptIn {
    id
    opportunity {
      id
      name
      currentMemberDataRequest {
        cumulativeStatus
        shortId
        opportunityId
      }
      fees {
        firstTime
        monthly
        cash
      }
    }
  }
`
