import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Spacing, Text } from '../../../../../components/ui'
import { InlineTags } from '../../../../Home/components/CommunityPosts/components'

export const InvestorLeaderboardCard = ({ data, index, tribeId }) => {
  const {
    member,
    percentageChange,
    averageprice,
    quantity,
    symbol,
    currency
  } = data

  const { tags, colors, publicTagCategories, memberId } = JSON.parse(member)

  const nativeAmount = averageprice * quantity
  const balance = nativeAmount * (1 + percentageChange / 100) - nativeAmount
  const backgroundColor = percentageChange <= 0 ? 'redNegative' : 'green'

  return (
    <Spacing size="s">
      <Spacing size="l" direction="row" align="center">
        <Text h6>{index + 1}.</Text>
        <Link
          style={{ color: 'inherit' }}
          to={`/profile/${memberId}/${tribeId}`}
        >
          <InlineTags
            avatarId={`${memberId}-${tribeId}-${index}`}
            colors={colors}
            tags={tags}
            publicTagCategories={publicTagCategories}
            member={member}
            numberOfTags={3}
          />
        </Link>
      </Spacing>
      <EntriesContent>
        {(symbol || currency) && (
          <EntryWrap backgroundColor="lightBlue" color="darkBlue">
            {symbol || currency}
          </EntryWrap>
        )}
        <EntryWrap backgroundColor={backgroundColor}>
          {`ROI: ${parseFloat(percentageChange).toFixed(2)}%`}
        </EntryWrap>
        <EntryWrap backgroundColor={backgroundColor}>
          {`${balance > 0 ? 'Profit' : 'Loss'} : $${parseFloat(balance).toFixed(
            2
          )}`}
        </EntryWrap>
      </EntriesContent>
    </Spacing>
  )
}

const EntriesContent = styled.div`
  padding-left: ${({ theme }) => theme.padding.xl};
  display: flex;
  flex-wrap: wrap;
`

const EntryWrap = styled.div`
  ${({ theme }) => theme.text.h7}
  background: ${({ theme, backgroundColor }) => theme.color[backgroundColor]};
  color: ${({ theme, color = 'white' }) => theme.color[color]};
  padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.s}`};

  border-radius: 4px;
  display: inline;
  margin-left: 5px;
  margin-bottom: 3px;
`
