import { gql } from 'apollo-boost'

const mutation = gql`
  mutation StartSyncAccounts($accounts: [DataSourceAccountInput!]) {
    startSyncAccounts(accounts: $accounts) {
      success
      errors {
        key
        message
      }
    }
  }
`

export default mutation
