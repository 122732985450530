import { either, groupBy, map, pipe, prop } from 'ramda'

export const CRYPTO_QUOTE_CURRENCY = 'USD' // Currency against which we quote Crypto prices

export const groupTradesByHoldings = trades => {
  return pipe(
    groupBy(either(prop('symbol'), prop('currency'))),
    map(trades => {
      const [{ name, country, symbol, currency }] = trades

      const profitloss = trades.reduce(
        (acc, { cumulativequantity, side, averageprice, state }) => {
          const volume = parseFloat(cumulativequantity)
          const price = parseFloat(averageprice)

          if (state !== 'filled') return acc

          const cashPrice = volume * price
          const cash = acc.cash + (side === 'buy' ? cashPrice * -1 : cashPrice)

          return {
            ...acc,
            cash
          }
        },
        { cash: 0, position: 0 }
      )

      return {
        name,
        country,
        currency,
        symbol,
        trades,
        ...profitloss
      }
    })
  )(trades)
}
