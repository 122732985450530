import {
  pipe,
  map,
  filter,
  equals,
  reduce,
  add,
  find,
  propEq,
  includes
} from 'ramda'

export const CATEGORIES = [
  {
    label: 'Travel',
    color: '#D16E58',
    subcategories: ['Travel', 'Lodging'],
    key: 'travel'
  },
  {
    label: 'Entertainment',
    color: '#EBA96C',
    subcategories: [
      'Recreation',
      'Arts and Entertainment',
      'Entertainment/Recreation',
      'Electronics/General Merchandise',
      'Gifts'
    ],
    key: 'entertainment'
  },
  {
    label: 'Food & groceries',
    color: '#46B385',
    subcategories: [
      'Shops',
      'Food and Beverage Store',
      'Restaurants',
      'Groceries'
    ],
    key: 'foodGroceries'
  },
  {
    label: 'Bills & utilities',
    color: '#7361E4',
    subcategories: [
      'Bills',
      'Utilities',
      'Taxes',
      'Insurance',
      'Loans',
      'Check Payment',
      'Cable/Satellite/Telecom',
      'Services/Supplies',
      'Rent',
      'Automotive/Fuel',
      'Mortgage'
    ],
    key: 'billsUtilities'
  },
  {
    label: 'Health',
    color: '#5771CA',
    subcategories: ['Health', 'Healthcare/Medical'],
    key: 'health'
  },
  { label: 'Other', color: '#DD628E', subcategories: [], key: 'other' }
]

export const getTotalAmount = scopedData => {
  if (scopedData.length > 0) {
    return reduce(
      add,
      0,
      map(({ totalAmount }) => totalAmount || 0)(scopedData)
    )
  }
  return 0
}

export const getTotalPerCategory = (label, flattenData) =>
  pipe(
    filter(item => equals(item.category.label, label)),
    reduce((sum, item) => sum + item.amount, 0)
  )(flattenData)

export const findCategory = txCategory => {
  const result = find(
    category =>
      !!find(subcategory => includes(subcategory, txCategory))(
        category.subcategories
      )
  )(CATEGORIES)

  if (!result) {
    return find(propEq('label', 'Other'))(CATEGORIES)
  }
  return result
}

export const getCurrencySymbol = (number, currency) => {
  if (currency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0
    }).format(number)
  }
}
