import gql from 'graphql-tag'

const mutation = gql`
  mutation createOpportunityResponse(
    $opportunity: String
    $matchedTags: [MatchedTagInput!]
    $targetAudienceBucketIds: [String]
    $createExtensionToken: Boolean
    $shortIds: [String]
  ) {
    createOpportunityResponse(
      opportunity: $opportunity
      matchedTags: $matchedTags
      targetAudienceBucketIds: $targetAudienceBucketIds
      createExtensionToken: $createExtensionToken
      shortIds: $shortIds
    ) {
      id
      shortId
      pendingTransactionId
      responseToken
      extensionTokenUrlCode
      surveyCompleted
      opportunity {
        id
        opportunityResponse {
          responseToken
          shortId
          surveyCompleted
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export default mutation
