import React from 'react'
import { graphql } from 'react-apollo'
import styled from 'styled-components'
import { isEmpty, pipe } from 'ramda'
import { Button } from '../../../components/ui'
import { useModal } from '../../../hooks'
import { AudienceSchemaQuery } from '../../../state/queries'
import { retrofitAudienceSchema } from '../../../state/selectors'
import { ProfileSection } from './ProfileSection'
import { ProfileTagsAudience } from './ProfileTagsAudience'

const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PrivateTraitsSectionInner = ({ profileSchema, member, isOwnProfile }) => {
  const { openModal } = useModal()

  return (
    <ProfileSection
      isOwnProfile={isOwnProfile}
      label="Public Traits"
      emptyLabel={
        isOwnProfile
          ? "You don't have any added traits, or your public profile settings are not letting any traits show"
          : 'There are no added traits'
      }
      emptyAction={
        <Button size="small" onClick={() => openModal('ProfileManagement')}>
          Add new traits
        </Button>
      }
      manageAction={
        <Button link transparent onClick={() => openModal('ProfileManagement')}>
          Manage
        </Button>
      }
    >
      {renderProfileSection({ member, profileSchema })}
    </ProfileSection>
  )
}

const renderProfileSection = ({ member, profileSchema }) => {
  const tags = member.publicTags

  if (isEmpty(tags)) return null
  return (
    <TagsWrap>
      {tags.map((tag, index) => (
        <ProfileTagsAudience
          key={`${tag}_${index}`}
          profileSchema={profileSchema}
        >
          {tag}
        </ProfileTagsAudience>
      ))}
    </TagsWrap>
  )
}

export const PrivateTraitsSection = pipe(
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema })
)(PrivateTraitsSectionInner)
