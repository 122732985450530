import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'

import {
  prop,
  contains,
  isNil,
  isEmpty,
  keys,
  pipe,
  split,
  length,
  lt,
  find,
  pathOr
} from 'ramda'

import { Text, Spacing, Button } from '../../../../../../components/ui'
import { DataSchemaQuery } from '../../../../../../state/queries'
import {
  DataCardBits,
  ShareButton,
  NO_OF_DATABITS_DEFAULT
} from '../../../components'

const CardsWrap = styled.div`
  background: white;
  padding: ${({ theme }) => theme.padding.m};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-shadow: 0px 6px 8px rgba(111, 98, 255, 0.06);
  border-radius: 10px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const ExpandButtonWrap = styled.div`
  height: 30px;
  display: flex;
`

const DataStreamCardLinkedIn = ({
  data,
  title,
  objectTypeId,
  objectType,
  dataSourceId,
  shouldBeExpandable,
  onShare,
  viewOnly = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onToggleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <CardsWrap disabled={viewOnly}>
      <Spacing>
        <Spacing direction="row" justify="space-between">
          <Text h4>{title}</Text>
          {onShare && (
            <ShareButton onClick={() => onShare({ data, objectTypeId })} />
          )}
        </Spacing>
        <DataCardBits
          fields={data}
          objectType={objectType}
          dataSourceId={dataSourceId}
          dataSeparator=";"
          isExpanded={isExpanded}
        />
        {shouldBeExpandable && (
          <ExpandButtonWrap>
            <Button
              block
              rounded
              outline
              link
              onClick={onToggleExpanded}
              graphic={isExpanded ? 'ChevronUp' : 'ChevronDown'}
              graphicSize={15}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </ExpandButtonWrap>
        )}
      </Spacing>
    </CardsWrap>
  )
}

const LinkedInCard = ({ objectTypeId, dataSourceId, data, ...props }) => {
  const { data: dataSchemaData } = useQuery(DataSchemaQuery, {})
  const objectTypes = pathOr([], ['dataSchema', 'objectTypes'], dataSchemaData)

  if (isEmpty(objectTypes)) return null
  const objectType = find(({ id }) => id === objectTypeId)(objectTypes)

  const filteredData = Object.keys(data)
    .map(itemKey => {
      if (
        contains(itemKey, objectType.featuredFields) &&
        !isNil(data[itemKey]) &&
        !isEmpty(data[itemKey])
      )
        return { [itemKey]: data[itemKey] }
      return false
    })
    .filter(Boolean)

  return (
    <Spacing>
      {filteredData.map((data, i) => {
        const itemKey = pipe(keys, prop('0'))(data)
        const { displayName } = objectType.fieldMappings.find(
          ({ name }) => name === itemKey
        )

        const shouldBeExpandable = pipe(
          split(';'),
          length,
          lt(NO_OF_DATABITS_DEFAULT)
        )(data[itemKey])

        return (
          <DataStreamCardLinkedIn
            key={`${itemKey}-${i}`}
            data={data}
            title={displayName}
            objectType={objectType}
            dataSourceId={dataSourceId}
            shouldBeExpandable={shouldBeExpandable}
            {...props}
          />
        )
      })}
    </Spacing>
  )
}

export default LinkedInCard
