export const createRedeem = async ({
  opportunity,
  createOpportunityResponse
}) => {
  const { data } = await createOpportunityResponse({
    variables: {
      opportunity: opportunity.id
    }
  })

  const {
    id: responseId,
    pendingTransactionId,
    extensionTokenUrlCode,
    responseToken
  } = data.createOpportunityResponse

  return {
    responseId,
    shortId: responseId,
    pendingTransactionId,
    extensionTokenUrlCode,
    responseToken
  }
}
