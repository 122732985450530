export default {
  tos: {
    heading: {
      title: 'UBDI Terms of Service',
      tagline:
        "Please read our terms of service. We've summarized the main points of the ToS to help you better understand our rules. You can review the full document here.",
      text:
        'UBDI was created to give you ownership and 100% control over your data so you can use your data to talk with communities like you, learn about yourself, and safely and securely monetize insights from your data when you choose! If you choose to take part in UBDI earn, you’ll be presented with market research opportunities or ads that will clearly state the purpose of your data’s usage, the company looking to reach you, and more, so that you are always in the know! You select which opportunities are right for you and receive compensation.',
    },
    sections: [
      //       {
      //         title: 'Introduction',
      //         short:
      //           'This contract governs your use of UBDI and may affect your legal rights.',
      //         text: `1.1  UBDI, Inc. provides this website, our computing applications and the data, information, tools, updates and similar materials delivered or provided by us (collectively, the "Services") subject to your agreement to and compliance with the conditions set forth in this Terms and Conditions Agreement (the "Agreement"). This Agreement sets forth the legally binding terms and conditions governing your use of the Services. By using the Services or otherwise entering into this Agreement, you are creating a binding contract with us. If you do not agree to these terms and conditions, you may not use the Services.

      // The following additional terms are incorporated into this Agreement as if fully set forth herein:

      //   • Privacy Policy
      //   • Owner Data Agreement
      //   • Copyright Policy
      //   • Complaint Policy (including Privacy and Trademark)

      // 1.2 Conditions
      // As long as you are in compliance with the conditions of this Agreement and all incorporated documents, we hereby grant to you a limited, revocable, non-assignable, non-transferrable, non-sublicensable, non-exclusive license to access and use the Services and materials thereon that are intended to be displayed publicly. No rights not explicitly listed are granted.  We may revise or update this Agreement by posting an amended version through the Services and/or making you aware of the revisions. Your use of the Services following an update to this Agreement (or other acceptance method) is considered acceptance of the updated Agreement. If you do not accept the changes, you must stop using the Services.`
      //       },
      {
        title: 'Who can use this service? ',
        short: 'No one under 16 allowed.',
        text: `2.1 Use of Service

        You must be at least sixteen (16) years old to use the Services. By using the Services, you represent that you meet this minimum age requirement.

        Some parts or all of the Services may not be available to the general public, and we may impose eligibility rules from time to time. We reserve the right to amend or eliminate these eligibility requirements at any time. Use of the Services may cause you to incur data charges from your provider.
        2.2 Children

        The Services are not directed to individuals under the age of 16, nor do they contain information which would be potentially harmful to minors. In the event that we discover that a child under the age of 13 has provided personally identifiable information to us, we will make efforts to delete the child’s information in accordance with the Children’s Online Privacy Protection Act. Please see the Federal Trade Commission’s website for (www.ftc.gov) for more information.

        Notwithstanding the foregoing, pursuant to 47 U.S.C. Section 230(d), as amended, we hereby notify you that parental control protections are commercially available to assist you in limiting access to material that is harmful to minors.`,
      },
      {
        title: 'Data Ownership',
        short:
          'You own your Data at UBDI and manage your data through your private data vault with digi.me. You choose what data you share by opting into opportunities in the UBDI app or service, which will each have distinct details about how your data will be used.',
        text:
          'The Owner Data Agreement is the contract that makes you the legal owner of that Data, which belongs to you and not UBDI.',
      },
      {
        title: 'Privacy',
        short:
          'We take the privacy and security of your data very seriously. By agreeing to these terms of services you are also agreeing to our privacy policy. Data is only used with your explicit consent for talking, learning, and earning unless there is a legal reason we may be forced to share.',
        text:
          'Privacy and security are built into our technology and business practices. We will not disclose the Data you store in the UBDI Service to anyone except in the rarest of circumstances, per our Privacy Policy, such as for legal process, unless you direct us to do so. Even in those situations, we can’t technically grant access to your sensitive Data or any files, all of which are encrypted and for which you hold the only key. In the course of using the UBDI Service, you may be asked to provide certain information to us, such as a username or email address at registration. These Terms and our Privacy Policy, which is incorporated into these Terms, shall govern the use of any such information. You agree that you are solely responsible for the accuracy and content of such information, including keeping your email address updated, which is the only way we can reach you about your account, new features, offerings and partnerships, and other important announcements. ',
      },
      {
        title: 'Rules of Conduct',
        short:
          ' We expect our users to refrain from uploading content that is unlawful, malicious, or protected under intellectual property laws. Don’t break the law. Respect the data and privacy of others. And, yes, don’t be a jerk or try to scam us or you’ll be banned!',
        text: `5.1 Use of Service
        Your use of the Services is conditioned on your compliance with the terms of this Agreement, including but not limited to these rules of conduct.

        You agree that you will not violate any applicable law or regulation in connection with your use of the Services. Without limiting the foregoing, you agree that you will not make available through the Services any material or information that infringes any copyright, trademark, patent, trade secret, or other right of any party (including rights of privacy or publicity).

        You agree not to distribute, upload, make available or otherwise publish through the Services any suggestions, information, ideas, comments, causes, promotions, documents, questions, notes, plans, drawings, proposals, or materials similar thereto ("Submissions") or graphics, text, information, links, profiles, audio, photos, software, music, sounds, video, comments, messages or tags, or similar materials ("Content") that:

        • is unlawful or encourages another to engage in anything unlawful;
        • contains a virus or any other similar programs or software which may damage the operation of our or another’s computer;
        • violates the rights of any party or infringes upon the patent, trademark, trade secret, copyright, right of privacy or publicity or other intellectual property right of any party; or,
        • is libelous, defamatory, pornographic, obscene, lewd, indecent, inappropriate, invasive of privacy or publicity rights, abusing, harassing, threatening or bullying.

        You must keep your user name and password and any other information needed to login to the Services, if applicable, confidential and secure. We are not responsible for any unauthorized access to your account or profile by others.

        5.2 Malicious Intent
        You further agree that you will not do any of the following:

        • modify, adapt, translate, copy, reverse engineer, decompile or disassemble any portion of the Services;
        • interfere with or disrupt the operation of the Services, including restricting or inhibiting any other person from using the Services by means of hacking or defacing;
        • transmit to or make available in connection with the Services any denial of service attack, virus, worm, Trojan horse or other harmful code or activity;
        • attempt to probe, scan or test the vulnerability of a system or network of the Services or to breach security or authentication measures without proper authorization;
        • take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on our infrastructure;
        • harvest or collect the email address or other contact information of other users of the Services;
        • scrape or collect content from the Services via automated or large group means;
        • submit, post or make available false, incomplete or misleading information to the Services, or otherwise provide such information to us;
        • register for more than one user account; or,
        • impersonate any other person or business.

        You are not licensed to access any portion of the Services that is not public, and you may not attempt to override any security measures in place on the Services.

        Notwithstanding the foregoing rules of conduct, our unlimited right to terminate your access to the Services shall not be limited to violations of these rules of conduct.

        5.3 Content Policing
        You agree that we have no obligation or duty to screen, approve, endorse, limit or select who can post information on the Services, although we reserve the right to do so. We do not control the sources of this information, and do not guarantee the accuracy, suitability, completeness, currency, quality, adequacy or applicability of any such information.

        In addition to reserving the right to review, remove or edit any content from the site, we do routinely screen, monitor, and/or review the content on the Services. YOU AGREE THAT WE SHALL NOT BE RESPONSIBLE FOR ANY SUCH INFORMATION. YOUR RELIANCE ON ANY SUCH INFORMATION IS AT YOUR OWN RISK.

        5.4 Denial of Service
        We reserve the right to deny all or some portion of the Services to any user, in our sole discretion, at any time. Without limiting the foregoing or assuming additional legal obligations, we have a policy of terminating repeat violators of the Copyright Act, in accordance with applicable law.

        All grants of any rights from you to us related to Content, Submissions, or other materials, including but not limited to copyright licenses, shall survive any termination of this Agreement. Further, your representations, defense and indemnification obligations survive any termination of this Agreement.`,
      },
      {
        title: 'Our Intellectual Property',
        short:
          'We own the UBDI Service and all of our stuff. Your Data is your stuff, and so we need your permission to use your data solely to do the things you choose to do with it, and you need ours.',
        text: `6.1 Ownership

        We own our graphics, logos, names, designs, page headers, button icons, scripts, and service names, which are our trademarks, trade names and/or trade dress. The "look and feel" of the Services (including color combinations, button shapes, layout, design and all other graphical elements) are protected by U.S. copyright and trademark law. All product names, names of services, trademarks and service marks ("Marks") are our property or the property of their respective owners, as indicated. You may not use the Marks or copyrights for any purpose whatsoever other than as permitted by this Agreement.

        You acknowledge that the software used to provide Services, and all enhancements, updates, upgrades, corrections and modifications to the software, all copyrights, patents, trade secrets, or trademarks or other intellectual property rights protecting or pertaining to any aspect of the software (or any enhancements, corrections or modifications) and any and all documentation therefore, are and shall remain the sole and exclusive property of us and/or our licensors, as the case may be. This Agreement does not convey title or ownership to you, but instead gives you only the limited rights set forth herein.`,
      },
      {
        title: 'Complaint Policy',
        short:
          'If you have a complaint, contact us legal@ubdi.com! We are happy to talk things through.',
        text: `Contact us with problem at legal@ubdi.com and we will address this if you have a good faith complaint.

        If you believe in good faith that any material posted on our Services infringes the copyright in your work, please contact our copyright agent, designated under the Digital Millennium Copyright Act ("DMCA") (17 U.S.C. §512(c)(3)), with correspondence containing the following:

        • A physical or electronic signature of the owner, or a person authorized to act on behalf of the owner, of the copyright that is allegedly infringed;
        • Identification of the copyrighted work claimed to have been infringed;
        • Identification, with information reasonably sufficient to allow its location of the material that is claimed to be infringing;
        • Information reasonably sufficient to permit us to contact you;
        • A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and,
        • A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.

        You acknowledge that if you fail to comply with all of the requirements of this policy, your DMCA notice may not be valid. For any questions regarding this procedure, or to submit a complaint, please contact our designated DMCA Copyright Agent:

        Copyright Agent
        UBDI
        5514 Uppingham St.
        Chevy Chase, MD 20815
        e-mail: legal@ubdi.com`,
      },
      {
        title: 'Your responsibilities',
        short:
          'UBDI is committed to ensuring your data is shared ethically and only with your explicit consent. This section covers your responsibility to save passwords and what your consent means.',
        text: `8.1 Protect Yourself
        You understand that by sharing information on the Services, and requesting information to be sent through the Services, you may be revealing information about yourself that you may include. You understand and acknowledge that you are fully aware and responsible for the impact of sharing such materials, and you agree that we shall not be held responsible, and we shall be released and held harmless by you from any liability or damages arising out of such conduct.

        8.2 Passwords
        You are responsible for controlling the access to and use of your account. Always make sure that your password is kept as confidential. You understand and agree that we may assume that instructions from an individual associated with your account are authoritative and should be acted upon by us. We are not responsible for any unauthorized access to your account or profile, and any ramifications of such access, and is not required to take action to disable any account. You agree that you will not bring an action against us arising out of or related to any claimed unauthorized access using your account credentials.`,
      },
      {
        title: 'Rights to Content',
        short:
          'You are under no obligation to submit anything to us, and unless otherwise noted, we will not claim ownership of any Content. However, in order for us to provide the Services, we need your permission to process, display, reproduce and otherwise use content you make available to us and to others. UBDI will never access content without a user’s explicit consent.',
        text: `By submitting any Content or Submissions to us you hereby agree, warrant and represent that: (a) the Content and Submissions do not contain proprietary or confidential information, and the provision of the Content and Submissions is not a violation of any third-party’s rights; (b) all such Submissions and Content are accurate and true, (c) we are not under any confidentiality obligation relating to the Content or Submissions; (d) we shall be entitled to use or disclose the Content or Submissions in any way; and (e) you are not entitled to compensation or attribution from us in exchange for the Submissions or Content.

        You acknowledge that we are under no obligation to maintain the Services, or any information, materials, Submissions, Content or other matter you submit, post or make available to or on the Services. We reserve the right to withhold, remove and or discard any such material at any time.`,
      },
      {
        title: 'Linked Sites and Third Party Organizations',
        short:
          "We may link you to other sites. We aren't responsible for them.",
        text: `You may be able to link to third-party websites from the UBDl Service. Or, you may choose to share your Data via UBDI with third-party organizations, such as partners or online services with no relationship to UBDI (collectively, "Linked Sites") through, for example, automatic form-filling or granting access to your Data. UBDI does not control Linked Sites in any way, and UBDI is not responsible for the content, availability, advertising, products, information or additional links, use of user information, security or privacy policies and practices, or materials of any such Linked Sites. Such links do not imply UBDI endorsement of or association with the Linked Sites. In some cases, you may have an independent relationship with a Linked Site by virtue of being an existing customer or member of the third-party organization.

        In no event shall UBDI be liable, directly or indirectly, to anyone for any loss or damage arising from or occasioned by the creation or use of Linked Sites, the information or material accessed/imported through Linked Sites, or information shared from your account with Linked Sites through the UBDI Service.

        UBDI reserves the right, at its discretion at any time, to deny any request, or rescind any permission granted, to link to the UBDI Service, and to require termination of any such link.`,
      },
      {
        title: 'Summary, Section Headings, and Translation',
        short:
          ' We did all this work to try and make it simple, but the summary, section headings and the translations aren’t really part of the contract. You have to read the full Terms.',
        text: `The Summary, section headings and "translations" are for convenience only and have no legal or contractual effect. If the terms and conditions of these Terms and the Summary, section headings or translations conflict, the terms and conditions shall prevail.`,
      },
      {
        title: 'General',
        short:
          'If a contract provision is found to be invalid, the rest still stands. Even after this contract ends, certain sections still apply.',
        text: `12.1 Severability
        If any provision of this Agreement is found for any reason to be unlawful, void or unenforceable, then that provision will be given its maximum enforceable effect, or shall be deemed severable from this Agreement and will not affect the validity and enforceability of any remaining provision.

        Translation: If a contract provision is found to be invalid, the rest still stands.

        12.2 Revisions
        In the event that we update this Agreement and you are made aware of the update, your continued use of the Services after the update, or other agreement to the updated terms, shall constitute an agreement to the updated terms.

        12.3 No Partnership
        You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of this Agreement or your use of the Services.

        12.4 Assignment
        We may assign our this Agreement, in whole or in part, to any person or entity at any time with or without your consent. You may not assign the Agreement without our prior written consent, and any unauthorized assignment by you shall be null and void.

        12.5 No Waiver
        Our failure to enforce any provision of this Agreement shall in no way be construed to be a present or future waiver of such provision, nor in any way affect the right of any party to enforce each and every such provision thereafter. The express waiver by us of any provision, condition or requirement of this Agreement shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement.

        12.6 Notices
        All notices given by you or required under this Agreement shall be in writing and addressed to: legal@ubdi.com.

        12.7 Entire Agreement
        This Agreement, including the documents expressly incorporated by reference, constitutes the entire agreement between you and us with respect to the Services, and supersedes all prior or contemporaneous communications, whether electronic, oral or written.

        12.8 Summary, Section Headings and Translations
        The Summary, section headings and "translations" are for convenience only and have no legal or contractual effect. If the terms and conditions of these Terms and the Summary, section headings or translations conflict, the terms and conditions shall prevail.

        12.9 Cancellation and Termination
        You can cancel your account and export and delete your Data at any time. UBDI may also suspend or terminate your account if you violate these Terms or the Owner Data Agreement. In such situations, we may attempt to notify you so that you can export your data, but we may immediately suspend or terminate your account in certain circumstances, such as causing harm to another Owner, disrupting others’ use of the UBDI Service, or causing UBDI potential legal liability.

        12.10 Survival
        All provisions of these Terms that, by their nature, should survive any termination or expiration of these Terms shall do so, including, without limitation, Sections 2-16. Any termination or expiration of these Terms shall not relieve you of any obligations that may have arisen or accrued prior to such termination or expiration or limit any liability you otherwise may have to UBDI, including, without limitation, any indemnification obligations contained herein.

        Translation: Even after this contract ends, certain sections still apply.`,
      },
      {
        title: 'Disclaimers and Limitation on Liability',
        short:
          'We can’t guarantee our service will be working perfectly all the time, but reserve the right to make changes accordingly. We aren’t licensed to give you financial advice, or help with filing any taxes that may be incurred while using our products.',
        text: `13.1 Network Access
        We cannot guarantee this system will be running perfectly 100% of the time. We will address bugs and flaws as quickly as possible to optimize your experience. If our service contains an inaccuracy or error, we reserve the right to correct any such errors and to change/update information at any time without prior notice.

        13.2 Advice
        UBDI.co does not offer advice in legal, medical, financial, investment, or any other category. Please contact a professional if you have legal questions.

        13.3 Tax Implications
        If you choose to get paid for licensing your data there could be potential tax implications. Until we have launched the blockchain, this isn’t a problem and we are creating an incredible way for you to remain tax compliant with your digital currency. We will update you prior to our blockchain launch to inform you of your possibilities and keep you safe.

        13.4 Warranties
        YOU AGREE AND ACKNOWLEDGE THAT WE PROVIDE THE SERVICES "AS IS" AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY. WE, OUR PARENTS, SUBSIDIARIES, OFFICERS, DIRECTORS, SHAREHOLDERS, MEMBERS, MANAGERS, EMPLOYEES AND SUPPLIERS, SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, ACCURACY, SUITABILITY, APPLICABILITY, MERCHANTABILITY, PERFORMANCE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR ANY OTHER WARRANTIES OF ANY KIND RELATING TO THE SERVICES. NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM US SHALL CREATE ANY WARRANTY.

        13.5 Indemnification
        You agree to defend, indemnify and hold us and our suppliers, subsidiaries, licensors, and licensees, and each of their officers, directors, shareholders, members, employees and agents harmless from all allegations, judgments, awards, losses, liabilities, costs and expenses, including but not limited to reasonable attorney’s fees, expert witness fees, and costs of litigation arising out of or based on (a) Submissions or Content you submit, post to or transmit through the Services (b) your use of the Services, (c) your violation of the Agreement, and (d) any conduct, activity or action which is unlawful or illegal under any state, federal or common law, or is violative of the rights of any individual or entity, engaged in, caused by, or facilitated in any way through the use of the Services.`,
      },
      {
        title: 'Legal',
        short:
          'You agree that any claim or dispute arising out of or relating in any way to the Services will be resolved solely and exclusively by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this agreement. The laws of the District of Columbia shall govern this Agreement, and shall be used in any arbitration proceeding.',
        text: `You agree that any claim or dispute arising out of or relating in any way to the Services will be resolved solely and exclusively by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this agreement. The laws of the District of Columbia shall govern this Agreement, and shall be used in any arbitration proceeding.

        There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of this Agreement as a court would.

        To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to the following address: 5514 Uppingham St.   Chevy Chase, MD 20815

        Arbitration under this Agreement will be conducted by the American Arbitration Association (AAA) under its rules then in effect, and shall be located in Washington, DC. Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules.

        You and us agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration, both you and us agree that parties have each waived any right to a jury trial.

        Notwithstanding the foregoing, you agree that we may bring suit in court to enjoin infringement or other misuse of intellectual property or other proprietary rights.

        To the extent arbitrations does not apply, you agree that any dispute arising out of or relating to the Services, or to us, may only be brought by you in a state or federal court located in District of Columbia, DC. YOU HEREBY WAIVE ANY OBJECTION TO THIS VENUE AS INCONVENIENT OR INAPPROPRIATE, AND AGREE TO EXCLUSIVE JURISDICTION AND VENUE IN THE DISTRICT OF COLUMBIA.`,
      },
    ],
  },
}
