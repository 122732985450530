import * as React from 'react'

const AvatarIncognito = ({
  size = 80,
  color = '#8F9BFF',
  circleColorPrimary,
  circleColorSecondary,
  avatarId,
  ...props
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" {...props}>
      <mask
        id={`mask012-${avatarId}`}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={26}
        height={26}
      >
        <circle
          cx={13}
          cy={13}
          r={13}
          fill={`url(#paint0_linear12-${avatarId})`}
        />
        <circle
          cx={13}
          cy={13}
          r={13}
          fill={`url(#paint1_linear12-${avatarId})`}
          fillOpacity={0.5}
        />
      </mask>
      <g mask={`url(#mask012-${avatarId})`}>
        <circle
          cx={13}
          cy={13}
          r={13}
          fill={`url(#paint2_linear-${avatarId})`}
        />
        <circle
          cx={13}
          cy={13}
          r={13}
          fill={`url(#paint3_linear-${avatarId}`}
        />
        <path
          d="M4.16016 10.0651C4.16016 8.81414 5.17425 7.80005 6.42519 7.80005H19.5751C20.8261 7.80005 21.8402 8.81414 21.8402 10.0651V13.3524C21.8402 16.2264 19.9467 18.7569 17.1895 19.5679C14.4545 20.3723 11.5458 20.3723 8.81081 19.5679C6.05357 18.7569 4.16016 16.2264 4.16016 13.3524V10.0651Z"
          fill="black"
        />
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x={5}
          y={8}
          width={16}
          height={12}
        >
          <path
            d="M5.72021 10.7511C5.72021 9.69569 6.57581 8.84009 7.63125 8.84009H19.4003C19.8863 8.84009 20.2802 9.23403 20.2802 9.71999V13.6257C20.2802 15.9704 18.7423 18.0375 16.4965 18.7112C14.2158 19.3954 11.7846 19.3954 9.50396 18.7112C7.25817 18.0375 5.72021 15.9704 5.72021 13.6257V10.7511Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask1)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.90895 23.9199L23.0289 7.79994L20.4551 5.22607L4.33508 21.3461L6.90895 23.9199Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.000156784 23.9198L16.1202 7.79984L15.3848 7.06445L-0.735234 23.1844L0.000156784 23.9198Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear12-${avatarId}`}
          x1={26}
          y1={53.3684}
          x2={-23.6814}
          y2={39.759}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1B2FF" />
          <stop offset={1} stopColor="#77C6FF" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear12-${avatarId}`}
          x1={13}
          y1={0}
          x2={13}
          y2={26}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F0F0" />
          <stop offset={1} stopColor="white" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={`paint2_linear-${avatarId}`}
          x1={18.72}
          y1={4.14705e-7}
          x2={4.94}
          y2={24.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0CBFF" />
          <stop offset={1} stopColor="#8F9BFF" />
        </linearGradient>
        <linearGradient
          id={`paint3_linear-${avatarId}`}
          x1={18.72}
          y1={4.14705e-7}
          x2={4.94}
          y2={24.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={circleColorSecondary || color} />
          <stop offset={1} stopColor={circleColorPrimary || color} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AvatarIncognito
