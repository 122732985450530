import React from 'react'
import styled, { css } from 'styled-components/macro'
import { withRouter } from 'react-router-dom'

import { Graphic } from '..'

const Wrapper = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    border-radius: ${theme.borderRadius.base};
    background: ${({background}) => background || 'rgba(255, 255, 255, 0.3)'} ;
    border: ${({borderColor}) => borderColor? `1px solid ${borderColor}` : 'none'} ;
    padding: ${({ theme }) => theme.padding.s};
    display: flex;
    justify-content: center;
    transition: all ${theme.animationSpeed.normal};

    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.4);
      outline: none;
    }
  `}
`

const BackInner = ({ history, backRouteName, color, background, borderColor }) => (
  <Wrapper
  background={background}
  borderColor={borderColor}
    onClick={() =>
      backRouteName ? history.push(backRouteName) : history.goBack()
    }
  >
    <Graphic size={20} name="Back" themeColor={color || 'main'} />
  </Wrapper>
)

export const Back = withRouter(BackInner)
