import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import {
  isEmpty,
  propOr,
  length,
  map,
  prop,
  take,
  includes,
  without,
  drop
} from 'ramda'
import { getCategoryChildrenValues } from '../../../state/selectors'
import { Spacing, Tag } from '../../../components/ui'
import { TAG_COLOR } from '../../../util'

const CUSTOM_HANDLING_CATEGORIES = ['general_age']
const NUMBER_OF_TAG_RESULTS = 5

const TagWrap = styled.div`
  padding-top: ${({ theme }) => theme.padding.s};
  cursor: pointer;
`

const FilterTagsLegend = ({
  category,
  resetExpandedCategory,
  selectedResultsTags,
  setSelectedResultTags
}) => {
  const categoryUniqueId = propOr(null, 'uniqueId', category)
  const categoryChildren = propOr([], 'children', category)

  const tagValues = !isEmpty(categoryChildren)
    ? categoryChildren
    : getCategoryChildrenValues(categoryUniqueId)

  useEffect(() => {
    if (includes(categoryUniqueId, CUSTOM_HANDLING_CATEGORIES)) {
      const ageUniqueIds = map(prop('label'))(tagValues)

      setSelectedResultTags(take(NUMBER_OF_TAG_RESULTS, ageUniqueIds))
      return
    }

    const categoryUniqueIds = map(prop('uniqueId'))(tagValues)
    setSelectedResultTags(take(NUMBER_OF_TAG_RESULTS, categoryUniqueIds))
  }, [categoryUniqueId, setSelectedResultTags, tagValues])

  const onToggleResultTag = ({ uniqueId, label }) => {
    const tagToSearch = includes(categoryUniqueId, CUSTOM_HANDLING_CATEGORIES)
      ? label
      : uniqueId

    // remove item
    if (includes(tagToSearch, selectedResultsTags)) {
      setSelectedResultTags(without([tagToSearch], selectedResultsTags))

      // reset category if last one
      if (selectedResultsTags.length === 1) resetExpandedCategory()

      return
    }

    // remove first, append current
    if (length(selectedResultsTags) === NUMBER_OF_TAG_RESULTS) {
      setSelectedResultTags([...drop(1, selectedResultsTags), tagToSearch])
      return
    }

    // append current
    setSelectedResultTags([...selectedResultsTags, tagToSearch])
  }

  const isSelectedTag = ({ uniqueId, label }) => {
    const tagToSearch = includes(categoryUniqueId, CUSTOM_HANDLING_CATEGORIES)
      ? label
      : uniqueId

    return includes(tagToSearch, selectedResultsTags)
  }

  if (!categoryUniqueId) return null

  return (
    <Spacing direction="row" size="s" wrap>
      {tagValues.map((tagValue, index) => (
        <TagWrap
          key={tagValue.uniqueId}
          onClick={() => onToggleResultTag(tagValue)}
        >
          <Tag
            selected={isSelectedTag(tagValue)}
            backgroundColor={isSelectedTag(tagValue) && TAG_COLOR[index % 5]}
          >
            {tagValue.label}
          </Tag>
        </TagWrap>
      ))}
    </Spacing>
  )
}

export default FilterTagsLegend
