import React from 'react'
import { TribeLeaderboards } from '../../../../constants'

import { InvestorLeaderboardCard, FitnessLeaderboardCard } from './components'

export const TribeLeaderboardCard = props => {
  const { tribeId } = props

  switch (tribeId) {
    case TribeLeaderboards.Investors:
      return <InvestorLeaderboardCard {...props} />
    case TribeLeaderboards.RealInvestors:
      return <InvestorLeaderboardCard {...props} />
    case TribeLeaderboards.Fitness:
      return <FitnessLeaderboardCard {...props} />

    default:
      return null
  }
}
