import ndl from './serviceProviders/ndl'
import localNdl from './serviceProviders/localNdl'

export const ServiceProviderIds = {
  ndl: 'ndl',
  localNdl: 'localNdl'
}

export const NetflixSourceId = 'netflix'
export const AmazonSourceId = 'amazon'
export const LinkedInSourceId = 'linkedin'
export const GoogleTakeoutSourceId = '433'
export const LocationSourceId = '50'

export const ServiceProviders = {
  [ServiceProviderIds.ndl]: {
    id: ServiceProviderIds.ndl,
    name: 'UBDI',
    hasCustomLink: false,
    supportedSources: [
      'twitch',
      'steam', // will not be possible at all
      'twitter',
      'youtube',
      'googleFit',
      'coinbase',
      'fitbit',
      'yodlee',
      'binance', // requires additional UI
      'strava',
      'battleNet',
      'gemini',
      'robinhood', // just for testing Portfolio Private DataStream => REVERT
      'uphold',
      'spotify',
      'metamask',
      'instagram'
    ],
    disabledSources: ['steam', 'binance']
  },
  [ServiceProviderIds.localNdl]: {
    id: ServiceProviderIds.localNdl,
    name: 'UBDI',
    hasCustomLink: true,
    supportedSources: ['netflix', 'linkedin']
  }
}

export const ServiceProviderApis = {
  ndl,
  localNdl
}
