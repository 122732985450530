import React, { useRef } from 'react'
import styled from 'styled-components/macro'

import { Modal } from '../../components/ui'
import DataStream from '../../screens/DataBank/DataStream'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 450px;
  position: relative;
  max-height: 750px;
  height: auto;
  overflow: auto;
`

export const ShareDataStream = ({ onDismiss, params = {} }) => {
  const scrollParentRef = useRef()

  const {
    dataSource: { id: dataSourceId, name },
    onShare,
    onShareDismiss
  } = params

  const onDismissModal = () => {
    onShareDismiss()
    onDismiss()
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismissModal}
      onRequestClose={onDismissModal}
      title={`${name} data`}
    >
      {() => (
        <ModalWrap ref={scrollParentRef}>
          <DataStream
            dataSourceId={dataSourceId}
            hideInsights
            onShare={props => {
              onShare(props)
              onDismiss()
            }}
            parentRef={scrollParentRef}
          />
        </ModalWrap>
      )}
    </Modal>
  )
}
