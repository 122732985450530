import React, { useState, useEffect } from 'react'
import { pipe, last, find, propEq, prop, isEmpty, includes } from 'ramda'
import styled from 'styled-components/macro'
import {
  Text,
  Graphic,
  Spacing,
  Button,
  ProgressBar,
  DataSourceGraphic
} from '../../../components/ui'
import { Dropzone } from '../../../components/Dropzone'
import { ApiProviders } from '../../../providers/localNdlProvider/_apis'
import { crunch } from '../../../util/window'
import { capitalizeFirstLetter } from '../../../util'
import { uploadInsights } from '../../../util/chunks'

const statuses = {
  idle: 'idle',
  errored: 'errored',
  selected: 'selected',
  crunching: 'crunching',
  uploading: 'uploading',
  uploaded: 'uploaded'
}

export const UploadCard = ({
  inputDataSource,
  provider,
  warehouseJWT,
  warehouseUploadStatuses,
  incUploadsCompleted
}) => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadTotal, setUploadTotal] = useState(0)
  const [status, setStatus] = useState(statuses.idle)

  const {
    objectType: { name: objectTypeName },
    objectTypeId,
    dataSources,
    sql,
    dataSourceIds
  } = inputDataSource.query

  const [firstDataSourceId] = dataSourceIds

  useEffect(() => {
    if (isEmpty(warehouseUploadStatuses)) return

    const isCompleted = pipe(
      find(
        i =>
          propEq('objectTypeId', objectTypeId.toString())(i) &&
          propEq('dataSourceId', firstDataSourceId)(i)
      ),
      prop('completed')
    )(warehouseUploadStatuses)

    if (isCompleted && status !== statuses.uploaded) {
      setStatus(statuses.uploaded)
    }
  }, [warehouseUploadStatuses, firstDataSourceId, objectTypeId, status])

  useEffect(() => {
    if (status === statuses.uploaded) {
      incUploadsCompleted()
    }
  }, [status, incUploadsCompleted])

  const incProgress = uploaded =>
    setUploadProgress(uploadProgress => uploadProgress + uploaded)

  const providerAPI = ApiProviders[provider.id]

  const [error, setError] = useState()
  const [selectedFile, setSelectedFile] = useState()

  const onFilesChange = async files => {
    if (!files.length) return
    const file = last(files)

    setSelectedFile(file)
    setStatus(statuses.selected)

    const parsedFile = await providerAPI.parseFile(file)

    if (parsedFile.length === 0) {
      setStatus(statuses.errored)
      setError(
        'We found 0 of the requested files after looking into the selected file. No data was uploaded.'
      )
      return
    }

    setStatus(statuses.crunching)

    try {
      const crunchedInsights = crunch(
        [{ query: sql }],
        parsedFile,
        objectTypeId,
        provider.id,
        firstDataSourceId
      )

      setUploadTotal(crunchedInsights.length)
      setStatus(statuses.uploading)

      await uploadInsights({
        token: warehouseJWT,
        insights: crunchedInsights,
        objectTypeId,
        dataSourceId: parseInt(firstDataSourceId),
        incProgress
      })

      setStatus(statuses.uploaded)
    } catch (e) {
      setStatus(statuses.errored)
      setError(e.toString())
    }
  }

  const onFilesError = error => {
    setStatus(statuses.errored)
    setError(error.message)
  }

  const disableDopzone = !includes(status, [statuses.idle, statuses.errored])

  return (
    <div style={{ position: 'relative' }}>
      <Dropzone
        onFilesChange={onFilesChange}
        onFilesError={onFilesError}
        accepts={provider.supportedExtensions}
      >
        <Wrapper>
          <UpperWrapper>
            <Spacing direction="row" align="center" justify="space-between">
              {dataSources.map(({ name: dataSourceName, id }) => (
                <Spacing
                  key={dataSourceName}
                  direction="row"
                  size="l"
                  align="center"
                >
                  <DataSourceGraphic id={id} size={36} />
                  <Text h4>{dataSourceName + ' - ' + objectTypeName}</Text>
                </Spacing>
              ))}
              {renderRightContent({ status })}
            </Spacing>
          </UpperWrapper>
          <ProgressBar total={uploadTotal} current={uploadProgress} />
          <LowerWrapper>
            {renderLowerContent({
              error,
              file: selectedFile,
              status,
              firstDataSourceId,
              objectTypeId
            })}
          </LowerWrapper>
        </Wrapper>
      </Dropzone>
      <Overlay visible={disableDopzone} />
    </div>
  )
}

const renderStyledText = ({ bold, regular }) => (
  <Text t2>
    <Text t2 bold inline>
      {bold}
      {':'}
    </Text>{' '}
    {regular}
  </Text>
)

const renderRightContent = ({ status }) => {
  switch (status) {
    case statuses.errored:
    case statuses.idle: {
      return <UploadButton />
    }

    case statuses.crunching:
    case statuses.uploading:
    case statuses.selected: {
      return <Graphic name="Spinner" size={34} themeColor="green" />
    }
    case statuses.uploaded: {
      return (
        <TickWrapper>
          <Graphic size={13} height={10} name="Tick" themeColor="white" />
        </TickWrapper>
      )
    }
    default: {
      return null
    }
  }
}

const renderLowerContent = ({
  error,
  file,
  status,
  firstDataSourceId,
  objectTypeId
}) => {
  switch (status) {
    case statuses.idle: {
      return (
        <InstructionsComponent
          firstDataSourceId={firstDataSourceId}
          objectTypeId={objectTypeId}
        />
      )
    }
    case statuses.errored: {
      return (
        <Text t2 bold color="redNegative">
          {error}
        </Text>
      )
    }

    case statuses.crunching:
    case statuses.uploading:
    case statuses.selected: {
      return (
        <Spacing size="s">
          {renderStyledText({
            bold: capitalizeFirstLetter(status),
            regular: `${file.name} (${file.sizeReadable})`
          })}
          <Text t2 color="grey">
            Please wait...
          </Text>
        </Spacing>
      )
    }
    case statuses.uploaded: {
      return (
        <Spacing>
          <Text t2 bold>
            Upload complete
          </Text>
          <Text t2 color="grey">
            Data has been successfully uploaded
          </Text>
        </Spacing>
      )
    }
    default: {
      return null
    }
  }
}

const UploadButton = () => (
  <Button link>
    <ButtonContentWrap>
      <Text h7 color="white">
        Upload
      </Text>
    </ButtonContentWrap>
  </Button>
)

const InstructionsComponent = ({ firstDataSourceId, objectTypeId }) => (
  <InstructionsLink
    t2
    href={`/instructions/${firstDataSourceId}/${objectTypeId}`}
    onClick={e => e.stopPropagation()}
  >
    How to get the file?
  </InstructionsLink>
)

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};

  box-sizing: border-box;

  box-shadow: 0px 6px 8px rgba(111, 98, 255, 0.06);
  border-radius: 10px;
`

const UpperWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.l};
  border-bottom: 1px solid ${({ theme }) => theme.color.purpleL2};
  cursor: pointer;
`
const LowerWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.l};
`

const ButtonContentWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.m}`};
`

const InstructionsLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.color.main};
`

const TickWrapper = styled.div`
  width: 34px;
  height: 34px;
  background-color: ${({ theme }) => theme.color.green};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: not-allowed;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`
