import React, { useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { complement, isNil, pipe, prop } from 'ramda'

import styled from 'styled-components/macro'
import { Modal, Text, Spacing, VideoPlayer } from '../../components/ui'
import { ActionButtons } from './components/ActionButtons'
import { SurveyOptions } from './components/SurveyOptions'
import { uploadSurvey } from '../../services/warehouse'
import { useMutation } from 'react-apollo'
import { updateOpportunityResponse } from '../../state/mutations'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 450px;
  position: relative;
`

export const InHouseSurvey = ({ params, onDismiss }) => {
  const {
    survey,
    survey: { questions },
    createdRedeem,
    opportunityId
  } = params

  const [currentQuestion, setCurrentQuestion] = useState(0)

  const [updateOpportunityResponseMutation] = useMutation(
    updateOpportunityResponse
  )

  const onClaim = async values => {
    await uploadSurvey({
      token: createdRedeem.responseToken,
      data: { results: values }
    })

    await updateOpportunityResponseMutation({
      variables: {
        shortId: createdRedeem.shortId,
        opportunityId,
        surveyCompleted: true
      }
    })

    onDismiss()
  }

  return (
    <Modal isOpen onDismiss={onDismiss} title="Survey">
      {() => (
        <Form mutators={arrayMutators} onSubmit={onClaim}>
          {({ handleSubmit, values, submitting }) => {
            const {
              title,
              type,
              choices,
              required,
              imageUrl,
              videoUrl
            } = questions[currentQuestion]

            const strippedTitle = title
              .replace(/[^a-zA-Z0-9]/g, '_')
              .substr(0, 62)
              .toLowerCase()

            const fieldName = `${currentQuestion}_${strippedTitle}`

            const hasSubmittedRequired = required
              ? pipe(prop(fieldName), complement(isNil))(values)
              : true

            return (
              <ModalWrap>
                <ContentWrapper>
                  <Spacing size="xl">
                    <Spacing direction="row" size="s">
                      <Text h3>{title}</Text>
                      {required && <Text color="red"> *</Text>}
                    </Spacing>
                    <Spacing size="l">
                      {imageUrl && <Image src={imageUrl.default} />}
                      {videoUrl && <VideoPlayer url={videoUrl} />}

                      <SurveyOptions
                        name={fieldName}
                        type={type}
                        choices={choices}
                      />
                    </Spacing>
                  </Spacing>
                </ContentWrapper>

                <Modal.Footer>
                  <ActionButtons
                    handleSubmit={handleSubmit}
                    hasSubmittedRequired={hasSubmittedRequired}
                    submitting={submitting}
                    currentQuestion={currentQuestion}
                    totalQuestions={(survey.questions || []).length}
                    setCurrentQuestion={setCurrentQuestion}
                  />
                </Modal.Footer>
              </ModalWrap>
            )
          }}
        </Form>
      )}
    </Modal>
  )
}

const ContentWrapper = styled.div``

const Image = styled.img`
  display: block;
  height: 250px;
  width: 100%;
  margin: auto;
  object-fit: contain;
`
