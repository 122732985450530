import React from 'react'
import styled from 'styled-components/macro'
import { Field } from 'react-final-form'
import {
  filter,
  find,
  includes,
  map,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr
} from 'ramda'
import {
  Box,
  Dropdown,
  RenderFromQuery,
  Text
} from '../../../../../components/ui'

import { AllUserGroupsWithMemberQuery } from '../../../../../state/queries/UserGroupsQueries'
import { getHasAdminRole } from '../../../../../util/user'
import { UBDI_TRIBE_ID } from '../../../../TribeDetails/_utils'

const StyledDropdown = styled(Dropdown)`
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #dfdfdf;
  padding-left: 0px;
`

const EmptyStateBox = styled(Box)`
  // background: ${({ theme }) => theme.color.purpleL3};
  // border: 1px solid ${({ theme }) => theme.color.main};
  padding: ${({ theme }) => theme.padding.l};
  margin: ${({ theme }) => theme.padding.l} 0;
`

export const TribeSelect = ({
  onSelectTribe,
  selectedControl,
  setEmptyState
}) => (
  <RenderFromQuery
    query={AllUserGroupsWithMemberQuery}
    renderData={data => {
      const hasAdminRole = getHasAdminRole(data)

      const allTribes = pipe(
        pathOr([], ['allUserGroups', 'edges']),
        map(prop('node'))
      )(data)

      const allMemberTribes = pipe(
        pathOr([], ['currentUser', 'member', 'tags']),
        find(propEq('uniqueId', 'system_groups')),
        propOr([], 'values')
      )(data)

      const joinedTribes = pipe(
        filter(({ id, publicDataStreamId }) => {
          if (selectedControl === 'Data') {
            return publicDataStreamId && includes(id, allMemberTribes)
          }
          return includes(id, allMemberTribes)
        }),
        map(({ id, name: label, logoUrl: imgUrl }) => ({
          id,
          label,
          imgUrl
        })),
        filter(tribe => (!hasAdminRole ? tribe.id !== UBDI_TRIBE_ID : tribe))
      )(allTribes)

      if (joinedTribes.length === 0) {
        setEmptyState(true)
        return (
          <EmptyStateBox noShadow backgroundColor="purpleL3">
            <Text t1>Join some tribe to post</Text>
          </EmptyStateBox>
        )
      }

      setEmptyState(false)

      return (
        <Field
          name="tribe"
          render={({ input }) => (
            <StyledDropdown
              onSelect={option => {
                input.onChange(option)
                onSelectTribe(option)
              }}
              selectedOption={input.value}
              options={joinedTribes}
              placeholder="Select tribe"
            />
          )}
        />
      )
    }}
  />
)
