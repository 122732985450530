import React, { createContext, useState } from 'react'

export const TribeContext = createContext({})

export const TribeContextWrapper = ({ children }) => {
  const [tribeId, setTribeId] = useState(null)
  const [selectedFilterValue, setSelectedFilterValue] = useState('')

  return (
    <TribeContext.Provider
      value={{
        tribeId,
        setTribeId,
        selectedFilterValue,
        setSelectedFilterValue
      }}
    >
      {children}
    </TribeContext.Provider>
  )
}
