import { gql } from 'apollo-boost'

export const AllDataStreamsQuery = gql`
  query allDataStreams {
    allDataStreams {
      edges {
        node {
          id
          label
          inputDataQueries {
            id
            objectTypeId
            dataSourceIds
            sql
          }
          outputDataQueries {
            id
            objectTypeId
            dataSourceIds
            sql
          }
        }
      }
    }
  }
`
