import React from 'react'
import { Form, Field } from 'react-final-form'

import {
  Spacing,
  Input,
  SubmitButton,
  ProgressBar,
  RenderFromQuery
} from '../../../components/ui'
import { validatorPassword } from '../../../util/validators'
import { pipe } from 'ramda'
import { withMutation } from '../../../components/wrappers'
import { calculatePasswordStrength } from '../../../util/passwordStrength'
import { UserMetaQuery } from '../../../state/queries/UserQueries'
import { StepWrapper } from './StepWrapper'
import { ButtonWrapper } from './ButtonWrapper'

const passwordInitialValues = {
  password: '********',
  repeatPassword: '********'
}

const StepPasswordInner = ({ updateUser, onNextStep }) => {
  const onSubmit = (values, form) => {
    const dirty = form.getState().dirty

    if (!dirty) {
      return onNextStep()
    }

    return updateUser({ variables: { newPassword: values.password } }).then(
      async ({ errors, userErrors, data }) => {
        if (!userErrors && data && data.updateUser) {
          onNextStep()
        }
        return userErrors || errors
      }
    )
  }

  return (
    <RenderFromQuery
      query={UserMetaQuery}
      renderData={({ currentUser: { hasPassword } }) => (
        <StepWrapper>
          <Form
            onSubmit={onSubmit}
            initialValues={hasPassword && passwordInitialValues}
            validate={validatorPassword('password')}
          >
            {({
              handleSubmit,
              submitting,
              values: { password },
              ...formProps
            }) => {
              const { level, color } = calculatePasswordStrength(password)

              return (
                <form onSubmit={handleSubmit}>
                  <Spacing size="l">
                    <Field
                      component={Input}
                      name="password"
                      label="Password"
                      type="password"
                      securedTextEntry
                      autoFocus
                      block
                    />
                    <ProgressBar
                      current={level}
                      total={4}
                      color={color}
                      showLabel={false}
                    />
                    <Field
                      component={Input}
                      name="repeatPassword"
                      label="Repeat Password"
                      type="password"
                      securedTextEntry
                      block
                    />
                  </Spacing>
                  <ButtonWrapper>
                    <SubmitButton block disabled={submitting} {...formProps}>
                      Next
                    </SubmitButton>
                  </ButtonWrapper>
                </form>
              )
            }}
          </Form>
        </StepWrapper>
      )}
    />
  )
}

export const StepPassword = pipe(withMutation('updateUser'))(StepPasswordInner)
