import { gql } from 'apollo-boost'
import { CommunityPollCommentFragment } from './CommunityPollComment'

export const UserGroupFragment = gql`
  fragment UserGroup on UserGroup {
    id
    name
    description
    membersLimit
    membersCount
    logoUrl
    customTag
    gradient
    filterCategories
    requiredTags
    newPostsCount
    requiredTagValues {
      uniqueId
      values
    }
    socialMedia {
      url
      logoUrl
      label
      defaultGraphic
    }
    publicDataStreamId
    leaderboardDataStreamId
    dataQuery {
      id
      objectTypeId
      sql
    }
    forums {
      key
      value
      label
      promoted
      dataStreamQueryId
      dataQuery {
        id
        objectTypeId
        sql
      }
      communityPoll {
        id
        totalComments
        viewCount
        comments {
          id
          ...CommunityPollComment
          comments {
            id
            ...CommunityPollComment
          }
        }
      }
    }
  }
  ${CommunityPollCommentFragment}
`
