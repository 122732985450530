import React from 'react'
import { useLocalStorageState } from 'react-storage-hooks'

import { Notice as NoticeText } from '../../../constants'
import { Notice } from '../../ui'

export const LocalStorageNotice = ({ noticeId }) => {
  const [isNoticeDismissed, setIsNoticeDismissed] = useLocalStorageState(
    noticeId,
    null
  )

  if (isNoticeDismissed) return null

  return (
    <Notice
      title={NoticeText[noticeId].title}
      onClose={() => {
        setIsNoticeDismissed(true)
      }}
    >
      {NoticeText[noticeId].text}
    </Notice>
  )
}
