import React from 'react'
import styled from 'styled-components/macro'
import { Text } from '../../../../../../components/ui'

const StretchedContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const VerticalOuterBase = styled.div`
  display: flex;
  background: ${({ theme }) => theme.color.purpleL4};
  height: 100px;
  justify-content: flex-end;
  flex-direction: column;
`

const VerticalInnerBase = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => height};
  width: 100%;
`

const VerticalTextPercentage = styled.div`
  padding-top: ${({ theme }) => theme.padding.s};
`

export const PollResultVerical = ({ height, backgroundColor }) => (
  <StretchedContainer>
    <VerticalOuterBase>
      <VerticalInnerBase
        height={`${height}%`}
        backgroundColor={backgroundColor}
      />
    </VerticalOuterBase>
    <VerticalTextPercentage>
      <Text h7 center>
        {Math.round(height)}%
      </Text>
    </VerticalTextPercentage>
  </StretchedContainer>
)
