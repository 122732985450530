import { isEmpty } from 'ramda'
import styled from 'styled-components/macro'
import React, { useState } from 'react'
import { Dropzone } from '../../../../../../../components/Dropzone'
import {
  Button,
  Spacing,
  Text,
  VideoPlayer
} from '../../../../../../../components/ui'
import { isImg } from '../../../../../../../util'

const HEIGHT = 350

const LoadingMediaPlaceholder = styled.div`
  background: ${({ theme }) => theme.color.dark1};
  border-radius: 5px;
  width: 100%;
  height: ${HEIGHT}px;
`

const StyledImage = styled.img`
  border-radius: 5px;
  height: ${HEIGHT}px;
  width: 100%;
  object-fit: cover;
`

const Touchable = styled.div`
  cursor: pointer;
`

const UploadMedia = ({ input }) => {
  const [mediaLoading, setMediaLoading] = useState(false)

  const onFilesChange = files => {
    if (isEmpty(files)) return
    setMediaLoading(true)

    const file = files[0]

    const reader = new FileReader()

    reader.onload = event => {
      if (isImg(file)) {
        input.onChange({
          data: event.target.result,
          preview: file.preview.url,
          type: file.type
        })
      } else {
        input.onChange({
          file,
          preview: URL.createObjectURL(file),
          type: file.type
        })
      }

      setMediaLoading(false)
    }
    reader.readAsDataURL(file)
  }

  const removeMedia = () => {
    input.onChange(null)
  }

  if (mediaLoading) return <LoadingMediaPlaceholder />

  return (
    <>
      <Spacing>
        {input.value ? (
          <Spacing>
            {isImg(input.value) ? (
              <StyledImage src={input.value.preview} />
            ) : (
              <VideoPlayer url={input.value.preview} />
            )}
            <Touchable onClick={removeMedia}>
              <Text h5 color="main" center>
                Remove
              </Text>
            </Touchable>
          </Spacing>
        ) : (
          <Dropzone
            onFilesChange={onFilesChange}
            onFilesError={() => {}}
            accepts={['image/*', 'video/*']}
            clickable
          >
            <Button outline block>
              Browse media
            </Button>
          </Dropzone>
        )}
      </Spacing>
    </>
  )
}

export default UploadMedia
