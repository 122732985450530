import { gql } from 'apollo-boost'
import { UsersDataSourceFragment } from '../fragments'

const mutation = gql`
  mutation UninkDataSource($dataSourceId: String!) {
    unlinkDataSource(dataSourceId: $dataSourceId) {
      success
      query {
        currentUser {
          id
          ...UsersDataSource
        }
      }
      errors {
        key
        message
      }
    }
  }
  ${UsersDataSourceFragment}
`

export default mutation
