import gql from 'graphql-tag'

export default gql`
  mutation ResendVerificationCode {
    resendVerificationCode {
      success
      errors {
        key
        message
      }
    }
  }
`
