import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Spacing, Text } from '../../../../components/ui'
import { LoadingBlanket } from './LoadingBlanket'
import { TransactionsListItem } from './TransactionsListItem'

export const TransactionsList = ({
  data,
  limit = 10,
  dataSourceId,
  onShare,
  parentRef
}) => {
  const [page, setPage] = useState(1)

  const dataLimit = page * limit

  const renderTransactions = () => {
    if (data.length === 0) {
      return <EmptyState />
    }
    return (
      <InfiniteScroll
        key="transactions"
        hasMore={dataLimit < data.length}
        loadMore={setPage}
        pageStart={1}
        useWindow={!parentRef}
        loader={
          <div style={{ paddingTop: 10 }}>
            <LoadingBlanket key={page} />
          </div>
        }
        {...(parentRef && { getScrollParent: () => parentRef.current })}
      >
        <Spacing size="s">
          {data.slice(0, dataLimit).map(({ data }, i) => (
            <TransactionsListItem
              key={`${data.createddate}-${i}`}
              data={data}
              dataSourceId={dataSourceId}
              objectTypeId={201}
              onShare={onShare}
            />
          ))}
        </Spacing>
      </InfiniteScroll>
    )
  }

  return (
    <Spacing size="l">
      <Text h5 dark>
        Your transactions
      </Text>
      {renderTransactions()}
    </Spacing>
  )
}

const EmptyState = () => (
  <Text t0 center color="black">
    No transactions
  </Text>
)
