import { format } from 'date-fns'
import React from 'react'
import { Box, Spacing, Text } from '../../../components/ui'
import { Formats } from '../../../constants'
import theme from '../../../theme'

export const TransactionCard = ({ name, totalUbdi, totalUsd, createdAt }) => {
  const isIncoming = totalUbdi > 0 || totalUsd > 0
  return (
    <Box>
      <Spacing size="xs">
        <Spacing direction="row" align="center" justify="space-between">
          <Text h6 bold wrap>
            {name}
          </Text>
          {totalUbdi && (
            <Text h6 bold>
              {totalUbdi} U
            </Text>
          )}
          {totalUsd && (
            <Text h6 bold>
              {totalUsd} USD
            </Text>
          )}
        </Spacing>
        <Spacing direction="row" align="center" justify="space-between">
          <Text t4 color={theme.color.grey70}>
            {format(new Date(parseInt(createdAt)), Formats.dates.medium)}
          </Text>
          <Text t4 color={isIncoming ? 'green' : 'red'}>
            Money {isIncoming ? 'In' : 'Out'}
          </Text>
        </Spacing>
      </Spacing>
    </Box>
  )
}
