import React from 'react'
import { Field, Form } from 'react-final-form'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { flatten, map, pathOr, pipe, prop, without } from 'ramda'
import { AudienceSchemaQuery, UserQuery } from '../../state/queries'
import { Back, Button, Spacing, Text } from '../ui'
import { withMutation } from '../wrappers'
import { getMemberPublicTags } from '../../screens/TribeDetails/util'
import { TribeTraitsList } from './components/TribeTraitsList'
import { tribeTraitsLengthValidator } from '../../util/validators/tribes'
import theme from '../../theme'

const Container = styled.div`
  margin-top: ${({ theme, showTopBorder }) => showTopBorder && theme.padding.l};
  padding: ${({ theme }) => `${theme.padding.xl} ${theme.padding.m}  `};
  display: flex;
  flex-direction: column;
  border-top: ${({ theme, showTopBorder }) =>
    showTopBorder && `1px solid ${theme.color.greyL1}`};
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ArrowWrap = styled.div`
  &:hover {
    opacity: 0.8;
  }
`

export const TribeSettingsInner = ({
  updateMember,
  tribe,
  setShowTribeSetting = () => {},
  setEditTribe = () => {},
  showHeader
}) => {
  const { data: { currentUser } = {}, loading: userLoading } = useQuery(
    UserQuery
  )
  const { data, loading: schemaLoading } = useQuery(AudienceSchemaQuery)
  const audienceSchema = pathOr([], ['audienceSchema'], data)

  if (userLoading || schemaLoading) {
    return null
  }
  const allQuestions = pipe(
    map(prop('children')),
    flatten,
    map(prop('uniqueId'))
  )(audienceSchema)

  const publicTags = getMemberPublicTags(currentUser, tribe.id)

  const onSavePublicSettings = values =>
    updateMember({
      variables: {
        tribeSettings: {
          id: tribe.id,
          publicTags: values.publicTags
        }
      }
    }).then(({ userErrors, data, errors }) => {
      if (!userErrors && data && data.updateMember) {
        setShowTribeSetting(false)
        setEditTribe(null)
        return
      }

      return userErrors || errors
    })

  return (
    <Container showTopBorder={!showHeader}>
      <Form
        initialValues={{
          publicTags
        }}
        onSubmit={onSavePublicSettings}
        validate={tribeTraitsLengthValidator}
      >
        {({ handleSubmit, values, submitting, invalid }) => (
          <Spacing size="xl">
            {showHeader && (
              <Header
                submitting={submitting}
                invalid={invalid}
                tribe={tribe}
                handleSubmit={handleSubmit}
                setEditTribe={setEditTribe}
                currentUser={currentUser}
                updateMember={updateMember}
              />
            )}
            <Spacing size="l">
              <Text t1 bold>
                Choose up to 10 public traits for {tribe.name}
              </Text>
              <Field
                name="publicTags"
                component={TribeTraitsList}
                publicTags={values.publicTags}
                filterCategories={allQuestions}
                currentUser={currentUser}
              />
            </Spacing>
            {!showHeader && (
              <SaveChanges
                submitting={submitting}
                invalid={invalid}
                handleSubmit={handleSubmit}
              />
            )}
          </Spacing>
        )}
      </Form>
    </Container>
  )
}

const SaveChanges = ({
  submitting,
  invalid,
  handleSubmit,
  buttonText = 'Save settings'
}) => (
  <ButtonWrap>
    <Button disabled={submitting || invalid} onClick={handleSubmit}>
      {buttonText}
    </Button>
  </ButtonWrap>
)

const Header = ({
  submitting,
  invalid,
  tribe,
  handleSubmit,
  updateMember,
  setEditTribe,
  currentUser
}) => {
  const tags = pathOr([], ['member', 'tags'], currentUser)

  const onLeaveTribe = () => {
    const newTags = withoutTribe({ tags, tribeId: tribe.id })
    return updateMember({
      variables: {
        tags: newTags
      }
    }).then(({ userErrors, data }) => {
      if (!userErrors && data && data.updateMember) {
        setEditTribe(null)
        return
      }
    })
  }

  return (
    <Spacing direction="row" justify="space-between">
      <Spacing direction="row" size="l" align="center">
        <ArrowWrap onClick={() => setEditTribe(null)}>
          <Back
            backRouteName="account"
            background="white"
            borderColor={theme.color.main}
          />
        </ArrowWrap>
        <Text h2>{tribe.name} Public Settings</Text>
      </Spacing>

      <Spacing direction="row" size="s">
        <Button outline onClick={onLeaveTribe}>
          Leave Group
        </Button>
        <SaveChanges
          submitting={submitting}
          invalid={invalid}
          handleSubmit={handleSubmit}
          buttonText="Save changes"
        />
      </Spacing>
    </Spacing>
  )
}

const withoutTribe = ({ tags, tribeId }) => {
  const currentTribes = tags.find(
    ({ uniqueId }) => uniqueId === 'system_groups'
  )
  return map(tag => {
    if (tag.uniqueId === 'system_groups') {
      return {
        uniqueId: tag.uniqueId,
        values: without(tribeId, currentTribes.values)
      }
    }
    return tag
  })(tags)
}

export const TribeSettings = withMutation('updateMember')(TribeSettingsInner)
