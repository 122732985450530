export const STEPS = [
  {
    id: 'welcome',
    graphic: 'HumanULogoWithDome',
    title: 'Welcome to UBDI',
    description: `Just by joining, you’ve been 
    added to our UBDI tribe. Here’s how to use 
    our app as we work together towards a Universal Basic Data Income.`,
    isPng: false
  },
  {
    id: 'dataSources',
    graphic: 'DataBankActive',
    height: 180,
    title: 'Add data sources & traits to your databank',
    description: `Add data to your private and encrypted data bank. Adding a source or trait does't mean you are "selling data" - any opportunity to earn will be clear in the earn section.`,
    isPng: false,
    paddingTop: 100
  },
  {
    id: 'publicProfile',
    graphic: 'ProfileExample',
    height: 250,
    title: 'Build your public profile',
    description: `Add public traits, data streams, and posts to your profile so you can start interacting with others in tribes. You can be as anonymous or as well known as you'd like.`
  },
  {
    id: 'joinTribes',
    graphic: 'JoinTribesExample',
    height: 240,
    title: 'Join verified tribes',
    description: `Both data sources and these traits will be used to verify and qualify you for entry into tribes, while helping you get matched and paid through ads, research, and more in the earn section.`
  },
  {
    id: 'talkTribe',
    graphic: 'TalkTribeExample',
    title: 'Join tribes to TALK!',
    description: `Inside you can post links, photos, videos and even the trades, songs, or runs from your databank. You can also create polls and filter the answers by traits like gender, age, or political ideology.`
  },
  {
    id: 'learnTribe',
    graphic: 'LearnTribeExample',
    title: 'Join tribes to LEARN!',
    description: `Learn what others are doing online as they stream data generated in their everyday lives, or learn about the data you generate in your private databank.`
  },
  {
    id: 'earnTribe',
    graphic: 'EarnTribeExample',
    title: 'Join tribes to EARN!',
    description: `Get paid anytime you’re matched with researchers looking for heavy traders, or an ad for the next Fitbit, with 70% of the revenue paid out directly to you!`
  }
]
