import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { Box, Spacing, Text } from '../../../components/ui'
import Switch from 'react-ios-switch'
import { withMutation } from '../../../components/wrappers'
import theme from '../../../theme'

export const PublicPostsManagement = withMutation('updateMember')(
  ({ updateMember, member }) => {
    const handlePostsPublicChange = (values, form) => {
      const { dirty } = form.getState()
      if (values && dirty) {
        const { arePostsPublic } = values
        return updateMember({ variables: { arePostsPublic } })
      }
    }

    return (
      <Form
        initialValues={{ arePostsPublic: member.arePostsPublic }}
        onSubmit={handlePostsPublicChange}
        render={({ handleSubmit }) => {
          return (
            <Box>
              <FormSpy
                onChange={handleSubmit}
                subscription={{ values: true }}
              />
              <Spacing direction="row" justify="space-between" align="center">
                <Spacing size="xs">
                  <Text h5>Make my posts public</Text>
                  <Text t3>
                    Visitors to your profile will see all the posts you made
                  </Text>
                </Spacing>
                <Field
                  name="arePostsPublic"
                  render={({ input }) => (
                    <Switch
                      checked={input.value}
                      onChange={input.onChange}
                      onColor={theme.color.main}
                    />
                  )}
                />
              </Spacing>
            </Box>
          )
        }}
      />
    )
  }
)
