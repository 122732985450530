import gql from 'graphql-tag'

export const TargetAudienceBucketsFragment = gql`
  fragment TargetAudienceBucket on TargetAudienceBucket {
    id
    name
    tags {
      and {
        uniqueId
        values
      }
    }
    queries {
      objectTypeId
      dataSourceIds
      sql
    }
    quota
    responseCount
  }
`
