import React from 'react'
import { Dropzone } from '../../../components/Dropzone'
import { Button, Spacing, Text } from '../../../components/ui'
import { Sources } from '../../../constants'
import { DataSourceCard } from '../../DataBank/components'
import { DataSourceLinker } from '../../DataBank/components/DataSourceLinker'
import SyncStateIcon from '../../../components/ui/SyncStateIcon'
import styled from 'styled-components'

export const DataSourceLink = props => {
  return (
    <DataSourceLinker {...props}>
      {({
        hasCustomLink,
        status,
        dataSource,
        onStartLink,
        onFilesChange,
        apiProvider,
        apiProviderName
      }) => (
        <DataSourceCard
          {...dataSource}
          status={status}
          setSelectedSourceId={() => {}}
          renderAction={() =>
            hasCustomLink ? (
              <LocalUploadAction
                status={status}
                dataSource={dataSource}
                onFilesChange={onFilesChange}
                apiProviderName={apiProviderName}
                apiProvider={apiProvider}
              />
            ) : (
              <LinkButton
                status={status}
                dataSource={dataSource}
                onStartLink={onStartLink}
              />
            )
          }
        />
      )}
    </DataSourceLinker>
  )
}

const ButtonWrapper = styled(Button)`
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  background: ${({ theme }) => theme.color.main};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
`

const LinkButton = ({ status, onStartLink }) => {
  const getButtonText = () => {
    switch (status) {
      case Sources.statuses.pending:
        return `Link`
      case Sources.statuses.running:
        return 'Linking...'
      case Sources.statuses.failed:
        return `Retry`
      default:
        return ''
    }
  }

  if (
    status === Sources.statuses.completed ||
    status === Sources.statuses['needs-resync']
  ) {
    return <SyncStateIcon syncState={status} size={32} />
  }

  return (
    <Spacing size="xl" align="center" justify="center">
      <ButtonWrapper onClick={onStartLink}>
        <Text h6 color="white">
          {getButtonText()}
        </Text>
      </ButtonWrapper>
    </Spacing>
  )
}

const LocalUploadAction = ({
  status,
  onFilesChange,
  apiProvider,
  apiProviderName
}) => {
  const isPending = status === Sources.statuses.pending

  const isDisabled = [
    Sources.statuses.running,
    Sources.statuses.completed,
    Sources.statuses['needs-resync']
  ].includes(status)

  const getButtonText = () => {
    switch (status) {
      case Sources.statuses.pending:
        return `Upload ${apiProviderName} file`
      case Sources.statuses.running:
        return 'Uploading...'
      case Sources.statuses.failed:
        return `Retry uploading ${apiProviderName} file`
      default:
        return ''
    }
  }

  if (
    status === Sources.statuses.completed ||
    status === Sources.statuses['needs-resync']
  ) {
    return <SyncStateIcon syncState={status} size={32} />
  }

  return (
    <Spacing size="xl" align="center" justify="center">
      <Dropzone
        provider={apiProvider}
        onFilesChange={onFilesChange}
        onFilesError={onFilesChange}
        disabled={isDisabled}
      >
        <ButtonWrapper
          status={status}
          outline={!isPending}
          disabled={isDisabled}
        >
          <Text h6 color="white">
            {getButtonText()}
          </Text>
        </ButtonWrapper>
      </Dropzone>
    </Spacing>
  )
}
