import gql from 'graphql-tag'
import { PublicMemberFragment } from '../fragments'

export const AllFollowersQuery = gql`
  query allFollowers($targetMemberId: String, $tribeId: String!) {
    allFollowers(targetMemberId: $targetMemberId) {
      id
      ...PublicMember
      publicTags(tribeId: $tribeId)
    }
  }
  ${PublicMemberFragment}
`

export const AllFollowingQuery = gql`
  query allFollowing($followerMemberId: String, $tribeId: String!) {
    allFollowing(followerMemberId: $followerMemberId) {
      id
      ...PublicMember
      publicTags(tribeId: $tribeId)
    }
  }
  ${PublicMemberFragment}
`

export const AllFollowingCurrentMemberLeanQuery = gql`
  query AllFollowingMemberLean {
    allFollowing {
      id
      ...PublicMember
    }
  }
  ${PublicMemberFragment}
`
