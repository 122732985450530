import NDLProvider from '../../../providers/ndlProvider'
import { LocalNdlProvider } from '../../../providers/localNdlProvider'

const authorize = ({ onSuccess, onFail }) => async dispatch => {
  try {
    await NDLProvider.authorize()

    onSuccess && onSuccess()
  } catch (err) {
    console.log('Error initializing Local UBDI Sources', err)

    alert(
      'Authorization with UBDI Sources failed',
      'If you believe this to be an issue, please shake the app to report it.'
    )
    onFail && onFail()
    return false
  }
}

const startLinkingSource = async ({
  dataSourceId,
  objectTypeId,
  data,
  onComplete,
  onError
}) => {
  const { accountId, encryptionKey } = await NDLProvider.createAccount(
    dataSourceId
  )

  await NDLProvider.registerAccount({
    registerAccountId: accountId,
    dataSourceId,
    encryptionKey
  })

  try {
    const SourceProvider = LocalNdlProvider(dataSourceId)

    const localData = await SourceProvider.importData(objectTypeId, data)

    console.log(
      'Starting upload UBDI Sources local data ',
      dataSourceId,
      objectTypeId
    )

    await NDLProvider.uploadLocalData({
      accountId,
      dataSourceId,
      objectTypeId,
      data: localData
    })

    onComplete && onComplete({ status: 'linked' })
  } catch (err) {
    console.log(`Error initializing ${dataSourceId}`, err)

    localStorage.removeItem(`account_${accountId}`)
    onError && onError(err)
    return false
  }
}

const getData = async ({ dataQueries, connectedAccounts, dataRequest }) => {
  const { opportunityId, shortId, responseToken } = dataRequest

  const kickoffs = await NDLProvider.kickoffOpportunity({
    dataQueries,
    connectedAccounts,
    opportunityId,
    shortId,
    responseToken
  })

  console.log(
    'UBDI Sources kicked off data queries',
    kickoffs,
    dataQueries,
    connectedAccounts
  )

  return { kickoffs }
}

export default {
  authorize,
  startLinkingSource,
  getData
}
