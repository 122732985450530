import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Avatar } from '../../../components/Avatar'
import { Button, Graphic, Spacing, Text } from '../../../components/ui'
import { User } from '../../../constants'
import { useModal } from '../../../hooks'

const UserInfoWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.padding.xl};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  border-radius: 10px;
  padding-top: 70px;
  text-align: center;
  min-width: 285px;
`
const AvatarWrapper = styled.div`
  position: absolute;
  top: -52px;
  left: 0;
  right: 0;
  margin: auto;
  width: 105px;
`

const SpacingWrap = styled(Spacing)`
  flex: 1;
`
const BoxWrap = styled.div`
  background: ${({ theme }) => theme.color.purpleL3};
  border-radius: 10px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.padding.s};
`

export const UserInfo = ({ currentUser: { username, member } }) => {
  const { totalUbdi, totalUsd } = member
  const { openModal } = useModal()
  const { push } = useHistory()

  const onLogOut = () => {
    localStorage.removeItem(User.AUTH_TOKEN)
    push('/signin')
  }

  const options = [
    {
      id: 'UsdSign',
      value: totalUsd
    },
    {
      id: 'UbdiSign',
      value: totalUbdi
    }
  ]

  const onDestroy = () => openModal('DataBomb', { member })

  return (
    <UserInfoWrapper>
      <AvatarWrapper>
        <Avatar member={member} size={105} />
      </AvatarWrapper>
      <SpacingWrap size="l">
        <Spacing size="l" align="center">
          <Text h2 ellipsis>
            {username}
          </Text>
          <Spacing direction="row" size="s">
            {options.map(({ id, value }) => (
              <BoxWrap key={id}>
                <Spacing size="xs" align="center">
                  <Graphic name={id} size={24} />
                  <Text h3>{value}</Text>
                </Spacing>
              </BoxWrap>
            ))}
          </Spacing>
        </Spacing>
        <Spacing size="s">
          <Button block outline onClick={onDestroy}>
            Data Bomb
          </Button>
          <Button block onClick={onLogOut}>
            Log Out
          </Button>
        </Spacing>
      </SpacingWrap>
    </UserInfoWrapper>
  )
}
