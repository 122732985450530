import React from 'react'
import { CompetitionEntriesLeaderboard, CompetitionDataLeaderboard } from './'

export const CompetitionLeaderboard = props => {
  const {
    post: { competition }
  } = props
  const { dataStreamId } = competition

  if (dataStreamId) return <CompetitionDataLeaderboard {...props} />
  return <CompetitionEntriesLeaderboard {...props} />
}
