import React from 'react'
import {
  CompetitionCard,
  PostCard
} from '../../screens/Home/components/CommunityPosts/components'

export const CommunityPostCard = ({ post, ...props }) => {
  const PostCardComponent = post.competition ? CompetitionCard : PostCard

  return <PostCardComponent key={post.id} post={post} {...props} />
}
