import React from 'react'
import styled from 'styled-components'
import { Graphic, Spacing, Text } from '../../../../../components/ui'

const InfoPillWrapper = styled.div`
  padding: 5px 9px 4px 9px;
  border-radius: 7px;
  background: ${({ theme }) => theme.color.purpleL3};
  ${({ onClick }) => onClick && `cursor: pointer;`}
`

const GraphicWrapper = styled(Graphic)`
  display: flex;
`

export const InfoPillWrap = ({
  graphicName,
  graphicColor,
  children,
  onClick,
  graphicSize = 16
}) => (
  <InfoPillWrapper onClick={onClick}>
    <Spacing direction="row" align="center" size="s">
      <GraphicWrapper
        name={graphicName}
        size={graphicSize}
        color={graphicColor}
      />
      <Text h6>{children}</Text>
    </Spacing>
  </InfoPillWrapper>
)
