import { join, map, pipe, range } from 'ramda'

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const generateRandomString = pipe(
  range(0),
  map(() => characters.charAt(Math.floor(Math.random() * characters.length))),
  join('')
)
