import { gql } from 'apollo-boost'
import { CompetitionFragment } from '../fragments'

const mutation = gql`
  mutation EntryLike($id: String!, $entryId: String!) {
    entryLike(id: $id, entryId: $entryId) {
      competition {
        id
        ...Competition
      }
      errors {
        key
        message
      }
    }
  }
  ${CompetitionFragment}
`

export const getOptimisticEntryLike = ({ competition, entry }) => ({
  __typename: 'Mutation',
  entryLike: {
    __typename: 'CompetitionPayload',
    competition: {
      __typename: 'Competition',
      ...competition,
      entries: competition.entries.map(competitionEntry => {
        return {
          __typename: 'Entry',
          ...competitionEntry,
          totalLikes:
            competitionEntry.id === entry.id
              ? competitionEntry.totalLikes + (competitionEntry.liked ? -1 : 1)
              : competitionEntry.totalLikes,
          liked:
            competitionEntry.id === entry.id
              ? !competitionEntry.liked
              : competitionEntry.liked
        }
      })
    },
    errors: null
  }
})

export default mutation
