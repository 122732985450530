import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, Tabs } from '../../components/ui'
import { DismissableNotice } from '../../components/ui/DismissableNotice'
import { LocalStorage } from '../../constants'
import { usePushNotifications } from '../../hooks'
import {
  TribesList,
  CommunityPosts,
  ReferralBox,
  CommunityStatistics
} from './components'
import {
  CreatePost,
  FollowingPostsAndData
} from './components/CommunityPosts/components'
import { DiscoverMembers } from './components/Tribes/components/DiscoverMembers'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftPart = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.padding.xl} 0px ${theme.padding.xl} ${theme.padding.xl}`};
`

const CenterPart = styled.div`
  display: flex;
  flex: 2;
  padding: ${({ theme }) => theme.padding.xl} 45px;
`

const RightPart = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.padding.xl} ${theme.padding.xl} ${theme.padding.xl} 0px`};
`

export const HomeScreen = () => {
  usePushNotifications()

  return (
    <Container>
      <LeftPart>
        <TribesList
          memberGroups
          showCounter
          title="My tribes"
          isRowClickable
          showSocialMedia
        />
      </LeftPart>
      <CenterPart>
        <Spacing size="l" stretched>
          <CommunityStatistics />
          <CreatePost />
          <DismissableNotice notice={LocalStorage.welcomeToUbdiNotice} />

          <Tabs>
            <Tabs.Tab title="Tribes">
              <CommunityPosts />
            </Tabs.Tab>
            <Tabs.Tab title="Following">
              <FollowingPostsAndData />
            </Tabs.Tab>
          </Tabs>
        </Spacing>
      </CenterPart>
      <RightPart>
        <Spacing size="xl" stretched>
          <ReferralBox />
          <TribesList title="Discover tribes" isRowClickable />
          <DiscoverMembers />
        </Spacing>
      </RightPart>
    </Container>
  )
}
