import React from 'react'
import { head } from 'ramda'
import { Graphic, Spacing } from '../ui'
import { tribeColorSchema } from './constants'
import styled from 'styled-components'

const GraphicWrap = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`

const CircleWrap = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

const colorSchema = tribeColorSchema
export const AvatarColorPicker = ({ currentColors, input, tribeId }) => {
  const isColorChecked = colors => head(colors) === head(currentColors)

  return (
    <Spacing direction="row" size="xl" align="center">
      <Graphic
        name="AvatarIncognito"
        size={80}
        color={head(currentColors)}
        avatarId={tribeId}
      />
      <Spacing direction="row" size="s" justify="center">
        {colorSchema.map((colors, index) => (
          <CircleWrap
            color={head(colors)}
            onClick={() => input.onChange(colors)}
            key={`${index}-${head(colors)}`}
          >
            {isColorChecked(colors) && (
              <GraphicWrap>
                <Graphic name="Checkmark" size={20} color="white" />
              </GraphicWrap>
            )}
          </CircleWrap>
        ))}
      </Spacing>
    </Spacing>
  )
}
