import { head, values, last, filter, pipe, isNil, map } from 'ramda'
import { Countries } from '../../constants'

export const getUsersGender = gender => {
  if (!gender) return null

  switch (head(gender)) {
    case 'general_gender_0':
      return 2
    case 'general_gender_1':
      return 1
    case 'general_gender_2':
      return 3
    default:
      return null
  }
}

export const getUsersBirthYear = year => {
  if (!year) return null
  return parseInt(head(year))
}

export const getUsersMaritalStatus = maritalStatus => {
  if (!maritalStatus) return null

  switch (head(maritalStatus)) {
    case 'general_maritalStatus_0':
      return 0 // SINGLE
    case 'general_maritalStatus_1':
      return 1 // MARRIED
    case 'general_maritalStatus_3':
      return 2 // DIVORCED
    case 'general_maritalStatus_7':
      return 3 // LIVING_WITH_PARTNER
    case 'general_maritalStatus_4':
      return 4 // SEPARATED
    case 'general_maritalStatus_2':
      return 5 // WIDOWED
    default:
      return null
  }
}

export const getEducationLevel = educationLevel => {
  if (!educationLevel) return null

  switch (head(educationLevel)) {
    case 'general_educationLevel_1':
      return 0 // ELEMENTARY_SCHOOL
    case 'general_educationLevel_2':
      return 1 // MIDDLE_SCHOOL
    case 'general_educationLevel_3':
      return 2 // HIGH_SCHOOL
    case 'general_educationLevel_6':
    case 'general_educationLevel_7':
      return 4 // UNIVERSITY
    case 'general_educationLevel_8':
    case 'general_educationLevel_9':
      return 5 // POST_GRADUATE
    default:
      return null
  }
}

export const getEmploymentInfo = employment => {
  if (!employment) return null

  switch (head(employment)) {
    case 'general_employment_0':
    case 'general_employment_1':
      return 0 // EMPLOYED_FOR_WAGES
    case 'general_employment_2':
      return 2 // UNEMPLOYED_LOOKING
    case 'general_employment_3':
      return 3 // UNEMPLOYED_NOT_LOOKING
    case 'general_employment_4':
      return 7 // RETIRED
    case 'general_employment_5':
      return 8 // UNABLE_TO_WORK
    default:
      return null
  }
}

export const getUsersEthnicity = ethnicity => {
  if (!ethnicity) return null

  switch (head(ethnicity)) {
    case 'general_ethnicity_3':
      return 1 // ASIAN
    case 'general_ethnicity_1':
      return 2 // BLACK
    case 'general_ethnicity_0':
      return 3 // WHITE
    case 'general_ethnicity_8':
      return 4 // HISPANIC
    case 'general_ethnicity_5':
      return 6 // MULTIRACIAL
    default:
      return 7 // OTHER
  }
}

export const getUsersIncome = incomeLevel => {
  if (!incomeLevel) return null

  const income = parseInt(last(head(incomeLevel)))

  switch (income) {
    case 5:
    case 6: {
      return 6 // HIGH_III
    }
    case 7:
      return 4 // HIGH_I
    default:
      return income
  }
}

export const getUsersLanguage = language => {
  if (!language) return null

  switch (head(language)) {
    case 'general_bilinguality_16':
      return [1] // ARABIC
    case 'general_bilinguality_6':
      return [3] // CHINESE
    case 'general_bilinguality_4':
      return [10] // FRENCH
    case 'general_bilinguality_3':
      return [11] // GERMAN
    case 'general_bilinguality_14':
      return [13] // HINDI
    case 'general_bilinguality_12':
      return [15] // ITALIAN
    case 'general_bilinguality_13':
      return [16] // JAPANESE
    case 'general_bilinguality_11':
      return [18] // PORTUGUESE
    case 'general_bilinguality_5':
      return [21] // RUSSIAN
    case 'general_bilinguality_2':
      return [23] // SPANISH
    case 'general_bilinguality_22':
      return [25] // TURKISH
    case 'general_bilinguality_24':
      return [26] // VIETNAMESE
    case 'general_bilinguality_10':
      return [27] // KOREAN
    case 'general_bilinguality_15':
      return [33] // HEBREW
    case 'general_bilinguality_17':
      return [34] // BENGALI
    case 'general_bilinguality_9':
      return [36] // PERSIAN
    default:
      return null
  }
}

export const getUsersLocationData = ({ postalCode, country }) => {
  if (!postalCode || !country) return null

  const result = pipe(
    values,
    filter(item => item.name === head(country)),
    head
  )(Countries.list)

  if (!result || isNil(result)) {
    return null
  }

  return `${postalCode}|${result.code}`
}

export const enforceStrings = map(String)
