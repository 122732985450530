import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Graphic, Spacing, Text } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { getOptimisticEntryLike } from '../../../state/mutations/EntryLike'

export const ComptitionEntryLike = withMutation('entryLike')(
  ({ entryLike, entry, competition }) => {
    const { id, isCompetitionFinished } = competition
    const { liked, totalLikes, id: entryId } = entry

    const onVote = () =>
      entryLike({
        variables: { id, entryId },
        optimisticResponse: getOptimisticEntryLike({ competition, entry })
      })

    return (
      <LikeCompetitionWrap
        onClick={() => !isCompetitionFinished && onVote()}
        disabled={isCompetitionFinished}
      >
        <Spacing size="xs" direction="row" align="center" justify="center">
          <Graphic name={liked ? 'Heart' : 'HeartOutline'} size={18} />
          <Text h5 color={getTextColor(liked)}>
            {totalLikes}
          </Text>
        </Spacing>
      </LikeCompetitionWrap>
    )
  }
)

const getTextColor = liked => {
  if (liked) return 'redNegative'

  return 'main'
}

const LikeCompetitionWrap = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.color.purpleL3};
  border-radius: 50px;
  padding: 7px 12px;
  min-width: 100px;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`
