import React from 'react'
import styled from 'styled-components/macro'

import { Text, Spacing, Graphic } from '../../../../components/ui'

const ButtonWrap = styled.div`
  cursor: pointer;
`

export const ShareButton = ({ onClick }) => (
  <ButtonWrap onClick={onClick}>
    <Spacing direction="row" align="center" size="xxs">
      <Graphic name="Link" themeColor="main" size={20} />
      <Text h6 color="main">
        Post
      </Text>
    </Spacing>
  </ButtonWrap>
)
