import React from 'react'
import styled from 'styled-components/macro'
import { Graphic } from '../../components/ui'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`

const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
const LogoWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 60px;
  width: 240px;
  height: 70px;
  @media only screen and (max-width: 767px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
`

export const LogoBackgroundScreen = ({ children }) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Graphic name="Header" />
      </HeaderWrapper>
      <ContentWrapper>
        <LogoWrapper>
          <Graphic name="LogoWhite" />
        </LogoWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}
