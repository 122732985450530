import React from 'react'
import styled from 'styled-components/macro'
import { Box } from '../Box'

const ContentBox = styled(Box)`
  padding: 50px 0;
  width: 35%;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 70%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

export const OnboardingContextBox = ({ children }) => (
  <ContentBox>{children}</ContentBox>
)
