import { isNil, path, pathOr } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  GradientCard,
  RenderFromQuery,
  Spacing,
  SpacingItem,
  Text
} from '../../../components/ui'
import { useModal } from '../../../hooks'
import { AllTransactionsQuery } from '../../../state/queries'
import theme from '../../../theme'
import { ContentSection } from './ContentSection'
import { SetupUphold } from './SetupUphold'
import { TransactionCard } from './TransactionCard'
import { UnverifiedNotice } from './UnverifiedNotice'
import { WalletNotice } from './WalletNotice'

const EmptyWrapper = styled.div`
  width: 100%;
  height: 240px;
  padding: ${({ theme }) => theme.padding.l};
  background: ${({ theme }) => theme.color.purpleL3};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.padding.xl};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
`
const ButtonWrap = styled(Button)`
  height: 34px;
  padding: ${({ theme }) => `0 ${theme.padding.l}`};
  border: ${({ theme }) => theme.color.main} 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.pill};
`

const INITIAL_LIMIT = 3

export const WalletSection = ({ userData }) => {
  const [shownAll, setShownAll] = useState(false)

  const earnPreferences = pathOr(
    {},
    ['currentUser', 'member', 'earnPreferences'],
    userData
  )

  const upholdEmail = path(
    ['currentUser', 'member', 'uphold', 'email'],
    userData
  )
  const hyperwalletId = path(
    ['currentUser', 'member', 'hyperwallet', 'id'],
    userData
  )

  const gradientCards = [
    {
      gradient: theme.gradient.greenGradient,
      title: 'USD',
      value: path(['currentUser', 'member', 'totalUsd'], userData)
    },
    {
      gradient: theme.gradient.blueGradient,
      title: 'UBDI',
      value: path(['currentUser', 'member', 'totalUbdi'], userData)
    }
  ]

  const renderWalletNotice = () => {
    if (isNil(upholdEmail) && !isNil(hyperwalletId)) {
      return (
        <Spacing>
          <WalletNoticeWrap
            currentUser={userData.currentUser}
            title="We have moved away from Hyperwallet payments"
            text="Your wallet linked through Hyperwallet has expired. Please
                create a new wallet using Uphold."
            renderAction={() => <SetupUphold />}
          />
          <EditPreferences earnPreferences={earnPreferences} />
        </Spacing>
      )
    }

    return (
      <Spacing size="l">
        <Spacing size="s">
          <WalletNotice currentUser={userData.currentUser} />
          <EditPreferences earnPreferences={earnPreferences} />
        </Spacing>
        <Text h6>Past transactions</Text>
        <RenderFromQuery
          query={AllTransactionsQuery}
          renderData={renderTransactions({ shownAll, setShownAll })}
          renderError={renderEmptyTransactions}
        />
      </Spacing>
    )
  }

  const renderWallet = () => {
    return (
      <Spacing size="l">
        <Spacing direction="row" size="s">
          {gradientCards.map(card => (
            <SpacingItem key={card.title} flex={1}>
              <GradientCard {...card} />
            </SpacingItem>
          ))}
        </Spacing>

        {renderWalletNotice()}
      </Spacing>
    )
  }

  return <ContentSection title="My wallet">{renderWallet()}</ContentSection>
}

const renderTransactions = ({ shownAll, setShownAll }) => ({
  allTransactions: { edges: transactions }
}) => {
  if (transactions.length === 0) {
    return renderEmptyTransactions()
  } else
    return (
      <Spacing size="l">
        <Spacing size="s">
          {sliceTransactionsByAllShown(transactions, shownAll).map(
            ({ node: transaction }) => (
              <TransactionCard key={transaction.id} {...transaction} />
            )
          )}
        </Spacing>
        {!shownAll && transactions.length > INITIAL_LIMIT && (
          <Button
            transparent
            block
            text="Show more"
            onClick={() => setShownAll(true)}
          >
            Show more
          </Button>
        )}
      </Spacing>
    )
}

const renderEmptyTransactions = () => (
  <EmptyWrapper>
    <Text t1>No past transactions</Text>
  </EmptyWrapper>
)

const sliceTransactionsByAllShown = (transactions, shownAll) => {
  if (shownAll) return transactions
  return transactions.slice(0, INITIAL_LIMIT)
}

const WalletNoticeWrap = ({ title, text, currentUser, renderAction }) => (
  <Wrapper>
    <Spacing size="xl">
      <Spacing size="l">
        <Text h4 center>
          {title || 'Verify account to create wallet'}
        </Text>
        <Text t2 center>
          {text ||
            'In order to be able to create wallet, you need to verify your phone number first'}
        </Text>
      </Spacing>
      {renderAction ? (
        renderAction()
      ) : (
        <UnverifiedNotice currentUser={currentUser} />
      )}
    </Spacing>
  </Wrapper>
)

const EditPreferences = ({ earnPreferences }) => {
  const { openModal } = useModal()

  return (
    <ButtonWrap
      block
      outline
      onClick={() => openModal('EarningPreferences', { earnPreferences })}
    >
      Edit Earning Preferences
    </ButtonWrap>
  )
}
