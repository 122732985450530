import React, { useState } from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { Graphic } from '../Graphic'
import { Spacing } from '../Spacing'
import { Text } from '../Text'

const Wrapper = styled.div`
  position: relative;
`

const SelectedOption = styled.div`
  height: ${props => props.optionHeight};
  position: relative;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  border-radius: 7px;
  border-bottom-left-radius: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '7px')};
  border-bottom-right-radius: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '7px')};
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.l}`};
  border-bottom: ${({ isMenuOpen }) => isMenuOpen && 'none'};
`

const Menu = styled.div`
  position: absolute;
  width: 100%;
  top: ${props => props.optionHeight};
  left: 0;
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  border-radius: 7px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 190px;
  overflow: auto;
  z-index: 10;
`

const MenuItem = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.color.purpleL1};
  padding: ${props => props.theme.padding.m} ${props => props.theme.padding.l};
  color: ${props => props.theme.color.dark};
  background-color: ${props => props.theme.color.white};
  overflow: hidden;
  text-align: left;
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    background-color: ${props => props.theme.color.purpleL3};
    transition: background-color ${props => props.theme.animationSpeed.normal};
  }
`

const ImgWrapper = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  object-fit: contain;
`

const ImgWrapperBlank = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background: ${({ theme }) => theme.color.purpleL3};
`

export const Dropdown = ({
  placeholder,
  options,
  selectedOption,
  onSelect,
  className,
  hideImage
}) => {
  const optionHeight = hideImage ? '55px' : '64px'

  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!isMenuOpen)

  const selectOption = option => {
    onSelect(option)
    toggleMenu()
  }
  return (
    <Wrapper>
      <SelectedOption
        onClick={toggleMenu}
        isMenuOpen={isMenuOpen}
        className={className}
        optionHeight={optionHeight}
      >
        <Spacing direction="row" justify="space-between" align="center">
          <MenuItemInner
            hideImage={hideImage}
            label={placeholder || 'Select...'}
            {...selectedOption}
          />
          <Graphic
            name={isMenuOpen ? 'ChevronUp' : 'ChevronDown'}
            color={theme.color.main}
            width={20}
            height={10}
          />
        </Spacing>
      </SelectedOption>

      <Menu visible={isMenuOpen}>
        {options.map((option, index) => (
          <MenuItem
            onClick={() => selectOption(option)}
            key={`${option.label}-${index}`}
          >
            <MenuItemInner {...option} hideImage={hideImage} />
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  )
}

const renderImage = imgUrl => {
  if (imgUrl) {
    return <ImgWrapper src={imgUrl} resizeMode="contain" />
  }
  return <ImgWrapperBlank />
}

const MenuItemInner = ({ imgUrl, label, hideImage }) => (
  <Spacing direction="row" align="center">
    {!hideImage && renderImage(imgUrl)}
    <Text t1>{label}</Text>
  </Spacing>
)
