export default {
  WelcomeToUbdiNotice: {
    title: 'Welcome to UBDI!',
    text: `Facebook, Twitter, and Reddit make billions of dollars off of your data, but it may not be how you think!
    
One person’s data is not valuable alone and their $ comes from their large network and because they pair data with a digital space in front of your face to place ads/research & more!`
  },
  DataSourcesNotice: {
    title: 'Sync data sources across platforms',
    text:
      'Data sources you link through web app will automatically be synced inside your UBDI Mobile app and vice versa.'
  },
  DataSourceNotSellingNotice: {
    title: `Does linking a data source mean I'm selling this data? NO!`,
    text: `Link data sources to get into verified & bot-free communities so you can interact while working together to earn. Your data is locked up -even from us!- so don't worry you'll need to permission & participate in earn when studies/ads come around!`
  }
}
