import React from 'react'
import { includes, map, pipe, prop, filter } from 'ramda'
import styled from 'styled-components'
import { Graphic, Spacing, Text } from '../../ui'
import { getTagValues } from '../../../state/selectors'
import { TagValue } from '../../TribeSettings/components/TagValue'
import { useModal } from '../../../hooks'

const onEditTraits = (openModal, category) =>
  openModal('EditTraits', { category })

export const TraitCategory = ({
  tags,
  category,
  answeredQuestions,
  audienceDataVerifiedTags = [],
  profileSchema
}) => {
  const { label, value } = category
  const { openModal } = useModal()

  const hasAnswers = !!answeredQuestions.find(question =>
    question.includes(value)
  )

  const tagsToList = category.children
    .map(tag => ({
      uniqueId: tag.uniqueId,
      tagValues: getTagValues(tags, profileSchema, tag.uniqueId)
    }))
    .filter(tag => !!tag.tagValues)

  return (
    <Wrapper>
      <Spacing>
        <Spacing direction="row" justify="space-between">
          <Spacing direction="row" align="center" justify="center">
            <Graphic name={['TagCategory', value]} size={30} />
            <Text h4>{label}</Text>
          </Spacing>
          {hasAnswers && (
            <GraphicWrapper
              name="LinkCircle"
              size={32}
              onClick={() => onEditTraits(openModal, category)}
            />
          )}
        </Spacing>

        {!hasAnswers ? (
          <EmptyState onClick={() => onEditTraits(openModal, category)} />
        ) : (
          <Container>
            <Spacing size="xs">
              {tagsToList.map(({ uniqueId, tagValues }, index) => {
                const verifiedTagIds = pipe(
                  filter(tag => includes(uniqueId, tag.uniqueId)),
                  map(prop('uniqueId'))
                )(audienceDataVerifiedTags)

                const isDataVerified = includes(uniqueId, verifiedTagIds)
                return (
                  <Spacing size="xs" key={`${uniqueId}_${index}`}>
                    {tagValues.map(tag => (
                      <TagValue
                        key={tag.uniqueId}
                        uniqueId={tag.parent}
                        tagValues={tagValues}
                        tags={tags}
                        tag={tag}
                        profileSchema={profileSchema}
                        isDataVerified={isDataVerified}
                        showIcon
                        showStats
                      />
                    ))}
                  </Spacing>
                )
              })}
            </Spacing>
          </Container>
        )}
      </Spacing>
    </Wrapper>
  )
}

const EmptyState = ({ onClick }) => (
  <EmptyWrapper>
    <Spacing align="center">
      <Text t2>You don't have any added traits</Text>
      <ButtonWrapper onClick={onClick}>
        <Text h6 color="white">
          Add new traits
        </Text>
      </ButtonWrapper>
    </Spacing>
  </EmptyWrapper>
)

const ButtonWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  background: ${({ theme }) => theme.color.main};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  cursor: pointer;
`

const Wrapper = styled.div`
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  padding: ${({ theme }) => `${theme.padding.xl} ${theme.padding.l}`};
  background: ${({ theme }) => theme.color.white};
`

const EmptyWrapper = styled.div`
  border-radius: 10px;
  padding: ${({ theme }) => theme.padding.xl};
  background: ${({ theme }) => theme.color.purpleL4};
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const GraphicWrapper = styled(Graphic)`
  cursor: pointer;
`
