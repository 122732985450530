import { useMutation } from '@apollo/react-hooks'
import { head } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Graphic,
  Notice,
  Spacing,
  SpacingItem,
  Text
} from '../../../components/ui'
import { useModal } from '../../../hooks'
import * as typeformEmbed from '@typeform/embed'
import {
  createOpportunityResponse,
  kickoffOpportunityResponse,
  optInOpportunity,
  updateOpportunityResponse
} from '../../../state/mutations'
import { createRedeem } from '../../../util/state/studyRedeem'

export const CompensationDetails = ({ opportunity }) => {
  const {
    survey,
    compensation: { usd, ubdi },
    inputDataSources,
    isJoinable,
    opportunityResponse
  } = opportunity

  const { id: opportunityId } = opportunity
  const [redeeming, setRedeeming] = useState(false)

  const [createOpportunityResponseMutation] = useMutation(
    createOpportunityResponse
  )
  const [updateOpportunityResponseMutation] = useMutation(
    updateOpportunityResponse
  )
  const [kickoffOpportunityResponseMutation] = useMutation(
    kickoffOpportunityResponse
  )

  const [optInOpportunityMutation] = useMutation(optInOpportunity)

  const { openModal } = useModal()

  const startExternalSurvey = createdRedeem => {
    const typeformPopup = typeformEmbed.makePopup(survey.externalLink, {
      autoClose: 1, // close popup within 1 sec after onSubmit
      mode: 'popup',
      onSubmit: () => {
        updateOpportunityResponseMutation({
          variables: {
            shortId: createdRedeem.shortId,
            opportunityId,
            surveyCompleted: true
          }
        })
      }
    })

    typeformPopup.open()
  }

  const startInHouseSurvey = createdRedeem => {
    openModal('InHouseSurvey', {
      survey: survey.inHouse,
      createdRedeem,
      opportunityId
    })
  }

  const createRedeemAndThen = onRedeemCreated => async () => {
    setRedeeming(true)
    await optInOpportunityMutation({ variables: { id: opportunity.id } })

    const createdRedeem = await createRedeem({
      createOpportunityResponse: createOpportunityResponseMutation,
      opportunity
    })

    await onRedeemCreated(createdRedeem)

    setRedeeming(false)
  }

  const submitData = async createdRedeem => {
    setRedeeming(true)
    const { shortId } = createdRedeem

    await kickoffOpportunityResponseMutation({
      variables: {
        shortId,
        opportunityId
      }
    })

    setRedeeming(false)

    return true
  }

  const renderAction = () => {
    if (!isJoinable) {
      return (
        <Button
          block
          onClick={() => openModal('MissingSources', { opportunityId })}
        >
          Unlock this study
        </Button>
      )
    }

    const { currentMemberDataRequest } = opportunity
    if (currentMemberDataRequest) {
      if (currentMemberDataRequest.cumulativeStatus === 'completed') {
        return <Notice success>Your data has been received!</Notice>
      }

      if (currentMemberDataRequest.cumulativeStatus === 'pending') {
        return (
          <Spacing stretched>
            <Notice success>
              You have successfully entered this study. Receiving your data
              might take awhile, but you can rest assured that all is well for
              now!
            </Notice>
            <Button block disabled>
              We are receiving your data...
            </Button>
          </Spacing>
        )
      }

      if (currentMemberDataRequest.cumulativeStatus === 'failed') {
        return (
          <Spacing stretched>
            <Notice error>We failed to receive your data...</Notice>
            <Button
              block
              onClick={() => submitData(opportunityResponse)}
              disabled={redeeming}
            >
              Try again
            </Button>
          </Spacing>
        )
      }
    }

    if (survey) {
      if (opportunityResponse && opportunityResponse.surveyCompleted) {
        return (
          <Button
            block
            onClick={() => submitData(opportunityResponse)}
            disabled={redeeming}
          >
            {redeeming ? 'Submitting...' : 'Complete the Study'}
          </Button>
        )
      }

      const hasDataSubmission = inputDataSources.length > 0
      if (survey.inHouse) {
        return (
          <Button block onClick={createRedeemAndThen(startInHouseSurvey)}>
            {hasDataSubmission ? 'Go to Step 1: Survey' : 'Complete Survey'}
          </Button>
        )
      }

      if (survey.externalLink) {
        return (
          <Button block onClick={createRedeemAndThen(startExternalSurvey)}>
            {hasDataSubmission ? 'Go to Step 1: Survey' : 'Complete Survey'}
          </Button>
        )
      }
    }

    return (
      <Button
        block
        onClick={createRedeemAndThen(submitData)}
        disabled={redeeming}
      >
        {redeeming ? 'Submitting...' : 'Complete the Study'}
      </Button>
    )
  }

  return (
    <CompensationDetailsWrap>
      <SpacingItem display="flex">
        <ContentWrap>
          <Spacing direction="row" justify="center" stretchChildren>
            <Card graphic="UsdSign" amount={head(usd)} border />
            <Card graphic="UbdiSign" amount={head(ubdi)} />
          </Spacing>
        </ContentWrap>
      </SpacingItem>
      <ButtonWrap>{renderAction()}</ButtonWrap>
    </CompensationDetailsWrap>
  )
}

const Card = ({ graphic, amount, border }) => (
  <CardWrap border={border}>
    <Spacing size="xs" align="center" justify="center">
      <Spacing direction="row" size="s" align="center" justify="center">
        <SpacingItem display="flex">
          <Graphic name={graphic} size={40} />
        </SpacingItem>
        <Text h2>+ {amount}</Text>
      </Spacing>
      <Text t1>to be on hold</Text>
    </Spacing>
  </CardWrap>
)

const CompensationDetailsWrap = styled(Box)`
  padding: 0;
`
const CardWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.padding.l};

  ${({ border }) =>
    border && 'border-right: 1.5px solid rgba(111, 98, 255, 0.1);'}
`

const ContentWrap = styled.div`
  flex: 1;
  border-bottom: 1.5px solid rgba(111, 98, 255, 0.1);
`

const ButtonWrap = styled(CardWrap)`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xxxl}`};
`
