import { gql } from 'apollo-boost'

export const StatisticsQuery = gql`
  query Statistics {
    statistics {
      member {
        memberDataPoints
        memberLinked
        memberOrdinalNumber
        memberReferred
        totalLinked
        totalUsers
        distinctCountries
        totalDataPoints
        maxReferred
      }
    }
  }
`
