import { lighten } from 'polished'
import React from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components'
import { DataSourceGraphic, Spacing, Text } from '../../../components/ui'
import { OnlyDataSourcesQuery } from '../../../state/queries'

const HorizontalScrollWrap = styled.div`
  overflow-x: auto;
`

const PillWrap = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.padding.s} ${theme.padding.l} ${theme.padding.s} ${theme.padding.m}`};
  border-radius: 35px;
  background: ${({ theme }) => theme.color.lightBlue};
  border: ${({ theme }) => theme.color.lightBlue} 1px solid;

  cursor: pointer;
  &:hover {
    background: ${({ theme }) => lighten(0.015, theme.color.lightBlue)};
  }
`

const SelectedPillWrap = styled(PillWrap)`
  background: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => theme.color.secondary} 1px solid;
`

const DataSourcesCarousel = ({
  dataSourceIds,
  selectedDataSourceId,
  onDataSourceIdSelect
}) => {
  const { data, loading } = useQuery(OnlyDataSourcesQuery)
  if (!dataSourceIds || dataSourceIds.length === 0 || loading) return null

  return (
    <HorizontalScrollWrap>
      <Spacing direction="row" size="s">
        {dataSourceIds.map(dataSourceId => {
          const dataSource = data.allDataSources.edges.find(
            ({ node: { id } }) => id === dataSourceId
          )

          const Pill =
            selectedDataSourceId === dataSourceId ? SelectedPillWrap : PillWrap
          return (
            <Pill
              onClick={() => onDataSourceIdSelect(dataSourceId)}
              key={dataSourceId}
            >
              <Spacing direction="row" align="center" justify="center">
                <DataSourceGraphic id={dataSourceId} size={30} />
                <Text h6 color="darkBlue">
                  {dataSource.node.name}
                </Text>
              </Spacing>
            </Pill>
          )
        })}
      </Spacing>
    </HorizontalScrollWrap>
  )
}

export default DataSourcesCarousel
