import { gql } from 'apollo-boost'

const mutation = gql`
  mutation GetWarehouseToken($dataStreamId: String!) {
    getWarehouseToken(dataStreamId: $dataStreamId) {
      token
      errors {
        key
        message
      }
    }
  }
`

export default mutation
