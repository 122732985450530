import React from 'react'
import { Spacing, SpacingItem } from '../../../components/ui'
import { TribeForumCard } from '../components'

export const TribeForums = ({ tribe }) => {
  const { id, forums } = tribe

  return (
    <Spacing direction="row" size="s" wrap>
      {forums.map((forum, index) => (
        <SpacingItem flex="0 1 48%">
          <TribeForumCard
            key={`${id}-forumCard-${index}`}
            forum={forum}
            tribeId={id}
          />
        </SpacingItem>
      ))}
    </Spacing>
  )
}
