import React, { useCallback, useEffect } from 'react'
import { includes, last, pathOr } from 'ramda'
import { useQuery } from 'react-apollo'
import { Sources } from '../../../constants'
import { ServiceProviderApis } from '../../../state/local/_providers'
import { DataSchemaQuery } from '../../../state/queries'
import { syncAccounts } from '../../../util/dataSources'
import { DataSourceSpaceEmpty } from './DataSourceSpaceEmpty'
import {
  ApiProviderNames,
  ApiProviders,
  getAvailableApiProviderConverters
} from '../../../providers/localNdlProvider/_apis'
import { useModal } from '../../../hooks'

const OBJECT_TYPES = {
  netflix: 4311,
  amazon: 4321,
  linkedin: 4404
  //[4400, 4401, 4402, 4403, 4404]
}

export const DataSourceLinker = ({
  dataSource,
  onCompletedLink,
  onUnlink,
  setSourceStatus,
  status,
  onSync,
  children,
  autoStartLink
}) => {
  const { data: { dataSchema } = {}, loading } = useQuery(DataSchemaQuery, {})
  const { openModal } = useModal()

  const onStartLink = useCallback(() => {
    setSourceStatus({
      dataSourceId: dataSource.id,
      status: Sources.statuses.running
    })

    serviceProviderApi.startLinkingSource({
      openModal,
      dataSourceId: dataSource.id,
      onComplete: onLinkingCompleted,
      onError: onLinkingError
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource])

  useEffect(() => {
    if (autoStartLink) {
      onStartLink()
    }
  }, [autoStartLink, onStartLink])

  if (!dataSource) return <DataSourceSpaceEmpty />

  if (loading) {
    return null
  }

  const {
    name,
    availableServiceProviders: [
      { id: providerId, hasCustomLink, disabledSources = [] }
    ]
  } = dataSource

  const isDisabled = includes(dataSource.id, disabledSources)

  const serviceProviderApi = ServiceProviderApis[providerId]

  const onLinkingCompleted = linked => {
    setSourceStatus({
      dataSourceId: dataSource.id,
      status:
        linked.status === 'linked'
          ? Sources.statuses.completed
          : Sources.statuses.failed
    })

    onCompletedLink({ dataSourceId: dataSource.id, providerId })
    syncAccounts({ dataSourceId: dataSource.id, dataSchema })
  }

  const onLinkingError = () => {
    onUnlink && onUnlink(dataSource.id)
  }

  const showUnlink = [
    Sources.statuses.completed,
    Sources.statuses['needs-resync']
  ].includes(status)

  const apiProviderConverter = pathOr(
    null,
    '0',
    getAvailableApiProviderConverters(dataSource.id)
  )
  const apiProvider = apiProviderConverter
    ? ApiProviders[apiProviderConverter.id]
    : null

  const apiProviderName = apiProviderConverter
    ? ApiProviderNames[apiProviderConverter.id]
    : null

  const onFilesChange = async files => {
    if (!files.length) return
    const file = last(files)

    const parsedFile = await apiProvider.parseFile(file)

    if (parsedFile.length === 0) {
      setSourceStatus({
        dataSourceId: dataSource.id,
        status: Sources.statuses.failed
      })
      return
    }

    setSourceStatus({
      dataSourceId: dataSource.id,
      status: Sources.statuses.running
    })

    ServiceProviderApis[providerId].startLinkingSource({
      dataSourceId: dataSource.id,
      objectTypeId: OBJECT_TYPES[dataSource.id],
      data: parsedFile,
      onComplete: () =>
        onCompletedLink({ dataSourceId: dataSource.id, providerId }),
      onError: onLinkingError
    })
  }

  return children({
    name,
    hasCustomLink,
    isDisabled,
    status,
    dataSource,
    onStartLink,
    onSync,
    showUnlink,
    onUnlink,
    onFilesChange,
    apiProvider,
    apiProviderName
  })
}
