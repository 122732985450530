import React from 'react'
import styled from 'styled-components/macro'

import { Text, Spacing, Graphic } from '../../components/ui'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const UpperPart = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.color.purpleL3};
`

const LowerPart = styled.div`
  flex: 2;
  background: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const GraphicWrapperContainer = styled.div`
  position: relative;
  top: -50px;
`

const ContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 50px 50px 50px;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  border-radius: 20px;
  width: 400px;
  top: -150px;
`

export const OAuthLoadingScreen = () => {
  return (
    <Container>
      <UpperPart />
      <LowerPart>
        <ContentWrapper>
          <GraphicWrapperContainer>
            <Graphic name="Logo" size={150} />
          </GraphicWrapperContainer>
          <Spacing size="xxl" align="center">
            <Text t0 center>
              Please wait while we redirect you to the requested source...
            </Text>
          </Spacing>
        </ContentWrapper>
      </LowerPart>
    </Container>
  )
}
