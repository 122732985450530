import { gql } from 'apollo-boost'

export default gql`
  mutation SignUp(
    $username: String
    $oauthUserId: String
    $appleJWT: String
    $email: String
    $fullName: String
  ) {
    signUp(
      username: $username
      oauthUserId: $oauthUserId
      appleJWT: $appleJWT
      email: $email
      fullName: $fullName
    ) {
      token
      user {
        id
        member {
          id
          tosAccepted
          totalUbdi
          totalUsd
        }
      }
      errors {
        key
        message
      }
    }
  }
`
