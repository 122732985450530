import { gql } from 'apollo-boost'

const mutation = gql`
  mutation unfollowMember($targetMemberId: String!) {
    unfollowMember(targetMemberId: $targetMemberId) {
      publicMember {
        id
        followingCount
        followersCount
        isFollowedByMe
      }
      query {
        currentUser {
          id
          member {
            id
            followingCount
            followersCount
          }
          publicMember {
            id
            followingCount
            followersCount
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export default mutation
