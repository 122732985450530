import { gql } from 'apollo-boost'
import { UserFragment } from '../fragments'

const mutation = gql`
  mutation UpdateMember(
    $tags: [TagInput!]
    $arePostsPublic: Boolean
    $publicDataSourceId: String
    $tribeSettings: TribeSettingsInput
    $pollfishConsent: Boolean
    $coverPhotoUrl: String
    $profileColor: String
    $profileUsername: String
    $profileDescription: String
    $profileImage: PhotoInput
    $fcmWebToken: String
    $earnPreferences: EarnPreferencesInput
  ) {
    updateMember(
      tags: $tags
      arePostsPublic: $arePostsPublic
      publicDataSourceId: $publicDataSourceId
      tribeSettings: $tribeSettings
      pollfishConsent: $pollfishConsent
      coverPhotoUrl: $coverPhotoUrl
      profileColor: $profileColor
      profileUsername: $profileUsername
      profileDescription: $profileDescription
      profileImage: $profileImage
      fcmWebToken: $fcmWebToken
      earnPreferences: $earnPreferences
    ) {
      currentUser {
        id
        ...User
      }
      errors {
        key
        message
      }
    }
  }
  ${UserFragment}
`

export default mutation
