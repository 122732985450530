import React from 'react'
import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'

import { Text } from '../'

const getBackgroundColor = ({ theme, backgroundColor, selected }) => {
  if (backgroundColor) return backgroundColor
  if (selected) return theme.color.main
  return theme.color.purpleL3
}

const ContentWrapper = styled.div`
  ${props => {
    const backgroundColor = getBackgroundColor(props)
    return css`
      flex-direction: row;
      background: ${backgroundColor};
      padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.s}`};

      border-radius: 4px;

      ${props.maxWidth && `max-width: ${props.maxWidth}px`}
    `
  }}
`

export const Tag = props => {
  const { children, selected, textColor } = props

  const tagTextColor = textColor ? textColor : selected ? 'white' : 'main'

  return (
    <ContentWrapper {...props}>
      <Text color={tagTextColor} h7 bold>
        {children}
      </Text>
    </ContentWrapper>
  )
}

Tag.propTypes = {
  selected: PropTypes.bool,
  small: PropTypes.bool,
  backgroundColor: PropTypes.string, // color hash
  textColor: PropTypes.string, // color hash
  maxWidth: PropTypes.number
}
