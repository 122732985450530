import { gql } from 'apollo-boost'
import { CommunityPollCommentFragment } from '../fragments'

const mutation = gql`
  mutation CommentCommunityPoll(
    $id: String!
    $text: String
    $commentId: String
    $tags: [String!]
    $gifUrl: String
    $postData: IPostDataInput
  ) {
    commentCommunityPoll(
      id: $id
      text: $text
      commentId: $commentId
      tags: $tags
      gifUrl: $gifUrl
      postData: $postData
    ) {
      success
      errors {
        key
        message
      }
      communityPoll {
        id
        comments {
          id
          ...CommunityPollComment
          comments {
            id
            ...CommunityPollComment
          }
        }
      }
    }
  }
  ${CommunityPollCommentFragment}
`

export default mutation
