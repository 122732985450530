import React from 'react'
import styled from 'styled-components/macro'
import { Graphic, Input, GifInput } from '../../../../../../../components/ui'

const DefaultWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  border-radius: 10px;
  background: ${({ theme }) => theme.color.white};
`
const StyledInput = styled(Input)`
  ${({ theme }) => theme.text.h6};
  background: ${({ theme }) => theme.color.white};
  border: 2px solid ${({ theme }) => theme.color.transparent};
  margin: 6px 0px;

  width: 100%;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
  }
`

const GraphicWrap = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 2px solid ${({ theme }) => theme.color.dark1};
  margin: 0px 12px;
`
const PollVoteOption = props => {
  const {
    input,
    meta: { dirty },
    withGiphy = false,
    onFieldRemove
  } = props

  const onClickRemoveInput = () =>
    onFieldRemove ? onFieldRemove() : input.onChange('')

  const onClickRemoveGifInput = () =>
    onFieldRemove ? onFieldRemove() : input.onChange({})

  return withGiphy ? (
    <DefaultWrapper>
      <GifInput onRemove={onClickRemoveGifInput} {...props} />
    </DefaultWrapper>
  ) : (
    <DefaultWrapper>
      <div style={{ width: dirty ? '85%' : '100%' }}>
        <StyledInput {...props} meta={{}} />
      </div>
      {dirty && (
        <GraphicWrap onClick={onClickRemoveInput}>
          <Graphic name="X" size={12} themeColor="dark1" />
        </GraphicWrap>
      )}
    </DefaultWrapper>
  )
}

export default PollVoteOption
