import { endOfMonth, parse, startOfMonth, startOfYesterday } from 'date-fns'
import { intersection, keys, map, pipe } from 'ramda'

export const hasDateKey = data => {
  const dateKeys = ['createdate', 'date']

  return !!pipe(keys, intersection(dateKeys))(data).length > 0
}

export const getDataBits = ({ fields, objectType }) => {
  return Object.keys(fields)
    .map(fieldName => ({
      value: fields[fieldName],
      fieldMapping: objectType.fieldMappings.find(
        ({ name }) => name === fieldName
      )
    }))
    .filter(
      ({ fieldMapping }) =>
        !!fieldMapping && objectType.featuredFields.includes(fieldMapping.name)
    )
}
const generateCaseForCategories = categories =>
  `CASE ${map(category =>
    category.subcategories.length > 0
      ? `WHEN category LIKE ${category.subcategories
          .map(category => `'%${category}%'`)
          .join(' or category LIKE ')} THEN '${category.label}'`
      : `ELSE 'Other'`
  )(categories).join('\n')} END`

const generateFilterForTransactions = ({ filters }) => {
  const filteredMonth = filters.selectedDate
    ? parse(filters.selectedDate, 'MMMM, yyyy', new Date())
    : new Date()

  return `basetype='${filters.basetype || 'DEBIT'}' AND
    currency='${filters.selectedCurrency}' AND
    createddate BETWEEN ${startOfMonth(
      filteredMonth
    ).getTime()} AND ${endOfMonth(filteredMonth).getTime()}`
}

export const generateDailyAverageQuery = fieldName =>
  `select round(avg(${fieldName})) as avgdaily${fieldName}
    from ?
    where
      createddate > ${startOfYesterday().getTime()}`

export const getMostLikedTweet = () =>
  `SELECT likecount, text
    FROM ?
    ORDER BY likecount DESC
    LIMIT 1`

export const getMostSharedTweet = () =>
  `SELECT resharecount, text
    FROM ?
    WHERE text NOT LIKE '%RT @%'
    ORDER BY resharecount DESC
    LIMIT 1`

export const getHashtags = () =>
  `SELECT hashtags, text
    FROM ?
    WHERE (text NOT LIKE '%RT @%' or retweeted = 0) and LEN(hashtags) > 0`

export const getFollowers = () =>
  `SELECT followerscount
    FROM ?
    LIMIT 1`
export const getAvailableMonths = () =>
  `SELECT 
    Substring(CONVERT(STRING,[date],106),4,10) AS period 
    FROM ?
    GROUP BY  Substring(CONVERT(STRING,[date],106),4,10) 
    ORDER BY Substring(CONVERT(STRING,date,112),1,6)`

export const getAvailableCurrencies = () =>
  `SELECT count(*) as cnt, currency
    FROM ?
    GROUP BY currency 
    ORDER BY cnt desc`

export const getAllTransactions = ({ filters }) =>
  `SELECT * 
    FROM ? 
    WHERE ${generateFilterForTransactions({ filters })}
    `

export const getTransactionsByCategory = ({ categories, filters }) => `SELECT
    SUM(amount) as totalAmount, ${generateCaseForCategories(
      categories
    )} as masterCategory, currency
    FROM ?
    WHERE 1=1 and ${generateFilterForTransactions({ filters })}
    GROUP BY ${generateCaseForCategories(categories)}, currency`
