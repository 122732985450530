import React from 'react'
import { withRouter } from 'react-router-dom'
import { filter, identity, map, pathOr, pipe, prop, times } from 'ramda'

import { RenderFromQuery, Spacing, Text } from '../../../../../components/ui'
import { AllUserGroupsWithMemberQuery } from '../../../../../state/queries/UserGroupsQueries'
import { TribeRowCard } from '../components'
import { checkIsTribeMember } from '../util'
import { TribeSocialMedia } from './TribeSocialMedia'
import { UBDI_TRIBE_ID } from '../../../../TribeDetails/_utils'

const TribesListInner = ({
  history,
  memberGroups,
  showCounter,
  title,
  isRowClickable,
  outline,
  settings,
  showSocialMedia
}) => (
  <RenderFromQuery
    query={AllUserGroupsWithMemberQuery}
    renderLoading={() => renderLoading({ title })}
    renderData={data => {
      const allTribes = pipe(
        pathOr([], ['allUserGroups', 'edges']),
        map(prop('node'))
      )(data)

      const member = pathOr({}, ['currentUser', 'member'])(data)

      const tribesToRender = filter(({ id }) =>
        memberGroups
          ? checkIsTribeMember({ tribeId: id, member })
          : !checkIsTribeMember({ tribeId: id, member })
      )(allTribes)

      const onRowClick = ({ tribe }) => {
        if (isRowClickable) {
          const isTribeMember = checkIsTribeMember({
            tribeId: tribe.id,
            member
          })

          return history.push(`/tribes/${tribe.id}`, { isTribeMember })
        }
      }

      const ubdiTribe = allTribes.find(({ id }) => id === UBDI_TRIBE_ID)

      return (
        <Spacing size="xl" stretched>
          {title && <Text h6>{title}</Text>}
          <Spacing size="l" stretched>
            {tribesToRender.map((tribe, i) => (
              <TribeRowCard
                key={tribe.id + i}
                tribe={tribe}
                settings={settings}
                isRowClickable={isRowClickable}
                onRowClick={() => onRowClick({ tribe })}
                outline={outline}
                total={tribe.newPostsCount}
                showCounter={showCounter}
              />
            ))}
          </Spacing>

          {showSocialMedia && <TribeSocialMedia tribe={ubdiTribe} />}
        </Spacing>
      )
    }}
  />
)

const renderLoading = ({ title }) => (
  <Spacing size="xl" stretched>
    {title && <Text h6>{title}</Text>}
    <Spacing size="l" stretched>
      {times(identity, 5).map(i => (
        <TribeRowCard.loading key={`loading-tribe-${i}`} />
      ))}
    </Spacing>
  </Spacing>
)

export const TribesList = withRouter(TribesListInner)
