import React from 'react'
import Files from 'react-files'
import './dropzone.css'

export const Dropzone = ({
  children,
  onFilesChange,
  onFilesError,
  disabled,
  accepts
}) => {
  return (
    <Files
      className="files-dropzone"
      onChange={onFilesChange}
      onError={onFilesError}
      accepts={accepts}
      clickable={!disabled}
      multiple={false}
    >
      {children}
    </Files>
  )
}
