import React, { useState } from 'react'
import { pathOr, map, reduce, isEmpty, includes } from 'ramda'
import { useQuery } from '@apollo/react-hooks'
import styled, { css } from 'styled-components/macro'

import {
  AudienceDataVerifiedTagsWithDataSchemaQuery,
  CompetitionDataLeaderboardQuery,
  UserGroupWithMemberQuery
} from '../../../state/queries'
import { Box, Button, Spacing, Text } from '../../../components/ui'
import { TribeLeaderboardCard } from '../../TribeDetails/components/TribeLeaderboardCard'
import { withMutation } from '../../../components/wrappers'
import { TribeLeaderboard } from '../../TribeDetails/components'
import { getMissingSourcesAndTagsForTribe } from '../../TribeDetails/util'

const BOARD_LENGTH = 5

export const CompetitionDataLeaderboard = ({ post, customOptInBox }) => {
  const { competition } = post
  const { id, tribeId, dataStreamId } = competition

  const {
    data: leaderboardCompetitionData,
    loading
  } = useQuery(CompetitionDataLeaderboardQuery, { variables: { id } })
  const { data } = useQuery(UserGroupWithMemberQuery, {
    variables: { id: tribeId }
  })
  const { data: audienceData } = useQuery(
    AudienceDataVerifiedTagsWithDataSchemaQuery
  )

  const optedInDataStreams = pathOr(
    [],
    ['currentUser', 'member', 'optedInDataStreams'],
    data
  )

  const tags = pathOr([], ['currentUser', 'member', 'tags'], data)

  const linkedDataSources = pathOr(
    [],
    ['currentUser', 'member', 'linkedDataSources'],
    data
  )

  const audienceDataVerifiedTags = pathOr(
    [],
    ['audienceDataVerifiedTags'],
    audienceData
  )

  const tribe = pathOr({}, ['userGroup'], data)

  const { missingSources } = getMissingSourcesAndTagsForTribe({
    tags,
    audienceDataVerifiedTags,
    tribe,
    linkedDataSources
  })

  // if requirement for joining Tribe is satisfied then it is for opting in to competition too
  const optInDisabled = !isEmpty(missingSources)

  const alreadyOptedIn = includes(dataStreamId, optedInDataStreams)

  if (loading) return <TribeLeaderboard.loading />

  const competitionData = pathOr(
    {},
    ['competitionDataLeaderboard'],
    leaderboardCompetitionData
  )

  const { results = [], positionInLeaderboard } = competitionData

  const competitionLeaderboard = map(
    reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {})
  )(results)

  const showBorder = ({ index }) => {
    if (positionInLeaderboard > BOARD_LENGTH) {
      return true
    }
    return index !== competitionLeaderboard.slice(0, BOARD_LENGTH).length - 1
  }

  if (isEmpty(competitionLeaderboard)) {
    return (
      <Spacing>
        {renderOptIn({
          alreadyOptedIn,
          customOptInBox,
          dataStreamId,
          optInDisabled
        })}
        {renderEmpty()}
      </Spacing>
    )
  }

  return (
    <div style={{ marginTop: -20 }}>
      {renderOptIn({
        alreadyOptedIn,
        customOptInBox,
        dataStreamId,
        optInDisabled
      })}
      {competitionLeaderboard.slice(0, BOARD_LENGTH).map((data, index) => (
        <RowWrap
          key={data.id}
          backgroundColor={positionInLeaderboard === index && 'purpleL3'}
          showBorder={showBorder({ index })}
        >
          <TribeLeaderboardCard tribeId={tribeId} data={data} index={index} />
        </RowWrap>
      ))}
      {positionInLeaderboard > BOARD_LENGTH - 1 && (
        <RowWrap key="leadboard-own-entry" backgroundColor="purpleL3">
          <TribeLeaderboardCard
            tribeId={tribeId}
            data={competitionLeaderboard[positionInLeaderboard]}
            index={positionInLeaderboard}
          />
        </RowWrap>
      )}
    </div>
  )
}

const OptInBox = withMutation('toggleDataStreamOptIn')(
  ({ toggleDataStreamOptIn, dataStreamId, optInDisabled }) => {
    const [toggling, setToggling] = useState(false)

    const onToggleOptIn = () => {
      setToggling(true)
      toggleDataStreamOptIn({
        variables: { id: dataStreamId, optIn: true }
      })
        .then(() => setToggling(false))
        .catch(() => setToggling(false))
    }

    return (
      <OptInBoxWrapper disabled={optInDisabled}>
        <Box>
          <Spacing size="xl" align="center">
            <Text t1 center>
              Opting into a Competition is unavailable at this time.
            </Text>
            <Button onClick={onToggleOptIn} disabled>
              {toggling ? 'Opting in...' : 'Opt-in to Competition'}
            </Button>
          </Spacing>
        </Box>
      </OptInBoxWrapper>
    )
  }
)

const renderEmpty = () => (
  <EmptyWrap>
    <TextWrap>
      <Text color="dark1" h6>
        There are no any competition entries yet.
      </Text>
    </TextWrap>
  </EmptyWrap>
)

const renderOptIn = ({
  alreadyOptedIn,
  customOptInBox,
  dataStreamId,
  optInDisabled
}) => {
  if (alreadyOptedIn) return null

  if (customOptInBox) return customOptInBox({ disabled: optInDisabled })
  return <OptInBox dataStreamId={dataStreamId} disabled={optInDisabled} />
}

const EmptyWrap = styled.div`
  display: flex;
  flex: 1;
  height: 170px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.purpleL3};
  border-radius: 10px;
  padding: ${({ theme }) => `0px ${theme.padding.xl}`};
`

const TextWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const OptInBoxWrapper = styled.div`
  padding: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`

const RowWrap = styled.div`
  padding: ${({ theme }) =>
    `${theme.padding.l} 0px ${theme.padding.l} ${theme.padding.xs}`};

  background: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.color[backgroundColor] : theme.color.white};

  ${({ theme, showBorder }) =>
    showBorder &&
    css`
      padding-bottom: ${({ theme }) => theme.padding.l};
      border-bottom: 1px solid ${theme.color.dark2};
    `}

  ${({ theme, showBorder }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${theme.color.dark2};
    `};
`
