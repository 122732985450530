import { gql } from 'apollo-boost'

export const CheckSessionExchangeQuery = gql`
  query CheckSessionExchange($sessionId: String!) {
    checkSession(sessionId: $sessionId) {
      session {
        sessionId
        memberJWT
        dataSourceId
      }
    }
  }
`
