import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { pipe, concat, times } from 'ramda'
import { Form, Field } from 'react-final-form'

import {
  Text,
  Graphic,
  Button,
  Spacing,
  Input,
  CountryPicker,
  ProgressBar,
  CodeVerify,
  OnboardingContextBox
} from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { Countries } from '../../constants'
import { LogoBackgroundScreen } from '../components/LogoBackgroundScreen'
import {
  validatorUsernameOrPhoneNumber,
  validatorPassword
} from '../../util/validators'
import { calculatePasswordStrength } from '../../util/passwordStrength'

const ContentHeader = styled.div`
  padding-bottom: 15px;
  margin-top: -20px;
  padding: 0 40px 15px 40px;
`

const BackWrapper = styled.div`
  cursor: pointer;
`

const HorizontalLine = styled.div`
  border: 1px solid #dbd8ff;
`

const ContentWrapper = styled.div`
  padding: 25px 40px 0 40px;
`

const StepContentWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.l} 0px`};
`

const CodeWrap = styled.div`
  width: 80%;
  margin: auto;
`

const ForgetPasswordInner = ({
  requestPasswordChange,
  verifyResetCode,
  setNewPassword,
  history: { push, goBack }
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [currentData, setCurrentData] = useState({})

  const onStepDone = (newData = {}) => {
    setCurrentStep(currentStep + 1)
    setCurrentData({ ...currentData, ...newData })
  }

  const renderAppropriateStep = () => {
    if (currentStep === 1)
      return (
        <EnterUsernameOrPhoneNumber
          requestPasswordChange={requestPasswordChange}
          onStepDone={onStepDone}
        />
      )
    else if (currentStep === 2)
      return (
        <EnterSmsCode
          verifyResetCode={verifyResetCode}
          onStepDone={onStepDone}
          currentData={currentData}
        />
      )

    return (
      <EnterNewPassword
        setNewPassword={setNewPassword}
        goBack={goBack}
        currentData={currentData}
      />
    )
  }

  return (
    <LogoBackgroundScreen>
      <OnboardingContextBox>
        <ContentHeader>
          <Spacing
            direction="row"
            size="xs"
            align="center"
            justify="space-between"
          >
            <Text h4>Forgot Password?</Text>
            <BackWrapper onClick={() => push('/signin')}>
              <Graphic name="X" width={20} height={13} themeColor="main" />
            </BackWrapper>
          </Spacing>
        </ContentHeader>
        <HorizontalLine />

        <ContentWrapper>{renderAppropriateStep()}</ContentWrapper>
      </OnboardingContextBox>
    </LogoBackgroundScreen>
  )
}

const EnterUsernameOrPhoneNumber = ({ requestPasswordChange, onStepDone }) => {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  const onSubmitPhoneNumber = values => {
    const usernameOrPhoneNumber = getUsernamePhoneValue(values, showPhoneNumber)

    return requestPasswordChange({
      variables: { usernameOrPhoneNumber }
    }).then(({ userErrors, data }) => {
      !userErrors &&
        data &&
        data.requestPasswordChange.success &&
        onStepDone({ usernameOrPhoneNumber })
      return userErrors
    })
  }

  return (
    <Spacing size="xl">
      <Text t1>
        Please enter your {!showPhoneNumber ? 'username' : 'phone number '} to
        search for your account.
      </Text>

      <Form
        initialValues={{ country: 'US' }}
        onSubmit={onSubmitPhoneNumber}
        validate={validatorUsernameOrPhoneNumber}
        render={({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Spacing size="xl">
              {showPhoneNumber ? (
                <PhoneNumberFields handleSubmit={handleSubmit} />
              ) : (
                <InputField label="Username" handleSubmit={handleSubmit} />
              )}

              <Spacing size="xs">
                <Button disabled={submitting} onClick={handleSubmit} block>
                  <Text t1 color="white" center>
                    Submit
                  </Text>
                </Button>
                {!showPhoneNumber && (
                  <Spacing size="xs">
                    <Text t1 center>
                      or
                    </Text>
                    <Button
                      onClick={() => {
                        form.reset()
                        setShowPhoneNumber(!showPhoneNumber)
                      }}
                      outline
                      block
                    >
                      <Text t1 color="main" center>
                        Use my phone number
                      </Text>
                    </Button>
                  </Spacing>
                )}
              </Spacing>
            </Spacing>
          </form>
        )}
      />
    </Spacing>
  )
}

const codeInitialValues = {
  code: times(() => null, 6).reduce(
    (acc, item, index) => ({ ...acc, [index]: item }),
    {}
  )
}

const getCodeArray = code => Object.keys(code).map(item => code[item])

const EnterSmsCode = ({ onStepDone, verifyResetCode, currentData }) => {
  const onSuccess = code => {
    onStepDone({ code })
  }

  const onSubmit = values => {
    const code = getCodeArray(values.code).join('')

    return verifyResetCode({ variables: { code, ...currentData } }).then(
      ({ userErrors, errors, data }) => {
        if (userErrors || errors) {
          return errors
        } else {
          data && data[Object.keys(data)[0]].success && onSuccess(code)
        }
      }
    )
  }

  return (
    <Spacing size="l">
      <Text t1>
        A 6-digit confirmation number has been sent to your phone via SMS.
      </Text>
      <StepContentWrap>
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...codeInitialValues }}
          render={({ handleSubmit }) => (
            <CodeWrap>
              <Field
                name="code"
                component={CodeVerify}
                onSubmit={handleSubmit}
              />
            </CodeWrap>
          )}
        />
      </StepContentWrap>
    </Spacing>
  )
}

const EnterNewPassword = ({ setNewPassword, goBack, currentData }) => {
  const onSubmitNewPassword = values =>
    setNewPassword({
      variables: {
        ...currentData,
        password: values.password
      }
    }).then(({ userErrors, data }) => {
      if (!userErrors && data && data.setNewPassword.success) {
        goBack()
      }
      return userErrors
    })

  return (
    <Spacing size="l">
      <Text t1>Please enter a new password</Text>
      <Form
        onSubmit={onSubmitNewPassword}
        validate={validatorPassword('password')}
        render={({ handleSubmit, submitting, values: { password } }) => {
          const { level, color } = calculatePasswordStrength(password)

          return (
            <form onSubmit={handleSubmit}>
              <Spacing size="xl">
                <Spacing>
                  <Field
                    name="password"
                    component={Input}
                    label="New Password"
                    securedTextEntry
                    block
                  />
                  <ProgressBar
                    current={level}
                    total={4}
                    color={color}
                    showLabel={false}
                  />
                  <Field
                    name="repeatPassword"
                    component={Input}
                    label="Confirm New Password"
                    securedTextEntry
                    block
                  />
                </Spacing>
                <Button disabled={submitting} onClick={handleSubmit} block>
                  Submit
                </Button>
              </Spacing>
            </form>
          )
        }}
      />
    </Spacing>
  )
}

const PhoneNumberFields = ({ handleSubmit }) => (
  <Spacing size="m">
    <Field name="country" component={CountryPicker} placeholder="Country" />
    <InputField label="Phone Number" handleSubmit={handleSubmit} />
  </Spacing>
)

const InputField = ({ label, handleSubmit }) => (
  <Field
    name="usernameOrPhoneNumber"
    textContentType="telephoneNumber"
    component={Input}
    label={label}
    onSubmit={handleSubmit}
    block
  />
)

const getUsernamePhoneValue = (values, showPhoneNumber) => {
  if (showPhoneNumber) {
    const country = Countries.list[values.country]
    if (country) {
      return concat(`+${country.callingCode}`, values.usernameOrPhoneNumber)
    }
    return values.usernameOrPhoneNumber
  }
  return values.usernameOrPhoneNumber
}

export const ForgetPassword = pipe(
  withMutation('requestPasswordChange'),
  withMutation('verifyResetCode'),
  withMutation('setNewPassword')
)(ForgetPasswordInner)
