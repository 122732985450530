import { gql } from 'apollo-boost'

export const GetURLMetaQuery = gql`
  query GetURLMeta($url: String!) {
    getURLMeta(url: $url) {
      url
      shortUrl
      ogTitle
      ogImage
    }
  }
`
