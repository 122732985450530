import React from 'react'
import { FieldArray } from 'react-final-form-arrays'

import { Spacing, Checkbox } from '../../ui'

export const CheckList = ({
  uniqueId,
  options,
  size = 's',
  onChange,
  noShadow = false,
  hideBox = false
}) => (
  <FieldArray
    name={uniqueId}
    render={props => {
      const { fields } = props

      const toggle = option => () => {
        return !isSelected(option)
          ? fields.push(option)
          : fields.remove(fields.value.indexOf(option))
      }

      const isSelected = option => fields.value && fields.value.includes(option)

      return (
        <Spacing size={size}>
          {options.map(({ value, label, ...extra }) => {
            const radioProps = {
              input: {
                onChange: toggle(value),
                value: isSelected(value),
                checked: isSelected(value)
              },
              label,
              extra,
              ...props
            }

            return (
              <Checkbox
                key={value}
                {...radioProps}
                noShadow={noShadow}
                hideBox={hideBox}
              />
            )
          })}
        </Spacing>
      )
    }}
  />
)
