import React from 'react'
import {
  SubmitButton,
  RenderFromQuery,
  CountryPicker
} from '../../../components/ui'
import { Form, Field } from 'react-final-form'
import { pipe } from 'ramda'
import { withMutation } from '../../../components/wrappers'
import { UserMetaQuery } from '../../../state/queries/UserQueries'
import { validatorCountry } from '../../../util/validators'
import { StepWrapper } from './StepWrapper'
import { ButtonWrapper } from './ButtonWrapper'

const StepCountryInner = ({ updateUser, onNextStep }) => {
  const onSubmit = (values, form) => {
    const dirty = form.getState().dirty

    if (!dirty) {
      return onNextStep()
    }

    return updateUser({ variables: values }).then(
      async ({ errors, userErrors, data }) => {
        if (!userErrors && data && data.updateUser) {
          onNextStep()
        }
        return userErrors || errors
      }
    )
  }

  return (
    <RenderFromQuery
      query={UserMetaQuery}
      renderData={({ currentUser: { country } }) => (
        <StepWrapper>
          <Form
            onSubmit={onSubmit}
            validate={validatorCountry}
            initialValues={{ country }}
          >
            {({ handleSubmit, ...formProps }) => (
              <>
                <Field component={CountryPicker} name="country" />
                <ButtonWrapper>
                  <SubmitButton onClick={handleSubmit} block {...formProps}>
                    Next
                  </SubmitButton>
                </ButtonWrapper>
              </>
            )}
          </Form>
        </StepWrapper>
      )}
    />
  )
}

export const StepCountry = pipe(withMutation('updateUser'))(StepCountryInner)
