import gql from 'graphql-tag'

const mutation = gql`
  mutation updateOpportunityResponse(
    $opportunityId: String!
    $shortId: String!
    $surveyCompleted: Boolean
  ) {
    updateOpportunityResponse(
      opportunityId: $opportunityId
      shortId: $shortId
      surveyCompleted: $surveyCompleted
    ) {
      id
      surveyCompleted
      shortId
      pendingTransactionId
      opportunity {
        id
        opportunityResponse {
          shortId
          responseToken
          surveyCompleted
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export default mutation
