import React from 'react'
import { Form, Field } from 'react-final-form'
import { pipe, pathOr, path } from 'ramda'
import { useQuery } from 'react-apollo'
import {
  UserGroupQuery,
  UserMetaQuery,
  UserQuery
} from '../../../state/queries'
import {
  Button,
  GifInput,
  Graphic,
  Notice,
  Spacing,
  Text
} from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { getSpecificTagCategoriesValues } from '../../../state/selectors'
import styled from 'styled-components'
import { getPostData } from '../../../util'

const CHAR_LIMIT = 500
const ALWAYS_VISIBLE_TAGS = []

const getRemainingCharacters = values => {
  const text = path(['comment', 'text'], values)
  if (!text) return CHAR_LIMIT
  return CHAR_LIMIT - text.length
}

const commentValidator = values => {
  const comment = pathOr({}, ['comment'], values)
  const { text, gifUrl, postData } = comment

  if (!text && !gifUrl && !postData)
    return { text: 'Either text, GIF or Data is mandatory' }
  if (text && text.length > CHAR_LIMIT)
    return { text: `Comment can't be longer than ${CHAR_LIMIT} characters` }
  return {}
}

const CommentForm = ({
  commentCommunityPoll,
  id,
  commentId,
  onReplyDimiss,
  tribeId
}) => {
  const { error: isUnauthorizedUser, loading } = useQuery(UserMetaQuery)
  const { data: currentUserData } = useQuery(UserQuery, {
    skip: loading || isUnauthorizedUser
  })
  const { data: groupData } = useQuery(UserGroupQuery, {
    variables: { id: tribeId }
  })

  const filterCategories = pathOr(
    [],
    ['userGroup', 'filterCategories'],
    groupData
  )
  const userTags = pathOr(
    [],
    ['currentUser', 'member', 'tags'],
    currentUserData
  )

  const commentSpecificTags = getSpecificTagCategoriesValues(
    userTags,
    ALWAYS_VISIBLE_TAGS.concat(filterCategories)
  )

  const onSubmit = ({ comment: { text, gifUrl, ...rest } }, form) => {
    const postData = getPostData(rest)

    const variables = {
      id,
      text,
      tags: commentSpecificTags,
      gifUrl,
      postData,
      ...(commentId ? { commentId } : {})
    }

    return commentCommunityPoll({
      variables
    }).then(({ userErrors, data }) => {
      if (userErrors) {
        return userErrors
      }

      if (
        data &&
        data.commentCommunityPoll &&
        data.commentCommunityPoll.success
      ) {
        onReplyDimiss()
        setTimeout(() => form.reset(), 500)
      }
    })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={commentValidator}
      render={({ handleSubmit, submitting, form, invalid, values }) => {
        const remainingCharacters = getRemainingCharacters(values)

        return isUnauthorizedUser ? (
          <Notice>Sign up or Log in to leave a comment</Notice>
        ) : (
          <Spacing size="s" stretched>
            {commentId && (
              <PaddedWrapper>
                <Spacing direction="row" size="s" align="center">
                  <XWrapper onClick={onReplyDimiss}>
                    <Graphic
                      name="X"
                      width={10}
                      height={13}
                      themeColor="main"
                    />
                  </XWrapper>
                  <Text t3 color="main">
                    Reply to a comment
                  </Text>
                </Spacing>
              </PaddedWrapper>
            )}
            <PaddedWrapper>
              <Text
                t3
                color={remainingCharacters < 0 ? 'redNegative' : 'brandBlack'}
              >
                {remainingCharacters} characters left
              </Text>
            </PaddedWrapper>
            <LowerWrapper>
              <InputWrapper>
                <Field
                  name="comment"
                  component={StyledGif}
                  textarea
                  label="Add a comment"
                  autoFocus={!!commentId}
                  disabled={submitting}
                  withPostData
                  block
                  onRemove={() => form.reset()}
                  tribeId={tribeId}
                />
              </InputWrapper>
              <PaddedWrapper>
                <Button onClick={handleSubmit} disabled={submitting || invalid}>
                  <Text h6 color="white">
                    Post
                  </Text>
                </Button>
              </PaddedWrapper>
            </LowerWrapper>
          </Spacing>
        )
      }}
    />
  )
}

const StyledGif = styled(GifInput)`
  ${({ theme }) => theme.text.t2}
  background: ${({ theme }) => theme.color.purpleL3};
  margin: 0px;

  &:hover {
    background-color: ${({ theme }) => theme.color.purpleL3};
  }

  ::placeholder {
    ${({ theme }) => theme.text.h6}
    color: ${({ theme }) => theme.color.dark};
  }
`

const LowerWrapper = styled.div`
  flex-direction: row;
  display: flex;
`

const PaddedWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.padding.s};
`

const InputWrapper = styled.div`
  flex: 1;
`

const XWrapper = styled.div`
  cursor: pointer;
`

export default pipe(withMutation('commentCommunityPoll'))(CommentForm)
