import React from 'react'
import styled from 'styled-components'
import { Text, Spacing } from '../../../components/ui'

const CardWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.xl} ${theme.padding.l}`};
  margin: ${({ theme }) => theme.padding.s};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  width: 31%;
  @media only screen and (max-width: 1025px) {
    width: 48%;
  }
  @media only screen and (max-width: 769px) {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  background: ${({ theme }) => theme.color.main};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  &:hover {
    opacity: 0.8;
  }
`
const Image = styled.img`
  display: block;
  width: 62px;
  height: 62px;
  border-radius: 50%;
`

export const TribeCard = ({ tribe, onTribeSettings }) => {
  return (
    <CardWrap>
      <Spacing justify="center" align="center">
        <Image src={tribe.logoUrl} />
        <Text h2 center>
          {tribe.name}
        </Text>
        <ButtonWrapper onClick={() => onTribeSettings(tribe)}>
          <Text h4 center color="white">
            Settings
          </Text>
        </ButtonWrapper>
      </Spacing>
    </CardWrap>
  )
}
