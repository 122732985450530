import { gql } from 'apollo-boost'
import { MemberFragment } from './Member'

export const UserFragment = gql`
  fragment User on User {
    id
    username
    email
    phoneNumber
    totalUbdi
    hasPassword
    fullName
    country
    verified
    availableRoles
    member {
      id
      ...Member
    }
  }
  ${MemberFragment}
`
