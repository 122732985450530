import React from 'react'
import {
  Text,
  Button,
  Spacing,
  Input,
  SubmitButton
} from '../../../components/ui'
import { Form, Field } from 'react-final-form'
import { validatorUsername } from '../../../util/validators'
import { pipe } from 'ramda'
import { withMutation } from '../../../components/wrappers'
import { User } from '../../../constants'
import { StepWrapper } from './StepWrapper'
import { ButtonWrapper } from './ButtonWrapper'

const StepUsernameInner = ({ signUp, onNextStep, history }) => {
  const onSubmit = (values, form) => {
    const dirty = form.getState().dirty

    if (!dirty) {
      return onNextStep()
    }

    return signUp({ variables: values }).then(
      ({ errors, userErrors, data }) => {
        if (!userErrors && data && data.signUp) {
          localStorage.setItem(User.AUTH_TOKEN, data.signUp.token)
          onNextStep()
        }
        return userErrors || errors
      }
    )
  }

  return (
    <StepWrapper>
      <Form onSubmit={onSubmit} validate={validatorUsername}>
        {({ handleSubmit, submitting, ...formProps }) => (
          <form onSubmit={handleSubmit}>
            <Spacing size="xl">
              <Text t1>
                Your UBDI account is anonymous. We won't even ask for your
                email.
              </Text>
              <Field
                component={Input}
                name="username"
                label="Enter a unique username..."
                autoFocus
                block
              />
              <Spacing align="center">
                <Button
                  transparent
                  link
                  onClick={() => history.push('/signin')}
                >
                  Already have an account?
                </Button>
              </Spacing>
            </Spacing>
            <ButtonWrapper>
              <SubmitButton disabled={submitting} block {...formProps}>
                Next
              </SubmitButton>
            </ButtonWrapper>
          </form>
        )}
      </Form>
    </StepWrapper>
  )
}

export const StepUsername = pipe(withMutation('signUp'))(StepUsernameInner)
