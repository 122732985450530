import React from 'react'
import { filter, reduce, includes, isEmpty } from 'ramda'
import styled from 'styled-components/macro'

import { Spacing, Text } from '../../../../../../components/ui'
import { getSelectedTagColor } from '../../../../../../util'
import { PollResultHorizontal } from './PollResultHorizontal'
import { PollResultVerical } from './PollResultVertical'
import theme from '../../../../../../theme'

const StretchedContainer = styled.div`
  display: flex;
  flex: 1;
`

const PollOptionWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PollResults = ({ poll, ...props }) => (
  <Spacing size="l">
    {poll.options.map(
      (
        { id, choice: { text: label }, voted, totalVotes, votesPerCategory },
        index
      ) => (
        <PollResult
          key={id}
          id={id}
          label={label}
          totalPollVotes={poll.totalVotes}
          totalOptionVotes={totalVotes}
          votes={votesPerCategory}
          voted={voted}
          index={index}
          {...props}
        />
      )
    )}
  </Spacing>
)

const PollResult = ({
  id,
  votes,
  totalOptionVotes,
  totalPollVotes,
  voted,
  label,
  index,
  selectedResultsTags
}) => {
  const renderPollResultsChart = votes => {
    if (votes.length === 1) {
      const voteCount = votes[0].count
      const width = (voteCount / totalPollVotes || 0) * 100
      const backgroundColor = voted ? theme.color.secondary : theme.color.main

      return (
        <PollResultHorizontal
          showLabel={false}
          width={width}
          backgroundColor={backgroundColor}
        />
      )
    }

    if (selectedResultsTags.length < 4) {
      return (
        <PollResultsHorizontal
          id={id}
          votes={votes}
          totalVotes={totalOptionVotes}
          selectedResultsTags={selectedResultsTags}
        />
      )
    }

    return (
      <PollResultsVertical
        id={id}
        votes={votes}
        totalVotes={totalOptionVotes}
        selectedResultsTags={selectedResultsTags}
      />
    )
  }

  const sumVotes = reduce((acc, { count }) => acc + count, 0, votes)

  if (isEmpty(selectedResultsTags) && votes.length > 1) return null

  return (
    <Spacing size="xs">
      <PollOptionWrap>
        <Text h7>
          {index + 1}. {label}
        </Text>
        <Text h7 color="dark1">
          {Math.round((sumVotes / totalPollVotes) * 100)}%
        </Text>
      </PollOptionWrap>
      <Spacing
        size="xs"
        direction={votes.length < 4 ? 'column' : 'row'}
        stretchChildren
      >
        {renderPollResultsChart(votes)}
      </Spacing>
    </Spacing>
  )
}

const PollResultsVertical = ({
  id,
  votes,
  totalVotes,
  selectedResultsTags
}) => {
  const filteredVotes = filter(({ uniqueId }) =>
    includes(uniqueId, selectedResultsTags)
  )(votes)

  return (
    <StretchedContainer>
      <Spacing direction="row" size="xs" stretchChildren stretched>
        {filteredVotes.map(({ count: voteCount, uniqueId }, i) => {
          const height = (voteCount / totalVotes || 0) * 100
          const backgroundColor = getSelectedTagColor(uniqueId, votes)

          return (
            <PollResultVerical
              key={`vertical-${id}-${i}`}
              height={height}
              backgroundColor={backgroundColor}
            />
          )
        })}
      </Spacing>
    </StretchedContainer>
  )
}

const PollResultsHorizontal = ({
  id,
  votes,
  totalVotes,
  selectedResultsTags
}) => {
  const filteredVotes = filter(({ uniqueId }) =>
    includes(uniqueId, selectedResultsTags)
  )(votes)

  return (
    <Spacing size="xs">
      {filteredVotes.map(({ count: voteCount, uniqueId }, i) => {
        const width = (voteCount / totalVotes || 0) * 100
        const shouldLabelBeInside = width > 90

        const backgroundColor = getSelectedTagColor(uniqueId, votes)

        return (
          <PollResultHorizontal
            key={`horizontal-${id}-${i}`}
            shouldLabelBeInside={shouldLabelBeInside}
            backgroundColor={backgroundColor}
            width={width}
          />
        )
      })}
    </Spacing>
  )
}

export default PollResults
