import convert from 'convert-units'
import { formatLocaleNumber } from '../../../../../util'

export const convertUnit = (value, inputUnit) => {
  try {
    const { val, unit } = convert(value).from(inputUnit).toBest()
    return `${formatLocaleNumber({ number: val })} ${unit}`
  } catch (e) {
    return `${value} ${inputUnit}`
  }
}
