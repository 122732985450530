import gql from 'graphql-tag'

import { UserQuery } from '../queries'

const mutation = gql`
  mutation UpdateNotificationSubscriptions(
    $newEarnOpportunity: Boolean
    $newFollowers: Boolean
    $newPosts: Boolean
    $liveCommunityPoll: Boolean
    $commentCommunityPoll: Boolean
    $reactCommunityPoll: Boolean
    $voteCommunityPoll: Boolean
    $replyCommentCommunityPoll: Boolean
  ) {
    updateNotificationSubscriptions(
      newEarnOpportunity: $newEarnOpportunity
      newFollowers: $newFollowers
      newPosts: $newPosts
      liveCommunityPoll: $liveCommunityPoll
      commentCommunityPoll: $commentCommunityPoll
      reactCommunityPoll: $reactCommunityPoll
      voteCommunityPoll: $voteCommunityPoll
      replyCommentCommunityPoll: $replyCommentCommunityPoll
    ) {
      success
      errors {
        key
        message
      }
    }
  }
`

mutation.refetchQueries = [{ query: UserQuery }]

export default mutation
