import React from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components'
import { Button, Spacing } from '../../../components/ui'
import { useModal } from '../../../hooks'
import { OnlyDataSourcesQuery } from '../../../state/queries'
import { DataStreamSection } from './DataStreamSection'
import { ProfileSection } from './ProfileSection'

const Wrapper = styled.div`
  flex: 1;
`
const TextWrap = styled(Text)`
  cursor: pointer;
`

export const DataStreamSections = ({
  isOwnProfile,
  isEditingProfile,
  dataStreamOptions,
  member,
  showEmptyState
}) => {
  const { data, loading } = useQuery(OnlyDataSourcesQuery)
  const { openModal } = useModal()

  const onEditDataStream = () => openModal('DataStreamOptIn')
  if (!dataStreamOptions || loading) return null

  if (dataStreamOptions.length === 0 && showEmptyState) {
    return (
      <Wrapper>
        <ProfileSection
          isOwnProfile={isOwnProfile}
          label="Data stream"
          emptyLabel="Member has not made any Streams public"
          manageAction={
            <TextWrap h6 color="main" onClick={onEditDataStream}>
              Edit
            </TextWrap>
          }
          emptyAction={
            <Button onClick={onEditDataStream}>Pick public streams</Button>
          }
        ></ProfileSection>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <Spacing>
        {dataStreamOptions.map(({ dataSourceId }) => {
          const dataSource = data.allDataSources.edges.find(
            ({ node: { id } }) => id === dataSourceId
          )
          return (
            <DataStreamSection
              key={dataSourceId}
              isOwnProfile={isOwnProfile}
              isEditingProfile={isEditingProfile}
              member={member}
              dataSource={dataSource}
            />
          )
        })}
      </Spacing>
    </Wrapper>
  )
}
