import React, { forwardRef } from 'react'
import { pipe } from 'ramda'
import styled from 'styled-components/macro'
import {
  Box,
  Button,
  ColorizedHeader,
  Spacing,
  SpacingItem,
  Text
} from '../../../components/ui'
import { FollowButton } from './FollowButton'
import { useModal } from '../../../hooks'
import { Avatar } from '../../../components/Avatar'
import { tribeColorSchema } from '../../../components/AvatarColorPicker/constants'

const ProfileHeaderInner = ({ member, isOwnProfile, fref, currentUser }) => {
  const { openModal } = useModal()

  const getProfileColor = () => {
    if (member && member.profileColor) {
      return member.profileColor
    }

    return tribeColorSchema[0][0]
  }

  const profileColor = getProfileColor()
  return (
    <Wrapper>
      <ColorizedHeader background={profileColor} />
      <StyledBox size="xl" ref={fref} coverPhotoUrl={member.coverPhotoUrl}>
        <Spacing size="l">
          <Spacing direction="row">
            <Avatar member={member} size={70} avatarId={member.id} />
            {renderStats({ member, openModal, currentUser })}
          </Spacing>
          <Spacing size="s">
            <Spacing direction="row" justify="space-between" align="center">
              {renderUsername({ member })}
              {isOwnProfile && (
                <Button
                  size="small"
                  outline
                  onClick={() => openModal('PickCoverPhoto')}
                >
                  Pick cover
                </Button>
              )}
            </Spacing>
            {renderDescription({ member })}
          </Spacing>
          {isOwnProfile ? (
            <ButtonWrap
              outline
              rounded
              block
              onClick={() => openModal('EditProfile', { member })}
            >
              Edit profile
            </ButtonWrap>
          ) : (
            <FollowButton member={member} isOwnProfile={isOwnProfile} />
          )}
        </Spacing>
      </StyledBox>
    </Wrapper>
  )
}

export const ProfileHeader = pipe(Component =>
  forwardRef((props, ref) => <Component {...props} fref={ref} />)
)(ProfileHeaderInner)

const renderUsername = ({ member }) => {
  return <Text h3>{member.profileUsername}</Text>
}

const renderDescription = ({ member }) => (
  <Text t2>{member.profileDescription}</Text>
)

const renderStats = ({ member, openModal, currentUser }) => (
  <SpacingItem style={{ width: '70%', margin: 'auto' }}>
    <Spacing direction="row" align="center" justify="space-around">
      <StatItem label="posts">{member.totalPosts}</StatItem>
      <StatItem
        label="followers"
        onStatClick={() => openModal('Followers', { member, currentUser })}
      >
        {member.followersCount}
      </StatItem>
      <StatItem
        label="following"
        onStatClick={() => openModal('Following', { member, currentUser })}
      >
        {member.followingCount}
      </StatItem>
    </Spacing>
  </SpacingItem>
)

const StatItem = ({ label, children, onStatClick }) => (
  <StatItemWrap onClick={onStatClick}>
    <Spacing size="xxs" align="center">
      <Text h5>{children}</Text>
      <Text t2 color="grey">
        {label}
      </Text>
    </Spacing>
  </StatItemWrap>
)

const StatItemWrap = styled.div`
  cursor: pointer;
`

const Wrapper = styled.div`
  position: relative;
`

const StyledBox = styled(Box)`
  position: absolute;
  padding-top: ${({ theme }) => theme.padding.l};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-sizing: border-box;

  box-shadow: 0px 6px 8px rgba(111, 98, 255, 0.06);
  border-radius: 10px;
  width: 40%;
  margin: auto;
  top: 30px;
  left: 30%;
`

const ButtonWrap = styled(Button)`
  padding: ${({ theme }) => `12px ${theme.padding.l}`};
`
