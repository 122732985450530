import { gql } from 'apollo-boost'

export const AudienceSchemaQuery = gql`
  query AudienceSchemaQuery {
    audienceSchema {
      ...AudienceSchemaItem
      children {
        ...AudienceSchemaItem
        children {
          ...AudienceSchemaItem
        }
        subfields {
          ...AudienceSchemaItem
        }
      }
      subfields {
        ...AudienceSchemaItem
      }
    }
  }

  fragment AudienceSchemaItem on AudienceSchemaItem {
    label
    inputLabel
    uniqueId
    editable
    selfReported
    question
    multi
    custom
    queries {
      objectTypeId
      dataSourceIds
      sql
    }
  }
`

export const AudienceDataVerifiedTagsQuery = gql`
  query AudienceDataVerifiedTags {
    audienceDataVerifiedTags {
      uniqueId
      queries {
        objectTypeId
        dataSourceIds
        sql
      }
    }
  }
`

export const AudienceDataVerifiedTagsWithDataSchemaQuery = gql`
  query AudienceDataVerifiedTagsWithDataSchema {
    audienceDataVerifiedTags {
      uniqueId
      queries {
        objectTypeId
        dataSourceIds
        sql
      }
    }

    dataSchema {
      services {
        id
        name
        availableObjectTypes
      }
      objectTypes {
        id
        name
      }
    }
  }
`
