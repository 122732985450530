import React from 'react'
import { Link } from 'react-router-dom'
import { Text } from '../../../../../components/ui'

export const TribeAwareTitleWithLinks = ({ children, tribe, poll }) => {
  const forumRegex = /\$[a-zA-Z]+/gi
  const mentionedForums = children.match(forumRegex)

  if (!tribe.forums || !tribe.forums.length || !mentionedForums) {
    return (
      <Text h3={!!poll} t1={!poll}>
        {children}
      </Text>
    )
  }

  if (mentionedForums && mentionedForums.length > 0) {
    const remainingWords = children.split(forumRegex)

    const merged = remainingWords.reduce((acc, word, i) => {
      const mentionedForum = mentionedForums[i]
      if (mentionedForum) {
        const forum = tribe.forums.find(
          ({ label, value }) =>
            `$${label.toUpperCase()}` === mentionedForum.toUpperCase() ||
            `$${value.toUpperCase()}` === mentionedForum.toUpperCase()
        )

        if (!forum) return [...acc, word, mentionedForum]

        return [
          ...acc,
          word,
          <Link to={`/tribes/${tribe.id}/${forum.value}`}>
            <Text t1 bold inline>
              {mentionedForum}
            </Text>
          </Link>
        ]
      }

      return [...acc, word]
    }, [])

    return <Text t1>{merged}</Text>
  }

  return null
}
