import React from 'react'
import { flatten, pathOr, isEmpty } from 'ramda'
import styled, { css } from 'styled-components/macro'
import { useQuery } from 'react-apollo'
import { filterAndSortDataSources } from '../../../../../state/selectors'
import { DataSourcesQuery } from '../../../../../state/queries'
import {
  Box,
  Button,
  DataSourceGraphic,
  Spacing,
  Text
} from '../../../../../components/ui'

const Wrapper = styled.div`
  ${({ theme }) =>
    css`
      background: ${theme.color.white};
      border: 1px solid ${theme.color.purpleL2};
      box-sizing: border-box;
      border-radius: 10px;
      padding: ${theme.padding.m};
      display: flex;
      box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
    `}
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

const TribeSourcesList = ({ dataSources, onDataSourceLinkedClick }) => {
  const { data, loading } = useQuery(DataSourcesQuery)

  if (dataSources.length === 0) {
    return null
  }
  if (loading) {
    return <DataSourceRowLoading />
  }

  const edges = pathOr([], ['allDataSources', 'edges'], data)
  const linkedDataSources = pathOr(
    [],
    ['currentUser', 'linkedDataSources'],
    data
  )

  const tribeSources = flatten(dataSources).map(dataSourceId =>
    edges.find(({ node }) => node.id === dataSourceId)
  )

  const dataSourcesToRender = filterAndSortDataSources(
    tribeSources,
    linkedDataSources
  ).filter(({ linked }) => linked) // temporary => just linked ones, until we extract logic for linkingDataSource from DataSourceSpace

  if (isEmpty(dataSourcesToRender))
    return (
      <Box noShadow size="l" backgroundColor="purpleL3">
        <Text t1 center>
          Currently there are no available data sources for selected tribe
        </Text>
      </Box>
    )

  return (
    <Spacing size="s">
      {dataSourcesToRender.map(dataSource => (
        <DataSourceRow
          key={dataSource.id}
          {...dataSource}
          onDataSourceLinkedClick={() => onDataSourceLinkedClick(dataSource)}
        />
      ))}
    </Spacing>
  )
}

const StyledButton = styled(Button)`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  border-radius: 35px;
`

const DataSourceRow = ({ name, id, linked, onDataSourceLinkedClick }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Spacing direction="row" align="center">
          <DataSourceGraphic id={id} size={32} />
          <Text h4 white>
            {name}
          </Text>
        </Spacing>
        <StyledButton onClick={onDataSourceLinkedClick} outline={linked}>
          {linked ? 'View' : 'Link'}
        </StyledButton>
      </ContentWrapper>
    </Wrapper>
  )
}

const GraphicPlaceholder = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background: ${({ theme }) => theme.color.greyL2};
`

const TextPlaceHolder = styled.div`
  width: 300px;
  height: 20px;
  background: ${({ theme }) => theme.color.greyL2};
  border-radius: 50px;
`

const ButtonPlaceholder = styled.div`
  width: 80px;
  height: 34px;
  background: ${({ theme }) => theme.color.greyL2};
  border-radius: 35px;
`

const DataSourceRowLoading = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Spacing direction="row" align="center">
          <GraphicPlaceholder />
          <TextPlaceHolder />
        </Spacing>
        <ButtonPlaceholder />
      </ContentWrapper>
    </Wrapper>
  )
}

export default TribeSourcesList
