import React from 'react'
import styled from 'styled-components'
import { Graphic, Spacing, Text } from '../../../components/ui'

export const StepTemplate = ({
  title,
  description,
  graphic,
  width,
  height = 250,
  isPng = true,
  paddingTop = 0
}) => {
  return (
    <Spacing justify="center" align="center" size="xl">
      <GraphicWrap paddingTop={paddingTop}>
        <Graphic name={graphic} width={width} height={height} png={isPng} />
      </GraphicWrap>
      <Spacing justify="center" align="center" size="l">
        <Text h1 center>
          {title}
        </Text>
        <Text t1 center>
          {description}
        </Text>
      </Spacing>
    </Spacing>
  )
}

const GraphicWrap = styled.div`
  padding-top: ${({ paddingTop }) => `${paddingTop}px`};
`
