import { find, pathOr, pipe, prop } from 'ramda'
import { getAllAccountsFromLocalStorage } from './'
import NDLProvider from '../providers/ndlProvider'

export const syncAccounts = ({ dataSourceId, dataSchema }) => {
  const accounts = getAllAccountsFromLocalStorage(dataSourceId)

  const sources = accounts.map(account => {
    const objectTypeIds = getAvailableObjectTypesForSource(
      account.dataSourceId,
      dataSchema
    )

    return {
      ...account,
      objectTypeIds
    }
  })

  return sources.map(({ accountId, objectTypeIds }) =>
    NDLProvider.triggerAccountSync({ accountId, objectTypeIds })
  )
}

export const getAvailableObjectTypesForSource = (dataSourceId, dataSchema) =>
  pipe(
    pathOr([], ['services']),
    find(dataSource => dataSourceId === dataSource.id),
    prop('availableObjectTypes')
  )(dataSchema)
