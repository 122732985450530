import React from 'react'
import { useQuery } from 'react-apollo'
import { map, pathOr, reduce } from 'ramda'
import styled from 'styled-components'
import { TraitsEntry } from '../../../components/TraitsEntry'
import {
  Button,
  Graphic,
  RenderFromQuery,
  Spacing,
  Text
} from '../../../components/ui'
import { DataSourcesQuery, UserGroupQuery } from '../../../state/queries'
import { AudienceDataVerifiedTagsWithDataSchemaQuery } from '../../../state/queries/AudienceSchemaQueries'
import { canJoinTribe, getMissingSourcesAndTagsForTribe } from '../util'
import { TribeSourcesList } from './TribeSourcesList'
import { withMutation } from '../../../components/wrappers'

const renderDescription = ({ joinDisabled, joinPossible }) => {
  if (joinDisabled) {
    return 'This tribe is locked and in order to join you will need to link certain sources or enter traits'
  }
  if (!joinPossible) {
    return 'Sorry! Seems like you are not a match for this tribe, but don’t worry, there is a ton of different tribes out there!'
  }
  return 'Congrats, you meet all the requirements for joining this tribe! Tap the Join button below to get in.'
}

const JoinTribeWrapper = styled.div`
  padding: ${({ theme }) => `${theme.padding.xxl} 95px`};
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: 10px;
`

const TextWrapper = styled.div`
  padding: ${({ theme }) => `0 ${theme.padding.xxl}`};
`

const GraphicWrapper = styled.div`
  text-align: center;
`

const getReducedTagsList = reduce(
  (acc, value) => ({
    ...acc,
    [value.uniqueId]: value.values
  }),
  []
)

const addNewGroup = (tags, groupTag) => {
  if (tags.find(({ uniqueId }) => uniqueId === 'system_groups')) {
    return map(tag => {
      if (tag.uniqueId !== 'system_groups') {
        return tag
      }
      return {
        ...tag,
        values: tag.values.concat(groupTag)
      }
    })(tags)
  }
  return tags.concat([{ uniqueId: 'system_groups', values: [groupTag] }])
}

const JoinTribeInner = ({ tribeId, updateMember }) => {
  const { data: allDataSources, loading: loadingDataSources } = useQuery(
    DataSourcesQuery
  )

  const { data: userGroupData, loading: loadingUserGroup } = useQuery(
    UserGroupQuery,
    {
      variables: { id: tribeId }
    }
  )

  if (loadingDataSources || loadingUserGroup) {
    return null
  }

  const { userGroup: tribe } = userGroupData

  const linkedDataSources = pathOr(
    [],
    ['currentUser', 'linkedDataSources'],
    allDataSources
  )

  const tags = pathOr([], ['currentUser', 'member', 'tags'], allDataSources)

  const newTags = addNewGroup(tags, tribe.id)

  const onJoinTribe = () => {
    updateMember({
      variables: {
        tags: newTags
      }
    })
  }

  return (
    <RenderFromQuery
      query={AudienceDataVerifiedTagsWithDataSchemaQuery}
      renderData={data => {
        const { audienceDataVerifiedTags } = data

        if (!audienceDataVerifiedTags) return null

        const {
          missingSources,
          missingTags,
          allTribeSources
        } = getMissingSourcesAndTagsForTribe({
          tags,
          audienceDataVerifiedTags,
          tribe,
          linkedDataSources
        })

        const joinDisabled = !(
          missingSources.length === 0 && missingTags.length === 0
        )

        const joinPossible = canJoinTribe({
          tags: getReducedTagsList(tags),
          requiredTagValues: tribe.requiredTagValues
        })

        return (
          <JoinTribeWrapper>
            <Spacing size="xl">
              <GraphicWrapper>
                <Graphic name="Lock" size={60} color="black" />
              </GraphicWrapper>
              <TextWrapper>
                <Text t1 center>
                  {renderDescription({ joinDisabled, joinPossible })}
                </Text>
              </TextWrapper>
              <Spacing>
                {tribe.requiredTags && tribe.requiredTags.length > 0 && (
                  <Spacing>
                    <Text h6>You need to enter these traits</Text>
                    <TraitsEntry
                      disableCategories
                      showOnlyUniqueIds={tribe.requiredTags}
                    />
                  </Spacing>
                )}
                {allTribeSources.length > 0 && (
                  <Spacing>
                    <Text h6>You need to link one of these data sources</Text>
                    <TribeSourcesList
                      dataSources={allTribeSources}
                      tribe={tribe}
                      audienceDataVerifiedTags={audienceDataVerifiedTags}
                      data={allDataSources}
                      missingSources={missingSources}
                      linkedDataSources={linkedDataSources}
                    />
                  </Spacing>
                )}
              </Spacing>
              <Button
                block
                onClick={onJoinTribe}
                disabled={joinDisabled || !joinPossible}
              >
                <Text h6 color="white">
                  + Join tribe
                </Text>
              </Button>
            </Spacing>
          </JoinTribeWrapper>
        )
      }}
    />
  )
}

export const JoinTribe = withMutation('updateMember')(JoinTribeInner)
