import { flatten, isEmpty, pathOr, pipe, prop, propEq } from 'ramda'
import React, { useEffect, useState } from 'react'
import { graphql } from 'react-apollo'
import styled from 'styled-components/macro'

import { Button, Graphic, Spacing, Text } from '../../../components/ui'
import { useDataStream, useModal } from '../../../hooks'
import { AudienceSchemaQuery } from '../../../state/queries'
import { retrofitAudienceSchema } from '../../../state/selectors'
import { DataStreamCard } from '../../DataBank/DataStream/components'
import {
  ALL_ITEMS_STREAM,
  STREAMS_SCHEMA
} from '../../DataBank/DataStream/streams/_config'
import { hasDateKey } from '../../DataBank/DataStream/util'
import { ProfileSection, ProfileTagsAudience } from './'
import DataSourcesCarousel from './DataSourcesCarousel'
import { EmptyProfileSection } from './ProfileSection'

const TextWrap = styled(Text)`
  cursor: pointer;
`
const GraphicWrap = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.xl};
  border-radius: 10px;
`
const ProfileOverviewInner = ({
  isOwnProfile,
  member,
  profileSchema,
  isEditingProfile
}) => {
  const { openModal } = useModal()

  const onEditDataStream = () => openModal('DataStreamOptIn')

  return (
    <Spacing size="l">
      <ProfileSection
        isOwnProfile={isOwnProfile}
        label={isOwnProfile ? 'Private Traits' : 'Public Traits'}
        emptyLabel={
          isOwnProfile
            ? "You don't have any added traits, or your public profile settings are not letting any traits show"
            : 'There are no added traits'
        }
        emptyAction={
          <Button size="small" onClick={() => openModal('ProfileManagement')}>
            Add new traits
          </Button>
        }
        manageAction={
          <Button
            link
            transparent
            onClick={() => openModal('ProfileManagement')}
          >
            Manage
          </Button>
        }
      >
        {renderProfileSection({ member, profileSchema, isEditingProfile })}
      </ProfileSection>

      <ProfileSection
        isOwnProfile={isOwnProfile}
        label="Data stream"
        emptyLabel="Member has not made any Streams public"
        manageAction={
          <TextWrap h6 color="main" onClick={onEditDataStream}>
            Edit
          </TextWrap>
        }
        emptyAction={
          <Button onClick={onEditDataStream}>Pick public streams</Button>
        }
      >
        {member.publicProfileSourceOptIns &&
          member.publicProfileSourceOptIns.length > 0 && (
            <DataStream member={member} isEditingProfile={isEditingProfile} />
          )}
      </ProfileSection>
      <ProfileSection
        isOwnProfile={isOwnProfile}
        label="Data insights"
        emptyLabel="Coming soon!"
      />
    </Spacing>
  )
}

export const ProfileOverview = pipe(
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema })
)(ProfileOverviewInner)

const DataStream = ({ member, isEditingProfile }) => {
  const dataSourceOptinIds = member.publicProfileSourceOptIns
    .map(({ dataSourceId, ...rest }) => {
      const accountIds = getAccountIds({
        publicOptIns: member.publicProfileSourceOptIns,
        selectedDataSourceId: dataSourceId
      })

      return {
        ...rest,
        dataSourceId,
        accountIds,
        sort: ['robinhood', 'coinbase'].includes(dataSourceId) ? 2 : 1
      }
    })
    .filter(({ dataSourceId, accountIds }) => {
      return !!STREAMS_SCHEMA[dataSourceId] && accountIds.length > 0
    })
    .sort((a, b) => b.sort - a.sort)

  const [selectedDataSourceId, setSelectedDataSourceId] = useState(
    pathOr(null, ['0', 'dataSourceId'], dataSourceOptinIds)
  )

  const sourceStreamDefinition = pathOr(
    STREAMS_SCHEMA[selectedDataSourceId],
    [selectedDataSourceId, 'publicStreams'],
    STREAMS_SCHEMA
  )

  const streamDefinition =
    sourceStreamDefinition &&
    sourceStreamDefinition.find(
      ({ dataStreamId }) => dataStreamId === ALL_ITEMS_STREAM
    )

  useEffect(() => {
    const publicDataSource = getPublicDataSource({
      publicOptIns: member.publicProfileSourceOptIns,
      selectedDataSourceId
    })

    if (
      !publicDataSource &&
      member.publicProfileSourceOptIns &&
      member.publicProfileSourceOptIns.length > 0
    ) {
      setSelectedDataSourceId(member.publicProfileSourceOptIns[0].dataSourceId)
    }
  }, [selectedDataSourceId, member.publicProfileSourceOptIns])

  const { streamCards, loading, error, refetch } = useDataStream({
    accountIds: getAccountIds({
      publicOptIns: member.publicProfileSourceOptIns,
      selectedDataSourceId
    }),
    dataQueries: [streamDefinition],
    isPublicRead: true
  })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSourceId])

  return (
    <Spacing size="l">
      <DataSourcesCarousel
        dataSourceIds={dataSourceOptinIds.map(prop('dataSourceId'))}
        selectedDataSourceId={selectedDataSourceId}
        onDataSourceIdSelect={setSelectedDataSourceId}
      />
      {selectedDataSourceId
        ? loading
          ? renderLoading()
          : error
          ? renderError()
          : renderContent({ streamCards, dataSourceId: selectedDataSourceId })
        : null}
    </Spacing>
  )
}

const renderContent = ({ streamCards, dataSourceId }) => {
  const renderableItems = streamCards[ALL_ITEMS_STREAM]
    ? streamCards[ALL_ITEMS_STREAM].slice(0, 5)
    : []

  const hasDataToShow =
    renderableItems &&
    renderableItems.length > 0 &&
    !isEmpty(renderableItems[0].data) &&
    hasDateKey(renderableItems[0].data)

  return hasDataToShow ? (
    <Spacing size="s">
      {renderableItems.map((item, index) => (
        <DataStreamCard
          key={`${item.data.id}_${index}`}
          objectTypeId={item.objectTypeId}
          dataSourceId={dataSourceId}
          data={item.data}
        />
      ))}
    </Spacing>
  ) : (
    renderEmpty()
  )
}

const renderLoading = () => (
  <GraphicWrap>
    <Graphic name="Loading" width={100} height={60} themeColor="main" />
  </GraphicWrap>
)

const renderError = () => (
  <EmptyProfileSection>
    <Text t3 center>
      There was an error loading data for this stream
    </Text>
  </EmptyProfileSection>
)

const renderEmpty = () => (
  <EmptyProfileSection>
    <Text t3 center>
      No data
    </Text>
  </EmptyProfileSection>
)

const renderProfileSection = ({ member, profileSchema, isEditingProfile }) => {
  const tags = !isEditingProfile
    ? member.publicTags
    : flatten(member.tags.map(prop('values')))

  if (isEmpty(tags)) return null
  return (
    <TagsWrap>
      {tags.map((tag, index) => (
        <ProfileTagsAudience
          key={`${tag}_${index}`}
          profileSchema={profileSchema}
        >
          {tag}
        </ProfileTagsAudience>
      ))}
    </TagsWrap>
  )
}

const getAccountIds = ({ publicOptIns, selectedDataSourceId }) => {
  const publicDataSource = getPublicDataSource({
    publicOptIns,
    selectedDataSourceId
  })
  if (!publicDataSource) {
    return []
  }
  return publicDataSource.accountIds
}

const getPublicDataSource = ({ publicOptIns, selectedDataSourceId }) => {
  if (!publicOptIns) {
    return []
  }
  return publicOptIns.find(propEq('dataSourceId', selectedDataSourceId))
}

const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
