import { pipe, keys, map, filter } from 'ramda'

import csvProvider from './csvApiProvider'
import jsonProvider from './jsonApiProvider'

const ApiProvidersIds = {
  csvProvider: 'csvProvider',
  jsonProvider: 'jsonProvider',
}

export const ApiProviderNames = {
  [ApiProvidersIds.csvProvider]: 'CSV',
  [ApiProvidersIds.jsonProvider]: 'JSON',
}

const ApiProvidersConverters = {
  [ApiProvidersIds.jsonProvider]: {
    id: ApiProvidersIds.jsonProvider,
    supportedSources: [],
    supportedExtensions: ['.json'],
  },
  [ApiProvidersIds.csvProvider]: {
    id: ApiProvidersIds.csvProvider,
    supportedSources: ['netflix', 'amazon', 'linkedin'],
    supportedExtensions: ['.csv'],
  },
}

export const ApiProviders = {
  [ApiProvidersIds.csvProvider]: csvProvider,
  [ApiProvidersIds.jsonProvider]: jsonProvider,
}

export const getAvailableApiProviderConverters = (sourceId) =>
  pipe(
    keys,
    map((id) => ApiProvidersConverters[id]),
    filter(({ supportedSources }) =>
      supportedSources.map(String).includes(String(sourceId))
    )
  )(ApiProvidersConverters)
