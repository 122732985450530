export default {
  Logo: require('./logo.png'),
  Header: require('./header.png'),
  DataSources: require('./dataSources.png'),
  ProfileExample: require('./profileExample.png'),
  JoinTribesExample: require('./joinTribesExample.png'),
  TalkTribeExample: require('./talkTribeExample.png'),
  LearnTribeExample: require('./learnTribeExample.png'),
  EarnTribeExample: require('./earnTribeExample.png')
}
