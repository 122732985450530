import gql from 'graphql-tag'

const mutation = gql`
  mutation KickoffOpportunityResponse(
    $opportunityId: String!
    $shortId: String!
  ) {
    kickoffOpportunityResponse(
      opportunityId: $opportunityId
      shortId: $shortId
    ) {
      opportunity {
        id
        currentMemberDataRequest {
          cumulativeStatus
          shortId
          opportunityId
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export default mutation
