import gql from 'graphql-tag'

import { OptInFragment } from '../fragments'

const mutation = gql`
  mutation OptInOpportunity($id: String!) {
    optInOpportunity(id: $id) {
      optIn {
        id
        ...OptIn
      }
      errors {
        key
        message
      }
    }
  }
  ${OptInFragment}
`

mutation.update = (store, { data: { optInOpportunity } }) => {
  const query = gql`
    query AllOptIns {
      allOptIns(redeemable: true) {
        id
        ...OptIn
      }
    }
    ${OptInFragment}
  `

  try {
    const { allOptIns } = store.readQuery({
      query
    })

    const { optIn } = optInOpportunity

    store.writeQuery({
      query,
      data: { allOptIns: [...allOptIns, optIn] }
    })
  } catch (e) {} // eslint-disable-line
}

mutation.getOptimistic = opportunity => ({
  optInOpportunity: {
    __typename: 'OptInPayload',
    optIn: {
      __typename: 'OptIn',
      id: `OptIn_${opportunity.id}`,
      opportunity: {
        __typename: 'Opportunity',
        ...opportunity
      }
    },
    errors: null
  }
})

export default mutation
