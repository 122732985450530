import gql from 'graphql-tag'

import { TargetAudienceBucketsFragment } from './TargetAudienceBucket'

export const OpportunityFragment = gql`
  fragment Opportunity on Opportunity {
    id
    name
    shortDescription
    description
    dependsOn
    type
    currentMemberDataRequest {
      cumulativeStatus
      shortId
      opportunityId
    }
    compensation {
      usd
      ubdi
    }
    inputDataSources {
      required
      query {
        id
        sql
        objectTypeId
        dataSourceIds
        dataSources {
          id
          name
        }
        nativeQueryDescription {
          aggregation
          breakout
          filter
        }
      }
    }
    missingInputDataSources {
      query {
        id
        objectType {
          id
          name
        }
        dataSourceIds
      }
    }
    missingTags
    customDataFields {
      label
      type
    }
    reach {
      maxPermitted
      reached
    }
    survey {
      totalQuestions
      externalLink
      resultsLink
      inHouse {
        description
        questions {
          required
          title
          type
          videoUrl
          imageUrl {
            thumbnail
            default
          }
          choices {
            label
            type
          }
        }
      }
    }
    duration {
      startDate
      endDate
      lengthInDays
    }
    company {
      id
      fullName
      photo {
        thumbnail
        default
        large
      }
    }
    showCompanyLogo

    category
    ad {
      imageUrl
      colorHex
      fullUrl
      hostUrl
      duration
      conversionEvents {
        url
      }
    }

    targetAudienceBuckets {
      ...TargetAudienceBucket
    }

    responseConfig {
      allTags
      additionalResponseTags
      requiredResponseTags
    }
    donationFor
    insightsDataType
    insightsStorageLocation
    isRedeemed
    isJoinable
    linkedStudies {
      id
      name
      duration {
        startDate
        endDate
        lengthInDays
      }
      compensation {
        usd
        ubdi
      }
    }
    opportunityResponse {
      shortId
      surveyCompleted
      responseToken
    }
  }

  ${TargetAudienceBucketsFragment}
`
