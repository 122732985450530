import Countries from '../constants/Countries'
import { isEmpty, startsWith, contains, toLower, tail } from 'ramda'

export const getSuggestions = (searchText, options) => {
  if (isEmpty(searchText)) {
    return Countries.entitled.map((item) => Countries.list[item])
  }

  return options.filter(({ callingCode, name }) => {
    if (startsWith('+', searchText) && searchText.length > 1) {
      return contains(tail(searchText), callingCode)
    }

    if (!Number.isNaN(parseInt(searchText, 10))) {
      return contains(searchText, callingCode)
    }

    return contains(toLower(searchText), toLower(name))
  })
}
