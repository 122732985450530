import React from 'react'
import styled from 'styled-components/macro'
import { FieldArray } from 'react-final-form-arrays'
import { Text, RadioButton } from '../'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin: -5px -5px;
`

const GifInputWrapper = styled.div`
  flex-basis: 48%;
  position: relative;
  padding: ${({ theme }) => theme.padding.s};
  margin: ${({ theme }) => theme.padding.xs};
  background: white;
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.color.main : theme.color.purpleL2};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  ${({ disabled }) => disabled && `opacity: 0.5;`}
  display:flex;
  flex-direction: column;
`
const ImgWrapper = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
`

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: stretch;
`

const TextWrapper = styled.div`
  margin-top: ${({ theme }) => theme.padding.s};
`

const CheckWrapper = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
`

export const GifRadioList = ({ uniqueId, options, onChange, ...rest }) => (
  <FieldArray
    name={uniqueId}
    render={props => {
      const { fields } = props

      const toggle = option => () => {
        if (!fields.length) {
          fields.push(option)
          onChange && onChange()
          return
        }

        if (!isSelected(option)) {
          fields.pop()
          fields.push(option)
        }
      }

      const isSelected = option => fields.value && fields.value.includes(option)

      return (
        <Wrapper>
          {options.map(({ value, choice, ...extra }) => {
            const radioProps = {
              input: {
                onChange: toggle(value),
                value: isSelected(value),
                checked: isSelected(value)
              },
              extra,
              ...props
            }

            return (
              <GifRadioInput
                key={value}
                choice={choice}
                {...radioProps}
                {...rest}
              />
            )
          })}
        </Wrapper>
      )
    }}
  />
)

const GifRadioInput = props => {
  const {
    noShadow = false,
    choice: { text, gifUrl },
    input: { value, onChange, checked }
  } = props

  return (
    <GifInputWrapper checked={checked} onClick={onChange}>
      {gifUrl && (
        <ImgWrapper>
          <StyledImage src={gifUrl} />
        </ImgWrapper>
      )}

      {text && (
        <TextWrapper>
          <Text h5 center>
            {text}
          </Text>
        </TextWrapper>
      )}
      <CheckWrapper>
        <RadioButton key={value} {...props} noShadow={noShadow} hideBox />
      </CheckWrapper>
    </GifInputWrapper>
  )
}
