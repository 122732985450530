import { useQuery } from '@apollo/react-hooks'
import { formatDistanceStrict } from 'date-fns'
import { includes, isEmpty, omit, pathOr, pipe, propOr } from 'ramda'
import React, { useContext, useEffect, useState } from 'react'
import { graphql } from 'react-apollo'
import { Link } from 'react-router-dom'
import { Box, Button, Spacing, Text } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { PriceContext, PRICE_PROVIDERS } from '../../../contexts/priceContext'
import { DataStreamResultsQuery, UserQuery } from '../../../state/queries'
import theme from '../../../theme'
import { capitalizeFirstLetter, formatLocaleNumber } from '../../../util'
import {
  DataStreamCard,
  LoadingBlanket
} from '../../DataBank/DataStream/components'
import { InlineTags } from '../../Home/components/CommunityPosts/components'
import { getQueryResult } from '../util'
import { JoinTribe } from './JoinTribe'

const FETCH_FIRST = 100
const DataFeedView = ({
  dataQuery,
  toggleDataStreamOptIn,
  publicDataStreamId,
  tribeId,
  data: userData,
  viewOnly = false
}) => {
  const [hasReceivedEmptyList, setHasReceivedEmptyList] = useState(false)
  const [initialLoadingDone, setInitialLoadingDone] = useState(false)
  const [toggling, setToggling] = useState(false)

  const optedInDataStreams = pathOr(
    [],
    ['currentUser', 'member', 'optedInDataStreams'],
    userData
  )

  const onToggleOptIn = () => {
    setToggling(true)
    toggleDataStreamOptIn({
      variables: { id: publicDataStreamId, optIn: true }
    })
      .then(() => setToggling(false))
      .catch(() => setToggling(false))
  }

  const { data, loading, error, fetchMore } = useQuery(DataStreamResultsQuery, {
    variables: {
      dataStreamId: publicDataStreamId,
      first: FETCH_FIRST,
      dataQuery: omit(['__typename'], dataQuery)
    },
    skip: !publicDataStreamId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  })

  if (!publicDataStreamId) return null

  if (loading && !initialLoadingDone)
    return <LoadingBlanket text="Loading Data feed posts" />

  if (error)
    return (
      <Spacing>
        {maybeRenderOptInBox({
          viewOnly,
          tribeId,
          onToggleOptIn,
          optedInDataStreams,
          toggling,
          publicDataStreamId
        })}
        <EmptyText text="There was an error loading posts for this stream" />
      </Spacing>
    )

  if (!initialLoadingDone) {
    setInitialLoadingDone(true)
  }

  const results = getQueryResult(data.dataStreamResults, dataQuery.id)

  const onShowMore = () => {
    if (loading || hasReceivedEmptyList) return

    fetchMore({
      variables: {
        dataStreamId: publicDataStreamId,
        first: FETCH_FIRST,
        after: results.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          dataStreamResults: fetchMoreResult.dataStreamResults.map(
            (result, index) => ({
              ...prev.dataStreamResults[index],
              results: [
                ...prev.dataStreamResults[index].results,
                ...result.results
              ]
            })
          )
        })
      }
    }).then(({ data }) => {
      const results = getQueryResult(data.dataStreamResults, dataQuery.id)

      if (isEmpty(results) || results.length < FETCH_FIRST)
        setHasReceivedEmptyList(true)
    })
  }

  return (
    <Spacing stretched>
      {maybeRenderOptInBox({
        viewOnly,
        tribeId,
        onToggleOptIn,
        optedInDataStreams,
        toggling,
        publicDataStreamId
      })}
      {results.map((item, index) => {
        switch (dataQuery.objectTypeId) {
          case 202:
          case 201:
            return (
              <TransactionCard
                key={`datafeed-transactions-${index}`}
                item={item}
                tribeId={tribeId}
                viewOnly={viewOnly}
              />
            )
          default:
            return (
              <DataStreamCard
                key={`datafeed-datastreams-${index}`}
                objectTypeId={dataQuery.objectTypeId}
                dataSourceId={item.datasourceid}
                data={item}
                isPublic
                viewOnly={viewOnly}
              />
            )
        }
      })}
      {!(hasReceivedEmptyList || results.length === 0 || loading) && (
        <Button onClick={onShowMore} block outline>
          Load more
        </Button>
      )}
    </Spacing>
  )
}

const TransactionCard = ({ item, tribeId, viewOnly = false }) => {
  const {
    side,
    quantity,
    symbol,
    currency,
    createddate,
    member,
    averageprice
  } = item
  const { tags, colors, publicTagCategories, memberId } = JSON.parse(member)

  const { fetchPrice, prices } = useContext(PriceContext)
  const ticker = symbol || `${currency}USDT`

  useEffect(() => {
    if (currency) {
      fetchPrice(ticker, PRICE_PROVIDERS.binance)
    } else if (symbol) {
      fetchPrice(ticker, PRICE_PROVIDERS.tiingo)
    }
  }, [ticker, currency, fetchPrice, symbol])

  const price = propOr(0, ticker, prices)
  const currentValue = quantity * price
  const nativeAmount = parseFloat(averageprice) * quantity

  const percentageChange = ((currentValue - nativeAmount) / nativeAmount) * 100
  const color =
    percentageChange <= 0 ? theme.color.redNegative : theme.color.green

  const customTags = [
    {
      value: `${capitalizeFirstLetter(side)} ${formatLocaleNumber({
        number: quantity,
        currency
      })} ${currency || symbol} @ ${parseFloat(averageprice).toFixed(2)}`,
      backgroundColor:
        side === 'sell' ? theme.color.redNegative : theme.color.green
    },
    side !== 'sell' && {
      value: `ROI: ${percentageChange.toFixed(2)}%`,
      backgroundColor: color
    }
  ]

  return (
    <Box size="m" disabled={viewOnly}>
      <Spacing direction="row" justify="space-between" align="center">
        <Link
          style={{ marginRight: theme.padding.small, color: 'inherit' }}
          to={`/profile/${memberId}/${tribeId}`}
        >
          <InlineTags
            avatarId={`${memberId}-${tribeId}-${createddate}`}
            colors={colors}
            tags={tags}
            publicTagCategories={publicTagCategories}
            customTags={customTags}
            numberOfTags={1}
            member={member}
            hideEmptyState
          />
        </Link>
        <Text t2 color={theme.color.redNegative.dark1}>
          {formatDistanceStrict(new Date(parseInt(createddate)), new Date(), {
            addSuffix: true
          })}
        </Text>
      </Spacing>
    </Box>
  )
}

const maybeRenderOptInBox = ({
  onToggleOptIn,
  optedInDataStreams,
  toggling,
  publicDataStreamId,
  viewOnly,
  tribeId
}) => {
  if (viewOnly) return <JoinTribe tribeId={tribeId} />
  if (includes(publicDataStreamId, optedInDataStreams)) return null

  return (
    <Box>
      <Spacing size="xl" align="center">
        <Text t1 center>
          Opting into a Data Stream is unavailable at this time.
        </Text>
        <Button onClick={onToggleOptIn} disabled>
          {toggling ? 'Opting in...' : 'Opt-in to Data Stream'}
        </Button>
      </Spacing>
    </Box>
  )
}

const EmptyText = ({ text }) => <div>{text}</div>

export const DataFeed = pipe(
  withMutation('toggleDataStreamOptIn'),
  graphql(UserQuery)
)(DataFeedView)
