import React from 'react'
import styled from 'styled-components'
import { Spacing, Text } from '../ui'

const Pill = styled.a`
  cursor: pointer;
  background-color: ${({ theme, isSelected }) =>
    theme.color[isSelected ? 'main' : 'purpleL3']};
  padding: 5px 13px;
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.padding.s};
  border-radius: ${({ theme }) => theme.borderRadius.base};
`

export const HorizontalPillFilter = ({
  options,
  selectedOption,
  setSelectedOption
}) => {
  return (
    <Spacing wrap direction="row" size="s">
      {options.map(option => {
        const isSelected = selectedOption === option.value
        return (
          <Pill
            key={option.value}
            onClick={() => setSelectedOption(option.value)}
            isSelected={isSelected}
          >
            <Text h7 color={isSelected ? 'white' : 'main'}>
              {option.label}
            </Text>
          </Pill>
        )
      })}
    </Spacing>
  )
}
