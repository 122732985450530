import { pipe } from 'ramda'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components/macro'

import {
  Button,
  Input,
  Modal,
  Spacing,
  Text,
  SyncStateIcon
} from '../../components/ui'
import { withMutation } from '../../components/wrappers'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

const TextWrapper = styled.div`
  width: 70%;
  margin: auto;
`

const ReferralCodeInner = ({ onDismiss, redeemBonusCode }) => {
  const [hasSubmittedCode, setHasSubmitted] = useState(false)

  const onSubmit = variables =>
    redeemBonusCode({ variables }).then(({ userErrors, errors, data }) => {
      if (!userErrors && data && data.redeemBonusCode) {
        setHasSubmitted(true)
        return
      }

      return userErrors || errors
    })

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Enter referral code"
    >
      {() => (
        <ModalWrap>
          {!hasSubmittedCode ? (
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, submitting }) => (
                <>
                  <Spacing size="l">
                    <Text t2>Please enter a referral code you received:</Text>
                    <Field
                      name="bonusCode"
                      component={Input}
                      label="Referral code"
                      autoFocus
                    />
                  </Spacing>
                  <Modal.Footer>
                    <Spacing direction="row" justify="flex-end" align="center">
                      <Button outline onClick={onDismiss}>
                        Cancel
                      </Button>
                      <Button disabled={submitting} onClick={handleSubmit}>
                        Submit
                      </Button>
                    </Spacing>
                  </Modal.Footer>
                </>
              )}
            </Form>
          ) : (
            <>
              <Spacing size="xl" align="center">
                <SyncStateIcon syncState="completed" size={78} />
                <Text h2 center>
                  Your code has been accepted
                </Text>
                <TextWrapper>
                  <Text t2 center>
                    Once you’ve set up your Uphold account and linked two data
                    sources, both you and your referral will earn $.20
                  </Text>
                </TextWrapper>
              </Spacing>
              <Modal.Footer>
                <Button onClick={onDismiss}>Got it</Button>
              </Modal.Footer>
            </>
          )}
        </ModalWrap>
      )}
    </Modal>
  )
}

export const ReferralCode = pipe(withMutation('redeemBonusCode'))(
  ReferralCodeInner
)
