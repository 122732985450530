import React from 'react'
import styled from 'styled-components/macro'
import { Text, Spacing, Box } from '../../../components/ui'
import { sourcesInstructions } from './_sourcesInstructions'

export const SourceInstructions = ({ dataSourceId }) => {
  return (
    <Spacing size="s">
      {sourcesInstructions[dataSourceId].map((step, i) => (
        <Step key={step} i={i + 1}>
          {step}
        </Step>
      ))}
    </Spacing>
  )
}

const Number = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.main};
`

const Step = ({ i, children }) => (
  <Box bordered>
    <Spacing size="l" direction="row" align="center">
      <Number>
        <Text h6 color="white">
          {i}
        </Text>
      </Number>
      <Text t2>{children}</Text>
    </Spacing>
  </Box>
)
