import React from 'react'
import styled from 'styled-components'

import { Modal, Spacing, Text } from '../../components/ui'
import SyncStateIcon from '../../components/ui/SyncStateIcon'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 400px;
  padding-top: 50px;
  display: flex;
  justify-content: center;
`

const TextWrap = styled.div`
  width: 360px;
`

export const UpholdAuthIntro = ({ onDismiss }) => {
  return (
    <Modal isOpen onDismiss={onDismiss} title="My Wallet">
      {() => {
        return (
          <ModalWrap>
            <Spacing size="xl" align="center">
              <SyncStateIcon size={80} syncState="completed" />
              <TextWrap>
                <Spacing size="l">
                  <Text h2 center>
                    Wallet has been successfully created
                  </Text>
                  <Text t2 center>
                    You will receive notification once your account has been
                    verified by Uphold
                  </Text>
                </Spacing>
              </TextWrap>
            </Spacing>
          </ModalWrap>
        )
      }}
    </Modal>
  )
}
