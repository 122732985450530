import { useQuery } from '@apollo/react-hooks'
import { pathOr, pipe } from 'ramda'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { Button, Spacing, SyncStateIcon, Text } from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { OpportunityQuery } from '../../state/queries/OpportunitiesScreenQueries'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) => theme.padding.l};
`

const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  width: 600px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AdClaimScreenInner = ({
  match: {
    params: { id }
  },
  optInOpportunity,
  createOpportunityResponse,
  redeem
}) => {
  const { data: { opportunity } = {}, loading } = useQuery(OpportunityQuery, {
    variables: {
      id
    }
  })
  const [secondsLeft, setSecondsLeft] = useState()
  const [claimed, setClaimed] = useState(false)

  useEffect(() => {
    const adDuration = pathOr(0, ['ad', 'duration'], opportunity)
    if (opportunity && !opportunity.isRedeemed) {
      setSecondsLeft(adDuration)
    }
  }, [opportunity])

  useEffect(() => {
    const adDismissInterval = setInterval(() => {
      setSecondsLeft(secondsLeft => secondsLeft - 1)
    }, 1000)

    return () => clearInterval(adDismissInterval)
  }, [])

  if (loading || !opportunity) {
    return null
  }

  const adUrl = pathOr('', ['ad', 'fullUrl'], opportunity)

  const onClaim = async () => {
    await optInOpportunity({ variables: { id: opportunity.id } })

    const {
      data: {
        createOpportunityResponse: { id }
      }
    } = await createOpportunityResponse({
      variables: { opportunity: opportunity.id }
    })

    await redeem({
      variables: {
        response: id
      }
    })
    setClaimed(true)
  }

  return (
    <Container>
      {!opportunity.isRedeemed ? (
        <Spacing size="l">
          <ContentWrapper>
            <Iframe src={adUrl} />
          </ContentWrapper>
          <Button
            block
            disabled={secondsLeft > 0 || opportunity.isRedeemed}
            onClick={onClaim}
          >
            {secondsLeft > 0 ? `Claim in ${secondsLeft}` : 'Claim'}
          </Button>
        </Spacing>
      ) : (
        <ContentWrapper>
          <Spacing size="l" align="center" justify="center">
            <SyncStateIcon syncState="completed" size={78} />
            <Text h3 center>
              {claimed
                ? 'Claim successful!'
                : 'You have already claimed this ad'}
            </Text>
          </Spacing>
        </ContentWrapper>
      )}
    </Container>
  )
}

export const AdClaimScreen = pipe(
  withMutation('createOpportunityResponse'),
  withMutation('redeem'),
  withMutation('optInOpportunity')
)(AdClaimScreenInner)

const Iframe = styled.iframe`
  border: none;
  width: 600px;
  height: 100%;
`
