import { gql } from 'apollo-boost'

export default gql`
  mutation VerifyResetCode($usernameOrPhoneNumber: String!, $code: String!) {
    verifyResetCode(
      usernameOrPhoneNumber: $usernameOrPhoneNumber
      code: $code
    ) {
      success
      errors {
        key
        message
      }
    }
  }
`
