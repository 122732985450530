import React from 'react'
import { Graphic, Spacing, Text } from '../../../components/ui'
import styled from 'styled-components'
import theme from '../../../theme'
import { OpportunityCard } from './OpportunityCard'
import { filter, length, map, pipe } from 'ramda'

export const OpportunitiesList = ({
  opportunities = [],
  loading,
  hideSubtitle = false,
  subtitleLink,
  opportunityCardProps
}) => {
  const numberOfStudies = pipe(
    map(opportunity => opportunity.category === 'Study'),
    filter(Boolean),
    length
  )(opportunities)

  return (
    <Wrapper>
      <Spacing size="xl">
        {!numberOfStudies && (
          <Spacing>
            <Graphic name="UbdiIntro" />
            <Text t0 center>
              Currently no opportunities
            </Text>
            <Text t2 center>
              Unfortunately we don’t have any earning opportunities right now as
              we’re going towards our goal of 150k members, but you can check
              out external surveys to earn some extra cash
            </Text>
          </Spacing>
        )}
        <Spacing size="s">
          {opportunities.map((opportunity, i) => (
            <OpportunityCard
              loading={loading}
              key={opportunity.id}
              {...opportunity}
              {...opportunityCardProps}
              gradientBg={theme.gradient.gradient3}
            />
          ))}
        </Spacing>
      </Spacing>
    </Wrapper>
  )
}

const Wrapper = styled.div``
