import React from 'react'

import { format } from 'date-fns'
import { find, equals, includes } from 'ramda'
import { CATEGORIES, getCurrencySymbol } from '../streams/util/financeHelpers'
import { Spacing, Text } from '../../../../components/ui'
import styled from 'styled-components/macro'
import Formats from '../../../../constants/Formats'
import { ShareButton } from './ShareButton'

const RED_DEBIT = '#ec5c5b'

const Wrapper = styled.div`
  box-shadow: 0px 6px 8px rgba(111, 98, 255, 0.06);
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  padding: ${({ theme }) => theme.padding.m};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AmountWrap = styled(Text)`
  color: ${RED_DEBIT};
  padding-right: ${({ theme }) => theme.padding.xs};
`

const DescriptionWrap = styled(Text)`
  padding-left: ${({ theme }) => theme.padding.xs};
  flex: 1;
`
const CategoryWrap = styled.div`
  border-radius: 5px;
  background-color: ${({ color }) => color};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  display: inline-block;
`

export const TransactionsListItem = ({ data, onShare }) => {
  const extendedItem = {
    ...data,
    ...find(({ subcategories, label }) => {
      return (
        includes(data.category, subcategories) === true ||
        equals(label, 'Other')
      )
    })(CATEGORIES)
  }

  return (
    <Wrapper>
      <Spacing size="s">
        <Text t4></Text>
        <Spacing direction="row" justify="space-between">
          <Text h4>
            {format(new Date(extendedItem.createddate), Formats.dates.dateTime)}
          </Text>
          {onShare && (
            <ShareButton onClick={() => onShare({ data, objectTypeId: 201 })} />
          )}
        </Spacing>
        <TextWrapper>
          <AmountWrap h5 bold>
            {getCurrencySymbol(extendedItem.amount, extendedItem.currency)}
          </AmountWrap>
          <Text h5 bold>
            -
          </Text>
          <DescriptionWrap>
            <Text h5 bold>
              {extendedItem.originalref}
            </Text>
          </DescriptionWrap>
        </TextWrapper>

        <CategoryWrap color={extendedItem.color}>
          <Text color="white" h6>
            {extendedItem.label}
          </Text>
        </CategoryWrap>
      </Spacing>
    </Wrapper>
  )
}
