import { gql } from 'apollo-boost'

import { UserFragment } from '../fragments'
import { UserWithSourcesQuery } from '../queries'

const mutation = gql`
  mutation ToggleDataStreamOptIn($id: String!, $optIn: Boolean!) {
    toggleDataStreamOptIn(id: $id, optIn: $optIn) {
      success
      query {
        currentUser {
          id
          ...User
        }
      }

      errors {
        key
        message
      }
    }
  }
  ${UserFragment}
`

mutation.refetchQueries = [{ query: UserWithSourcesQuery }]

export default mutation
