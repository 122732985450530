import React from 'react'

import styled from 'styled-components/macro'
import {
  pipe,
  find,
  path,
  isNil,
  pathOr,
  head,
  map,
  filter,
  flatten,
  countBy,
  prop,
  toPairs,
  reduce,
  sort
} from 'ramda'
import pluralize from 'pluralize'

import { getCurrencySymbol, formatLocaleNumber } from '../../../../util'
import { Spacing, Text } from '../../../../components/ui'
import theme from '../../../../theme'

const WrapGradientBg = styled.div`
  min-height: 120px;
  width: 48%;
  background: ${({ gradient }) => gradient};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.padding.m};
  border-radius: 10px;
  margin: 0 10px 10px 0;
`

export const DataInsightsCard = ({ dataSource: { baseColor }, card }) => {
  const gradient = baseColor
    ? `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), ${baseColor}`
    : theme.gradient.gradient1

  return (
    <WrapGradientBg gradient={gradient}>
      <Spacing size="xl">
        <Text h6 color="white" left>
          {formatLabel(card)}
        </Text>
        <Text h2 color="white">
          {formatValue(card)}
        </Text>
      </Spacing>
    </WrapGradientBg>
  )
}

const formatLabel = card => {
  // Handle custom/complex cards
  if (isCustomCard(card)) {
    const [{ custom }] = card
    return custom.label
  }

  if (card.label) return card.label
}

const formatValue = card => {
  // Handle custom/complex card values
  if (isCustomCard(card)) {
    const [{ custom }] = card

    return customFormatters[custom.valueFormatter](card)
  }

  if (!isNil(card.value)) {
    const { value } = card
    if (!isNaN(value)) return formatLocaleNumber({ number: value })

    return value
  }

  return 'N/A'
}

const isCustomCard = card => !!(card.length > 0 && card[0].custom)

const getDataValue = key =>
  pipe(
    find(({ data }) => !isNil(data[key])),
    path(['data', key])
  )

const customFormatters = {
  credit_minus_debit: values => {
    const debit = getDataValue('sum_debit')(values)
    const credit = getDataValue('sum_credit')(values)
    console.log({ debit, credit })
    return 0
  },
  years_of_experience: values => {
    const year = getDataValue('memberyearsofexperience')(values)
    return `${year} ${pluralize('year', year)}`
  },
  graduation_year: values => {
    const gaduationYear = getDataValue('membergraduationyear')(values)
    return gaduationYear
  },
  buy_minus_sell: values => {
    const sumBuy = getDataValue('sum_buy')(values)
    const sumSell = getDataValue('sum_sell')(values)

    return sumBuy - sumSell
  },
  currency_amount: values => {
    const getProp = prop => pipe(head, pathOr(0, ['data', prop]))
    const amount = getProp('itemtotal')(values)
    const currency = getProp('currency')(values)

    return getCurrencySymbol(amount, currency)
  },
  hashtag: values => {
    const mostUsedHashtag = pipe(
      map(path(['data', 'hashtags'])),
      filter(hashtags => hashtags.length > 0),
      flatten,
      countBy(prop('text')),
      toPairs,
      reduce(
        (acc, value) =>
          acc.concat([
            {
              key: value[0],
              value: value[1]
            }
          ]),
        []
      ),
      sort((a, b) => b.value - a.value),
      head
    )(values)

    return `#${mostUsedHashtag.key}`
  },
  username: values => pipe(head, path(['data', 'username']))(values),
  media_count: values => pipe(head, path(['data', 'mediacount']))(values)
}
