import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { head, pipe, propOr } from 'ramda'
import { AvatarColorPicker } from '../../components/AvatarColorPicker'
import { Button, Input, Modal, Spacing, Text } from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { UploadPhoto } from '../../components/UploadPhoto'

const TEXT_MAX_LENGTH = 240

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const EditProfileInner = ({ params = {}, onDismiss, updateMember }) => {
  const {
    member: { profileColor, profileUsername, profileDescription, profileImage }
  } = params

  const onSaveProfile = values => {
    const image = {
      src: values.profileImage,
      x: 0,
      y: 0,
      height: 1,
      width: 1
    }

    return updateMember({
      variables: {
        profileColor: head(values.profileColor),
        profileUsername: values.profileUsername,
        profileDescription: values.profileDescription || '',
        profileImage: values.profileImage ? image : null
      }
    }).then(({ userErrors }) => {
      if (!userErrors) {
        onDismiss()
      }
    })
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Edit profile"
    >
      {() => (
        <ModalWrap>
          <Form
            initialValues={{
              profileColor: [profileColor],
              profileUsername,
              profileDescription,
              profileImage
            }}
            onSubmit={onSaveProfile}
          >
            {({ handleSubmit, values, submitting }) => {
              const textLength = propOr('', 'profileDescription', values).length

              return (
                <Spacing size="xl">
                  <Spacing>
                    <Text h6>Default avatar</Text>
                    <Field
                      name="profileColor"
                      component={AvatarColorPicker}
                      disabled={submitting}
                      currentColors={values.profileColor}
                    />
                  </Spacing>
                  <Spacing>
                    <Text h6>Image avatar</Text>
                    <Field
                      name="profileImage"
                      component={UploadPhoto}
                      disabled={submitting}
                      image={values.profileImage}
                    />
                  </Spacing>
                  <Spacing>
                    <Text h6>Public username</Text>
                    <Field
                      name="profileUsername"
                      component={Input}
                      disabled={submitting}
                      block
                    />
                  </Spacing>
                  <Spacing>
                    <Text h6>Profile description</Text>
                    <Spacing size="s">
                      <Field
                        name="profileDescription"
                        component={Input}
                        disabled={submitting}
                        meta={{}}
                        maxLength={TEXT_MAX_LENGTH}
                        textarea
                        block
                      />
                      <Text t2 color="grey">
                        {textLength}/{TEXT_MAX_LENGTH} characters
                      </Text>
                    </Spacing>
                  </Spacing>
                  <Modal.Footer>
                    <ButtonWrap>
                      <Button disabled={submitting} onClick={handleSubmit}>
                        Save settings
                      </Button>
                    </ButtonWrap>
                  </Modal.Footer>
                </Spacing>
              )
            }}
          </Form>
        </ModalWrap>
      )}
    </Modal>
  )
}

export const EditProfile = pipe(withMutation('updateMember'))(EditProfileInner)
