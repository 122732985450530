import { keys, pipe, map, filter, propEq } from 'ramda'
import { ServiceProviders } from '../_providers'

export const getAvailableServiceProviders = (sourceId) =>
  pipe(
    keys,
    map((id) => ServiceProviders[id]),
    filter(({ supportedSources }) =>
      supportedSources.map(String).includes(String(sourceId))
    )
  )(ServiceProviders)

export const getExtensionServedServiceProviders = () =>
  findServiceProviders(propEq('servedOnlyViaExtension', true))

export const getAsynclyDeliveredServiceProviders = () =>
  findServiceProviders(propEq('deliversDataAsync', true))

const findServiceProviders = (criteria) =>
  pipe(
    keys,
    map((id) => ServiceProviders[id]),
    filter(criteria)
  )(ServiceProviders)

export const isSourceSupportedByProvider = (providerId) => (sourceId) => {
  return ServiceProviders[providerId].supportedSources
    .map(String)
    .includes(String(sourceId))
}

export const getServiceProvider = (providerId) => ServiceProviders[providerId]
