import React, { useState, useEffect, useRef } from 'react'
import { isEmpty, pathOr } from 'ramda'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'

import { Spacing, Box, Tabs } from '../../components/ui'
import { CommunityPollQuery } from '../../state/queries'
import {
  CompetitionCard,
  PostInfo
} from '../Home/components/CommunityPosts/components'

import { CommentForm, PostComments } from '../Post/components'
import { CompetitionEntriesList, CompetitionLeaderboard } from './components'
import { EmptyScreenTemplate } from '../Home/components/EmptyScreenTemplate'

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
const scrollToTop = () => window.scrollTo(0, 0)

const CompetitionScreenInner = ({
  match: { params: { postId } } = {},
  location,
  history: { goBack }
}) => {
  const [replyingToCommentId, setReplyingToCommentId] = useState()
  const scrollToComments = pathOr(
    false,
    ['state', 'scrollToComments'],
    location
  )
  const CommentsRef = useRef()

  const { data, loading } = useQuery(CommunityPollQuery, {
    variables: { id: postId }
  })

  useEffect(() => {
    if (loading) return

    if (scrollToComments) scrollToRef(CommentsRef)
    else scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (loading) return null

  const post = pathOr({}, ['communityPoll'], data)
  const competition = pathOr({}, ['competition'], post)
  const tribe = pathOr(false, ['tribe'], post)

  if (isEmpty(post)) {
    return <EmptyScreenTemplate />
  }

  return (
    <Wrapper>
      <BackButton onClick={goBack}>{`< Back to posts`}</BackButton>
      <Container>
        <StyledBoxUpper size="xl" bordered noShadow>
          <CompetitionCardWrapper>
            <CompetitionCard condensed post={post} />
          </CompetitionCardWrapper>
          <Spacing size="l">
            <PostInfo post={post} condensed={true} />
            <Tabs forceShowHeader>
              {!competition.dataStreamId && (
                <Tabs.Tab title="All entries">
                  <CompetitionEntriesList post={post} />
                </Tabs.Tab>
              )}
              <Tabs.Tab title="Leaderboard">
                <CompetitionLeaderboard post={post} />
              </Tabs.Tab>
            </Tabs>
          </Spacing>
        </StyledBoxUpper>

        <StyledBoxLower size="xl" bordered noShadow>
          <PostComments
            {...post}
            setReplyingToCommentId={setReplyingToCommentId}
          />
        </StyledBoxLower>
      </Container>
      <CommentFormWrapper ref={CommentsRef}>
        <CommentFormBox>
          <CommentForm
            id={postId}
            commentId={replyingToCommentId}
            tribeId={tribe.id}
            onReplyDimiss={() => setReplyingToCommentId('')}
          />
        </CommentFormBox>
      </CommentFormWrapper>
    </Wrapper>
  )
}

export const CompetitionScreen = CompetitionScreenInner

const Wrapper = styled.div`
  position: relative;
`

const Container = styled.div`
  padding: ${({ theme }) => `${theme.padding.xl} 0px`};
  width: 40%;
  height: 100%;
  margin: auto;
`

const BackButton = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;

  ${({ theme }) => theme.text.h6};
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
  color: ${({ theme }) => theme.color.main};
  background: ${({ theme }) => theme.color.white};
  border-radius: 35px;
  border: 1px solid ${({ theme }) => theme.color.main};
  cursor: pointer;
`

const CompetitionCardWrapper = styled.div`
  margin: -30px -30px 0px -30px;
`

const StyledBoxUpper = styled(Box)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`

const StyledBoxLower = styled(Box)`
  border-top-width: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`

const CommentFormWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`

const CommentFormBox = styled.div`
  margin: auto;
  width: 40%;
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
`
