import { isEmpty, isNil } from 'ramda'

export const createCommunityPollValidator = values => {
  // return { title: '' } // Posting Poll Posts is currenlty unavailable since API expects GIF format => TODO
  const { title, choices, tribe } = values

  if (!tribe || !tribe.id) return { tribe: '' }
  if (!title) return { title: '' } // this message is redudant
  if (isNil(choices) || isEmpty(choices)) return { title: '' }

  const emptyChoices = choices.filter(
    choice => isNil(choice) || isNil(choice.text)
  )

  if (!isEmpty(emptyChoices)) return { title: '' }
  return {}
}

export const newPlainPostValidator = values => {
  const { title, tribe } = values

  if (!tribe || !tribe.id) return { tribe: '' }
  if (!title) return { title: '' } // this message is redudant
}
