const isValidPassword = password => {
  if (!password) return 'Password is mandatory'
  if (password.length < 6) return 'Password must be at least 6 characters'
  return true
}

const isSamePassword = (password, repeatPassword) => {
  if (password !== repeatPassword) return 'Passwords must match'
  return true
}

export const validatorPassword = (passwordField = 'password') => values => {
  const isValid = isValidPassword(values[passwordField])
  const isSame = isSamePassword(values[passwordField], values.repeatPassword)
  if (isValid !== true) return { [passwordField]: isValid }
  if (isSame !== true) return { repeatPassword: isSame }

  return {}
}

export const validatorSamePassword = (passwordField = 'password') => values => {
  const isSame = isSamePassword(values[passwordField], values.repeatPassword)
  if (isSame !== true) return { repeatPassword: isSame }

  return {}
}

export const validatorUsername = values => {
  if (!values.username) return { username: 'Username is mandatory' }
  return {}
}

export const validatorCountry = values => {
  if (!values.country) return { country: 'Country is mandatory' }
  return {}
}

export const validatorUsernameOrPhoneNumber = values => {
  if (!values.usernameOrPhoneNumber)
    return { usernameOrPhoneNumber: 'Username / Phone Number is mandatory' }
  return {}
}
