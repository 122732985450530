import React from 'react'
import { flatten, pathOr, isEmpty } from 'ramda'
import styled from 'styled-components/macro'
import { useQuery } from 'react-apollo'
import { filterAndSortDataSources } from '../../../../../state/selectors'
import { DataSourcesQuery } from '../../../../../state/queries'
import { Box, Graphic, Spacing, Text } from '../../../../../components/ui'

const EmptyBox = () => (
  <Box noShadow size="l" backgroundColor="purpleL3">
    <Text t2 center>
      Currently there are no available data sources for desired tribe
    </Text>
  </Box>
)

const TribeSourceCarousel = ({ dataSources, onDataSourceLinkedClick }) => {
  const { data, loading } = useQuery(DataSourcesQuery)

  if (isEmpty(dataSources)) {
    return <EmptyBox />
  }
  if (loading) {
    return null
  }

  const edges = pathOr([], ['allDataSources', 'edges'], data)
  const linkedDataSources = pathOr(
    [],
    ['currentUser', 'linkedDataSources'],
    data
  )

  const tribeSources = flatten(dataSources).map(dataSourceId =>
    edges.find(({ node }) => node.id === dataSourceId)
  )
  const dataSourcesToRender = filterAndSortDataSources(
    tribeSources,
    linkedDataSources
  ).filter(({ linked }) => linked) // temporary => just linked ones, until we extract logic for linkingDataSource from DataSourceSpace

  if (isEmpty(dataSourcesToRender)) return <EmptyBox />

  return (
    <Spacing size="s" direction="row" wrap>
      {dataSourcesToRender.map(dataSource => (
        <DataSourcePill
          key={dataSource.id}
          {...dataSource}
          onDataSourceLinkedClick={() => onDataSourceLinkedClick(dataSource)}
        />
      ))}
    </Spacing>
  )
}

const DataSourcePillWrapper = styled.div`
  cursor: pointer;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.padding.xs} ${theme.padding.s}`};
  background: ${({ theme }) => theme.color.lightBlue};
  margin-bottom: ${({ theme }) => theme.padding.m};
`

const DataSourcePill = ({ name, onDataSourceLinkedClick }) => (
  <DataSourcePillWrapper onClick={onDataSourceLinkedClick}>
    <Spacing direction="row" align="center">
      <Graphic name={'Source' + name.replace(/ /g, '')} size={32} color="red" />
      <Text h6 color="darkBlue">
        {name}
      </Text>
    </Spacing>
  </DataSourcePillWrapper>
)

export default TribeSourceCarousel
