import React from 'react'
import styled from 'styled-components'

import { includes } from 'ramda'
import { Spacing, TagGraphic, Text } from '../../ui'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.padding.m};
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.color.main : theme.color.purpleL2};
  border-radius: ${({ theme }) => theme.borderRadius.base};
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  cursor: pointer;
`

const CircleWrap = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.main : theme.color.purpleL2};
`
export const TraitCard = ({
  tag,
  onTagClick,
  selectedTag,
  alwaysVisibleTags
}) => {
  const { label, uniqueId } = tag
  const disabled = includes(uniqueId, alwaysVisibleTags)

  return (
    <Container onClick={onTagClick} selected={selectedTag || disabled}>
      <Spacing direction="row" align="center" justify="space-between">
        <Spacing direction="row" align="center">
          <TagGraphic size={32} uniqueId={uniqueId} />
          <Text t1 brandColor>
            {label}
          </Text>
        </Spacing>
        <CircleWrap selected={selectedTag || disabled} />
      </Spacing>
    </Container>
  )
}
