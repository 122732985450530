import { gql } from 'apollo-boost'
import { UsersDataSourceFragment } from '../fragments'

const mutation = gql`
  mutation LinkDataSource(
    $dataSourceId: String!
    $providerId: String!
    $accountId: String!
    $encKey: String!
    $origin: DataSourceOrigin!
  ) {
    linkDataSource(
      dataSourceId: $dataSourceId
      providerId: $providerId
      accountId: $accountId
      encKey: $encKey
      origin: $origin
    ) {
      success
      query {
        currentUser {
          id
          ...UsersDataSource
        }
      }
      errors {
        key
        message
      }
    }
  }
  ${UsersDataSourceFragment}
`

export default mutation
