import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Box, Spacing, Text } from '../../../../../components/ui'
import { TribeContext } from '../../../../../contexts/tribeContext'
import { getTickerData, getTickerHistoryData } from '../../../_utils'
import { TribeForumChart } from '../../'

const BoxWrapper = styled.div`
  padding: ${({ theme }) => `${theme.padding.xs} 0px`};
  cursor: pointer;
`

export const InvestorForumCard = ({ tribeId, forum }) => {
  const { push } = useHistory()
  const [forumData, setForumData] = useState(null)
  const [history, setHistory] = useState([])
  const { setSelectedFilterValue } = useContext(TribeContext)

  useEffect(() => {
    const getForumDataAsync = async () => {
      const { value, label } = forum

      const receivedHistory = await getTickerHistoryData({ ticker: value })
      setHistory(receivedHistory)
      setForumData(await getTickerData({ tribeId, ticker: value, label }))
    }

    getForumDataAsync()
  }, [forum, tribeId])

  if (!forumData) return <InvestorForumCardLoading />

  const { price } = forumData

  const areaData = history.map(({ close, date }) => ({
    value: close,
    time: date
  }))

  return (
    <BoxWrapper
      onClick={() => {
        setSelectedFilterValue(forum.value)
        push(`/tribes/${tribeId}/${forum.value}`)
      }}
    >
      <Box padding="l" bordered noShadow>
        <Spacing size="xs">
          <Text t4 color="dark1">
            {`${parseFloat(price).toFixed(2)} USD`}
          </Text>
          {areaData.length > 0 ? (
            <TribeForumChart
              width={120}
              height={50}
              scaleEnabled={false}
              areaData={areaData}
              handleScroll={false}
            />
          ) : (
            <div style={{ height: 50, width: 120 }} />
          )}
          <Text h3 bold>
            {forum.label}
          </Text>
        </Spacing>
      </Box>
    </BoxWrapper>
  )
}

const InvestorForumCardLoading = () => (
  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
    <Box padding="l" bordered noShadow>
      <Spacing size="l">
        <div
          style={{
            width: 75,
            height: 20,
            backgroundColor: '#DBD8FF',
            borderRadius: 5
          }}
        />

        <div
          style={{
            height: 30,
            width: 120,
            borderRadius: 5,
            backgroundColor: '#D9D9D9'
          }}
        />
        <div
          style={{
            width: 85,
            height: 15,
            backgroundColor: '#DBD8FF',
            borderRadius: 5
          }}
        />
      </Spacing>
    </Box>
  </div>
)
