import { useEffect, useState } from 'react'
import { groupBy, isEmpty, isNil, prop } from 'ramda'
import ndlProvider from '../providers/ndlProvider'

export const useDataStream = ({ accountIds, dataQueries, isPublicRead }) => {
  const [streamCards, setStreamCards] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  async function getStreamCards() {
    try {
      setLoading(true)
      const fetchedStreamCards = await ndlProvider.readAccounts({
        accountIds,
        dataQueries,
        isPublicRead
      })

      const mergedStreamCards = mergeStreamsFromAccounts(fetchedStreamCards)

      const allStreams = groupStreams(mergedStreamCards)

      setStreamCards({ ...streamCards, ...allStreams })
      setLoading(false)
      setError(false)
    } catch (e) {
      console.log({ accountIds }, e)
      setLoading(false)
      setError('Failed to load Stream Cards')
    }
  }

  useEffect(() => {
    if (
      isNil(dataQueries) ||
      isEmpty(dataQueries) ||
      isNil(dataQueries[0]) ||
      isNil(accountIds) ||
      isEmpty(accountIds)
    ) {
      return
    }

    getStreamCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIds])

  return { streamCards, error, loading, refetch: getStreamCards }
}

const mergeStreamsFromAccounts = accountStreams => {
  return accountStreams.reduce(
    (acc, accountStream) => [...acc, ...accountStream.response],
    []
  )
}

const groupStreams = streams => {
  const grouped = groupBy(prop('dataStreamId'), streams)

  return Object.keys(grouped).reduce((acc, dataStreamId) => {
    const existingStream = acc[dataStreamId] || []
    const stream = grouped[dataStreamId]

    return {
      ...acc,
      [dataStreamId]: [
        ...existingStream,
        ...stream.reduce(
          (streamAcc, { objectTypeId, results, ...rest }) => [
            ...streamAcc,
            ...results.map(data => ({
              data,
              objectTypeId,
              ...rest
            }))
          ],
          []
        )
      ]
    }
  }, {})
}
