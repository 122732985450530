import React from 'react'
import styled from 'styled-components'
import { Button, Text } from '../../../components/ui'
import { useModal } from '../../../hooks'
import { DataStream } from './DataStream'
import { ProfileSection } from './ProfileSection'

const TextWrap = styled(Text)`
  cursor: pointer;
`

export const DataStreamSection = ({
  isOwnProfile,
  member,
  isEditingProfile,
  dataSource
}) => {
  const { openModal } = useModal()

  const onEditDataStream = () => openModal('DataStreamOptIn')

  return (
    <ProfileSection
      isOwnProfile={isOwnProfile}
      label="Data stream"
      emptyLabel="Member has not made any Streams public"
      manageAction={
        <TextWrap h6 color="main" onClick={onEditDataStream}>
          Edit
        </TextWrap>
      }
      emptyAction={
        <Button onClick={onEditDataStream}>Pick public streams</Button>
      }
    >
      {member.publicProfileSourceOptIns &&
        member.publicProfileSourceOptIns.length > 0 && (
          <DataStream
            member={member}
            isEditingProfile={isEditingProfile}
            dataSource={dataSource.node}
          />
        )}
    </ProfileSection>
  )
}
