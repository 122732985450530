import { useQuery } from '@apollo/react-hooks'
import { pipe } from 'ramda'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import {
  Text,
  Spacing,
  Button,
  Input,
  CountryPicker,
  Notice,
  SyncStateIcon
} from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import { UserQuery } from '../../../state/queries'
import { validatorSamePassword } from '../../../util/validators'

const ButtonWrap = styled(Button)`
  height: 41px;
  padding: 10px 25px;
`

const NotificationsWrapper = styled.div`
  flex: 1;
`

const SectionWrap = styled.div`
  padding: 25px;
  border-radius: ${({ theme }) => theme.borderRadius.base};
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
`
const AccountInfoInner = ({ updateUser }) => {
  const [successful, setSuccessful] = useState(false)
  const { data: { currentUser } = {}, loading } = useQuery(UserQuery)
  if (loading) {
    return null
  }
  const { username, phoneNumber, country, hasPassword } = currentUser

  const onSubmit = (variables, form) => {
    return updateUser({ variables }).then(({ userErrors, data }) => {
      if (!userErrors && data && data.updateUser.currentUser) {
        setTimeout(() => form.reset(), 500)
        return setSuccessful(true)
      }
      setSuccessful(false)
      return userErrors
    })
  }
  return (
    <NotificationsWrapper>
      <Form
        initialValues={{ username, phoneNumber, country }}
        onSubmit={onSubmit}
        validate={validatorSamePassword('newPassword')}
      >
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <Spacing size="xl">
              <Spacing direction="row" justify="space-between" align="center">
                <Text h2>Account Information</Text>
                <Spacing direction="row" size="l">
                  {successful && pristine && (
                    <SyncStateIcon syncState="completed" size={42} />
                  )}
                  <ButtonWrap disabled={pristine} onClick={handleSubmit}>
                    Save Changes
                  </ButtonWrap>
                </Spacing>
              </Spacing>
              <Spacing size="xxl">
                <Spacing>
                  <Text t0>Country</Text>
                  <Field component={CountryPicker} name="country" />
                </Spacing>
                <SectionWrap>
                  <Spacing>
                    <Text t0>Account Details</Text>
                    <Spacing size="l">
                      <Field
                        name="username"
                        component={Input}
                        label="Username"
                        disabled={submitting}
                        block
                      />
                      <Field
                        name="phoneNumber"
                        component={Input}
                        label="Phone Number"
                        disabled={submitting}
                        keyboardType="phone-pad"
                        block
                      />
                    </Spacing>
                  </Spacing>
                </SectionWrap>

                <SectionWrap>
                  <Spacing>
                    <Text t0>Change Password</Text>
                    <Spacing size="l">
                      {hasPassword ? (
                        <Field
                          name="oldPassword"
                          component={Input}
                          label="Old Password"
                          disabled={submitting}
                          securedTextEntry
                          block
                        />
                      ) : (
                        <Notice>
                          You have registered via a 3rd party service, so your
                          password has never been set. You can set your new
                          password below.
                        </Notice>
                      )}

                      <Field
                        name="newPassword"
                        component={Input}
                        label="New Password"
                        disabled={submitting}
                        securedTextEntry
                        block
                      />
                      <Field
                        name="repeatPassword"
                        component={Input}
                        label="Confirm New Password"
                        disabled={submitting}
                        securedTextEntry
                        block
                      />
                    </Spacing>
                  </Spacing>
                </SectionWrap>
              </Spacing>
            </Spacing>
          )
        }}
      </Form>
    </NotificationsWrapper>
  )
}

export const AccountInfo = pipe(withMutation('updateUser'))(AccountInfoInner)
