import { filter, map, path, pipe, values } from 'ramda'
import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, Text } from '../../../../components/ui'
import { DataInsightsCard } from '../components'

const InsightsWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -25px;
`

export const DataInsights = ({ streamCards, dataSource }) => {
  const streamCardsValues = values(streamCards)
  const labeledStreamCards = pipe(
    filter(path([0, 'label'])),
    map(([{ label, data }]) => {
      const value = data[Object.keys(data)[0]]
      return { label, value }
    })
  )(streamCardsValues)

  const customStreamCards = filter(path([0, 'custom']), streamCardsValues)
  const mergedStreamCards = [...labeledStreamCards, ...customStreamCards]

  if (!mergedStreamCards.length) return null

  return (
    <Spacing size="xl">
      <Text h5>Your data insights</Text>
      <InsightsWrap>
        {mergedStreamCards.map((card, i) => (
          <DataInsightsCard key={i} dataSource={dataSource} card={card} />
        ))}
      </InsightsWrap>
    </Spacing>
  )
}
