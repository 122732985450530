import { gql } from 'apollo-boost'

export default gql`
  mutation LogIn($username: String!, $password: String!) {
    logIn(username: $username, password: $password) {
      token
      user {
        id
        username
        email
      }
      errors {
        key
        message
      }
    }
  }
`
