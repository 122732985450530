import gql from 'graphql-tag'

import {
  OpportunityFragment,
  OptInFragment,
  DataSourceFragment,
  UsersDataSourceFragment,
  TargetAudienceBucketsFragment
} from '../fragments'

export const RedeemableOpportunitiesQuery = gql`
  query RedeemableOpportunitiesQuery($type: String, $tribeId: String) {
    allOpportunities(
      first: 100
      redeemed: false
      type: $type
      state: [published]
      categories: []
      tribeId: $tribeId
    ) {
      edges {
        node {
          id
          ...Opportunity
        }
      }
    }

    allOptIns(redeemable: true) {
      id
      ...OptIn
    }

    currentUser {
      id
      member {
        id
        totalUsd
        totalUbdi
      }
    }
  }

  ${OpportunityFragment}
  ${OptInFragment}
`

export const EligibleOpportunitiesQuery = gql`
  query EligibleOpportunitiesQuery(
    $opportunityIds: [String!]!
    $shortIds: [String!]!
    $accounts: [LinkedAccountInput!]
  ) {
    allEligibleOpportunities(
      opportunityIds: $opportunityIds
      shortIds: $shortIds
      accounts: $accounts
    ) {
      id
      ...Opportunity
    }
  }

  ${OpportunityFragment}
`

RedeemableOpportunitiesQuery.possibleVariants = {
  earn: { type: 'Earn' },
  donate: { type: 'Donate' },
  story: { type: 'Story' },
  tribe: { type: 'Tribe' }
}

export const RedeemedOpportunitiesQuery = gql`
  query RedeemedOpportunities {
    allOpportunities(redeemed: true, categories: []) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const DataSourcesQuery = gql`
  query AllDataSources {
    allDataSources {
      edges {
        node {
          id
          ...DataSource
        }
      }
    }

    currentUser {
      id
      ...UsersDataSource
    }
  }
  ${DataSourceFragment}
  ${UsersDataSourceFragment}
`

export const DataSourceServiceGroupsQuery = gql`
  query DataSourceServiceGroups {
    dataSourceServiceGroups {
      id
      name
    }
  }
`

export const TargetBucketsOpportunitiesQuery = gql`
  query TargetBucketsOpportunities {
    allOpportunities(
      first: 100
      redeemed: false
      state: [published]
      categories: []
    ) {
      edges {
        node {
          id
          targetAudienceBuckets {
            ...TargetAudienceBucket
          }
        }
      }
    }
  }

  ${TargetAudienceBucketsFragment}
`

export const OpportunityInputDataSources = gql`
  query OpportunityInputDataSources($id: String!) {
    opportunity(id: $id) {
      id
      name
      inputDataSources {
        query {
          id
          objectTypeId
          dataSourceIds
          sql
        }
      }
    }
  }
`

export const OpportunityQuery = gql`
  query OpportunityQuery($id: String!) {
    opportunity(id: $id) {
      id
      ...Opportunity
    }
  }

  ${OpportunityFragment}
`
