import React from 'react'
import styled from 'styled-components/macro'

import { Countries } from '../../../constants'
import { SearchDropdown } from '../../ui'
import { getSuggestions } from '../../../util'

const SearchDropdownCustom = styled(SearchDropdown)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.purpleL1};
  border-radius: 7px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-width: 1px;
`

export const CountryPicker = props => {
  const options = Object.keys(Countries.list).map(k => Countries.list[k])

  return (
    <SearchDropdownCustom
      {...props}
      label="Search Country"
      graphic="Search"
      options={options}
      getSuggestions={getSuggestions}
    />
  )
}
