import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { pipe } from 'ramda'

import { withMutation } from '../../../components/wrappers'
import { getOptimisticCommentLike } from '../../../state/mutations/LikeCommunityPollComment'
import { Spacing, Text } from '../../../components/ui'
import { getDistanceTime } from '../../../util/formatters/format_date'
import { InlineTags } from '../../Home/components/CommunityPosts/components'
import { DataStreamCard } from '../../DataBank/DataStream/components'

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.l}`};
`

const Touchable = styled.div`
  cursor: pointer;
`

const CardWrap = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => theme.color.white};
  border: ${({ theme, isSelected }) =>
    !isSelected
      ? `1px solid ${theme.color.purpleL2}`
      : `2px solid ${theme.color.purpleL1}`};
`

const ContentWrap = styled.div`
  padding: ${({ theme }) => theme.padding.l};
`

const GifImageWrapper = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
`

const StyledGifImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Dot = () => (
  <Text h7 color="dark1">
    ·
  </Text>
)

const CommentCard = ({
  onCommentReply,
  communityPollId,
  parentCommentId,
  isSelected,
  likeCommunityPollComment,
  comment,
  disabled
}) => {
  const {
    id: commentId,
    tags,
    colors,
    publicTagCategories,
    liked,
    likes,
    text,
    gifUrl,
    createdAt,
    postData,
    memberId,
    tribeId,
    publicMember
  } = comment

  const likeTextColor = liked ? 'dark' : 'main'

  const onLike = () => {
    const variables = parentCommentId
      ? {
          id: communityPollId,
          commentId: parentCommentId,
          replyCommentId: commentId
        }
      : {
          id: communityPollId,
          commentId
        }

    return likeCommunityPollComment({
      variables,
      optimisticResponse: getOptimisticCommentLike({
        comment
      })
    })
  }

  const renderCardFooter = () => (
    <FooterWrapper>
      <Spacing direction="row" size="s">
        <Touchable onClick={onLike}>
          <Text h7 color={likeTextColor} bold>
            {liked ? 'Liked' : 'Like'}
          </Text>
        </Touchable>

        <Spacing direction="row" size="s">
          <Dot />

          {!disabled && (
            <Spacing direction="row" size="s">
              <Touchable
                onClick={onCommentReply}
                style={{ alignSelf: 'flex-end' }}
              >
                <Text h7 color="main" bold>
                  Reply
                </Text>
              </Touchable>
              <Dot />
            </Spacing>
          )}

          <Text h7 color="grey" bold>
            {getDistanceTime({ date: createdAt })}
          </Text>
        </Spacing>

        <Text h7 color="dark1" bold></Text>
      </Spacing>
      <Text h7 color="dark1" bold>
        {likes} likes
      </Text>
    </FooterWrapper>
  )

  return (
    <CardWrap isSelected={isSelected}>
      <ContentWrap>
        <Spacing>
          {!disabled && (
            <Link
              style={{ color: 'inherit' }}
              to={`/profile/${memberId}/${tribeId}`}
            >
              <InlineTags
                avatarId={commentId}
                colors={colors}
                tags={tags}
                publicTagCategories={publicTagCategories}
                parentCommentId={parentCommentId}
                member={publicMember}
              />
            </Link>
          )}
          {gifUrl && (
            <GifImageWrapper>
              <StyledGifImage src={gifUrl} />
            </GifImageWrapper>
          )}
          {postData && (
            <DataStreamCard
              key
              objectTypeId={postData.objectTypeId}
              dataSourceId={postData.dataSourceId}
              data={JSON.parse(postData.data)}
            />
          )}
          {text && (
            <Text t1 brandBlack>
              {text}
            </Text>
          )}
        </Spacing>
      </ContentWrap>
      {renderCardFooter()}
    </CardWrap>
  )
}

export default pipe(withMutation('likeCommunityPollComment'))(CommentCard)
