import React from 'react'
import styled from 'styled-components/macro'

import { Modal, Spacing, Button, Graphic, Text } from '../../components/ui'
import theme from '../../theme'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

export const SyncAccounts = ({ onDismiss }) => {
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Sync Accounts"
    >
      {({ onDismiss }) => (
        <ModalWrap>
          <Spacing align="center" size="xl">
            <Graphic name="Checkmark" size={50} color={theme.color.green} />
            <Text h3>Done, your sources have been synced with mobile!</Text>
          </Spacing>
          <Modal.Footer>
            <Spacing direction="row" justify="flex-end" align="center">
              <Button onClick={onDismiss}>Done</Button>
            </Spacing>
          </Modal.Footer>
        </ModalWrap>
      )}
    </Modal>
  )
}
