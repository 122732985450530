import React from 'react'
import { TribeForums } from '../../../../constants'

import { InvestorForumCard, RealInvestorForumCard } from './components'

export const TribeForumCard = props => {
  const { tribeId } = props

  switch (tribeId) {
    case TribeForums.Investors:
      return <InvestorForumCard {...props} />

    case TribeForums.RealInvestors:
      return <RealInvestorForumCard {...props} />

    default:
      return null
  }
}
