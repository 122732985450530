import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { Button, Graphic, Modal, Spacing, Text } from '../../components/ui'
import { useDataSourcesStatuses } from '../../hooks'
import {
  DataSourceCard,
  DataSourceSpace
} from '../../screens/DataBank/components'
import { TraitsEntry } from '../../components/TraitsEntry'
import { getSelectedDataSource } from '../../state/selectors'
import { useQuery } from '@apollo/react-hooks'
import { OpportunityQuery } from '../../state/queries'
import theme from '../../theme'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

export const MissingSources = ({ onDismiss, params: { opportunityId } }) => {
  const { data: { opportunity } = {} } = useQuery(OpportunityQuery, {
    variables: { id: opportunityId }
  })

  const { missingInputDataSources, missingTags } = opportunity

  const {
    sourceStatuses,
    setSourceStatus,
    onSync,
    sources,
    onCompletedLink
  } = useDataSourcesStatuses({
    refetchQueries: [
      {
        query: OpportunityQuery,
        variables: { id: opportunity.id }
      }
    ]
  })

  const [selectedDataSourceId, setSelectedDataSourceId] = useState()

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Unlock the study"
    >
      {() => (
        <ModalWrap>
          <Spacing size="xl">
            {missingInputDataSources.length === 0 && missingTags.length === 0 && (
              <Spacing align="center" size="xl">
                <Graphic name="Checkmark" size={50} color={theme.color.green} />
                <Text h3>
                  Congrats, you did it all! Now go and Redeem your opportunity!
                </Text>
                <Button onClick={onDismiss} outline>
                  Dismiss
                </Button>
              </Spacing>
            )}
            {missingInputDataSources.length > 0 && (
              <Spacing size="s">
                <Text t2>
                  In order to access this study, you need to link additional
                  sources
                </Text>
                {missingInputDataSources.map(
                  ({ query: { dataSourceIds, objectType } }) => (
                    <Spacing>
                      <Text t2 bold>
                        For {objectType.name}, link one of:
                      </Text>
                      <Spacing>
                        {dataSourceIds.map(sourceId => {
                          const dataSource = getSelectedDataSource(
                            sources,
                            sourceId
                          )

                          if (!dataSource) return null

                          if (selectedDataSourceId === sourceId) {
                            return (
                              <DataSourceSpace
                                dataSource={dataSource}
                                onCompletedLink={onCompletedLink}
                                status={sourceStatuses[sourceId]}
                                setSourceStatus={setSourceStatus}
                                onSync={onSync}
                                autoStartLink
                              />
                            )
                          }

                          return (
                            <DataSourceCard
                              {...dataSource}
                              status={sourceStatuses[sourceId]}
                              setSelectedSourceId={setSelectedDataSourceId}
                            />
                          )
                        })}
                      </Spacing>
                    </Spacing>
                  )
                )}
              </Spacing>
            )}
            {missingTags.length > 0 && (
              <Spacing>
                <Text t2 bold>
                  You need to enter these tags:
                </Text>
                <TraitsEntry
                  disableCategories
                  showOnlyUniqueIds={missingTags}
                />
              </Spacing>
            )}
          </Spacing>

          <Modal.Footer />
        </ModalWrap>
      )}
    </Modal>
  )
}
