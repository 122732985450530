import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import firebase from 'firebase/app'
import 'firebase/messaging'

const { REACT_APP_SENTRY_DSN } = process.env

// Initialize Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyD0MH7592402UtwS0XxzET0Syt46dbt2fA',
  authDomain: 'ubdi-pushnotifications.firebaseapp.com',
  databaseURL: 'https://ubdi-pushnotifications.firebaseio.com',
  projectId: 'ubdi-pushnotifications',
  storageBucket: 'ubdi-pushnotifications.appspot.com',
  messagingSenderId: '766171766594',
  appId: '1:766171766594:web:29cca2b97a9179ef3072db',
  measurementId: 'G-XTNKZ3N15B'
})

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope)
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err)
    })
}
