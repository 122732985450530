import axios from 'axios'
import { format, isBefore, isSameDay, parse, parseISO } from 'date-fns'
import { pathOr } from 'ramda'

export const UBDI_TRIBE_ID = 'system_groups_ubdi_0'

export const getTickerHistoryData = async ({ ticker }) => {
  const { data: candles } = await axios.get(
    'https://api.binance.com/api/v3/klines',
    {
      params: {
        symbol: ticker,
        interval: '1d',
        limit: 100
      }
    }
  )

  if (candles && candles.length > 0) {
    return candles.map(candle => {
      const close = candle[4]
      const closeTime = candle[6]

      return {
        date: format(new Date(closeTime), 'yyyy-M-dd'),
        close: parseFloat(close)
      }
    })
  } else return []
}

export const getTickerData = async ({ ticker }) => {
  const {
    data: { askPrice: price, priceChangePercent: percentage }
  } = await axios.get('https://api.binance.com/api/v3/ticker/24hr', {
    params: {
      symbol: ticker
    }
  })

  return { price, percentage }
}

const getEndDayBalance = dayTrades =>
  dayTrades.reduce((acc, trade) => {
    const { cumulativequantity, side } = trade
    return parseFloat(
      acc + (side === 'buy' ? cumulativequantity : cumulativequantity * -1)
    )
  }, 0)

export const getDayHoldings = ({ dates, trades }) => {
  const beforeStartDateHoldingTrades = dates[0]
    ? trades.filter(({ date }) => {
        return isBefore(
          parseISO(date),
          parse(dates[0].date, 'yyyy-M-d', new Date())
        )
      })
    : []

  const startingBalance = getEndDayBalance(beforeStartDateHoldingTrades)

  return dates.reduce((acc, { date }, i) => {
    const tradesOnDay = trades.filter(trade => {
      return isSameDay(parseISO(trade.date), parseISO(date))
    })

    const endDayBalance = getEndDayBalance(tradesOnDay)
    const startingHolding = pathOr(startingBalance, [i - 1, 'value'], acc)

    return [
      ...acc,
      {
        time: date,
        value: Math.abs((startingHolding || 0) + (endDayBalance || 0))
      }
    ]
  }, [])
}
