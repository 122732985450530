import React from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { Links } from '../../constants'
import { Back, Button, Spacing, Text } from '../../components/ui'

import { OpportunityQuery } from '../../state/queries/OpportunitiesScreenQueries'
import { OpportunityCard } from '../Earn/components/OpportunityCard'
import { CompensationDetails } from './components/CompensationDetails'
import { DetailsBoxes } from './components/DetailsBoxes'
import { InsightsInfo } from './components/InsightsInfo'
import { EmptyScreenTemplate } from '../Home/components/EmptyScreenTemplate'
import { isEmpty } from 'ramda'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.padding.xl};
`

const LeftPart = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.padding.xl} ${theme.padding.l} ${theme.padding.xl} 75px`};
`

const RightPart = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.padding.xl} 75px ${theme.padding.xl} ${theme.padding.xl}`};
`

const Wrap = styled.div`
  border-top: 1px solid rgba(111, 98, 255, 0.1);
  padding-top: ${({ theme }) => theme.padding.xl};
`

const LinkWrap = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.grey};
  &:hover {
    text-decoration: underline;
  }
`

export const EarnDetailsScreen = ({
  match: { params: { opportunityId } } = {}
}) => {
  const { data: { opportunity } = {}, loading } = useQuery(OpportunityQuery, {
    variables: { id: opportunityId }
  })

  if (loading) return null

  if (!opportunity || isEmpty(opportunity)) {
    return <EmptyScreenTemplate />
  }
  return (
    <Container>
      <LeftPart>
        <Spacing size="xl" stretched>
          <Spacing direction="row" size="l" align="center">
            <Back background="white" borderColor={theme.color.main} />
            <Text h2>Study Overview</Text>
          </Spacing>
          <Spacing size="xl">
            <OpportunityCard
              {...opportunity}
              gradientBg={theme.gradient.gradient3}
              hideFeeInfo
            />
            <InsightsInfo opportunity={opportunity} />
            <DetailsBoxes opportunity={opportunity} />
          </Spacing>
        </Spacing>
      </LeftPart>

      <RightPart>
        <Spacing size="xl" stretched>
          <Text h2>
            {opportunity.isJoinable ? 'Complete Study' : 'Unlock Study'}
          </Text>
          <CompensationDetails opportunity={opportunity} />
          <Wrap>
            <Spacing size="l" align="center">
              <Spacing direction="row" size="l" justify="center">
                <Button
                  outline
                  onClick={() => window.open(Links.PrivacyPolicy)}
                >
                  <Text t2 color="main">
                    Privacy Policy
                  </Text>
                </Button>
                <Button
                  outline
                  onClick={() => window.open(Links.TermsOfService)}
                >
                  <Text t2 color="main">
                    Terms & Conditions
                  </Text>
                </Button>
              </Spacing>
              <Text t3 color="grey" center>
                Legal Statement. For More information please go to{' '}
                <LinkWrap
                  t3
                  color="grey"
                  center
                  onClick={() => window.open(Links.UBDI)}
                >
                  www.ubdi.com
                </LinkWrap>
              </Text>
            </Spacing>
          </Wrap>
        </Spacing>
      </RightPart>
    </Container>
  )
}
