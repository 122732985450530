import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { Spacing, Text } from '../../../../components/ui'
import { DataStreamCard, LoadingBlanket } from '../components'
import { isEmpty } from 'ramda'

export const DataStreamCards = ({
  data,
  dataSourceId,
  limit = 10,
  onShare,
  parentRef
}) => {
  const [page, setPage] = useState(1)

  const dataLimit = page * limit

  return (
    <Spacing size="xl">
      <Text h5>Your data stream</Text>
      <InfiniteScroll
        key="datastream"
        hasMore={dataLimit < data.length}
        loadMore={setPage}
        pageStart={1}
        useWindow={!parentRef}
        loader={
          <div style={{ paddingTop: 10 }}>
            <LoadingBlanket key={page} />
          </div>
        }
        {...(parentRef && { getScrollParent: () => parentRef.current })}
      >
        <Spacing size="s">
          {data
            .slice(0, dataLimit)
            .map(({ objectTypeId, data }, i) =>
              isEmpty(data) ? null : (
                <DataStreamCard
                  key={`data-stream-card-${data.id}-${i}`}
                  dataSourceId={dataSourceId}
                  objectTypeId={objectTypeId}
                  data={data}
                  onShare={onShare}
                />
              )
            )}
        </Spacing>
      </InfiniteScroll>
    </Spacing>
  )
}
