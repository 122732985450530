import { clamp } from 'ramda'

const potentialVariations = {
  digits: /\d/,
  lower: /[a-z]/,
  upper: /[A-Z]/,
  nonWords: /\W/,
}

const levels = [
  {
    color: 'transparent',
    level: 0,
  },
  {
    color: '#FF7373',
    level: 1,
  },
  {
    color: '#FCC14F',
    level: 2,
  },
  {
    color: '#3CEC8D',
    level: 3,
  },
  {
    color: '#6F62FF',
    level: 4,
  },
]

const calculateLevel = (score, levels) => {
  const normalizedLength = levels.length - 1
  const normalizedScore = score / 100
  const normalizedIndex = clamp(
    0,
    normalizedLength,
    Math.floor(normalizedLength * normalizedScore)
  )

  return levels[normalizedIndex]
}

const calculateScore = (
  password,
  minLength = 7,
  variations = potentialVariations
) => {
  let score = 0
  let variationCount = 0
  const letters = {}

  if (!password || password.length < minLength) {
    return score
  }

  /* Score unique letters until 5 repetitions */
  for (let i = 0; i < password.length; i += 1) {
    letters[password[i]] = (letters[password[i]] || 0) + 1
    score += 5.0 / letters[password[i]]
  }

  /* Score character variation */
  Object.keys(variations).forEach((variation) => {
    const variationCheck = variations[variation].test(password)
    variationCount += variationCheck === true ? 1 : 0
  })
  score += (variationCount - 1) * 10

  return score
}

export const calculatePasswordStrength = (password) => {
  const score = calculateScore(password)
  return calculateLevel(score, levels)
}
