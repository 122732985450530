import React from 'react'
import { Box, Button, Spacing, Text } from '../../../components/ui'
import { path, pathOr } from 'ramda'
import axios from 'axios'
import {
  getUsersGender,
  getUsersBirthYear,
  getUsersMaritalStatus,
  getEmploymentInfo,
  getEducationLevel,
  getUsersEthnicity,
  getUsersIncome,
  getUsersLocationData,
  getUsersLanguage,
  enforceStrings
} from '../../../util/pollfish/pollfishMapping'
import { Links } from '../../../constants'
import pollfishLogo from '../images/pollfish.png'
import styled from 'styled-components'
import { withMutation } from '../../../components/wrappers'

const RoundButton = styled(Button)`
  padding: 8px 20px;
  border-radius: 40px;
`

const PurpleBox = styled.div`
  background-color: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.l};
  border-radius: ${({ theme }) => theme.borderRadius.base};
`

export const ExternalSurveys = withMutation('updateMember')(
  ({ userData, updateMember }) => {
    const pollfishConsent = pathOr(
      false,
      ['currentUser', 'member', 'pollfishConsent'],
      userData
    )

    return (
      <Spacing stretched>
        <Text h6>External paid surveys</Text>
        <Box>
          <Spacing direction="row" align="center" justify="space-between">
            <Spacing direction="row" align="center">
              <img src={pollfishLogo} alt="Logo" width={34} />
              <Text h4>Pollfish</Text>
            </Spacing>
            {pollfishConsent ? (
              <RoundButton
                block
                outline
                size="small"
                onClick={async () =>
                  window.open(await generatePollfishUrl({ userData }), '_blank')
                }
              >
                View
              </RoundButton>
            ) : (
              <RoundButton
                block
                size="small"
                onClick={() =>
                  updateMember({ variables: { pollfishConsent: true } })
                }
              >
                Connect
              </RoundButton>
            )}
          </Spacing>
        </Box>
        {!pollfishConsent && (
          <PurpleBox>
            <Text t3 center>
              If you opt in, UBDI will share the traits you've entered like
              gender, age, etc. (not data from sources) with Pollfish to help
              match you with their studies. Click view to see what studies await
              you today.
            </Text>
          </PurpleBox>
        )}
      </Spacing>
    )
  }
)

const generatePollfishUrl = async ({ userData }) => {
  const memberId = path(['currentUser', 'member', 'id'], userData)
  const email = path(['currentUser', 'email'], userData)
  const ip = (await axios.get('https://api.ipify.org/')).data
  const tags = path(['currentUser', 'member', 'tags'], userData).reduce(
    (acc, tag) => {
      acc[tag.uniqueId] = tag.values
      return acc
    },
    {}
  )

  const pollfishParams = enforceStrings({
    api_key: process.env.REACT_APP_POLLFISH_API_KEY,
    always_return_content: true,
    device_id: memberId,
    encryption: 'NONE',
    ip,
    offerwall: true,
    os: 3,
    timestamp: new Date().getTime(),
    version: 7,
    gender: getUsersGender(tags.general_gender),
    year_of_birth: getUsersBirthYear(tags.general_age),
    marital_status: getUsersMaritalStatus(tags.general_maritalStatus),
    education: getEducationLevel(tags.general_educationLevel),
    employment: getEmploymentInfo(tags.general_employment),
    race: getUsersEthnicity(tags.general_ethnicity),
    income: getUsersIncome(tags.general_incomeLevel),
    email,
    postal_data: getUsersLocationData({
      postalCode: tags.general_location_zip,
      country: tags.general_location_country
    }),
    spoken_languages: getUsersLanguage(tags.general_bilinguality)
    // debug: true
    // ^ Add when testing on local/staging
  })

  return `${Links.Pollfish}&json=${JSON.stringify(pollfishParams)}`
}
