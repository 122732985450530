import { isEmpty } from 'ramda'
import React from 'react'
import { Spacing } from '../../../../components/ui'
import { useDataStream } from '../../../../hooks/useDataStream'
import { DataStreamWrapper } from '../../components/DataStreamWrapper'
import {
  LoadingBlanket,
  EmptyState,
  DataStreamCards,
  DataInsights,
  DataStreamHeader
} from '../components'
import { hasDateKey } from '../util'
import { STREAMS_SCHEMA, ALL_ITEMS_STREAM } from './_config'

const StandardStream = ({
  accountIds,
  dataSource,
  hideInsights,
  onShare,
  ...props
}) => {
  const dataQueries = STREAMS_SCHEMA[dataSource.id]

  const { streamCards, loading, refetch } = useDataStream({
    accountIds,
    dataQueries
  })

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <LoadingBlanket text="Please wait while your data is being synced" />
          <div style={{ flex: 1 }}></div>
        </>
      )
    }

    const contentCards = streamCards[ALL_ITEMS_STREAM] || []

    if (getData(contentCards).length === 0) {
      return <EmptyState refetch={refetch} />
    }

    return hideInsights ? (
      <DataStreamCards
        data={getData(contentCards)}
        dataSourceId={dataSource.id}
        onShare={onShare}
        {...props}
      />
    ) : (
      <DataStreamWrapper>
        <Spacing>
          <DataInsights streamCards={streamCards} dataSource={dataSource} />
          <DataStreamCards
            data={getData(contentCards)}
            dataSourceId={dataSource.id}
            onShare={onShare}
            {...props}
          />
        </Spacing>
      </DataStreamWrapper>
    )
  }

  const getData = contentCards => {
    if (
      contentCards[0] &&
      !isEmpty(contentCards[0].data) &&
      hasDateKey(contentCards[0].data)
    ) {
      return contentCards
    }
    return []
  }

  return hideInsights ? (
    renderContent()
  ) : (
    <Spacing>
      <DataStreamHeader dataSource={dataSource} />
      {renderContent()}
    </Spacing>
  )
}

export default StandardStream
