import React from 'react'
import styled from 'styled-components/macro'
import { Spacing, Text } from '../../../../components/ui'
import { getCurrencySymbol } from '../streams/util/financeHelpers'

const CardWrap = styled.div`
  background-color: ${props => props.color};
  padding: 20px;
  border-radius: 10px;
`
export const CategoryCard = ({ category }) => (
  <CardWrap color={category.color}>
    <Spacing size="s" alignItems="flex-start">
      <Text h6 color="white">
        {category.label}
      </Text>
      {renderValue({ category })}
    </Spacing>
  </CardWrap>
)

const renderValue = ({ category }) => (
  <Text h2 color="white">
    {!category.currency
      ? 0
      : getCurrencySymbol(
          Math.round(category.totalAmount || 0),
          category.currency
        )}
  </Text>
)
