import React, { forwardRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import TextareaAutosize from 'react-textarea-autosize'

import { Graphic } from '../../ui'
import { withFieldError } from '../../wrappers'

import {
  getBackgroundColor,
  getBorder,
  getHoveredBackgroundColor
} from '../../../util/ui'

const InputWrap = styled.div`
  display: inline-flex;
  width: ${({ block }) => (block ? '100%' : '320px')};
  border: ${getBorder};
  position: relative;
  align-items: center;
  border-radius: 7px;
  background-color: ${props => getBackgroundColor(props)};

  padding: ${({ theme, hasGraphic }) =>
    `0px ${theme.padding[hasGraphic ? 'xxl' : 'l']} 0px ${theme.padding.l}`};

  &:hover {
    background-color: ${getHoveredBackgroundColor};
  }

  ${props => props.isErrored && `border: 1px solid ${props.theme.color.red}`}

  ${props => props.success && `border: 1px solid ${props.theme.color.green}`}
`

const InputText = styled.input`
  width: 100%;
  outline: none;
  border: none !important;

  resize: none;

  background-color: transparent;
  border: transparent;
  transition: all 0.1s ease-in-out;

  ${({ theme }) => theme.text.t2}

  color: ${({ theme }) => theme.color.dark};
  padding: ${({ placeholder }) => (placeholder ? `0px` : `25px 0px 5px 0px`)};
  border-radius: ${({ theme, placeholder }) =>
    placeholder ? `0px` : theme.borderRadius.base};

  ::placeholder {
    ${({ placeholder, theme }) =>
      placeholder
        ? css`
            opacity: 1;
            color: ${theme.color.grey};
          `
        : css`
            opacity: 0;
          `}
  }

  ${props =>
    isLabelShrinked(props) &&
    !props.placeholder &&
    css`
      padding: 25px 0px 5px 0px;

      ::placeholder {
        opacity: 1;
        color: ${props.theme.color.purpleL2};
      }
    `}

  ${props =>
    props.clean &&
    css`
      padding: 0;
      background-color: transparent;
      border: none;
      ${props.theme.text.h1}
    `}
`

const Label = styled.label`
  position: absolute;
  top: ${props => (isLabelShrinked(props) ? `0px` : `15px`)};
  pointer-events: none;

  color: ${({ theme }) => theme.color.dark};
  ${({ disabled }) => disabled && `opacity: 0.5;`}
  ${({ theme }) => theme.text.h6};

  ${props =>
    isLabelShrinked(props) &&
    css`
      ${props.theme.text.label}
      padding-top: 5px;
    `}

  ${props =>
    (!!props.input.value || props.input.value === 0) &&
    `color: ${props.theme.color.main};`}
  ${props => props.isErrored && `color: ${props.theme.color.red}`}
  ${props => props.success && `color: ${props.theme.color.green}`}

  transition: all 0.1s ease-in-out;
`

const ContentWrap = styled.div`
  position: relative;
  width: 100%;
`

const GraphicWrapRight = styled.div`
  padding-left: 10px;
`

const GraphicWrapLeft = styled.div`
  padding-right: 10px;
`

const InputView = forwardRef(
  (
    {
      label,
      graphic,
      graphicColor,
      graphicSize = 18,
      block,
      placeholder,
      securedTextEntry = false,
      className,
      textarea = false,
      ...props
    },
    ref
  ) => {
    const [isSecuredEntry, setIsSecuredEntry] = useState(securedTextEntry)
    const onToggleSecuredText = () => setIsSecuredEntry(!isSecuredEntry)

    const renderEyeIcon = () => (
      <div onClick={onToggleSecuredText}>
        {isSecuredEntry ? (
          <Graphic name="PasswordEye" size={20} themeColor="main" />
        ) : (
          <Graphic name="PasswordEyeCrossed" size={20} themeColor="main" />
        )}
      </div>
    )

    return (
      <InputWrap
        block={block}
        hasGraphic={!!graphic}
        {...props}
        className={className}
      >
        {graphic && (
          <GraphicWrapLeft>
            <Graphic
              name={graphic}
              size={graphicSize}
              themeColor={graphicColor}
            />
          </GraphicWrapLeft>
        )}
        <ContentWrap>
          <Label {...props}>{label}</Label>
          <InputText
            {...props}
            {...props.input}
            ref={ref}
            placeholder={placeholder}
            type={isSecuredEntry ? 'password' : 'text'}
            {...(textarea ? { as: TextareaAutosize } : {})}
            className={className}
          />
        </ContentWrap>
        {securedTextEntry && (
          <GraphicWrapRight>{renderEyeIcon()}</GraphicWrapRight>
        )}
      </InputWrap>
    )
  }
)

export const Input = withFieldError()(InputView)

const isLabelShrinked = ({ meta, input, autoFocus }) => {
  return meta.active || !!input.value || input.value === 0 || autoFocus
}
