import { gql } from 'apollo-boost'
import { AllCommunityPollsQuery } from '../queries'
import { CommunityPollFragment } from '../fragments'

const mutation = gql`
  mutation createCommunityPoll(
    $title: String!
    $choices: [PollVoteChoiceInput!]
    $tribeId: String!
    $externalUrl: String
    $image: PhotoInput
    $videoSrc: String
    $postData: IPostDataInput
  ) {
    createCommunityPoll(
      title: $title
      choices: $choices
      tribeId: $tribeId
      externalUrl: $externalUrl
      image: $image
      videoSrc: $videoSrc
      postData: $postData
    ) {
      communityPoll {
        id
        ...CommunityPoll
      }
      errors {
        key
        message
      }
    }
  }
  ${CommunityPollFragment}
`

mutation.refetchQueries = [
  {
    query: AllCommunityPollsQuery,
    variables: { withCompetitions: true, first: 10 }
  }
]

export default mutation
