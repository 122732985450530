import React from 'react'
import pluralize from 'pluralize'
import { Spacing, Text, SpacingItem } from '../../../components/ui'
import { DetailsBox } from './DetailsBox'
import { map, path, pathOr, pipe, reduce, uniq } from 'ramda'
import { useQuery } from 'react-apollo'
import { DataSchemaQuery } from '../../../state/queries'

const allMatchedTags = opportunity => null // TODO
// pipe(
//   map(prop('values')),
//   flatten,
//   join(', ')
// )(
//   getMatchedTags({
//     targetAudienceBuckets: opportunity.targetAudienceBuckets,
//     profileSchema,
//   })
// )

export const DetailsBoxes = ({ opportunity }) => {
  const { data: dataSchemaData, loading } = useQuery(DataSchemaQuery)

  const dataSchemaSources = getDataSchemaSources(dataSchemaData)
  const dataSchemaObjectTypes = getDataSchemaObjectTypes(dataSchemaData)

  const { survey } = opportunity
  const matchedTags = allMatchedTags(opportunity)

  const hasInsights = ({ inputDataSources }) =>
    inputDataSources && inputDataSources.length > 0

  if (loading) return null

  return (
    <Spacing size="l">
      <DetailsBox
        key="PrivatelyMacthDetailBox"
        title="How was I privately matched?"
        description={matchedTags || 'Entire UBDI community eligible'}
        graphicName="PrivateMatch"
      />

      {survey && (
        <DetailsBox
          title="This study includes a survey"
          description={`${survey.totalQuestions} ${pluralize(
            'question',
            survey.totalQuestions
          )} to complete`}
          graphicName="Survey"
        />
      )}
      {hasInsights(opportunity) && (
        <DetailsBox
          title="Data to be analyzed"
          graphicSize={38}
          graphicName="Fingerprint"
          description={getObjectTypeIdsFromInputDataSources(
            opportunity.inputDataSources
          )
            .map(id => dataSchemaObjectTypes[id].name)
            .join(', ')}
        >
          {renderInputDataSourcesDescriptions(
            opportunity.inputDataSources,
            dataSchemaSources
          )}
        </DetailsBox>
      )}
    </Spacing>
  )
}

const renderInputDataSourcesDescriptions = (
  inputDataSources,
  dataSchemaSources
) => {
  if (!inputDataSources || !inputDataSources.length) return null

  const describedQueries = inputDataSources.filter(({ query }) => !!query)

  if (!describedQueries.length) return null

  const renderQueryDescription = ({
    query: {
      sql,
      dataSourceIds,
      nativeQueryDescription: { aggregation = [], filter = [], breakout = [] }
    }
  }) => {
    return (
      <Spacing key={dataSourceIds.join('_') + sql} size="xxs">
        <QueryDescriptor
          label="Source:"
          value={dataSourceIds
            .map(id => dataSchemaSources[id].name)
            .join(' or ')}
        />
        <QueryDescriptor
          label="View:"
          value={aggregation.length ? aggregation[0] : 'Everything'}
          injectHtml
        />
        {filter && filter.length && (
          <QueryDescriptor label="Filtered by:" value={filter[0]} injectHtml />
        )}
        {breakout && breakout.length && (
          <QueryDescriptor label="Grouped by:" value={breakout[0]} injectHtml />
        )}
      </Spacing>
    )
  }

  return <Spacing>{describedQueries.map(renderQueryDescription)}</Spacing>
}

const QueryDescriptor = ({ label, value, injectHtml = false }) => (
  <Spacing direction="row">
    <SpacingItem flex={1}>
      <Text t3 color="dark">
        {label}
      </Text>
    </SpacingItem>
    <SpacingItem flex={2}>
      <Text t3 color="main">
        {injectHtml ? (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          value
        )}
      </Text>
    </SpacingItem>
  </Spacing>
)

const getDataSchemaSources = pipe(
  pathOr([], ['dataSchema', 'services']),
  reduce((acc, service) => ({ ...acc, [service.id]: service }), {})
)

const getDataSchemaObjectTypes = pipe(
  pathOr([], ['dataSchema', 'objectTypes']),
  reduce((acc, objectType) => ({ ...acc, [objectType.id]: objectType }), {})
)

const getObjectTypeIdsFromInputDataSources = pipe(
  map(path(['query', 'objectTypeId'])),
  uniq
)
