import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import ReactCodeInput from 'react-verification-code-input'

import { pipe } from 'ramda'
import { Modal, Spacing, Text } from '../../components/ui'
import { withFieldError, withMutation } from '../../components/wrappers'
import { Link } from 'react-router-dom'
import { Field, Form } from 'react-final-form'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

const ReactCodeInputForFinalForm = withFieldError()(
  ({ input, meta, ...props }) => (
    <ReactCodeInput onChange={value => input.onChange(value)} {...props} />
  )
)

const ReactCodeInputWrapper = styled(ReactCodeInputForFinalForm)`
  input {
    border: 1px solid ${({ theme }) => theme.color.purpleL2};
    font-family: Rubik;
    font-size: 28px;
    &:focus {
      border: 1px solid ${({ theme }) => theme.color.main};
    }
  }
`

const EmptyWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.xxl} ${theme.padding.xl} `};
`

const ClickableText = styled(Text)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
const VerifyPhoneNumberInner = ({
  onDismiss,
  resendVerificationCode,
  verifyPhoneNumber,
  params
}) => {
  const { currentUser } = params
  useEffect(() => {
    resendVerificationCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCodeComplete = ({ phoneVerificationCode }) => {
    return verifyPhoneNumber({
      variables: {
        code: phoneVerificationCode
      }
    }).then(({ userErrors, data, errors }) => {
      if (!userErrors && data) {
        onDismiss()
        return
      }

      return userErrors || errors
    })
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Verify Phone Number"
    >
      {() => (
        <ModalWrap>
          {currentUser.phoneNumber ? (
            <Spacing align="center" size="xl">
              <Text t2>
                A 6-digit confirmation number has been sent to your phone via
                SMS
              </Text>
              <Form onSubmit={onCodeComplete}>
                {({ handleSubmit }) => (
                  <Field
                    name="phoneVerificationCode"
                    component={ReactCodeInputWrapper}
                    onComplete={handleSubmit}
                  />
                )}
              </Form>

              <Spacing direction="row" size="xs" align="center">
                <Text t3>Didn't get the code?</Text>
                <ClickableText
                  t3
                  color="main"
                  bold
                  onClick={resendVerificationCode}
                >
                  Click here
                </ClickableText>
                <Text t3>to resend it.</Text>
              </Spacing>
            </Spacing>
          ) : (
            <EmptyWrap>
              <Spacing size="s" align="center" justify="center">
                <Text t1>You don't have phone number saved.</Text>
                <Spacing
                  direction="row"
                  size="s"
                  align="center"
                  justify="center"
                >
                  <Text t1>Please go to</Text>
                  <Link to={`/account`}>
                    <Text t1 color="main" bold>
                      Account settings
                    </Text>
                  </Link>
                  <Text t1>and enter it.</Text>
                </Spacing>
              </Spacing>
            </EmptyWrap>
          )}
          <Modal.Footer />
        </ModalWrap>
      )}
    </Modal>
  )
}

export const VerifyPhoneNumber = pipe(
  withMutation('resendVerificationCode'),
  withMutation('verifyPhoneNumber')
)(VerifyPhoneNumberInner)
