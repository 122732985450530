import React, { useState } from 'react'
import { Spacing } from '../ui'
import Avatar from 'react-avatar-edit'
import styled from 'styled-components'

export const UploadPhoto = ({ input }) => {
  const imageSrc =
    typeof input.value === 'object' ? input.value.thumbnail : input.value
  const [preview, setPreview] = useState()

  const onClose = () => {
    setPreview(null)
  }

  const onCrop = preview => {
    setPreview(preview)
    input.onChange(preview)
  }

  const onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 300000) {
      alert('File is too big!')
      elem.target.value = ''
    }
  }

  return (
    <Spacing>
      <Spacing direction="row">
        <Avatar
          width={300}
          height={200}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          src={imageSrc}
          label="Choose avatar image"
        />
        {preview && <Image src={preview} alt="Preview" />}
      </Spacing>
    </Spacing>
  )
}

const Image = styled.img`
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
`
