import { propEq } from 'ramda'
import { getAvailableServiceProviders } from '../../state/local/serviceProviders'
import { STREAMS_SCHEMA } from '../DataBank/DataStream/streams/_config'

export const getPublicDataSource = ({ publicOptIns, selectedDataSourceId }) => {
  if (!publicOptIns) {
    return []
  }
  return publicOptIns.find(propEq('dataSourceId', selectedDataSourceId))
}

export const getAccountIds = ({ publicOptIns, selectedDataSourceId }) => {
  const publicDataSource = getPublicDataSource({
    publicOptIns,
    selectedDataSourceId
  })
  if (!publicDataSource) {
    return []
  }
  return publicDataSource.accountIds
}

export const getDataSourceOptinIds = member =>
  member.publicProfileSourceOptIns
    .filter(source => {
      const availableProviders = getAvailableServiceProviders(
        source.dataSourceId
      )
      if (availableProviders && availableProviders.length > 0) {
        return source
      }
      return null
    })
    .map(({ dataSourceId, ...rest }) => {
      const accountIds = getAccountIds({
        publicOptIns: member.publicProfileSourceOptIns,
        selectedDataSourceId: dataSourceId
      })

      return {
        ...rest,
        dataSourceId,
        accountIds,
        sort: ['robinhood', 'coinbase'].includes(dataSourceId) ? 2 : 1
      }
    })
    .filter(({ dataSourceId, accountIds }) => {
      return !!STREAMS_SCHEMA[dataSourceId] && accountIds.length > 0
    })
    .sort((a, b) => b.sort - a.sort)
