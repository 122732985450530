import { pipe } from 'ramda'
import React from 'react'
import styled from 'styled-components/macro'
import { DataSourceGraphic, Spacing, Text } from '../../../../components/ui'
import SyncStateIcon from '../../../../components/ui/SyncStateIcon'
import { withMutation } from '../../../../components/wrappers'
import { useDataSourcesStatuses } from '../../../../hooks'

const UnlinkButton = styled.div`
  cursor: pointer;
`
const IconWrap = styled.div`
  position: relative;
`
const StateIconWrap = styled.div`
  position: absolute;
  right: -5px;
  top -5px;
`

const DataStreamHeaderInner = ({
  unlinkDataSource,
  dataSource: { id, name }
}) => {
  const { onUnlinkSource } = useDataSourcesStatuses({
    unlinkDataSource
  })
  return (
    <Spacing direction="row" align="center" justify="space-between">
      <Spacing direction="row" size="l" align="center">
        <IconWrap>
          <StateIconWrap>
            <SyncStateIcon syncState="completed" size={15} height={15} />
          </StateIconWrap>

          <DataSourceGraphic id={id} size={32} />
        </IconWrap>
        <Text h2>{name} </Text>
      </Spacing>
      <UnlinkButton onClick={() => onUnlinkSource(id)}>
        <Text h6 color="grey">
          Unlink your account
        </Text>
      </UnlinkButton>
    </Spacing>
  )
}

export const DataStreamHeader = pipe(withMutation('unlinkDataSource'))(
  DataStreamHeaderInner
)
