import { gql } from 'apollo-boost'

const mutation = gql`
  mutation ReactCommunityPoll($id: String!, $operation: String!) {
    reactCommunityPoll(id: $id, operation: $operation) {
      communityPoll {
        id
        reactions {
          votes
          currentMemberReaction
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export const getOptimisticReactCommunityPoll = ({
  id,
  votes,
  voted,
  operation
}) => {
  const calculateVotes = () => {
    if (voted) {
      if (voted === 'inc') return operation === 'inc' ? votes - 1 : votes - 2
      if (voted === 'dec') return operation === 'inc' ? votes + 2 : votes + 1
    }

    return operation === 'inc' ? votes + 1 : votes - 1
  }

  return {
    reactCommunityPoll: {
      __typename: 'CommunityPollPayload',
      communityPoll: {
        __typename: 'CommunityPoll',
        id,
        reactions: {
          __typename: 'CommunityPollReactions',
          votes: calculateVotes(),
          currentMemberReaction: operation
        }
      },
      errors: null
    }
  }
}

export default mutation
