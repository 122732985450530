import { gql } from 'apollo-boost'
import { UserFragment } from '../fragments'

export default gql`
  mutation redeemBonusCode($bonusCode: String!) {
    redeemBonusCode(bonusCode: $bonusCode) {
      isCustomTag
      currentUser {
        id
        ...User
      }
      errors {
        key
        message
      }
    }
  }
  ${UserFragment}
`
