import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { Graphic, Modal } from '../../components/ui'
import { STEPS } from './constants'
import { StepIndicator } from './components/StepIndicator'
import { StepTemplate } from './components/StepTemplate'

const ModalWrap = styled.div`
  width: 600px;
  min-height: 600px;
  max-height: 750px;
  position: relative;
  height: auto;
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.padding.l};
`

export const OnboardingSteps = ({ onDismiss, params = {} }) => {
  const { setIsModalDismissed } = params
  const [currentStep, setCurrentStep] = useState(0)
  const currentStepData = STEPS[currentStep]

  const onPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const onNext = () => {
    if (currentStep < STEPS.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const onModalDismiss = () => {
    setIsModalDismissed(true)
    onDismiss()
  }
  return (
    <Modal isOpen onDismiss={onModalDismiss} noPadding>
      {() => (
        <ModalWrap>
          <Container>
            <Header>
              <Graphic name="Header" width={600} height={158} png />
              {currentStep === 1 && (
                <DataSourcesWrap>
                  <Graphic
                    name="OnboardingDataSources"
                    size={600}
                    height={250}
                  />
                </DataSourcesWrap>
              )}
            </Header>
            <Wrap>
              <Chevron
                name="ChevronLeft"
                disabled={currentStep === 0}
                onChevronClick={onPrevious}
              />
              <StepWrapper>
                <StepTemplate
                  title={currentStepData.title}
                  description={currentStepData.description}
                  graphic={currentStepData.graphic}
                  height={currentStepData.height}
                  width={currentStepData.width}
                  isPng={currentStepData.isPng}
                  paddingTop={currentStepData.paddingTop}
                />
              </StepWrapper>
              <Chevron
                name="ChevronRight"
                disabled={currentStep === STEPS.length - 1}
                onChevronClick={onNext}
              />
            </Wrap>
          </Container>
          <StepIndicator
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </ModalWrap>
      )}
    </Modal>
  )
}

const Chevron = ({ name, onChevronClick, disabled }) => (
  <GraphicWrap onClick={onChevronClick} disabled={disabled}>
    <Graphic name={name} width={20} height={20} themeColor="main" />
  </GraphicWrap>
)

const Container = styled.div`
  display: flex;
  position: absolute;
  height: 90%;
  padding-bottom: ${({ theme }) => theme.padding.xl};
`

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StepWrapper = styled.div`
  width: 70%;
`

const GraphicWrap = styled.div`
  padding: ${({ theme }) => theme.padding.m};
  margin: ${({ theme }) => theme.padding.xs};
  box-shadow: 1px 1px 5px rgba(111, 98, 255, 0.5);
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => disabled && 0.5};
  &:hover {
    box-shadow: ${({ disabled }) =>
      !disabled && '1px 1px 8px rgba(111, 98, 255, 0.5)'};
  }
`

const Header = styled.div`
  position: absolute;
  z-index: -1;
  text-align: center;
`

const DataSourcesWrap = styled.div`
  position: absolute;
  top: -30px;
`
