import { gql } from 'apollo-boost'
import { DataSourceFragment } from '../fragments'

export const DataSchemaQuery = gql`
  query DataSchema {
    dataSchema {
      services {
        id
        name
        serviceGroup {
          id
        }
        availableObjectTypes
      }
      objectTypes {
        id
        name
        featuredFields
        fieldMappings {
          displayName
          name
          unit
        }
      }
    }
  }
`

export const DataSourceQuery = gql`
  query DataSourceQuery($sourceId: String!) {
    dataSource(id: $sourceId) {
      id
      ...DataSource
    }
  }
  ${DataSourceFragment}
`
