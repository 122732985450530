import { gql } from 'apollo-boost'

export const DataSourceFragment = gql`
  fragment DataSource on DataSource {
    id
    name
    isMultipleLinkable
    category {
      id
    }
  }
`

export const UsersDataSourceFragment = gql`
  fragment UsersDataSource on User {
    id
    linkedDataSources {
      disabled
      dateLinked
      dataSource {
        id
        ...DataSource
      }
      accounts {
        providerId
        accountId
      }
    }
    member {
      id
      finalAccountSyncDone {
        webapp
      }
      tags {
        uniqueId
        values
      }
    }
  }
  ${DataSourceFragment}
`
