import {
  generateDailyAverageQuery,
  getMostLikedTweet,
  getMostSharedTweet,
  getHashtags,
  getFollowers,
  getAvailableMonths,
  getAvailableCurrencies,
  getAllTransactions,
  getTransactionsByCategory
} from '../util'
import { CATEGORIES } from './util/financeHelpers'

export const ALL_ITEMS_STREAM = 'allItemsStream'

const FITNESS_ACTIVITY = [
  {
    objectTypeId: 300,
    sql: generateDailyAverageQuery('distance'),
    dataStreamId: 'dailyDistanceAverage',
    label: 'Distance covered Yesterday'
  },
  {
    objectTypeId: 300,
    sql: generateDailyAverageQuery('calories'),
    dataStreamId: 'dailyCaloriesAverage',
    label: 'Calories spent Yesterday'
  },
  {
    objectTypeId: 300,
    sql: generateDailyAverageQuery('averageheartrate'),
    dataStreamId: 'dailyAverageHeartrate',
    label: 'Yesterday Heart rate average'
  },
  {
    objectTypeId: 300,
    sql: generateDailyAverageQuery('steps'),
    dataStreamId: 'dailyAverageSteps',
    label: 'Steps walked Yesterday'
  }
]

const CRYPTO_TRANSACTIONS = [
  {
    objectTypeId: 201,
    sql: 'select * from ?',
    dataStreamId: ALL_ITEMS_STREAM
  },
  {
    objectTypeId: 201,
    sql: `select count(*) from ?`,
    dataStreamId: 'totalTransactions',
    label: 'Number of transactions'
  },
  {
    objectTypeId: 201,
    id: 'btc_debit',
    sql: `select sum(amount) as sum_debit from ? where currency = 'BTC' and basetype = 'DEBIT'`,
    dataStreamId: 'totalBTC',
    custom: {
      label: 'Total BTC',
      valueFormatter: 'credit_minus_debit'
    }
  },
  {
    objectTypeId: 201,
    id: 'btc_credit',
    sql: `select sum(amount) as sum_credit from ? where currency = 'BTC' and basetype = 'CREDIT'`,
    dataStreamId: 'totalBTC'
  },
  {
    objectTypeId: 201,
    id: 'eth_debit',
    sql: `select sum(amount) as sum_debit from ? where currency = 'ETH' and basetype = 'DEBIT'`,
    dataStreamId: 'totalETH',
    custom: {
      label: 'Total ETH',
      valueFormatter: 'credit_minus_debit'
    }
  },
  {
    objectTypeId: 201,
    id: 'eth_credit',
    sql: `select sum(amount) as sum_credit from ? where currency = 'ETH' and basetype = 'CREDIT'`,
    dataStreamId: 'totalETH'
  },
  {
    objectTypeId: 201,
    id: 'ltc_debit',
    sql: `select sum(amount) as sum_debit from ? where currency = 'LTC' and basetype = 'DEBIT'`,
    dataStreamId: 'totalLTC',
    custom: {
      label: 'Total LTC',
      valueFormatter: 'credit_minus_debit'
    }
  },
  {
    objectTypeId: 201,
    id: 'ltc_credit',
    sql: `select sum(amount) as sum_credit from ? where currency = 'LTC' and basetype = 'CREDIT'`,
    dataStreamId: 'totalLTC'
  }
]

const CRYPTO_TRADES = [
  {
    objectTypeId: 202,
    sql: 'select * from ?',
    dataStreamId: ALL_ITEMS_STREAM
  },
  {
    objectTypeId: 202,
    id: 'btc_buy',
    sql: `select sum(quantity) as sum_buy from ? where currency = 'BTC' and side = 'buy'`,
    dataStreamId: 'totalBTC',
    custom: {
      label: 'Total BTC',
      valueFormatter: 'buy_minus_sell'
    }
  },
  {
    objectTypeId: 202,
    id: 'btc_sell',
    sql: `select sum(quantity) as sum_sell from ? where currency = 'BTC' and side = 'sell'`,
    dataStreamId: 'totalBTC'
  },
  {
    objectTypeId: 202,
    id: 'eth_buy',
    sql: `select sum(quantity) as sum_buy from ? where currency = 'ETH' and side = 'buy'`,
    dataStreamId: 'totalETH',
    custom: {
      label: 'Total ETH',
      valueFormatter: 'buy_minus_sell'
    }
  },
  {
    objectTypeId: 202,
    id: 'eth_sell',
    sql: `select sum(quantity) as sum_sell from ? where currency = 'ETH' and side = 'sell'`,
    dataStreamId: 'totalETH'
  },
  {
    objectTypeId: 202,
    id: 'ltc_buy',
    sql: `select sum(quantity) as sum_buy from ? where currency = 'LTC' and side = 'buy'`,
    dataStreamId: 'totalLTC',
    custom: {
      label: 'Total LTC',
      valueFormatter: 'buy_minus_sell'
    }
  },
  {
    objectTypeId: 202,
    id: 'ltc_sell',
    sql: `select sum(quantity) as sum_sell from ? where currency = 'LTC' and side = 'sell'`,
    dataStreamId: 'totaLTC'
  }
]

export const STREAMS_SCHEMA = {
  strava: [
    ...FITNESS_ACTIVITY,
    {
      objectTypeId: 300,
      sql: 'select * from ?',
      dataStreamId: ALL_ITEMS_STREAM
    }
  ],
  fitbit: [
    ...FITNESS_ACTIVITY,
    {
      objectTypeId: 300,
      sql: `SELECT  *, 
          SUM(calories) as calories, 
          ROUND(AVG(averageheartrate), 0) as averageheartrate, 
          SUM(steps) as steps, 
          SUM(duration) as duration, 
          ROUND(AVG(speed), 2) as speed,
          ROUND(AVG(elevationgain), 2) as elevationgain
          FROM ? 
          GROUP BY CAST(createddate as DATE)
          ORDER BY createddate DESC`,
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 302,
      sql: 'select * from ?',
      dataStreamId: 'achievementsStream'
    }
  ],
  appleHealth: [
    ...FITNESS_ACTIVITY,
    {
      objectTypeId: 300,
      sql: `SELECT  *, 
        SUM(calories) as calories, 
        ROUND(AVG(averageheartrate), 0) as averageheartrate, 
        SUM(steps) as steps, 
        SUM(duration) as duration, 
        ROUND(AVG(speed), 2) as speed,
        ROUND(AVG(elevationgain), 2) as elevationgain
        FROM ? 
        GROUP BY CAST(createddate as DATE)
        ORDER BY createddate DESC`,
      dataStreamId: ALL_ITEMS_STREAM
    }
  ],
  netflix: [
    {
      objectTypeId: 4311,
      sql: 'select * from ?',
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 4311,
      sql: `select count(distinct(name)) from ?`,
      dataStreamId: 'totalShowsWatched',
      label: 'Shows watched'
    },
    {
      objectTypeId: 4311,
      sql: `select name, count(name) as freq from ? group by name order by freq desc limit 1`,
      dataStreamId: 'mostWatched',
      label: 'Most watched show'
    }
  ],
  linkedin: [
    {
      objectTypeId: 4404,
      sql: 'select * from ?',
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 4404,
      sql: `select memberyearsofexperience from ?`,
      dataStreamId: 'memberYearsOfExperience',
      custom: {
        label: 'Experience',
        valueFormatter: 'years_of_experience'
      }
    },
    {
      objectTypeId: 4404,
      sql: `select membergraduationyear from ?`,
      dataStreamId: 'memberGraduationYear',
      custom: {
        label: 'Graduation Year',
        valueFormatter: 'graduation_year'
      }
    }
  ],
  binance: CRYPTO_TRANSACTIONS,
  uphold: CRYPTO_TRANSACTIONS,
  coinbase: CRYPTO_TRANSACTIONS,
  gemini: CRYPTO_TRADES,
  robinhood: CRYPTO_TRADES,
  metamask: CRYPTO_TRANSACTIONS,
  twitter: [
    {
      objectTypeId: 2,
      sql: 'select * from ?',
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 2,
      sql: getMostLikedTweet(),
      dataStreamId: 'mostLikedTweet',
      label: 'Most likes'
    },
    {
      objectTypeId: 2,
      sql: getMostSharedTweet(),
      dataStreamId: 'mostSharedTweet',
      label: 'Most retweets'
    },
    {
      objectTypeId: 2,
      sql: getHashtags(),
      dataStreamId: 'mostUsedHashtag',
      custom: {
        label: 'Most used hashtag',
        valueFormatter: 'hashtag'
      }
    },
    {
      objectTypeId: 2,
      sql: getFollowers(),
      dataStreamId: 'followers',
      label: 'Followers'
    }
  ],
  amazon: [
    {
      objectTypeId: 4321,
      sql: 'SELECT * FROM ?',
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 4321,
      sql: 'SELECT COUNT(*) as cnt FROM ?',
      dataStreamId: 'countPurchases',
      label: 'Purchases'
    },
    {
      objectTypeId: 4321,
      sql:
        'SELECT MAX(itemtotal) as itemtotal, currency FROM ? GROUP BY currency LIMIT 1',
      dataStreamId: 'biggestPurchase',
      custom: {
        label: 'Biggest purchase',
        valueFormatter: 'currency_amount'
      }
    }
  ],
  googleFit: [
    {
      objectTypeId: 301,
      sql: `SELECT  *,
      AVG(activitycalories) as activitycalories,
      AVG(steps) as steps,
      AVG(heartpoints) as heartpoints,
      AVG(veryactiveminutes) as veryactiveminutes
      FROM ?
      GROUP BY CAST(createddate as DATE)
      ORDER BY createddate DESC`,
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 301,
      sql: generateDailyAverageQuery('steps'),
      dataStreamId: 'dailyStepsAverage',
      label: 'Steps covered Yesterday'
    },
    {
      objectTypeId: 301,
      sql: generateDailyAverageQuery('activitycalories'),
      dataStreamId: 'dailyActivityCaloriesAverage',
      label: 'Calories spent Yesterday'
    },
    {
      objectTypeId: 301,
      sql: generateDailyAverageQuery('veryactiveminutes'),
      dataStreamId: 'dailyActiveMinutesAverage',
      label: 'Move Minutes Yesterday'
    },
    {
      objectTypeId: 301,
      sql: generateDailyAverageQuery('heartpoints'),
      dataStreamId: 'dailyHeartpointsAverage',
      label: 'Heart Pts Yesterday'
    }
  ],
  youtube: [
    {
      objectTypeId: 403,
      sql: 'SELECT * FROM ?',
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 403,
      sql: 'SELECT totallikedvideos FROM ? limit 1',
      dataStreamId: 'totalLikedVideos',
      label: 'Total likes'
    },
    {
      objectTypeId: 59,
      sql: 'SELECT totalsubscriptions FROM ? limit 1',
      dataStreamId: 'totalSubscriptions',
      label: 'Total subscriptions'
    }
  ],
  yodlee: {
    metadata: [
      {
        objectTypeId: 201,
        sql: getAvailableMonths(),
        dataStreamId: 'availableMonthsStream'
      },
      {
        objectTypeId: 201,
        sql: getAvailableCurrencies(),
        dataStreamId: 'availableCurrenciesStream'
      }
    ],
    publicStreams: [
      {
        objectTypeId: 201,
        sql: `select * from ?`,
        dataStreamId: ALL_ITEMS_STREAM
      }
    ],
    data: filters => [
      {
        objectTypeId: 201,
        sql: getAllTransactions({ filters }),
        dataStreamId: ALL_ITEMS_STREAM
      },
      {
        objectTypeId: 201,
        sql: getTransactionsByCategory({ categories: CATEGORIES, filters }),
        dataStreamId: 'categoryTransactionsStream'
      }
    ]
  },
  spotify: [
    {
      objectTypeId: 406,
      sql: 'SELECT * FROM ?',
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 407,
      sql: 'SELECT name, * FROM ? ',
      dataStreamId: 'topArtistSpotify',
      label: 'Favorite artist'
    },
    {
      objectTypeId: 408,
      sql: 'SELECT name, * FROM ? ',
      dataStreamId: 'topTrackSpotify',
      label: 'Favorite track'
    }
  ],
  instagram: [
    {
      objectTypeId: 1,
      sql: 'SELECT * FROM ?',
      dataStreamId: ALL_ITEMS_STREAM
    },
    {
      objectTypeId: 11,
      sql: 'SELECT name, * FROM ? limit 1',
      dataStreamId: 'instagramUsername',
      custom: {
        label: 'Username',
        valueFormatter: 'username'
      }
    },
    {
      objectTypeId: 11,
      sql: 'SELECT mediacount, * FROM ? ',
      dataStreamId: 'instagramMediaCount',
      custom: {
        label: 'Posts',
        valueFormatter: 'media_count'
      }
    }
  ]
}
