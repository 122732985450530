import { pipe, filter, map, tail, isEmpty } from 'ramda'

import converters from './_converters'

const extractData = parsedData => {
  try {
    const filteredData = pipe(
      tail,
      filter(row => row && !isEmpty(row) && !isEmpty(row[0]))
    )(parsedData)

    const headerRow = parsedData[0]

    return { filteredData, headerRow }
  } catch (error) {
    console.log(error)
    throw new Error('Failed to read the file')
  }
}

// checks if data file if correct (expected vs. provided fields)
const filterData = ({ providerId, objectTypeId, data }) => {
  const providerConverters = converters[providerId]
  const { filteredData, headerRow } = extractData(data)

  const maybeInvalidInputDiff = providerConverters.isInputInvalid(
    headerRow,
    objectTypeId
  )

  if (maybeInvalidInputDiff) {
    throw new Error(
      `Input from ${providerId} file seems invalid - maybe you have downloaded the wrong type of file, make sure to check our instructions`
    )

    // TODO: Notify FE, maybe alert
  }

  return filteredData
}

export const LocalNdlProvider = providerId => {
  const providerConverters = converters[providerId]

  const importData = async (objectTypeId, data) => {
    const filteredData = await filterData({
      providerId,
      objectTypeId,
      data
    })

    const dataFinal = map(providerConverters.convertIntoObject(objectTypeId))(
      filteredData
    )

    return dataFinal
  }

  return {
    importData
  }
}
