import React from 'react'
import { FieldArray } from 'react-final-form-arrays'

import { Spacing, RadioButton } from '../../ui'

export const RadioList = ({
  uniqueId,
  options,
  size = 's',
  onChange,
  noShadow = false,
  hideBox = false
}) => (
  <FieldArray
    name={uniqueId}
    render={props => {
      const { fields } = props

      const toggle = option => () => {
        if (!fields.length) {
          fields.push(option)
          onChange && onChange()
          return
        }

        if (!isSelected(option)) {
          fields.pop()
          fields.push(option)
        }
      }

      const isSelected = option => fields.value && fields.value.includes(option)

      return (
        <Spacing size={size}>
          {options.map(({ value, label, ...extra }, i) => {
            const radioProps = {
              input: {
                onChange: toggle(value),
                value: isSelected(value),
                checked: isSelected(value)
              },
              label,
              extra,
              ...props
            }

            return (
              <RadioButton
                key={value}
                {...radioProps}
                noShadow={noShadow}
                hideBox={hideBox}
              />
            )
          })}
        </Spacing>
      )
    }}
  />
)
