import { pipe } from 'ramda'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Graphic, Spacing, Text } from '../../../components/ui'
import { withMutation } from '../../../components/wrappers'
import theme from '../../../theme'
import { getDistanceTime } from '../../../util/formatters/format_date'
import {
  extractCommunityPollPropsFromUrl,
  extractProfilePropsFromUrl,
  extractTribePropsFromUrl
} from '../../Home/components/CommunityPosts/util'
import { UBDI_TRIBE_ID } from '../../TribeDetails/_utils'

const NotificationCardWrap = styled.div`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
  cursor: pointer;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  ${props => props.seen} {
    border: 1px solid ${({ theme }) => theme.color.main};
  }
`

const TimeTextWrap = styled(Text)`
  color: ${({ theme }) => theme.color.dark};
  opacity: 0.5;
`

const GraphicWrap = styled.div`
  padding: 20px;
  &:hover {
    opacity: 0.5;
  }
`
const LabelWrap = styled(Text)`
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.color.main : theme.color.dark};
`

const redirectOnNotificationClick = ({ contentUrl, push }) => {
  if (contentUrl.includes('databank')) {
    return push(`/databank`)
  }

  if (contentUrl.includes('wallet')) {
    return push(`/earn`)
  }

  if (contentUrl.includes('profile')) {
    const { memberId } = extractProfilePropsFromUrl(contentUrl)
    if (memberId) {
      return push(`/profile/${memberId}/${UBDI_TRIBE_ID}`)
    }
    return
  }

  if (contentUrl.includes('tribe')) {
    const { tribeId } = extractTribePropsFromUrl(contentUrl)

    if (tribeId) {
      return push(`/tribes/${tribeId}`)
    }
    return
  }

  if (contentUrl.includes('communityPoll') || contentUrl.includes('comment')) {
    const navigateProps = extractCommunityPollPropsFromUrl(contentUrl)
    return push(`/post/${navigateProps.communityPollId}`)
  }
}

const NotificationCardInner = ({
  clearNotification,
  notification: { id, subject, createdAt, seen, contentUrl }
}) => {
  const { push } = useHistory()

  const onNotificationClear = ({ id, e }) => {
    e.stopPropagation()
    return clearNotification({ variables: { id } }).then(({ userErrors }) => {
      if (userErrors) {
        return userErrors
      }
    })
  }

  return (
    <NotificationCardWrap
      seen={seen}
      onClick={() => redirectOnNotificationClick({ contentUrl, push })}
    >
      <Spacing direction="row" justify="space-between">
        <Spacing size="xs">
          {handleTextColor(subject)}
          {createdAt && (
            <TimeTextWrap t1>
              {getDistanceTime({ date: createdAt })}
            </TimeTextWrap>
          )}
        </Spacing>
        <GraphicWrap onClick={e => onNotificationClear({ id, e })}>
          <Graphic name="X" size={15} color={theme.color.main} />
        </GraphicWrap>
      </Spacing>
    </NotificationCardWrap>
  )
}

const handleTextColor = text => {
  const splitText = text.match(/.*\*(.*)\*.*/i)

  if (!splitText) {
    return <LabelWrap t1>{text}</LabelWrap>
  }

  const regularText = text.split(`*${splitText[1]}*`)

  return (
    <Spacing direction="row" size="xs">
      <LabelWrap t1 brandBlack>
        {regularText[0]}
      </LabelWrap>
      <LabelWrap t1 highlighted>
        {splitText[1]}
      </LabelWrap>
      <LabelWrap t1>{regularText[1]}</LabelWrap>
    </Spacing>
  )
}

export const NotificationCard = pipe(withMutation('clearNotification'))(
  NotificationCardInner
)
