import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Text, Graphic, Button } from '../../../components/ui'

const width = window.innerWidth

const HeaderWrapper = styled.div`
  position: absolute;
  top: -80px;
`
const HeadingWrapper = styled.div`
  position: absolute;
  top: 80px;
`
const GraphicWrapper = styled.div`
  position: absolute;
  top: 160px;
`
const TextWrapper = styled.div`
  position: absolute;
  width: 550px;
  top: 530px;
`
const ButtonWrapper = styled.div`
  position: absolute;
  width: 220px;
  top: 690px;
`

export class OnboardingOne extends Component {
  state = {
    login: true, // switch between Login and SignUp
    email: '',
    password: '',
    name: '',
  }

  render() {
    const { history } = this.props
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HeaderWrapper>
          <Graphic name="Header" width={width} />
        </HeaderWrapper>
        <HeadingWrapper>
          <Text
            color="white"
            bold
            style={{ fontSize: 50, fontFamily: 'Poppins' }}
          >
            Welcome to UBDI
          </Text>
        </HeadingWrapper>
        <GraphicWrapper>
          <Graphic name="HumanULogoWithDome" width={width * 0.35} />
        </GraphicWrapper>
        <TextWrapper>
          <Text center style={{ fontFamily: 'rubik' }}>
            Our mission is simple: empower you with you data and work together
            to create a <b>Universal Basic Income</b>. To get there, we built a{' '}
            <b>safe place</b> where you can
            <b> anonymously</b> communicate, learn, and earn from your data with
            communities of people like you
          </Text>
        </TextWrapper>
        <ButtonWrapper>
          <Button outline block onClick={() => history.push('./two')}>
            Next
          </Button>
        </ButtonWrapper>
      </div>
    )
  }
}
