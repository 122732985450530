import React from 'react'
import styled from 'styled-components'
import { Text } from '../../../../components/ui'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
`

export const EmptyScreenTemplate = ({ children }) => {
  return (
    <Wrapper>
      {children || (
        <Text h2>
          Oops! Seems like what you are looking for doesn't exist any more.
        </Text>
      )}
    </Wrapper>
  )
}
