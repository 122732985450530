import { gql } from 'apollo-boost'

export const CommunityPollCommentFragment = gql`
  fragment CommunityPollComment on CommunityPollComment {
    id
    createdAt
    text
    gifUrl
    likes
    liked
    tags
    colors
    tribeId
    memberId
    publicTagCategories
    postData {
      dataSourceId
      objectTypeId
      data
    }
    publicMember {
      id
      profileUsername
      isCustomUsername
      profileColor
      profileImage {
        thumbnail
      }
    }
  }
`

export const CommunityPollCommentExtendedFragment = gql`
  fragment CommunityPollCommentExtended on CommunityPollComment {
    id
    ...CommunityPollComment
    memberCommentsCount
    memberCommunityPollsCount
  }
  ${CommunityPollCommentFragment}
`
