import React from 'react'
import { Spacing, Text } from '../../../components/ui'

export const ContentSection = ({ children, title }) => {
  return (
    <Spacing stretched>
      <Text h6>{title}</Text>
      {children}
    </Spacing>
  )
}
