import {
  reduce,
  omit,
  pathOr,
  pipe,
  map,
  flatten,
  includes,
  filter,
  prop,
  equals,
  find,
  propOr,
  keys,
  path,
  contains,
  join,
  values as valuesRamda
} from 'ramda'
import { FILTER_AGES, getAgeRange, getCalendarAges } from '../../util'

export const TAG_COLOR = [
  '#6B82FC',
  '#FFB36D',
  '#F26C9C',
  '#46B385',
  '#D16E58',
  '#62B4FF',
  '#FF6A61',
  '#AF6CF2',
  '#7DD653',
  '#7195F2'
]

const flattenProfileSchema = (tags, parent) =>
  reduce(
    (acc, item) => ({
      ...acc,
      [item.uniqueId]: {
        parent: parent && parent.uniqueId,
        parentTag: parent && omit(['children', 'subfields'], parent),
        ...omit(['children'], item)
      },
      ...(item.children ? flattenProfileSchema(item.children, item) : {}),
      ...(item.subfields ? flattenProfileSchema(item.subfields, item) : {})
    }),
    {}
  )(tags)

/*
  Previously, we were pulling audience.json via HTTP and flattening in redux,
  then passing to components, etc...
  Now, we switched to GraphQL-served schema, but wanted to keep the code
  in components, to avoid introducing bugs.
  This fn just retrofits the API.
*/
export const retrofitAudienceSchema = ({
  data: { audienceSchema, loading }
}) => {
  if (!audienceSchema) {
    return { profileSchema: { loading, original: [], flattened: [] } }
  }

  return {
    profileSchema: {
      loading,
      original: audienceSchema,
      flattened: flattenProfileSchema(audienceSchema)
    }
  }
}

export const getQuestionLevelId = uniqueId => {
  const [category, question] = uniqueId.split('_')
  return [category, question].join('_')
}

export const getTagValue = (uniqueId, profileSchema) => {
  const tagFromSchema = pathOr(
    null,
    ['flattened', uniqueId, 'label'],
    profileSchema
  )

  if (!tagFromSchema) {
    const maybeAge = parseInt(uniqueId, 10)
    if (!maybeAge) return null

    return getAgeRange(maybeAge)
  }

  return tagFromSchema
}

export const getSpecificTagCategoriesValues = (tags, categories) =>
  pipe(
    filter(({ uniqueId }) => includes(uniqueId, categories)),
    map(prop('values')),
    flatten
  )(tags)

export const getTagFromProfileSchema = (profileSchema, tagId) =>
  pipe(
    map(category =>
      category.children.find(tag => {
        return equals(tag.uniqueId, getQuestionLevelId(tagId))
      })
    ),
    find(category => category !== undefined)
  )(profileSchema.original)

const CATEGORY_CHILDREN = {
  general_age: Object.entries(FILTER_AGES).map(([label, uniqueId]) => ({
    label,
    uniqueId: getCalendarAges(uniqueId)
  }))
}

export const getCategoryChildrenValues = uniqueId => {
  if (!uniqueId) return []

  return propOr([], uniqueId, CATEGORY_CHILDREN)
}

export const hasSubfieldTag = uniqueId => parentUniqueId =>
  contains(uniqueId, parentUniqueId)

export const getTagValues = (profileTags = {}, profileSchema, uniqueId) => {
  const parentTag = profileSchema.flattened[uniqueId]

  if (!parentTag) {
    return null
  }

  const { custom, subfields } = parentTag

  const getSubfieldTagsValues = (acc, key) => {
    acc[key] = profileTags[key]
    return acc
  }

  // handle subfields tags (e.g. Loction)
  if (subfields && subfields.length) {
    const subValues = pipe(
      keys,
      keys => filter(hasSubfieldTag(uniqueId), keys),
      subfieldTags => reduce(getSubfieldTagsValues, {}, subfieldTags),
      valuesRamda,
      flatten,
      values => join(', ', values)
    )(profileTags)

    if (!subValues) {
      return null
    }

    return [
      {
        uniqueId,
        label: subValues,
        parent: parentTag.uniqueId,
        parentTag
      }
    ]
  }

  const values = profileTags[uniqueId]

  if (!values || !values.length) return null

  // handle Custom tags (e.g. custom Text)
  if (custom) {
    return values.map(value => ({
      uniqueId: value,
      label: value,
      parent: parentTag.uniqueId,
      parentTag
    }))
  }

  return values.map(value => path(['flattened', value], profileSchema))
}
