import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Box, Spacing, Text } from '../../../../../components/ui'
import { TribeContext } from '../../../../../contexts/tribeContext'

const BoxWrapper = styled.div`
  padding: ${({ theme }) => `${theme.padding.xs} 0px`};
  cursor: pointer;
`

export const RealInvestorForumCard = ({ tribeId, forum }) => {
  const { push } = useHistory()
  const { setSelectedFilterValue } = useContext(TribeContext)

  return (
    <BoxWrapper
      onClick={() => {
        setSelectedFilterValue(forum.value)
        push(`/tribes/${tribeId}/${forum.value}`)
      }}
    >
      <Box bordered noShadow>
        <Spacing size="xs">
          <Text h5 bold>
            {forum.label}
          </Text>
        </Spacing>
      </Box>
    </BoxWrapper>
  )
}
