import React from 'react'
import { Graphic, Spacing, SpacingItem, Text } from '../../../components/ui'

export const FeeInfo = ({ usd = [], ubdi = [], borderlessStyle, lightBg }) => {
  if (usd.length === 0 && ubdi.length === 0) return null
  return (
    <Spacing direction="row" size="l">
      {usd.length > 0 && usd[0] > 0 && (
        <Fee
          amount={usd.join('-')}
          label="cash"
          borderlessStyle={borderlessStyle}
          lightBg={lightBg}
          graphic="UsdSign"
        />
      )}
      {ubdi.length > 0 && ubdi[0] > 0 && (
        <Fee
          amount={ubdi.join('-')}
          label="points"
          graphic="UbdiSign"
          lightBg={lightBg}
          borderlessStyle={borderlessStyle}
        />
      )}
    </Spacing>
  )
}

const Fee = ({ amount, borderlessStyle, lightBg, graphic }) => {
  const getColor = () => {
    if (lightBg) return 'dark'
    return amount < 0 ? 'redNegative' : 'white'
  }

  return (
    <Spacing size="xs" direction="row" align="center">
      <SpacingItem display="flex">
        {graphic && <Graphic name={graphic} size={16} />}
      </SpacingItem>
      <Text h7 light={borderlessStyle} color={getColor()}>
        {amount}
      </Text>
    </Spacing>
  )
}
