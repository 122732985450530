import React from 'react'
import styled from 'styled-components/macro'
import { Box, Spacing, Text } from '../../../components/ui'

export const EmptyProfileSection = styled.div`
  background: ${({ theme }) => theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.xl};
  border-radius: 10px;
  align-items: center;
`

export const ProfileSection = ({
  children,
  isOwnProfile,
  label,
  emptyLabel,
  emptyAction,
  manageAction
}) => {
  return (
    <Box size="xl" bordered noShadow>
      <Spacing>
        <Spacing direction="row" justify="space-between">
          <Text h5>{label}</Text>
          {children && isOwnProfile && manageAction}
        </Spacing>
        {children ||
          renderEmptyProfileSection({
            isOwnProfile,
            emptyLabel,
            emptyAction: isOwnProfile && emptyAction
          })}
      </Spacing>
    </Box>
  )
}

const renderEmptyProfileSection = ({ emptyLabel, emptyAction }) => {
  return (
    <EmptyProfileSection>
      <Spacing align="center">
        <Text t3>{emptyLabel}</Text>
        {emptyAction}
      </Spacing>
    </EmptyProfileSection>
  )
}
