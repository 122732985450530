import { find, includes, pathOr, pipe, propEq, propOr } from 'ramda'
import { UBDI_TRIBE_ID } from '../../../TribeDetails/_utils'

export const checkIsTribeMember = ({ tribeId, member }) => {
  const allMemberTribes = pipe(
    pathOr([], ['tags']),
    find(propEq('uniqueId', 'system_groups')),
    propOr([], 'values')
  )(member)

  return !!includes(tribeId, allMemberTribes)
}

export const getUbdiTribeSettings = ({ member: { tribeSettings } }) =>
  tribeSettings.find(({ tribeId }) => tribeId === UBDI_TRIBE_ID) || {}
