import React from 'react'
import styled from 'styled-components'
import { Spacing } from '../../../components/ui'
import theme from '../../../theme'
import { STEPS } from '../constants'

export const StepIndicator = ({ currentStep, setCurrentStep }) => {
  return (
    <DotsWrap>
      <Spacing direction="row">
        {STEPS.map((step, index) => (
          <Dot
            key={step.id}
            onClick={() => setCurrentStep(index)}
            color={index === currentStep && theme.color.main}
          />
        ))}
      </Spacing>
    </DotsWrap>
  )
}

const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(111, 98, 255, 0.5);
  background-color: ${({ color }) => color || 'white'};
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 8px rgba(111, 98, 255, 0.5);
  }
`

const DotsWrap = styled.div`
  display: flex;
  justify-content: center;
  max-width: 230px;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
`
