import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { Text, Spacing } from '../../../../components/ui'
import { getTickerData } from '../../../TribeDetails/_utils'

export const InvestorForumSummary = ({ forum }) => {
  const [forumData, setForumData] = useState(null)

  useEffect(() => {
    const getForumDataAsync = async () => {
      const data = await getTickerData({ ticker: forum.value })

      setForumData(data)
    }

    getForumDataAsync()
  }, [forum.value])

  return (
    <Spacing size="s">
      <Spacing direction="row" size="xs">
        <Text t2 brandBlack>
          {forum.label}
        </Text>
        <Text t2>-</Text>
        <Text t2>Updated {format(new Date(), 'MMM dd, yyyy h:mm aaa')}</Text>
      </Spacing>
      {forumData && (
        <Spacing direction="row" size="s" align="center">
          <Text h2>${forumData.price * 1}</Text>
          <Text h6 color={forumData.percentage > 0 ? 'green' : 'redNegative'}>
            {forumData.percentage > 0 && '+'}
            {forumData.percentage * 1}%
          </Text>
        </Spacing>
      )}
    </Spacing>
  )
}
