import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  box-shadow: 0px 5px 10px rgba(42, 37, 97, 0.05);
  border-radius: 20px;
  padding: 40px;
`

export const DataStreamWrapper = ({ children }) => (
  <Container>{children}</Container>
)
