import AvatarIncognito from './avatarIncognito'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as LogoWhite } from './logoWhite.svg'
import { ReactComponent as Spinner } from './spinner.svg'
import { ReactComponent as Tick } from './tick.svg'
import { ReactComponent as Back } from './back.svg'
import { ReactComponent as Header } from './header.svg'
import { ReactComponent as HumanULogoWithCircles } from './humanULogoWithCircles.svg'
import { ReactComponent as HumanULogoWithDome } from './humanULogoWithDome.svg'
import { ReactComponent as AuthRect } from './authRect.svg'
import { ReactComponent as AppleLogo } from './apple_logo.svg'
import { ReactComponent as PasswordEye } from './passwordEye.svg'
import { ReactComponent as PasswordEyeCrossed } from './passwordEyeCrossed.svg'
import { ReactComponent as AuthRectWithLine } from './authRectWithLine.svg'
import { ReactComponent as AuthTermsOfService } from './authTermsOfService.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as ChevronLeft } from './chevronLeft.svg'
import { ReactComponent as ChevronRight } from './chevronRight.svg'
import { ReactComponent as ChevronUp } from './chevronUp.svg'
import { ReactComponent as ChevronDown } from './chevronDown.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Blur } from './blur.svg'
import { ReactComponent as Checkmark } from './checkmark.svg'
import { ReactComponent as Sync } from './sync.svg'
import { ReactComponent as Loading } from './loading.svg'
import { ReactComponent as Link } from './link.svg'
import { ReactComponent as LinkCircle } from './linkCircle.svg'
import { ReactComponent as Answers } from './answers.svg'
import { ReactComponent as Comments } from './comments.svg'
import { ReactComponent as Views } from './views.svg'
import { ReactComponent as Entry } from './entry.svg'
import { ReactComponent as Bookmark } from './bookmark.svg'
import { ReactComponent as Cup } from './cup.svg'
import { ReactComponent as Gif } from './gif.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as HeartOutline } from './heartOutline.svg'
import { ReactComponent as Data } from './data.svg'
import { ReactComponent as Uphold } from './uphold.svg'
import { ReactComponent as PrivateMatch } from './privateMatch.svg'
import { ReactComponent as Survey } from './survey.svg'
import { ReactComponent as Fingerprint } from './fingerprint.svg'
import { ReactComponent as Referral } from './referral.svg'
import { ReactComponent as Synced } from './synced.svg'

//onboardingGraphics
import { ReactComponent as OnboardingDataBank } from './onboardingDataBank.svg'
import { ReactComponent as OnboardingTraits } from './onboardingTraits.svg'
import { ReactComponent as OnboardingTribes } from './onboardingTribes.svg'
import { ReactComponent as OnboardingNotifications } from './onboardingNotifications.svg'
import { ReactComponent as OnboardingDataSources } from './onboardingDataSources.svg'
import { ReactComponent as OnboardingBottomLine } from './onboardingBottomLine.svg'
import { ReactComponent as UbdiLogo } from './ubdiLogo.svg'
import { ReactComponent as Enter } from './enter.svg'
import { ReactComponent as DataBankSelected } from './dataBankSelected.svg'
import { ReactComponent as DataBankActive } from './dataBankActive.svg'
import { ReactComponent as Avatar } from './avatar.svg'
import { ReactComponent as SyncStateFailed } from './syncStateFailed.svg'
import { ReactComponent as X } from './x.svg'
import { ReactComponent as Account } from './account.svg'
import { ReactComponent as AccountMultiple } from './account-multiple.svg'
import { ReactComponent as Users } from './users.svg'
import { ReactComponent as Notifications } from './notifications.svg'
import { ReactComponent as UsdSign } from './usdSign.svg'
import { ReactComponent as UbdiSign } from './ubdiSign.svg'
import { ReactComponent as ArrowLeft } from './arrowLeft.svg'

// Sources
import { ReactComponent as SourceNetflix } from './source_netflix.svg'
import { ReactComponent as SourceMetamask } from './source_metamask.svg'
import { ReactComponent as SourceAmazon } from './source_amazon.svg'
import { ReactComponent as SourceGoogle } from './source_google.svg'
import { ReactComponent as SourceLinkedin } from './source_linkedin.svg'
import { ReactComponent as SourceFacebook } from './source_facebook.svg'
import { ReactComponent as SourceFitbit } from './source_fitbit.svg'
import { ReactComponent as SourceGarmin } from './source_garmin.svg'
import { ReactComponent as SourceInstagram } from './source_instagram.svg'
import { ReactComponent as SourceFinance } from './source_finance.svg'
import { ReactComponent as SourceSpotify } from './source_spotify.svg'
import { ReactComponent as SourceTwitter } from './source_twitter.svg'
import { ReactComponent as SourcePinterest } from './source_pinterest.svg'
import { ReactComponent as SourceFlickr } from './source_flickr.svg'
import { ReactComponent as SourceYoutube } from './source_youtube.svg'
import { ReactComponent as SourceGoogleFit } from './source_googlefit.svg'
import { ReactComponent as SourceSteam } from './source_steam.svg'
import { ReactComponent as SourceTwitch } from './source_twitch.svg'
import { ReactComponent as SourceCoinbase } from './source_coinbase.svg'
import { ReactComponent as SourceStrava } from './source_strava.svg'
import { ReactComponent as SourceBattleNet } from './source_battlenet.svg'
import { ReactComponent as SourceRobinhood } from './source_robinhood.svg'
import { ReactComponent as SourceGemini } from './source_gemini.svg'
import { ReactComponent as SourceUphold } from './source_uphold.svg'
import { ReactComponent as SourceBinance } from './source_binance.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as HomeSelected } from './homeSelected.svg'
import { ReactComponent as DataBank } from './dataBank.svg'
import { ReactComponent as UbdiIntro } from './ubdiIntro.svg'
import { ReactComponent as UbdiRound } from './ubdiRound.svg'
import { ReactComponent as UsdRound } from './usdRound.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as Earn } from './earn.svg'
import { ReactComponent as EarnSelected } from './earnSelected.svg'
import { ReactComponent as Profile } from './profile.svg'
import { ReactComponent as ProfileSelected } from './profileSelected.svg'
import { ReactComponent as Reddit } from './reddit.svg'

// tags
import { ReactComponent as general_gender } from './tags/general_gender.svg'
import { ReactComponent as general_age } from './tags/general_age.svg'
import { ReactComponent as general_location } from './tags/general_location.svg'
import { ReactComponent as general_location_country } from './tags/general_location.svg'
import { ReactComponent as general_maritalStatus } from './tags/general_maritalStatus.svg'
import { ReactComponent as general_ethnicity } from './tags/general_ethnicity.svg'
import { ReactComponent as general_educationLevel } from './tags/general_educationLevel.svg'
import { ReactComponent as general_incomeLevel } from './tags/general_incomeLevel.svg'
import { ReactComponent as general_employment } from './tags/general_employment.svg'
import { ReactComponent as fitness_sports } from './tags/fitness_sports.svg'
import { ReactComponent as dataverified_membership } from './tags/dataverified_membership.svg'

import { ReactComponent as TagsFamily } from './tags/family.svg'
import { ReactComponent as TagsOwnership } from './tags/ownership.svg'
import { ReactComponent as TagsLeisure } from './tags/leisure.svg'
import { ReactComponent as TagsNutrition } from './tags/nutrition.svg'
import { ReactComponent as TagsFitness } from './tags/fitness.svg'
import { ReactComponent as TagsGeneral } from './tags/general.svg'
import { ReactComponent as TagsWorldview } from './tags/worldview.svg'

import * as pngs from '../pngs'

export const verifiedTagsIconMap = {
  general_gender: 'general_gender',
  general_age: 'general_age',
  general_location: 'general_location',
  general_location_country: 'general_location',
  general_incomeLevel: 'general_incomeLevel',
  general_maritalStatus: 'general_maritalStatus',
  general_ethnicity: 'general_ethnicity',
  general_educationLevel: 'general_educationLevel',
  general_employment: 'general_employment',
  dataverified_membership: 'dataverified_membership',

  fitness_sports: 'fitness_sports'
}

export default {
  Logo,
  AvatarIncognito,
  UbdiLogo,
  Spinner,
  Tick,
  Back,
  Enter,
  HumanULogoWithCircles,
  HumanULogoWithDome,
  Header,
  AuthRect,
  AuthRectWithLine,
  AppleLogo,
  LogoWhite,
  PasswordEye,
  PasswordEyeCrossed,
  Close,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Search,
  AuthTermsOfService,
  Blur,
  Checkmark,
  Sync,
  Loading,
  Link,
  LinkCircle,
  Answers,
  Comments,
  Views,
  Entry,
  Bookmark,
  Cup,
  Gif,
  Lock,
  Heart,
  HeartOutline,
  Earn,
  EarnSelected,
  Profile,
  ProfileSelected,
  Data,
  Referral,
  Synced,
  Reddit,
  Twitter: SourceTwitter,
  Instagram: SourceInstagram,
  Facebook: SourceFacebook,
  LinkedIn: SourceLinkedin,
  Uphold,
  PrivateMatch,
  Survey,
  Fingerprint,

  // Onboarding
  OnboardingDataBank,
  OnboardingTraits,
  OnboardingTribes,
  OnboardingNotifications,
  OnboardingDataSources,
  OnboardingBottomLine,
  DataBankSelected,
  DataBankActive,
  Avatar,
  SyncStateFailed,
  X,
  Account,
  AccountMultiple,
  Users,
  Notifications,
  UsdSign,
  UbdiSign,
  ArrowLeft,

  // Sources
  SourceNetflix,
  SourceMetamask,
  SourceAmazon,
  SourceGoogle,
  SourceGoogleTakeout: SourceGoogle,
  SourceLinkedin,
  SourceFacebook,
  SourceFitbit,
  SourceGarmin,
  SourceInstagram,
  SourceGlobalFinance: SourceFinance,
  SourceUSFinance: SourceFinance,
  SourceYodlee: SourceFinance,
  SourceSpotify,
  SourceTwitter,
  SourcePinterest,
  SourceFlickr,
  SourceYoutube,
  SourceGoogleFit,
  SourceSteam,
  SourceTwitch,
  SourceCoinbase,
  SourceStrava,
  SourceBattleNet,
  SourceRobinhood,
  SourceGemini,
  SourceUphold,
  SourceBinance,
  Home,
  HomeSelected,
  DataBank,
  UbdiIntro,
  UbdiRound,
  UsdRound,
  Info,

  // data verified tags icons

  VerifiedTags: {
    general_gender,
    general_age,
    general_location,
    general_location_country,
    general_incomeLevel,
    general_maritalStatus,
    general_ethnicity,
    general_educationLevel,
    general_employment,
    fitness_sports,
    dataverified_membership
  },

  TagCategory: {
    custom: TagsFamily,
    family: TagsFamily,
    fitness: TagsFitness,
    general: TagsGeneral,
    leisure: TagsLeisure,
    nutrition: TagsNutrition,
    ownership: TagsOwnership,
    worldview: TagsWorldview
  },

  ...pngs
}
