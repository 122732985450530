import axios from 'axios'
import { stringify } from 'querystring'
import { v4 } from 'uuid'

import { generateEncryptionKey, generateCollectorToken } from '../../logic/ndl'
import { pathOr, splitEvery } from 'ramda'

const {
  REACT_APP_DATA_COLLECTOR_URL,
  REACT_APP_DATA_COLLECTOR_PUBKEY_URL
} = process.env

const NDL_STATUSES = {
  linked: 'linked',
  authFailed: 'auth-failed',
  syncStarted: 'sync-started',
  syncFailedAuth: 'sync-failed-auth',
  syncFailed: 'sync-failed',
  failed: 'failed',
  completed: 'completed',

  accountRemoved: 'account-removed'
}

class NotDataLakeProvider {
  constructor(config) {
    this._config = config

    this._accountInstances = {}
  }

  get config() {
    return this._config
  }

  setup = () => {
    // set up vault
    // authorize faceid
  }

  getAccountStatus = async account => {
    if (!account.id)
      throw new Error('accountId is required for getAccountStatus')

    const instance = await this._axiosInstance({ accountId: account.id })
    const { data } = await instance.post('/checkAuth', {
      dataSourceId: account.dataSourceId
    })

    console.log('Received account status', data)

    return data
  }

  createAccount = async dataSourceId => {
    const encryptionKey = await generateEncryptionKey()
    const registerAccountId = v4()

    localStorage.setItem(
      `account_${registerAccountId}`,
      JSON.stringify({ encryptionKey, dataSourceId })
    )

    return { accountId: registerAccountId, encryptionKey }
  }

  registerAccount = async ({
    dataSourceId,
    registerAccountId,
    encryptionKey
  }) => {
    const instance = await this._axiosInstance({ encryptionKey })
    const { data } = await instance.post('/accounts', {
      dataSourceId,
      registerAccountId
    })

    return data.accountId
  }

  saveToken = async ({
    accountId,
    dataSourceId,
    accessToken,
    refreshToken,
    ...restOfParams
  }) => {
    if (!accountId) throw new Error('accountId is required for saveToken')
    const instance = await this._axiosInstance({ accountId })

    const { data } = await instance.post('/exchangeToken', {
      dataSourceId,
      accessToken,
      refreshToken,
      ...restOfParams
    })

    return data
  }

  exchangeToken = async ({ accountId, dataSourceId, redirectUrl }) => {
    if (!accountId) throw new Error('accountId is required for exchangeToken')
    const instance = await this._axiosInstance({ accountId })

    const { data } = await instance.post('/exchangeToken', {
      dataSourceId,
      redirectUrl
    })

    return data
  }

  uploadLocalData = async ({ accountId, dataSourceId, objectTypeId, data }) => {
    if (!accountId) {
      throw new Error('accountId is required for uploadLocalData')
    }

    const instance = await this._axiosInstance({ accountId })
    const splittedData = splitEvery(1000, data)

    return Promise.all(
      splittedData.map(dataPiece =>
        instance.post('/upload', {
          dataSourceId,
          objectTypeId,
          data: dataPiece
        })
      )
    )
  }

  kickoffOpportunity = async ({
    dataQueries,
    connectedAccounts,
    opportunityId,
    shortId,
    responseToken
  }) => {
    const kickoffs = splitDataQueriesIntoKickoffs(
      dataQueries,
      connectedAccounts
    )

    return Promise.all(
      kickoffs.map(({ account, dataQueries }) =>
        this.kickoffAccount({
          shortId,
          opportunityId,
          dataQueries,
          accountId: account.id,
          dataSourceId: account.dataSourceId,
          warehouseToken: responseToken
        })
      )
    )
  }

  kickoffAccount = async ({
    accountId,
    dataSourceId,
    opportunityId,
    shortId,
    dataQueries,
    warehouseToken
  }) => {
    const instance = await this._axiosInstance({ accountId })

    const { data } = await instance.post('/kickoff', {
      accountId,
      dataSourceId,
      opportunityId,
      shortId,
      dataQueries,
      warehouseToken
    })

    if (data && data.kickoffs) {
      return data.kickoffs
    }

    return false
  }

  getRedirectUrl = async ({ dataSourceId, accountId }) => {
    const { requiresAuthorizationTokenInRedirectUrl } = pathOr(
      {},
      ['dataSourcesConfig', dataSourceId],
      this.config
    )

    const queryParams = stringify({
      cacheBuster: new Date().getDate(),
      authorizationToken: requiresAuthorizationTokenInRedirectUrl
        ? await this.getCollectorToken({ accountId })
        : undefined
    })

    return `${this.config.baseUrl}/redirect/${dataSourceId}?${queryParams}`
  }

  kickoffTagVerification = async ({
    accountId,
    tagVerificationRequestId,
    tags
  }) => {
    const instance = await this._axiosInstance({ accountId })

    await instance.post('/kickoff/tagVerification', {
      accountId,
      tagVerificationRequestId,
      tags
    })

    return
  }

  getCollectorToken = async ({ accountId, encryptionKey } = {}) => {
    const getEncryptionKey = async () => {
      if (encryptionKey) return encryptionKey
      if (accountId) {
        const storedCredentials = JSON.parse(
          localStorage.getItem(`account_${accountId}`)
        )

        if (!storedCredentials || !storedCredentials.encryptionKey) {
          throw new Error(`Requested account does not exist: ${accountId}`)
        }

        return storedCredentials.encryptionKey
      }
    }

    return await generateCollectorToken(this._config.publicKeyUrl, {
      accountId,
      encryptionKey: await getEncryptionKey()
    })
  }

  updateAccountConfiguration = ({ accountIds, publicObjectTypes }) => {
    return Promise.all(
      accountIds.map(async accountId => {
        const instance = await this._axiosInstance({ accountId })
        return instance.post('/configureAccount', {
          configurationUpdates: { publicObjectTypes }
        })
      })
    )
  }

  performLogin = async ({
    accountId,
    dataSourceId,
    username,
    password,
    mfa_code,
    challenge_type,
    challenge_id,
    challenge_response
  }) => {
    if (!accountId) throw new Error('accountId is required for performLogin')
    const instance = await this._axiosInstance({ accountId })

    const { data } = await instance.post('/exchangeToken', {
      dataSourceId,
      username,
      password,
      mfa_code,
      challenge_type,
      challenge_id,
      challenge_response
    })

    return data
  }

  _axiosInstance = async ({ accountId, encryptionKey } = {}) => {
    const collectorToken = await this.getCollectorToken({
      accountId,
      encryptionKey
    })

    return axios.create({
      baseURL: this._config.baseUrl,
      headers: {
        authorization: `Bearer ${collectorToken}`
      }
    })
  }

  _axiosUnauthorizedInstance = () =>
    axios.create({
      baseURL: this._config.baseUrl
    })

  _keychainServiceId = accountId => `ndl_${accountId}`

  readAccount = async ({ dataQueries, accountId, isPublicRead }) => {
    const instance = isPublicRead
      ? this._axiosUnauthorizedInstance()
      : await this._axiosInstance({ accountId })

    const { data } = await instance.post(
      isPublicRead ? '/publicRead' : '/read',
      { accountId, dataQueries }
    )

    return data
  }

  readAccounts = async ({ dataQueries, accountIds, isPublicRead }) => {
    return Promise.all(
      accountIds.map(async accountId => ({
        accountId,
        response: await this.readAccount({
          accountId,
          dataQueries,
          isPublicRead
        })
      }))
    )
  }

  triggerAccountSync = async ({ accountId, objectTypeIds = [] }) => {
    console.log('Triggering sync', { accountId, objectTypeIds })
    const instance = await this._axiosInstance({ accountId })

    const dataQueries = objectTypeIds.map(objectTypeId => ({ objectTypeId }))

    try {
      const { data } = await instance.post('/kickoff', { dataQueries })

      return data
    } catch (e) {
      console.log(
        'Failed triggering account sync',
        { accountId, objectTypeIds },
        e
      )
    }
  }

  getExternalApi = async ({ provider, payload, accountId }) => {
    const instance = accountId
      ? await this._axiosInstance({ accountId })
      : this._axiosUnauthorizedInstance()

    try {
      const { data } = await instance.post('/getExternalApi', {
        provider,
        payload
      })

      return data
    } catch (e) {
      console.log('Failed fetching external API', {
        provider,
        payload,
        accountId
      })
    }
  }

  getPublicMetadata = async params => {
    const { data } = await this._axiosUnauthorizedInstance().post(
      '/publicMetadata',
      params
    )

    return data
  }
}

const splitDataQueriesIntoKickoffs = (dataQueries, connectedAccounts) => {
  return connectedAccounts.map(account => ({
    account,
    dataQueries: dataQueries.filter(({ dataSourceIds }) =>
      dataSourceIds.includes(account.dataSourceId)
    )
  }))
}

export default new NotDataLakeProvider({
  NDL_STATUSES,
  baseUrl: REACT_APP_DATA_COLLECTOR_URL,
  publicKeyUrl: REACT_APP_DATA_COLLECTOR_PUBKEY_URL,
  dataSourcesConfig: {
    yodlee: {
      requiresAuthorizationTokenInRedirectUrl: true
    },
    robinhood: {
      useManualLoginFlow: true
    },
    metamask: {
      useCustomLocalFlow: true
    }
  }
})
