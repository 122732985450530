import React from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import styled from 'styled-components/macro'

import { Graphic, Spacing, Text } from '../../../../../../components/ui'
import theme from '../../../../../../theme'
import { capitalizeFirstLetter } from '../../../../../../util'

const ContentWrap = styled.div`
  flex-direction: row;
  flex: 1;
`
const TagWrap = styled.div`
  border-radius: 5px;

  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.color.purpleL3};
  padding: ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.m};
`

const TradeCard = ({
  onShare,
  data: {
    side,
    averageprice,
    cumulativequantity,
    createddate,
    symbol,
    currency
  }
}) => {
  return (
    <ContentWrap>
      <Spacing direction="row" justify="space-between" align="center">
        <Tag backgroundColor={side === 'buy' ? '#08E696' : '#EC5B5B'}>
          {capitalizeFirstLetter(side)} {parseFloat(cumulativequantity)}{' '}
          {symbol || currency} @ {parseFloat(averageprice).toFixed(2)} USD
        </Tag>
        <Spacing direction="row" align="center">
          <Text t2>
            {formatDistanceToNowStrict(createddate, { addSuffix: false })}
          </Text>
          {onShare && (
            <div onClick={onShare}>
              <Graphic name="Link" color={theme.color.main} size={15} />
            </div>
          )}
        </Spacing>
      </Spacing>
    </ContentWrap>
  )
}

const Tag = ({ children, backgroundColor, textColor }) => (
  <TagWrap backgroundColor={backgroundColor}>
    <Text h6 color={textColor ? textColor : 'white'}>
      {children}
    </Text>
  </TagWrap>
)

export default TradeCard
