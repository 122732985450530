import React from 'react'
import styled from 'styled-components'
import { Text } from '../../../components/ui'

const EmptyWrap = styled.div`
  padding: ${({ theme }) => theme.padding.xl};
`
export const EmptyFollowerList = ({ text = 'No followers yet. ' }) => {
  return (
    <EmptyWrap>
      <Text t1 center>
        {text}
      </Text>
    </EmptyWrap>
  )
}
