import React from 'react'
import styled from 'styled-components/macro'
import { TraitsEntry } from '../../components/TraitsEntry'

import { Modal } from '../../components/ui'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 450px;
  position: relative;
`

export const EditTraits = ({ onDismiss, params }) => {
  const { category } = params
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title={`${category.label} Traits`}
    >
      {() => (
        <ModalWrap>
          <TraitsEntry disableCategories selectedCategory={category.value} />
        </ModalWrap>
      )}
    </Modal>
  )
}
