import React, { useEffect, useRef } from 'react'
import { createChart, LineStyle } from 'lightweight-charts'

export const TribeForumChart = ({ parentRef, ...props }) => {
  const myRef = useRef()
  const chartRef = useRef()

  useEffect(() => {
    const width = myRef.current.offsetWidth
    setChart({ ...props, width })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setChart = ({
    backgroundColor = '#fff',
    scaleEnabled = true,
    width = 300,
    height = 300,
    areaData,
    lineData,
    handleScroll = {
      vertTouchDrag: false
    }
  } = {}) => {
    if (!myRef || !myRef.current) return

    chartRef.current = createChart(myRef.current, {
      width,
      height
    })

    chartRef.current.applyOptions({
      handleScroll,
      handleScale: false,
      layout: {
        textColor: '#494949',
        backgroundColor
      },
      rightPriceScale: {
        visible: scaleEnabled,
        borderVisible: false
      },
      leftPriceScale: {
        visible: scaleEnabled && lineData && lineData.length > 0,
        borderVisible: false
      },
      timeScale: {
        visible: scaleEnabled,
        tickMarkFormatter: (time, tickMarkType) => {
          return `${time.month}/${time.day}`
        },
        borderVisible: false,
        fixLeftEdge: true
      },
      crosshair: {
        vertLine: {
          width: 5,
          color: 'rgba(224, 227, 235, 0.1)',
          style: 0
        },
        horzLine: {
          visible: false,
          labelVisible: false
        }
      },
      grid: {
        vertLines: {
          visible: false,
          color: 'rgba(42, 46, 57, 0)'
        },
        horzLines: {
          visible: true,
          color: 'rgba(223,223,223,1)',
          style: LineStyle.Dashed
        }
      }
    })

    if (areaData) {
      chartRef.current
        .addAreaSeries({
          topColor: 'rgba(111, 98, 255, 0.3)',
          bottomColor: 'rgba(111, 98, 255, 0)',
          lineColor: 'rgba(111,98,255,1)',
          lineWidth: 2,
          crossHairMarkerVisible: false
        })
        .setData(areaData)
    }

    if (lineData) {
      chartRef.current.addLineSeries({ priceScaleId: 'left' }).setData(lineData)
    }
  }

  return <div ref={myRef} id="chart" />
}
