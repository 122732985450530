import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { InlineTags } from '../../../screens/Home/components/CommunityPosts/components'
import { UBDI_TRIBE_ID } from '../../../screens/TribeDetails/_utils'
import { FollowButton } from '../../../screens/Profile/components/FollowButton'
import { path } from 'ramda'
import { getUbdiTribeSettings } from '../../../screens/Home/components/Tribes/util'

const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme, isLastItem }) => (isLastItem ? 'none' : theme.color.purpleL3)};
  padding: ${({ theme }) => `${theme.padding.m} 0`};
`

const FollowersListItemInner = ({ item, isLastItem, currentUser }) => {
  const ubdiTribeSettings = getUbdiTribeSettings({ member: item })
  const currentMemberId = path(['member', 'id'], currentUser)
  const isOwnProfile = !!(item.id === currentMemberId)

  return (
    <ItemWrap isLastItem={isLastItem}>
      <Link to={`/profile/${item.id}/${UBDI_TRIBE_ID}`}>
        <InlineTags
          avatarId={item.id}
          publicTagCategories={ubdiTribeSettings.publicTags}
          tags={item.publicTags}
          numberOfTags={3}
          member={item}
        />
      </Link>
      {!isOwnProfile && <FollowButton member={item} />}
    </ItemWrap>
  )
}

export const FollowersListItem = FollowersListItemInner
