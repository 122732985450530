import React from 'react'
import styled, { css } from 'styled-components/macro'
import { pathOr, head, isEmpty } from 'ramda'

import { CompetitionLeaderboardQuery, UserQuery } from '../../../state/queries'
import { Graphic, Spacing, Text } from '../../../components/ui'
import { InlineTags } from '../../Home/components/CommunityPosts/components'
import { tribeColorSchema } from '../../../components/AvatarColorPicker/constants'
import { useQuery } from '@apollo/react-hooks'

const BOARD_LENGTH = 5

export const CompetitionEntriesLeaderboard = ({ post }) => {
  const { competition } = post
  const { id, positionInLeaderboard, tribeId } = competition

  const {
    data: leaderboardCompetitionData
  } = useQuery(CompetitionLeaderboardQuery, { variables: { id } })

  const { data: currentUserData } = useQuery(UserQuery)

  const competitionLeaderboard = pathOr(
    [],
    ['competitionLeaderboard'],
    leaderboardCompetitionData
  )
  const currentUser = pathOr({}, ['currentUser'], currentUserData)

  const showBorder = ({ index }) => {
    if (positionInLeaderboard > BOARD_LENGTH) {
      return true
    }
    return index !== competitionLeaderboard.slice(0, BOARD_LENGTH).length - 1
  }

  if (isEmpty(competitionLeaderboard)) return renderEmpty()

  return (
    <Spacing size="l">
      <Spacing size="l">
        {competitionLeaderboard.slice(0, BOARD_LENGTH).map((entry, index) => (
          <BottomWrap key={entry.id} showBorder={showBorder({ index })}>
            <LeaderboardRow
              position={index + 1}
              tribeId={tribeId}
              entry={entry}
              currentUser={currentUser}
            />
          </BottomWrap>
        ))}
      </Spacing>
      {positionInLeaderboard > BOARD_LENGTH && (
        <BottomWrap>
          <LeaderboardRow
            position={positionInLeaderboard}
            tribeId={tribeId}
            entry={{
              memberId: currentUser.member && currentUser.member.id
            }}
          />
        </BottomWrap>
      )}
    </Spacing>
  )
}

const renderEmpty = () => (
  <EmptyWrap>
    <TextWrap>
      <Text color="dark1" h6>
        There are no any competition entries yet.
      </Text>
    </TextWrap>
  </EmptyWrap>
)

export const LeaderboardRow = ({ entry, position, currentUser }) => {
  const {
    colors,
    publicTags,
    publicTagCategories,
    memberId,
    publicMember
  } = entry
  const avatarColors = colors || head(tribeColorSchema)

  const isCurrentMember = ({ memberId }) =>
    currentUser.member && currentUser.member.id === memberId

  return (
    <Spacing direction="row" size="l" align="center">
      <Text h6 color="dark">
        {position}.
      </Text>

      {isCurrentMember({ memberId }) ? (
        <Spacing direction="row" size="s" align="center">
          <Graphic
            name="IncognitoAvatar"
            size={30}
            color={head(avatarColors)}
          />
          <Text h6 color="dark">
            Your entry ranking
          </Text>
        </Spacing>
      ) : (
        <InlineTags
          avatarId={`avatar-${memberId}-${position}`}
          colors={colors}
          tags={publicTags}
          publicTagCategories={publicTagCategories}
          member={publicMember}
          numberOfTags={2}
        />
      )}
    </Spacing>
  )
}

const EmptyWrap = styled.div`
  display: flex;
  flex: 1;
  height: 170px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.purpleL3};
  border-radius: 10px;
  padding: ${({ theme }) => `0px ${theme.padding.xl}`};
`

const TextWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const BottomWrap = styled.div`
  ${({ theme, showBorder }) =>
    showBorder &&
    css`
      padding-bottom: ${({ theme }) => theme.padding.l};
      border-bottom: 1px solid ${theme.color.dark2};
    `}
`
