import React from 'react'
import { AdCard } from './AdCard'
import { StudyCard } from './StudyCard'

export const OpportunityCard = ({ category, loading, ...props }) => {
  if (loading) return null // TODO: <LoadingOpportunityCard {...props} />
  if (category === 'Ad') return <AdCard {...props} />

  return <StudyCard {...props} />
}
