import React from 'react'
import styled from 'styled-components'
import { Box, Graphic, Spacing, Text } from '../../../components/ui'

export const DetailsBox = ({ title, description, graphicName, children }) => {
  return (
    <BoxWrap>
      <Spacing>
        <Spacing direction="row" justify="space-between" stretched>
          <Spacing size="xs">
            <Text t0 bold>
              {title}
            </Text>
            <Text t2 color="main">
              {description}
            </Text>
          </Spacing>
          <GraphicWrap>
            <Graphic name={graphicName} size={25} />
          </GraphicWrap>
        </Spacing>
        {children && <>{children}</>}
      </Spacing>
    </BoxWrap>
  )
}

const BoxWrap = styled(Box)`
  padding: ${({ theme }) => `${theme.padding.l} ${theme.padding.xl}`};
`

const GraphicWrap = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.gradient.gradient3};
`
