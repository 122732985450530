import React, { useEffect, useState } from 'react'
import Lottie from 'lottie-web-react'
import { Modal, Text } from '../../components/ui'
import styled from 'styled-components'
import { filter, keys, map, pathOr, pipe } from 'ramda'
import { withMutation } from '../../components/wrappers'

const BOMB_PATH = require('../../animations/boom.json')

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

const ErasedDataWrapper = styled.div`
  padding: ${({ theme }) =>
    `150px ${theme.padding.xxxl} 200px ${theme.padding.xxxl}`};
`

const removeFromLocalStorage = pipe(
  keys,
  filter(key => key.includes('account_')),
  map(key => localStorage.removeItem(key))
)
const DataBombInner = ({
  params,
  onDismiss,
  updateMember,
  unlinkDataSource
}) => {
  const { member } = params
  const [initedDestroy, setInitedDestroy] = useState(false)
  const [completedDestroy, setCompletedDestroy] = useState(false)

  const linkedDataSources = pathOr([], ['linkedDataSources'], member)
  useEffect(() => {
    // Remove all traits and tribes except UBDI
    const initialTags = [
      {
        uniqueId: 'system_groups',
        values: ['system_groups_ubdi_0']
      }
    ]
    updateMember({ variables: { tags: initialTags } })

    // Unlink sources
    linkedDataSources.map(({ dataSource }) =>
      unlinkDataSource({ variables: { dataSourceId: dataSource.id } })
    )
    // Remove linked accounts from local storage
    removeFromLocalStorage(localStorage)

    // Animation shows
    setInitedDestroy(true)
    setTimeout(() => {
      setCompletedDestroy(true)
      setTimeout(() => {
        onDismiss()
      }, 30000)
    }, 1800)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderContent = () => {
    if (completedDestroy) {
      return (
        <ErasedDataWrapper>
          <Text h1 white center>
            Your data has been erased!
          </Text>
        </ErasedDataWrapper>
      )
    }

    if (initedDestroy) {
      return (
        <Lottie
          options={{
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: BOMB_PATH
          }}
          playingState="play"
        />
      )
    }
  }
  return (
    <Modal isOpen onDismiss={onDismiss}>
      {() => <ModalWrap>{renderContent()}</ModalWrap>}
    </Modal>
  )
}

export const DataBomb = pipe(
  withMutation('updateMember'),
  withMutation('unlinkDataSource')
)(DataBombInner)
