import React from 'react'
import { pathOr, prop } from 'ramda'
import { useQuery } from 'react-apollo'
import styled from 'styled-components/macro'

import { Button, Modal, Spacing, Text } from '../../components/ui'
import { withMutation } from '../../components/wrappers'
import { useDataSourcesStatuses } from '../../hooks'
import ndlProvider from '../../providers/ndlProvider'
import { DataSourceCard } from '../../screens/DataBank/components'
import { getDataSources } from '../../screens/TribeDetails/util'
import {
  DataSchemaQuery,
  DataSourcesQuery,
  UserQuery
} from '../../state/queries'
import { getAllAccountsFromLocalStorage } from '../../util'
import { getAvailableObjectTypesForSource } from '../../util/dataSources'

const ModalWrap = styled.div`
  width: 650px;
  position: relative;
`

const ButtonWrap = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  padding: ${({ theme }) => `0 ${theme.padding.s}`};
  border: ${({ theme }) => theme.color.main} 1px solid;
  border-radius: 100px;
  background: ${({ isoptedin, theme }) =>
    isoptedin ? theme.color.white : theme.color.main};
  cursor: pointer;
`

export const DataStreamOptInInner = ({ onDismiss, updateMember }) => {
  const { sourceStatuses } = useDataSourcesStatuses({})
  const { data = {}, loading } = useQuery(DataSourcesQuery)
  const { data: currentUserData = {}, loading: currentUserLoading } = useQuery(
    UserQuery
  )
  const { data: dataSchemaData } = useQuery(DataSchemaQuery)

  if (loading || currentUserLoading) {
    return null
  }

  const member = pathOr({}, ['currentUser', 'member'], currentUserData)

  const {
    allDataSources: { edges }
  } = data

  const dataSourcesToRender = getDataSources(
    edges,
    member.linkedDataSources || []
  ).filter(({ linked }) => linked)

  const onOptIn = async dataSourceId => {
    const accountIds = getAllAccountsFromLocalStorage(dataSourceId).map(
      prop('accountId')
    )
    const { dataSchema } = dataSchemaData
    const objectTypeIds = getAvailableObjectTypesForSource(
      dataSourceId,
      dataSchema
    )

    await updateMember({
      variables: {
        publicDataSourceId: dataSourceId
      }
    })

    await ndlProvider.updateAccountConfiguration({
      accountIds,
      publicObjectTypes: objectTypeIds
    })

    await Promise.all(
      accountIds.map(accountId =>
        ndlProvider.triggerAccountSync({ accountId, objectTypeIds })
      )
    )
  }

  const renderOptIn = ({ id }) => {
    const isOptedIn =
      member.publicProfileSourceOptIns &&
      member.publicProfileSourceOptIns.find(
        ({ dataSourceId }) => dataSourceId === id
      )

    return (
      <ButtonWrap isoptedin={isOptedIn}>
        <Text h2 color={isOptedIn ? 'main' : 'white'} center>
          {isOptedIn ? '-' : '+'}
        </Text>
      </ButtonWrap>
    )
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Add new data streams"
    >
      {() => (
        <ModalWrap>
          <Spacing size="xl">
            <Text t2>
              Select sources linked in your Data Bank to show in your profile
              data stream:
            </Text>
            <Spacing size="s">
              {dataSourcesToRender.map(source => (
                <DataSourceCard
                  key={source.id}
                  {...source}
                  setSelectedSourceId={onOptIn}
                  status={sourceStatuses[source.id]}
                  renderAction={() => renderOptIn({ id: source.id })}
                />
              ))}
            </Spacing>
            <Text t2 center>
              All of your activities will be shown
            </Text>
          </Spacing>

          <Modal.Footer />
        </ModalWrap>
      )}
    </Modal>
  )
}

export const DataStreamOptIn = withMutation('updateMember')(
  DataStreamOptInInner
)
