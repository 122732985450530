import React from 'react'
import styled from 'styled-components'
import { Graphic } from '../ui'
import { tribeColorSchema } from '../AvatarColorPicker/constants'

const getProfileColor = member => {
  if (member && member.profileColor) {
    return member.profileColor
  }
  return tribeColorSchema[0][0]
}

export const Avatar = ({ size = 26, member, avatarId }) => {
  if (member && member.profileImage && member.profileImage.thumbnail) {
    return <Image size={size} src={member.profileImage.thumbnail} />
  }

  const color = getProfileColor(member)

  return (
    <Graphic
      name="AvatarIncognito"
      size={size}
      color={color}
      avatarId={avatarId}
    />
  )
}

const Image = styled.img`
  display: block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
`
