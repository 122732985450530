import React from 'react'
import styled from 'styled-components/macro'
import { Text } from '../../../../../../components/ui'

const HorizontalOuterBase = styled.div`
  background-color: ${({ theme }) => theme.color.purpleL4};
  height: 20px;
  flex: 1;
  flex-direction: row;
  display: flex;
`

const HorizontalInnerBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width};
`

const HorizontalTextPercentage = styled.div`
  padding: ${({ theme }) => `0px ${theme.padding.s}`};
`

export const PollResultHorizontal = ({
  showLabel = true,
  shouldLabelBeInside,
  width,
  backgroundColor
}) => (
  <HorizontalOuterBase>
    <HorizontalInnerBase backgroundColor={backgroundColor} width={`${width}%`}>
      {showLabel && shouldLabelBeInside && (
        <HorizontalTextPercentage>
          <Text h7 color="white">
            {Math.round(width)}%
          </Text>
        </HorizontalTextPercentage>
      )}
    </HorizontalInnerBase>
    {showLabel && !shouldLabelBeInside && (
      <HorizontalTextPercentage>
        <Text h7>{Math.round(width)}%</Text>
      </HorizontalTextPercentage>
    )}
  </HorizontalOuterBase>
)
