import { gql } from 'apollo-boost'

export const EntryFragment = gql`
  fragment Entry on Entry {
    id
    image {
      thumbnail
      default
      large
    }
    videoSrc
    publicTags
    memberId
    createdAt
    liked
    likes
    totalLikes
    colors
    publicTagCategories
  }
`
