import React from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import { Spacing, Text } from '../../../components/ui'
import { InlineTags } from '../../Home/components/CommunityPosts/components'
import { Formats } from '../../../constants'
import { ComptitionEntryLike } from '../components'

const CardWrap = styled.div`
  padding: ${({ theme }) => theme.padding.l};
  border: 1px solid ${({ theme }) => theme.color.purpleL2};
  border-radius: 10px;
`

const StyledImage = styled.img`
  height: 350px;
  width: 300px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
`

export const CompetitionEntry = ({ entry, competition }) => {
  const {
    id: entryId,
    createdAt,
    image,
    publicTags,
    colors,
    publicTagCategories,
    publicMember
  } = entry
  const { tribeId } = competition
  const { memberId } = entry

  return (
    <CardWrap>
      <Spacing size="s">
        <Spacing>
          <Link
            style={{ color: 'inherit' }}
            to={`/profile/${memberId}/${tribeId}`}
          >
            <InlineTags
              avatarId={entryId}
              colors={colors}
              tags={publicTags}
              publicTagCategories={publicTagCategories}
              member={publicMember}
            />
          </Link>
          {image && (
            <div onClick={() => window.open(image.default)}>
              <StyledImage src={image.default} />
            </div>
          )}
        </Spacing>
        <Spacing direction="row" justify="space-between" align="center">
          {createdAt && (
            <Text t3 brandBlack>
              {format(new Date(parseInt(createdAt)), Formats.dates.imperial)}
            </Text>
          )}
          <ComptitionEntryLike competition={competition} entry={entry} />
        </Spacing>
      </Spacing>
    </CardWrap>
  )
}
