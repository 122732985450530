import React, { useState, useEffect } from 'react'

import { useQuery } from 'react-apollo'
import { Spacing, Text } from '../../../../components/ui'
import { DataStreamResultsQuery } from '../../../../state/queries'
import theme from '../../../../theme'
import { TribeForumChart } from '../../../TribeDetails/components'
import { getQueryResult } from '../../../TribeDetails/util'
import {
  getDayHoldings,
  getTickerHistoryData
} from '../../../TribeDetails/_utils'

export const InvestorForumChart = ({ forum, userGroup }) => {
  // History price calculation
  const [tickerHistory, setTickerHistory] = useState([])
  useEffect(() => {
    async function getHistory() {
      const data = await getTickerHistoryData({ ticker: forum.value })
      setTickerHistory(data)
    }

    getHistory()
  }, [forum.value])

  const { data, loading } = useQuery(DataStreamResultsQuery, {
    variables: {
      dataStreamId: userGroup.publicDataStreamId,
      first: 200
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  })

  if (loading) return null

  const trades = getQueryResult(data.dataStreamResults, forum.dataStreamQueryId)

  const areaData = tickerHistory.map(({ date, close }) => ({
    value: close,
    time: date
  }))

  const dayHoldings = getDayHoldings({ trades, dates: tickerHistory })

  return (
    <Spacing>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Spacing direction="row" size="s">
          <LegendItem backgroundColor={theme.color.main}>Price</LegendItem>
          <LegendItem backgroundColor={theme.color.secondary}>
            Member Holdings
          </LegendItem>
        </Spacing>
      </div>
      {areaData.length > 0 && (
        <TribeForumChart
          backgroundColor={theme.color.purpleL4}
          height={300}
          width={650}
          areaData={areaData}
          lineData={dayHoldings}
        />
      )}
    </Spacing>
  )
}

const LegendItem = ({ backgroundColor, children }) => (
  <Spacing direction="row" align="center" size="xs">
    <div style={{ width: '12px', height: '12px', backgroundColor }} />
    <Text h7 bold>
      {children}
    </Text>
  </Spacing>
)
