import { gql } from 'apollo-boost'

export const CompanyFragment = gql`
  fragment Company on Company {
    id
    typeformConnectUrl
    connectedTypeform
    photo {
      thumbnail
      default
      large
    }
    paymentBalance
    paymentMethod {
      cardType
      cardMaskedNumber
    }
  }
`
