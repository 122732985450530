import { useState, useEffect } from 'react'
import {
  pathOr,
  isEmpty,
  pipe,
  find,
  propEq,
  propOr,
  map,
  prop,
  includes,
  flatten
} from 'ramda'
import { useQuery } from 'react-apollo'
import {
  AllUserGroupsQuery,
  AudienceDataVerifiedTagsWithDataSchemaQuery,
  DataSourcesQuery
} from '../state/queries'
import { useModal } from './'
import { getAvailableServiceProviders } from '../state/local/serviceProviders'
import {
  getAllAccountsFromLocalStorage,
  getCredentialsFromLocalStorage,
  removeAllAccountsFromLocalStorage
} from '../util'

import {
  linkDataSource,
  startSyncAccounts,
  unlinkDataSource
} from '../state/mutations'
import {
  filterAndSortDataSources,
  getInitialSourceStatuses
} from '../state/selectors'
import { Sources } from '../constants'
import { getMissingSourcesAndTagsForTribe } from '../screens/TribeDetails/util'
import { useMutation } from '@apollo/react-hooks'

export const useDataSourcesStatuses = ({
  onCompleted = () => {},
  refetchQueries = []
} = {}) => {
  const [sourceStatuses, setSourceStatuses] = useState({})

  const [linkDataSourceMutation] = useMutation(linkDataSource)
  const [unlinkDataSourceMutation] = useMutation(unlinkDataSource)
  const [startSyncAccountsMutation] = useMutation(startSyncAccounts)

  const { data: dataSourcesData } = useQuery(DataSourcesQuery, {
    pollInterval: 5000
  })
  const { data: allUserGroupsData } = useQuery(AllUserGroupsQuery)
  const { data: audienceVerifiedData } = useQuery(
    AudienceDataVerifiedTagsWithDataSchemaQuery
  )

  const { openModal } = useModal()

  const allDataSources = pathOr(
    [],
    ['allDataSources', 'edges'],
    dataSourcesData
  )

  const allGroups = pipe(
    pathOr([], ['allUserGroups', 'edges']),
    map(prop('node'))
  )(allUserGroupsData)

  const audienceDataVerifiedTags = propOr(
    [],
    'audienceDataVerifiedTags',
    audienceVerifiedData
  )

  const linkedDataSources = pathOr(
    [],
    ['currentUser', 'linkedDataSources'],
    dataSourcesData
  )

  const hasDoneFinalSync = pathOr(
    true,
    ['currentUser', 'member', 'finalAccountSyncDone', 'webapp'],
    dataSourcesData
  )

  const tags = pathOr([], ['currentUser', 'member', 'tags'], dataSourcesData)

  const memberGroups = pipe(
    find(propEq('uniqueId', 'system_groups')),
    propOr([], 'values')
  )(tags)

  const notMemberGroups = allGroups.filter(
    ({ id }) => !includes(id, memberGroups)
  )

  const sources = filterAndSortDataSources(allDataSources, linkedDataSources)

  const syncAllAccountsToRemote = () => {
    const allAccounts = getAllAccountsFromLocalStorage()
    const accounts = allAccounts
      .map(item => {
        const availableProviders = getAvailableServiceProviders(
          item.dataSourceId
        )
        if (isEmpty(availableProviders)) return false

        const providerId = pathOr(null, ['0', 'id'], availableProviders)
        if (!providerId) return false

        return {
          ...item,
          providerId,
          origin: 'webapp'
        }
      })
      .filter(Boolean)

    return startSyncAccountsMutation({ variables: { accounts } })
  }

  /*
    Send locally linked accounts to the backend
  */
  useEffect(() => {
    if (!hasDoneFinalSync) syncAllAccountsToRemote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDoneFinalSync])

  useEffect(() => {
    setSourceStatuses(getInitialSourceStatuses(sources))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources.length, linkedDataSources.length])

  const setSourceStatus = ({ dataSourceId, status }) =>
    setSourceStatuses({ ...sourceStatuses, [dataSourceId]: status })

  const onSync = () => {
    syncAllAccountsToRemote()
    openModal('SyncAccounts')
  }

  const onCompletedLink = ({ dataSourceId, providerId }) => {
    const { accountId, encKey } = getCredentialsFromLocalStorage(dataSourceId)
    if (linkDataSourceMutation) {
      linkDataSourceMutation({
        variables: {
          dataSourceId,
          accountId,
          encKey,
          providerId,
          origin: 'webapp'
        },
        refetchQueries
      }).then(({ data, errors, userErrors }) => {
        if (errors || userErrors) return errors || userErrors

        const linkedDataSourcesNew = pathOr(
          [],
          ['linkDataSource', 'query', 'currentUser', 'linkedDataSources'],
          data
        )

        const avaiableGroupsToJoin = notMemberGroups.filter(tribe => {
          const {
            missingSources,
            allTribeSources
          } = getMissingSourcesAndTagsForTribe({
            tags,
            audienceDataVerifiedTags,
            tribe,
            linkedDataSources: linkedDataSourcesNew
          })

          if (!includes(dataSourceId, flatten(allTribeSources))) return false

          if (isEmpty(flatten(missingSources))) return tribe

          return false
        })

        if (onCompleted) {
          return onCompleted(linkedDataSourcesNew)
        }

        if (!isEmpty(avaiableGroupsToJoin)) {
          openModal('AvailableTribes', {
            tribes: avaiableGroupsToJoin,
            dataSourceId
          })
        }
      })
    }
  }

  const onUnlinkSource = dataSourceId => {
    unlinkDataSourceMutation({ variables: { dataSourceId } })
    removeAllAccountsFromLocalStorage(dataSourceId)

    setSourceStatus({ dataSourceId, status: Sources.statuses.pending })
  }

  return {
    sourceStatuses,
    sources, //todo: better name,
    setSourceStatus,
    onSync,
    onCompletedLink,
    onUnlinkSource
  }
}
