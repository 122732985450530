import Axios from 'axios'
import React, { createContext, useState } from 'react'
import ndlProvider from '../providers/ndlProvider'

export const PriceContext = createContext({})

export const PRICE_PROVIDERS = {
  binance: 'binance',
  tiingo: 'tiingo'
}

export const PriceContextWrapper = ({ children }) => {
  const [, setFetching] = useState([])
  const [prices, setPrices] = useState({})

  const fetchPrice = (ticker, provider) => {
    setFetching(nowFetching => {
      if (!nowFetching.includes(ticker)) {
        doFetchPrice(ticker, provider)
        return [...nowFetching, ticker]
      }
      return nowFetching
    })
  }

  const setTickerPrice = (ticker, price) =>
    setPrices(existingPrices => ({ ...existingPrices, [ticker]: price }))

  const doFetchPrice = async (ticker, provider) => {
    console.log({ ticker, provider })
    switch (provider) {
      case PRICE_PROVIDERS.binance: {
        const {
          data: { askPrice: price }
        } = await Axios.get('https://api.binance.com/api/v3/ticker/24hr', {
          params: {
            symbol: ticker
          }
        })

        setTickerPrice(ticker, parseFloat(price))
        break
      }
      case PRICE_PROVIDERS.tiingo: {
        console.log('fetching tiingo')
        const [{ data }] = await ndlProvider.getExternalApi({
          provider: PRICE_PROVIDERS.tiingo,
          payload: { symbol: ticker }
        })

        setTickerPrice(ticker, parseFloat(data[0].last_price))
        break
      }
      default:
        break
    }
  }

  return (
    <PriceContext.Provider
      value={{
        fetchPrice,
        prices
      }}
    >
      {children}
    </PriceContext.Provider>
  )
}
