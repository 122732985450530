import { gql } from 'apollo-boost'
import { UserFragment } from '../fragments/User'
import { UserGroupFragment } from '../fragments/UserGroup'

export const AllUserGroupsWithMemberQuery = gql`
  query allUserGroups($first: Int, $customTag: String, $category: String) {
    allUserGroups(first: $first, customTag: $customTag, category: $category) {
      edges {
        node {
          id
          ...UserGroup
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    currentUser {
      id
      ...User
    }
  }
  ${UserGroupFragment}
  ${UserFragment}
`

export const AllUserGroupsQuery = gql`
  query allUserGroups($first: Int, $customTag: String, $category: String) {
    allUserGroups(first: $first, customTag: $customTag, category: $category) {
      edges {
        node {
          id
          ...UserGroup
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${UserGroupFragment}
`

export const UserGroupQuery = gql`
  query UserGroup($id: String!) {
    userGroup(id: $id) {
      id
      ...UserGroup
    }
  }
  ${UserGroupFragment}
`

export const UserGroupWithMemberQuery = gql`
  query UserGroup($id: String!) {
    userGroup(id: $id) {
      id
      ...UserGroup
    }
    currentUser {
      id
      ...User
    }
  }
  ${UserGroupFragment}
  ${UserFragment}
`

export const UserGroupForumSearchQuery = gql`
  query UserGroupForumSearch($tribeId: String!, $query: String!) {
    searchForums(tribeId: $tribeId, query: $query) {
      value
      label
    }
  }
`
