import { gql } from 'apollo-boost'

export default gql`
  mutation SetNewPassword(
    $usernameOrPhoneNumber: String!
    $code: String!
    $password: String!
  ) {
    setNewPassword(
      usernameOrPhoneNumber: $usernameOrPhoneNumber
      code: $code
      password: $password
    ) {
      success
      errors {
        key
        message
      }
    }
  }
`
