export const extractProfilePropsFromUrl = url => {
  // profile deeplink in format: ubdi://profile/${memberId}
  const route = url.replace(/.*?:\/\//g, '')
  const params = route.split('/')

  const [, memberId] = params

  return { memberId }
}

export const extractTribePropsFromUrl = url => {
  // tribe deeplink in format: ubdi://tribe/${tribeId}
  const route = url.replace(/.*?:\/\//g, '')
  const params = route.split('/')

  const [, tribeId] = params

  return { tribeId }
}

export const extractCommunityPollPropsFromUrl = url => {
  // comment deeplink in format: ubdi://communityPoll/${communityPollId}/comment/${communityPollCommentId}
  const route = url.replace(/.*?:\/\//g, '')
  const params = route.split('/')

  const [, communityPollId, , communityPollCommentId] = params

  return { communityPollId, communityPollCommentId }
}
