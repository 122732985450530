import React from 'react'
import styled from 'styled-components/macro'

import { Modal } from '../../components/ui'
import { TribesList } from '../../screens/Home/components'

const ModalWrap = styled.div`
  width: 650px;
  min-height: 450px;
  position: relative;
`

export const ProfileManagement = ({ onDismiss }) => {
  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      onRequestClose={onDismiss}
      title="Traits"
    >
      {() => (
        <ModalWrap>
          <TribesList memberGroups settings outline />
        </ModalWrap>
      )}
    </Modal>
  )
}
