import React from 'react'
import styled from 'styled-components'
import { Text } from '../../../components/ui'

export const TypeButton = ({ text, backgroundColor, textColor, onClick }) => (
  <ButtonWrap backgroundColor={backgroundColor} onClick={onClick}>
    <Text h6 center color={textColor || 'white'}>
      {text}
    </Text>
  </ButtonWrap>
)

const ButtonWrap = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.padding.s};
  background: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.color.main};
  border: 1px solid ${({ backgroundColor, theme }) =>
    backgroundColor || theme.color.main}};
  &:hover {
    border: 1px solid ${({ backgroundColor, theme }) =>
      backgroundColor || theme.color.main}};
  }
  width: 150px;
  border-radius: ${({ theme }) => theme.borderRadius.base};
`
