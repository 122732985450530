import React from 'react'
import { graphql } from 'react-apollo'
import { pipe, includes, equals, filter } from 'ramda'

import { AudienceSchemaQuery } from '../../../state/queries'
import { Spacing } from '../../ui'
import {
  getTagFromProfileSchema,
  retrofitAudienceSchema
} from '../../../state/selectors'
import { TraitCard } from './TraitCard'
import { ALWAYS_VISIBLE_TAGS } from '../../AvatarColorPicker/constants'
import ProfileTagValues from './ProfileTagValues'
import { withFieldError } from '../../wrappers'

const addTag = (publicTags, tag) => [...publicTags, tag]

const removeTag = (publicTags, tag) =>
  filter(publicTag => !equals(publicTag, tag))(publicTags)

const TraitsList = ({
  currentUser,
  filterCategories,
  publicTags,
  profileSchema,
  input
}) => {
  const onTagClick = tag => {
    if (includes(tag, publicTags)) {
      input.onChange(removeTag(publicTags, tag))
    } else {
      input.onChange(addTag(publicTags, tag))
    }
  }

  if (profileSchema.original.length === 0) {
    return null
  }

  return (
    <Spacing size="l">
      <ProfileTagValues
        currentUser={currentUser}
        tagCategories={ALWAYS_VISIBLE_TAGS.concat(publicTags)}
      />

      <Spacing size="xs">
        {filterCategories.map((category, index) => {
          return (
            <TraitCard
              alwaysVisibleTags={ALWAYS_VISIBLE_TAGS}
              key={`${category}-${index}`}
              selectedTag={includes(category, publicTags)}
              tag={getTagFromProfileSchema(profileSchema, category)}
              onTagClick={() => onTagClick(category)}
            />
          )
        })}
      </Spacing>
    </Spacing>
  )
}

export const TribeTraitsList = pipe(
  graphql(AudienceSchemaQuery, { props: retrofitAudienceSchema }),
  withFieldError()
)(TraitsList)
