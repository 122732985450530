import NDLProvider from '../../../providers/ndlProvider'
import { pathOr } from 'ramda'

const authorize = ({ onSuccess, onFail }) => async dispatch => {
  try {
    await NDLProvider.authorize()

    onSuccess && onSuccess()
  } catch (err) {
    console.log('Error initializing UBDI Sources', err)

    alert(
      'Authorization with UBDI Sources failed',
      'If you believe this to be an issue, please shake the app to report it.'
    )
    onFail && onFail()
    return false
  }
}

const startLinkingSource = async ({
  dataSourceId,
  onComplete,
  onError,
  openModal
}) => {
  const { accountId, encryptionKey } = await NDLProvider.createAccount(
    dataSourceId
  )

  const sourceConfig = pathOr(
    {},
    ['dataSourcesConfig', dataSourceId],
    NDLProvider.config
  )

  const registerAccountPromise = NDLProvider.registerAccount({
    registerAccountId: accountId,
    dataSourceId,
    encryptionKey
  })

  const onErrorLink = () => {
    localStorage.removeItem(`account_${accountId}`)
    onError()
  }

  const onCompletedLink = linked => {
    if (!linked) {
      onComplete && onComplete(false)

      return onErrorLink()
    }
    onComplete && onComplete(linked)
  }

  if (sourceConfig.useCustomLocalFlow) {
    switch (dataSourceId) {
      case 'metamask':
        if (typeof window.ethereum !== 'undefined') {
          const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts'
          })
          const [address] = accounts

          const linked = await NDLProvider.saveToken({
            accountId,
            dataSourceId,
            address
          })

          return onCompletedLink(linked)
        } else {
          alert('You do not have Metamask installed in the browser')
          return onErrorLink()
        }
      default:
        return
    }
  }

  if (sourceConfig.useManualLoginFlow) {
    return openModal('LinkSourceLogin', {
      dataSourceId,
      accountId,
      onComplete,
      registerAccountPromise
    })
  }

  await startOAuthFlow({
    registerAccountPromise,
    accountId,
    dataSourceId,
    onComplete: onCompletedLink,
    onError: onErrorLink
  })
}

const startOAuthFlow = async ({
  registerAccountPromise,
  accountId,
  dataSourceId,
  onComplete,
  onError
}) => {
  const { requiresAuthorizationTokenInRedirectUrl } = pathOr(
    {},
    ['dataSourcesConfig', dataSourceId],
    NDLProvider.config
  )

  // Open and focus popup
  const spawnedWindow = window.open(
    '/oauthloading',
    'Loading',
    'toolbar=no, menubar=no, width=600, height=800, top=100, left=100'
  )
  spawnedWindow && spawnedWindow.focus()

  const onCloseListener = setInterval(() => {
    if (spawnedWindow && spawnedWindow.closed) {
      onError()
      clearInterval(onCloseListener)
    }
  }, 100)

  const redirectUrl = await NDLProvider.getRedirectUrl({
    dataSourceId,
    accountId
  })

  console.log('startOAuthFlow', { accountId, redirectUrl })

  const redirectPopupToOAuth = (spawnedWindow, url) => {
    const onMessageReceived = async event => {
      console.log('Received event', event)
      // Security check if message is coming from Auth URL
      if (event.origin !== process.env.REACT_APP_AUTH_URL) return

      const { data: redirectUrl } = event
      if (redirectUrl) {
        window.removeEventListener('message', onMessageReceived)
        clearInterval(onCloseListener)

        const search = redirectUrl.split('?')
        const hash = redirectUrl.split('#')
        const urlInfo = search[1] || hash[1]

        await registerAccountPromise

        if (urlInfo) {
          try {
            const linked = await NDLProvider.exchangeToken({
              accountId,
              dataSourceId,
              redirectUrl
            })

            return onComplete(linked)
          } catch (e) {
            onError(e)
          }
        }
      }
    }

    // Redirect the open popup to the real OAuth URL
    spawnedWindow.location.assign(url)

    // Attach listeners
    window.addEventListener('message', onMessageReceived, false)
  }

  if (redirectUrl) {
    if (requiresAuthorizationTokenInRedirectUrl) {
      await registerAccountPromise
    }
    redirectPopupToOAuth(spawnedWindow, redirectUrl)
  }
}

const getData = async ({ dataQueries, connectedAccounts, dataRequest }) => {
  const { opportunityId, shortId, responseToken } = dataRequest

  const kickoffs = await NDLProvider.kickoffOpportunity({
    dataQueries,
    connectedAccounts,
    opportunityId,
    shortId,
    responseToken
  })

  console.log(
    'UBDI Sources kicked off data queries',
    kickoffs,
    dataQueries,
    connectedAccounts
  )

  return { kickoffs }
}

export default {
  authorize,
  startLinkingSource,
  getData
}
