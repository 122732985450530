import React, { useEffect } from 'react'

import { prop, pathOr, sortWith, descend } from 'ramda'
import styled from 'styled-components'
import { RedeemableOpportunitiesQuery } from '../../../state/queries/OpportunitiesScreenQueries'
import { Graphic, Spacing, Text } from '../../../components/ui'
import { OpportunitiesList } from './OpportunitiesList'
import { useMatchedOpportunities } from '../../../hooks'

const Opportunities = ({
  variant,
  navigation,
  tribeId,

  currentUser
}) => {
  const selectedVariant = pathOr(
    variant,
    ['state', 'params', 'variant'],
    navigation
  )

  const earnPreferences = pathOr({}, ['member', 'earnPreferences'], currentUser)

  const variables = {
    ...RedeemableOpportunitiesQuery.possibleVariants[selectedVariant],
    ...({ tribeId } || {})
  }

  const {
    loading: loadingAllOpportunities,
    allOpportunities,
    refetch
  } = useMatchedOpportunities(RedeemableOpportunitiesQuery, { variables })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earnPreferences])

  const opportunities = sortOpportunities(allOpportunities)

  if (loadingAllOpportunities || !selectedVariant) {
    return (
      <Wrapper>
        <Graphic name="Loading" width={100} height={60} themeColor="main" />
      </Wrapper>
    )
  }

  return (
    <ContentWrap>
      <Spacing size="s">
        {opportunities && opportunities.length ? (
          <OpportunitiesList
            hideSubtitle
            opportunities={opportunities}
            currentUser={currentUser}
          />
        ) : (
          renderEmpty()
        )}
      </Spacing>
    </ContentWrap>
  )
}

const sortOpportunities = sortWith([descend(prop('isJoinable'))])
const renderEmpty = () => (
  <Text t3 center>
    No matching opportunities to show. :(
  </Text>
)

export default Opportunities

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
const ContentWrap = styled.div``
