import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Text } from '../../ui'
import { Spacing } from '../Spacing'

const ProgressWrapper = styled.div`
  width: 100%;
  height: 10px;
  background: ${({ theme }) => theme.color.purpleL3};
  overflow: hidden;
  border-radius: 10px;
`

const ProgressInner = styled.div`
  ${({ progress, color, theme, barThemeColor }) => {
    const backgroundColor = color
      ? color
      : barThemeColor
      ? theme.color[barThemeColor]
      : theme.color.main

    return css`
      width: ${progress}%;
      height: 10px;
      border-radius: 10px;
      background: ${backgroundColor};
    `
  }}
`

export const ProgressBar = ({
  total,
  current,
  color,
  barThemeColor = 'main',
  showLabel = true,
}) => {
  const progress = total ? (current / total) * 100 : 0

  return (
    <Spacing>
      {showLabel && <Text t2 center>{`${current} out of ${total}`}</Text>}
      <ProgressWrapper>
        <ProgressInner
          progress={progress}
          barThemeColor={barThemeColor}
          color={color}
        />
      </ProgressWrapper>
    </Spacing>
  )
}
