import { isEmpty, isNil, path, pathOr, prop } from 'ramda'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  ColorizedHeader,
  Graphic,
  Spacing,
  Tabs,
  Text
} from '../../components/ui'
import {
  UserGroupWithMemberQuery,
  LastActiveCompetitionQuery,
  AllCommunityPollsQuery
} from '../../state/queries'
import theme from '../../theme'
import { CommunityPosts } from '../Home/components'
import { checkIsTribeMember } from '../Home/components/Tribes/util'
import {
  TribeForums,
  TribeLeaderboard,
  JoinTribe,
  DataFeed
} from './components'
import { CreatePost } from '../Home/components/CommunityPosts/components'
import { getHasAdminRole } from '../../util/user'
import { UBDI_TRIBE_ID } from './_utils'
import { LoadingBlanket } from '../DataBank/DataStream/components'
import { CompetitionDataLeaderboard } from '../Competition/components/CompetitionDataLeaderboard'

export const TribeDetailsScreen = ({ match: { params: { tribeId } } = {} }) => {
  const { data } = useQuery(UserGroupWithMemberQuery, {
    variables: { id: tribeId }
  })

  const userGroup = prop('userGroup', data)
  const member = pathOr({}, ['currentUser', 'member'])(data)
  const hasAdminRole = getHasAdminRole(data)
  const isTribeMember = checkIsTribeMember({ tribeId, member })

  const showCreatePost = !!(
    getPermission({ hasAdminRole, tribeId }) && isTribeMember
  )

  const renderPosts = () => {
    return (
      <Spacing stretched>
        {!isTribeMember && <JoinTribe tribeId={tribeId} />}
        <CommunityPosts tribeId={tribeId} viewOnly={!isTribeMember} />
      </Spacing>
    )
  }

  const renderDataFeed = () => {
    return (
      <DataFeed
        tribeId={userGroup.id}
        dataQuery={userGroup.dataQuery}
        publicDataStreamId={userGroup.publicDataStreamId}
        viewOnly={!isTribeMember}
      />
    )
  }

  const renderRightContent = () => {
    if (!userGroup) return null
    if (!isNil(userGroup.forums) && !isEmpty(userGroup.forums)) {
      return <TribeForums tribe={userGroup} />
    }
  }

  return (
    <>
      <ColorizedHeader
        background={getGradient(userGroup && userGroup.gradient)}
      >
        <HeaderContentWrapper
          direction="row"
          size="l"
          align="center"
          justify="center"
        >
          {userGroup && <Image src={userGroup.logoUrl} />}
          <Text h2 color="white" center>
            {userGroup ? userGroup.name : 'Loading...'}
          </Text>
        </HeaderContentWrapper>
      </ColorizedHeader>
      <TribeDetailsWrapper>
        <LeftPart>
          <LeaderboardComponent userGroup={userGroup} />
        </LeftPart>
        {userGroup ? (
          <CenterPart>
            {showCreatePost && isTribeMember && (
              <CreatePost
                currentTribe={{
                  id: userGroup.id,
                  imgUrl: userGroup.logoUrl,
                  label: userGroup.name
                }}
              />
            )}
            <TabsWrapper showCreatePost={showCreatePost}>
              <Tabs>
                <Tabs.Tab title="Posts">{renderPosts()}</Tabs.Tab>
                {userGroup.publicDataStreamId && (
                  <Tabs.Tab title="Data Feed">{renderDataFeed()}</Tabs.Tab>
                )}
              </Tabs>
            </TabsWrapper>
          </CenterPart>
        ) : (
          <LoadingBlanket />
        )}
        <RightPart>{renderRightContent()}</RightPart>
      </TribeDetailsWrapper>
    </>
  )
}

const LeaderboardComponent = ({ userGroup }) => {
  const tribeId = prop('id', userGroup)

  const { data, loading, refetch } = useQuery(LastActiveCompetitionQuery, {
    skip: !tribeId,
    variables: { tribeId }
  })

  useEffect(() => {
    if (!tribeId) return

    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tribeId])

  if (!tribeId) return null
  if (loading) return <TribeLeaderboard.loading />

  const competition = prop('lastActiveTribeCompetition', data)

  // first we check for Tribe active Competition
  if (competition) {
    return (
      <Box size="xl" noShadow bordered>
        <Spacing size="l">
          <Spacing direction="row" align="center">
            <Graphic name="Cup" size={16} />
            <Text h6 color="main">
              {competition.subtitle}
            </Text>
          </Spacing>
          <Text h5>{competition.title}</Text>
          <CompetitionDataLeaderboard
            post={{ competition }}
            customOptInBox={({ disabled }) => (
              <CustomDataCompetitionOptInBox
                competition={competition}
                disabled={disabled}
              />
            )}
          />
        </Spacing>
      </Box>
    )
  }

  // default Tribe Leaderboard
  if (!isNil(userGroup.leaderboardDataStreamId)) {
    return <TribeLeaderboard tribe={userGroup} />
  }

  return null
}

const CustomDataCompetitionOptInBox = ({ competition, disabled }) => {
  const history = useHistory()

  const { data, loading } = useQuery(AllCommunityPollsQuery, {
    variables: {
      tribeId: competition.tribeId,
      memberId: competition.memberId,
      withCompetitions: true,
      containingWords: competition.title.split(' ').slice(-1)
    }
  })

  if (loading) return null

  const postId = path(['allCommunityPolls', '0', 'id'], data)

  return (
    <OptInBoxWrapper disabled={disabled}>
      <Box>
        <Spacing size="xl" align="center">
          <Text t1 center>
            You can contribute your anonymized data to this Competition too!
          </Text>
          <Button onClick={() => history.push(`/competition/${postId}`)}>
            Opt-in to Competition
          </Button>
        </Spacing>
      </Box>
    </OptInBoxWrapper>
  )
}

const getGradient = gradient => {
  if (gradient && gradient.length > 0) {
    return `linear-gradient(-50.39deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`
  }
  return theme.gradient.gradient1
}

const getPermission = ({ hasAdminRole, tribeId }) => {
  if (tribeId === UBDI_TRIBE_ID) {
    if (!hasAdminRole) {
      return false
    }
  }
  return true
}

const TribeDetailsWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.padding.xl};
`

const TabsWrapper = styled.div`
  padding-top: ${({ showCreatePost, theme }) =>
    showCreatePost ? theme.padding.xl : 0};
`

const CenterPart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 0px 75px;
`

const RightPart = styled.div`
  flex: 1;
  margin-top: -5px;
`

const LeftPart = styled.div`
  flex: 1;
`

const Image = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const HeaderContentWrapper = styled(Spacing)`
  margin-left: 0;
`

const OptInBoxWrapper = styled.div`
  padding: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`
