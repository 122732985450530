import { equals, find, isEmpty } from 'ramda'
import React from 'react'
import { Spacing } from '../../../components/ui'
import { useModal } from '../../../hooks'
import { StudyAttribute } from './StudyAttribute'

const DATA_TYPES = [
  {
    label: 'Anonymized',
    key: 'anonymized'
  },
  { label: 'Stripped of PII', key: 'strippedOfPii' },
  {
    label: 'Raw Data',
    key: 'rawData'
  }
]

const STORAGE_LOCATIONS = [
  { label: 'UBDI Server', key: 'ubdiServer' },
  { label: 'Company Server', key: 'companyServer' }
]

export const InsightsInfo = ({ opportunity }) => {
  const { openModal } = useModal()
  const getLabel = (key, items) => {
    const item = find(item => equals(item.key, key))(items)
    if (!item) return ''
    return item.label
  }

  return (
    <Spacing direction="row" size="m" stretchChildren>
      {opportunity.insightsDataType && (
        <StudyAttribute
          text="Data type"
          buttonText={getLabel(opportunity.insightsDataType, DATA_TYPES)}
          buttonColor="#5BD08A"
          onClick={() => openModal('InsightsDetails', { type: 'dataType' })}
        />
      )}
      {opportunity.insightsStorageLocation && (
        <StudyAttribute
          text="Stored on"
          buttonText={getLabel(
            opportunity.insightsStorageLocation,
            STORAGE_LOCATIONS
          )}
          onClick={() =>
            openModal('InsightsDetails', { type: 'storageLocation' })
          }
        />
      )}
      {!isEmpty(opportunity.company) && (
        <StudyAttribute
          text="Study for"
          buttonText={opportunity.company.fullName}
          buttonColor="#3D99DD"
          hideInfoIcon
        />
      )}
    </Spacing>
  )
}
