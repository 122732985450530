import gql from 'graphql-tag'

import { UserFragment } from '../fragments'

const mutation = gql`
  mutation SeenAllNotifications {
    seenAllNotifications {
      errors {
        key
        message
      }
      currentUser {
        id
        ...User
      }
    }
  }
  ${UserFragment}
`

export default mutation
