import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'react-apollo'
import { Box, Graphic, Spacing, Text, Button } from '../../../components/ui'
import { pathOr, pipe } from 'ramda'
import { ReferralsQuery } from '../../../state/queries'
import { useModal } from '../../../hooks'
import { withMutation } from '../../../components/wrappers'
import { Form } from 'react-final-form'
import SyncStateIcon from '../../../components/ui/SyncStateIcon'
import pluralize from 'pluralize'

const ReferralWrapper = styled.div`
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.l}`};
  background: ${({ theme }) => theme.color.purpleL3};
  border-radius: 10px;
`

const ReferralBoxInner = ({
  data: { loading, ...data },
  claimBonusCodeReward
}) => {
  const { openModal } = useModal()

  const [shouldShowAnimation, setShouldShowAnimation] = useState(false)

  const member = pathOr({}, ['currentUser', 'member'], data)
  const referralsDone = pathOr(0, ['referralStats', 'completedUsers'], member)
  const referralsPending = pathOr(0, ['referralStats', 'pendingUsers'], member)
  const hasClaimed = pathOr(
    false,
    ['referralStats', 'hasClaimedReferral'],
    member
  )
  const hasRedeemed = pathOr(
    false,
    ['referralStats', 'hasRedeemedReferral'],
    member
  )
  const onEnterReferralCode = () => openModal('ReferralCode')
  const onClaimReferralCode = () =>
    claimBonusCodeReward().then(({ userErrors, errors, data }) => {
      if (!userErrors && data && data.claimBonusCodeReward) {
        setShouldShowAnimation(true)
        return
      }

      return userErrors || errors
    })

  useEffect(() => {
    if (!shouldShowAnimation) return

    setTimeout(() => {
      setShouldShowAnimation(false)
    }, 2000)
  }, [shouldShowAnimation])

  const renderInstructions = () => {
    const hasCompletedWalletStep = pathOr(false, ['uphold', 'email'], member)
    const hasCompletedSourcesStep =
      pathOr([], ['currentUser', 'linkedDataSources'], data).length >= 2

    const getStepStatusColor = condition => (condition ? 'green' : 'greyL1')

    return (
      <Spacing>
        <Text h4>To earn $.20:</Text>
        <Spacing size="s">
          <Spacing direction="row" align="center">
            <Graphic
              name="Synced"
              size={22}
              themeColor={getStepStatusColor(hasCompletedWalletStep)}
            />
            <Text t2 color="main">
              Setup your wallet
            </Text>
          </Spacing>
          <Spacing direction="row" align="center">
            <Graphic
              name="Synced"
              size={22}
              themeColor={getStepStatusColor(hasCompletedSourcesStep)}
            />
            <Text t2 color="main">
              Link at least two data sources
            </Text>
          </Spacing>
        </Spacing>
        <Form onSubmit={onClaimReferralCode}>
          {({ handleSubmit, submitting, submitError }) => (
            <Spacing size="s">
              <Button
                onClick={handleSubmit}
                disabled={submitting}
                size="small"
                outline
                rounded
                block
              >
                {!shouldShowAnimation ? (
                  `Claim your reward`
                ) : (
                  <SyncStateIcon syncState="completed" size={15} />
                )}
              </Button>
              {submitError && (
                <Text t5 color="red">
                  {submitError}
                </Text>
              )}
            </Spacing>
          )}
        </Form>
      </Spacing>
    )
  }

  const renderAction = () => {
    if (shouldShowAnimation) return renderInstructions()
    if (hasClaimed) return null
    if (hasRedeemed) return renderInstructions()

    return (
      <Button onClick={onEnterReferralCode} outline rounded block size="small">
        Enter referral code
      </Button>
    )
  }

  return (
    <Box size="xl" bordered noShadow>
      <Spacing>
        <Spacing size="xs" direction="row" justify="space-between">
          <Text h4>Refer a friend, family member or even a stranger!</Text>
          <Graphic name="Referral" size={50} />
        </Spacing>
        <Spacing size="l">
          <Text t3>
            For every person you refer, you’ll both <b>earn $.20</b> after your
            referral is set up in Uphold and has linked two data sources!
          </Text>
          <ReferralWrapper>
            <Spacing
              size="xs"
              direction="row"
              justify="space-between"
              align="center"
            >
              <Text h7>Your code:</Text>
              <Text h5 color="main">
                {member.referralCode}
              </Text>
            </Spacing>
          </ReferralWrapper>
        </Spacing>
        <Spacing size="xl" separated>
          <Text t4 center>
            {referralsDone}{' '}
            {`${pluralize(
              'people',
              referralsDone
            )} have used your referral code and ${referralsPending} ${pluralize(
              'people',
              referralsPending
            )} are still pending`}
          </Text>
          {renderAction()}
        </Spacing>
      </Spacing>
    </Box>
  )
}

export const ReferralBox = pipe(
  graphql(ReferralsQuery),
  withMutation('claimBonusCodeReward')
)(ReferralBoxInner)
