import { gql } from 'apollo-boost'

export const ExtensionTokenQuery = gql`
  query ExtensionToken($urlCode: String!) {
    extensionToken(urlCode: $urlCode) {
      errors {
        key
        message
      }
      urlCode
      warehouseJWT
      shortId
      opportunity {
        id
        name
        inputDataSources {
          required
          query {
            sql
            objectType {
              id
              name
              description
            }
            objectTypeId
            dataSourceIds
            dataSources {
              id
              name
            }
            nativeQueryDescription {
              aggregation
              breakout
              filter
            }
          }
        }
      }
    }
  }
`
