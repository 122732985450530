const { difference, equals, match, isEmpty } = require('ramda')
const { parse, getUnixTime } = require('date-fns')

const getExpectedInputKeys = (objectTypeId) => {
  switch (objectTypeId) {
    // "Professional Resume"
    case 4400: {
      return [
        'First Name',
        'Last Name',
        'Maiden Name',
        'Address',
        'Birth Date',
        'Headline',
        'Summary',
        'Industry',
        'Zip Code',
        'Geo Location',
        'Twitter Handles',
        'Websites',
        'Instant Messengers',
      ]
    }
    // "Professional Connections"
    case 4401: {
      return [
        'First Name',
        'Last Name',
        'Email Address',
        'Company',
        'Position',
        'Connected On',
      ]
    }
    // Ad Targeting
    case 4404: {
      return [
        'Member Age',
        'Company Names',
        'Company Names',
        'Company Follower of',
        'Company Names',
        'Company Category',
        'Company Size',
        'Degrees',
        'degreeClass',
        'Member Schools',
        'Company Growth Rate',
        'Fields of Study',
        'Company Connections',
        'Function By Size',
        'Job Functions',
        'Member Gender',
        'Graduation Year',
        'Member Groups',
        'Company Industries',
        'Member Interests',
        'Interface Locales',
        'interfaceLocale',
        'Member Traits',
        'Profile Locations',
        'Job Seniorities',
        'Member Skills',
        'Job Titles',
        'Job Titles',
        'Job Titles',
        'Years of Experience',
      ]
    }
    default: {
      return []
    }
  }
}

const isInputInvalid = (firstRow, objectTypeId = 4404) => {
  const expectedInputKeys = getExpectedInputKeys(objectTypeId)

  if (!equals(expectedInputKeys, firstRow)) {
    return difference(expectedInputKeys, firstRow)
  }

  return false
}

const parseLinkedinDate = (date) =>
  getUnixTime(parse(date, 'dd MMM yyyy', new Date())) * 1000

const convertIntoObject = (objectTypeId = 4404) => (row) => {
  switch (objectTypeId) {
    /*
    "Professional Resume"

        headline -- [5]
        summary -- [6]
        industry -- [7]
        zipCode -- [8]
        location -- [9]
        websites -- [11]
      */
    case 4400: {
      const headline = row[5]
      const summary = row[6]
      const industry = row[7]
      const zipCode = row[8]
      const location = row[9]
      const websites = row[11]

      return {
        createddate: +new Date(), // field required by collector
        headline,
        summary,
        industry,
        zipCode,
        location,
        websites,
      }
    }
    /*
    "Professional Connections"

      company -- [3]
      position -- [4]
      connectedOn -- [5]
    */
    case 4401: {
      const company = row[3]
      const position = row[4]
      const connectedOn = row[5]

      return {
        createddate: +new Date(), // field required by collector
        company,
        position,
        connectedOn: parseLinkedinDate(connectedOn),
      }
    }
    /*
    "Ad Targeting"

      memberagestartrange -- [0][0]
      memberageendrange -- [0][1]
      companynames -- [1]
      companyfollowerof -- [3]
      companycategory -- [5]
      companysizestartrange -- [6][0]
      companysizeendrange -- [6][1]
      memberdegrees -- [7]
      memberschools -- [9]
      memberfieldsofstudy -- [11]
      companyconnections -- [12]
      membergender -- [15]
      membergraduationyear -- [16]
      membergroups -- [17]
      companyindustries -- [18]
      memberinterests -- [19]
      membertraits -- [22]
      memberprofilelocations -- [23]
      memberjobseniorities -- [24]
      memberskills -- [25]
      memberjobtitles -- [26]
      yearsOfExperience -- [29]
    */
    case 4404: {
      const memberAge = row[0]
      const companynames = row[1]
      const companyfollowerof = row[3]
      const companycategory = row[5]
      const companySize = row[6]
      const memberdegrees = row[7]
      const memberschools = row[9]
      const memberfieldsofstudy = row[11]
      const companyconnections = row[12]
      const membergender = row[15]
      const membergraduationyear = row[16]
      const membergroups = row[17]
      const companyindustries = row[18]
      const memberinterests = row[19]
      const membertraits = row[22]
      const memberprofilelocations = row[23]
      const memberjobseniorities = row[24]
      const memberskills = row[25]
      const memberjobtitles = row[26]
      const yearsOfExperience = row[29]

      const ageRange = match(/\d+/g, memberAge || '')
      const companySizeRange = match(/\d+/g, companySize || '')
      const yearsOfExperienceRange = match(/\d+/g, yearsOfExperience || '')

      const memberagestartrange = isEmpty(ageRange)
        ? null
        : parseInt(ageRange[0])
      const memberageendrange = isEmpty(ageRange) ? null : parseInt(ageRange[1])
      const companysizestartrange = isEmpty(companySizeRange)
        ? null
        : parseInt(companySizeRange[0])
      const companysizeendrange = isEmpty(companySizeRange)
        ? null
        : parseInt(companySizeRange[1])
      const memberyearsofexperience = isEmpty(yearsOfExperienceRange)
        ? null
        : parseInt(yearsOfExperienceRange[0])

      return {
        createddate: +new Date(), // field required by collector
        memberagestartrange,
        memberageendrange,
        companysizestartrange,
        companysizeendrange,
        companynames,
        companyfollowerof,
        companycategory,
        memberdegrees,
        memberschools,
        memberfieldsofstudy,
        companyconnections,
        membergender,
        membergraduationyear: parseInt(membergraduationyear),
        membergroups,
        companyindustries,
        memberinterests,
        membertraits,
        memberprofilelocations,
        memberjobseniorities,
        memberskills,
        memberjobtitles,
        memberyearsofexperience,
      }
    }
    default: {
      return null
    }
  }
}

export default {
  isInputInvalid,
  convertIntoObject,
}
