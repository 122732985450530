import gql from 'graphql-tag'

export const TransactionFragment = gql`
  fragment Transaction on Transaction {
    id
    name
    totalUbdi
    totalUsd
    createdAt
    categories
  }
`
