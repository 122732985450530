import React from 'react'
import styled from 'styled-components/macro'
import { Text, Spacing, TagGraphic } from '../../../components/ui'

const TagTouchable = styled.div`
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme, tag }) =>
    tag ? `${theme.padding.xs} ${theme.padding.s}` : `12px ${theme.padding.m}`};
  ${({ tag, theme }) => tag && `padding-right: ${theme.padding.l}`};
  background: ${({ isExpandedCategory, theme }) =>
    isExpandedCategory ? theme.color.white : theme.color.lightBlue};
  border: 2px solid
    ${({ isExpandedCategory, theme }) =>
      isExpandedCategory ? theme.color.secondary : theme.color.lightBlue};
`

const FilterCategory = ({ tag, isExpandedCategory, setExpandedCategory }) => (
  <Spacing size="s">
    <TagTouchable
      isExpandedCategory={isExpandedCategory}
      tag={tag}
      onClick={() => setExpandedCategory(tag)}
    >
      <Spacing direction="row" align="center">
        {tag && tag.uniqueId && <TagGraphic uniqueId={tag.uniqueId} />}
        <Text h6 color="darkBlue">
          {(tag && tag.label) || 'No Filter'}
        </Text>
      </Spacing>
    </TagTouchable>
  </Spacing>
)

export default FilterCategory
