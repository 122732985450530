export default {
  entitled: ['US', 'GB', 'AU'],
  list: {
    AF: {
      currency: 'AFN',
      callingCode: '93',
      flag: 'flag-af',
      name: 'Afghanistan',
      code: 'AF',
      unicode: 'U+1F1E6 U+1F1EB',
      emoji: '🇦🇫',
    },
    AL: {
      currency: 'ALL',
      callingCode: '355',
      flag: 'flag-al',
      name: 'Albania',
      code: 'AL',
      unicode: 'U+1F1E6 U+1F1F1',
      emoji: '🇦🇱',
    },
    DZ: {
      currency: 'DZD',
      callingCode: '213',
      flag: 'flag-dz',
      name: 'Algeria',
      code: 'DZ',
      unicode: 'U+1F1E9 U+1F1FF',
      emoji: '🇩🇿',
    },
    AS: {
      currency: 'USD',
      callingCode: '1684',
      flag: 'flag-as',
      name: 'American Samoa',
      code: 'AS',
      unicode: 'U+1F1E6 U+1F1F8',
      emoji: '🇦🇸',
    },
    AD: {
      currency: 'EUR',
      callingCode: '376',
      flag: 'flag-ad',
      name: 'Andorra',
      code: 'AD',
      unicode: 'U+1F1E6 U+1F1E9',
      emoji: '🇦🇩',
    },
    AO: {
      currency: 'AOA',
      callingCode: '244',
      flag: 'flag-ao',
      name: 'Angola',
      code: 'AO',
      unicode: 'U+1F1E6 U+1F1F4',
      emoji: '🇦🇴',
    },
    AI: {
      currency: 'XCD',
      callingCode: '1264',
      flag: 'flag-ai',
      name: 'Anguilla',
      code: 'AI',
      unicode: 'U+1F1E6 U+1F1EE',
      emoji: '🇦🇮',
    },
    AQ: {
      callingCode: '672',
      flag: 'flag-aq',
      name: 'Antarctica',
      code: 'AQ',
      unicode: 'U+1F1E6 U+1F1F6',
      emoji: '🇦🇶',
    },
    AG: {
      currency: 'XCD',
      callingCode: '1268',
      flag: 'flag-ag',
      name: 'Antigua and Barbuda',
      code: 'AG',
      unicode: 'U+1F1E6 U+1F1EC',
      emoji: '🇦🇬',
    },
    AR: {
      currency: 'ARS',
      callingCode: '54',
      flag: 'flag-ar',
      name: 'Argentina',
      code: 'AR',
      unicode: 'U+1F1E6 U+1F1F7',
      emoji: '🇦🇷',
    },
    AM: {
      currency: 'AMD',
      callingCode: '374',
      flag: 'flag-am',
      name: 'Armenia',
      code: 'AM',
      unicode: 'U+1F1E6 U+1F1F2',
      emoji: '🇦🇲',
    },
    AW: {
      currency: 'AWG',
      callingCode: '297',
      flag: 'flag-aw',
      name: 'Aruba',
      code: 'AW',
      unicode: 'U+1F1E6 U+1F1FC',
      emoji: '🇦🇼',
    },
    AU: {
      currency: 'AUD',
      callingCode: '61',
      flag: 'flag-au',
      name: 'Australia',
      code: 'AU',
      unicode: 'U+1F1E6 U+1F1FA',
      emoji: '🇦🇺',
    },
    AT: {
      currency: 'EUR',
      callingCode: '43',
      flag: 'flag-at',
      name: 'Austria',
      code: 'AT',
      unicode: 'U+1F1E6 U+1F1F9',
      emoji: '🇦🇹',
    },
    AZ: {
      currency: 'AZN',
      callingCode: '994',
      flag: 'flag-az',
      name: 'Azerbaijan',
      code: 'AZ',
      unicode: 'U+1F1E6 U+1F1FF',
      emoji: '🇦🇿',
    },
    BS: {
      currency: 'BSD',
      callingCode: '1242',
      flag: 'flag-bs',
      name: 'Bahamas',
      code: 'BS',
      unicode: 'U+1F1E7 U+1F1F8',
      emoji: '🇧🇸',
    },
    BH: {
      currency: 'BHD',
      callingCode: '973',
      flag: 'flag-bh',
      name: 'Bahrain',
      code: 'BH',
      unicode: 'U+1F1E7 U+1F1ED',
      emoji: '🇧🇭',
    },
    BD: {
      currency: 'BDT',
      callingCode: '880',
      flag: 'flag-bd',
      name: 'Bangladesh',
      code: 'BD',
      unicode: 'U+1F1E7 U+1F1E9',
      emoji: '🇧🇩',
    },
    BB: {
      currency: 'BBD',
      callingCode: '1246',
      flag: 'flag-bb',
      name: 'Barbados',
      code: 'BB',
      unicode: 'U+1F1E7 U+1F1E7',
      emoji: '🇧🇧',
    },
    BY: {
      currency: 'BYR',
      callingCode: '375',
      flag: 'flag-by',
      name: 'Belarus',
      code: 'BY',
      unicode: 'U+1F1E7 U+1F1FE',
      emoji: '🇧🇾',
    },
    BE: {
      currency: 'EUR',
      callingCode: '32',
      flag: 'flag-be',
      name: 'Belgium',
      code: 'BE',
      unicode: 'U+1F1E7 U+1F1EA',
      emoji: '🇧🇪',
    },
    BZ: {
      currency: 'BZD',
      callingCode: '501',
      flag: 'flag-bz',
      name: 'Belize',
      code: 'BZ',
      unicode: 'U+1F1E7 U+1F1FF',
      emoji: '🇧🇿',
    },
    BJ: {
      currency: 'XOF',
      callingCode: '229',
      flag: 'flag-bj',
      name: 'Benin',
      code: 'BJ',
      unicode: 'U+1F1E7 U+1F1EF',
      emoji: '🇧🇯',
    },
    BM: {
      currency: 'BMD',
      callingCode: '1441',
      flag: 'flag-bm',
      name: 'Bermuda',
      code: 'BM',
      unicode: 'U+1F1E7 U+1F1F2',
      emoji: '🇧🇲',
    },
    BT: {
      currency: 'BTN',
      callingCode: '975',
      flag: 'flag-bt',
      name: 'Bhutan',
      code: 'BT',
      unicode: 'U+1F1E7 U+1F1F9',
      emoji: '🇧🇹',
    },
    BO: {
      currency: 'BOB',
      callingCode: '591',
      flag: 'flag-bo',
      name: 'Bolivia',
      code: 'BO',
      unicode: 'U+1F1E7 U+1F1F4',
      emoji: '🇧🇴',
    },
    BA: {
      currency: 'BAM',
      callingCode: '387',
      flag: 'flag-ba',
      name: 'Bosnia and Herzegovina',
      code: 'BA',
      unicode: 'U+1F1E7 U+1F1E6',
      emoji: '🇧🇦',
    },
    BW: {
      currency: 'BWP',
      callingCode: '267',
      flag: 'flag-bw',
      name: 'Botswana',
      code: 'BW',
      unicode: 'U+1F1E7 U+1F1FC',
      emoji: '🇧🇼',
    },
    BV: {
      currency: 'NOK',
      callingCode: '55',
      flag: 'flag-bv',
      name: 'Bouvet Island',
      code: 'BV',
      unicode: 'U+1F1E7 U+1F1FB',
      emoji: '🇧🇻',
    },
    BR: {
      currency: 'BRL',
      callingCode: '55',
      flag: 'flag-br',
      name: 'Brazil',
      code: 'BR',
      unicode: 'U+1F1E7 U+1F1F7',
      emoji: '🇧🇷',
    },
    IO: {
      currency: 'USD',
      callingCode: '246',
      flag: 'flag-io',
      name: 'British Indian Ocean Territory',
      code: 'IO',
      unicode: 'U+1F1EE U+1F1F4',
      emoji: '🇮🇴',
    },
    VG: {
      currency: 'USD',
      callingCode: '1284',
      flag: 'flag-vg',
      name: 'British Virgin Islands',
      code: 'VG',
      unicode: 'U+1F1FB U+1F1EC',
      emoji: '🇻🇬',
    },
    BN: {
      currency: 'BND',
      callingCode: '673',
      flag: 'flag-bn',
      name: 'Brunei',
      code: 'BN',
      unicode: 'U+1F1E7 U+1F1F3',
      emoji: '🇧🇳',
    },
    BG: {
      currency: 'BGN',
      callingCode: '359',
      flag: 'flag-bg',
      name: 'Bulgaria',
      code: 'BG',
      unicode: 'U+1F1E7 U+1F1EC',
      emoji: '🇧🇬',
    },
    BF: {
      currency: 'XOF',
      callingCode: '226',
      flag: 'flag-bf',
      name: 'Burkina Faso',
      code: 'BF',
      unicode: 'U+1F1E7 U+1F1EB',
      emoji: '🇧🇫',
    },
    BI: {
      currency: 'BIF',
      callingCode: '257',
      flag: 'flag-bi',
      name: 'Burundi',
      code: 'BI',
      unicode: 'U+1F1E7 U+1F1EE',
      emoji: '🇧🇮',
    },
    KH: {
      currency: 'KHR',
      callingCode: '855',
      flag: 'flag-kh',
      name: 'Cambodia',
      code: 'KH',
      unicode: 'U+1F1F0 U+1F1ED',
      emoji: '🇰🇭',
    },
    CM: {
      currency: 'XAF',
      callingCode: '237',
      flag: 'flag-cm',
      name: 'Cameroon',
      code: 'CM',
      unicode: 'U+1F1E8 U+1F1F2',
      emoji: '🇨🇲',
    },
    CA: {
      currency: 'CAD',
      callingCode: '1',
      flag: 'flag-ca',
      name: 'Canada',
      code: 'CA',
      unicode: 'U+1F1E8 U+1F1E6',
      emoji: '🇨🇦',
    },
    CV: {
      currency: 'CVE',
      callingCode: '238',
      flag: 'flag-cv',
      name: 'Cape Verde',
      code: 'CV',
      unicode: 'U+1F1E8 U+1F1FB',
      emoji: '🇨🇻',
    },
    KY: {
      currency: 'KYD',
      callingCode: '1345',
      flag: 'flag-ky',
      name: 'Cayman Islands',
      code: 'KY',
      unicode: 'U+1F1F0 U+1F1FE',
      emoji: '🇰🇾',
    },
    CF: {
      currency: 'XAF',
      callingCode: '236',
      flag: 'flag-cf',
      name: 'Central African Republic',
      code: 'CF',
      unicode: 'U+1F1E8 U+1F1EB',
      emoji: '🇨🇫',
    },
    TD: {
      currency: 'XAF',
      callingCode: '235',
      flag: 'flag-td',
      name: 'Chad',
      code: 'TD',
      unicode: 'U+1F1F9 U+1F1E9',
      emoji: '🇹🇩',
    },
    CL: {
      currency: 'CLF',
      callingCode: '56',
      flag: 'flag-cl',
      name: 'Chile',
      code: 'CL',
      unicode: 'U+1F1E8 U+1F1F1',
      emoji: '🇨🇱',
    },
    CN: {
      currency: 'CNY',
      callingCode: '86',
      flag: 'flag-cn',
      name: 'China',
      code: 'CN',
      unicode: 'U+1F1E8 U+1F1F3',
      emoji: '🇨🇳',
    },
    CX: {
      currency: 'AUD',
      callingCode: '61',
      flag: 'flag-cx',
      name: 'Christmas Island',
      code: 'CX',
      unicode: 'U+1F1E8 U+1F1FD',
      emoji: '🇨🇽',
    },
    CC: {
      currency: 'AUD',
      callingCode: '61',
      flag: 'flag-cc',
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
      unicode: 'U+1F1E8 U+1F1E8',
      emoji: '🇨🇨',
    },
    CO: {
      currency: 'COP',
      callingCode: '57',
      flag: 'flag-co',
      name: 'Colombia',
      code: 'CO',
      unicode: 'U+1F1E8 U+1F1F4',
      emoji: '🇨🇴',
    },
    KM: {
      currency: 'KMF',
      callingCode: '269',
      flag: 'flag-km',
      name: 'Comoros',
      code: 'KM',
      unicode: 'U+1F1F0 U+1F1F2',
      emoji: '🇰🇲',
    },
    CK: {
      currency: 'NZD',
      callingCode: '682',
      flag: 'flag-ck',
      name: 'Cook Islands',
      code: 'CK',
      unicode: 'U+1F1E8 U+1F1F0',
      emoji: '🇨🇰',
    },
    CR: {
      currency: 'CRC',
      callingCode: '506',
      flag: 'flag-cr',
      name: 'Costa Rica',
      code: 'CR',
      unicode: 'U+1F1E8 U+1F1F7',
      emoji: '🇨🇷',
    },
    HR: {
      currency: 'HRK',
      callingCode: '385',
      flag: 'flag-hr',
      name: 'Croatia',
      code: 'HR',
      unicode: 'U+1F1ED U+1F1F7',
      emoji: '🇭🇷',
    },
    CU: {
      currency: 'CUC',
      callingCode: '53',
      flag: 'flag-cu',
      name: 'Cuba',
      code: 'CU',
      unicode: 'U+1F1E8 U+1F1FA',
      emoji: '🇨🇺',
    },
    CW: {
      currency: 'ANG',
      callingCode: '5999',
      flag: 'flag-cw',
      name: 'Curaçao',
      code: 'CW',
      unicode: 'U+1F1E8 U+1F1FC',
      emoji: '🇨🇼',
    },
    CY: {
      currency: 'EUR',
      callingCode: '357',
      flag: 'flag-cy',
      name: 'Cyprus',
      code: 'CY',
      unicode: 'U+1F1E8 U+1F1FE',
      emoji: '🇨🇾',
    },
    CZ: {
      currency: 'CZK',
      callingCode: '420',
      flag: 'flag-cz',
      name: 'Czechia',
      code: 'CZ',
      unicode: 'U+1F1E8 U+1F1FF',
      emoji: '🇨🇿',
    },
    CD: {
      currency: 'CDF',
      callingCode: '243',
      flag: 'flag-cd',
      name: 'DR Congo',
      code: 'CD',
      unicode: 'U+1F1E8 U+1F1E9',
      emoji: '🇨🇩',
    },
    DK: {
      currency: 'DKK',
      callingCode: '45',
      flag: 'flag-dk',
      name: 'Denmark',
      code: 'DK',
      unicode: 'U+1F1E9 U+1F1F0',
      emoji: '🇩🇰',
    },
    DJ: {
      currency: 'DJF',
      callingCode: '253',
      flag: 'flag-dj',
      name: 'Djibouti',
      code: 'DJ',
      unicode: 'U+1F1E9 U+1F1EF',
      emoji: '🇩🇯',
    },
    DM: {
      currency: 'XCD',
      callingCode: '1767',
      flag: 'flag-dm',
      name: 'Dominica',
      code: 'DM',
      unicode: 'U+1F1E9 U+1F1F2',
      emoji: '🇩🇲',
    },
    DO: {
      currency: 'DOP',
      callingCode: '1809',
      flag: 'flag-do',
      name: 'Dominican Republic',
      code: 'DO',
      unicode: 'U+1F1E9 U+1F1F4',
      emoji: '🇩🇴',
    },
    EC: {
      currency: 'USD',
      callingCode: '593',
      flag: 'flag-ec',
      name: 'Ecuador',
      code: 'EC',
      unicode: 'U+1F1EA U+1F1E8',
      emoji: '🇪🇨',
    },
    EG: {
      currency: 'EGP',
      callingCode: '20',
      flag: 'flag-eg',
      name: 'Egypt',
      code: 'EG',
      unicode: 'U+1F1EA U+1F1EC',
      emoji: '🇪🇬',
    },
    SV: {
      currency: 'SVC',
      callingCode: '503',
      flag: 'flag-sv',
      name: 'El Salvador',
      code: 'SV',
      unicode: 'U+1F1F8 U+1F1FB',
      emoji: '🇸🇻',
    },
    GQ: {
      currency: 'XAF',
      callingCode: '240',
      flag: 'flag-gq',
      name: 'Equatorial Guinea',
      code: 'GQ',
      unicode: 'U+1F1EC U+1F1F6',
      emoji: '🇬🇶',
    },
    ER: {
      currency: 'ERN',
      callingCode: '291',
      flag: 'flag-er',
      name: 'Eritrea',
      code: 'ER',
      unicode: 'U+1F1EA U+1F1F7',
      emoji: '🇪🇷',
    },
    EE: {
      currency: 'EUR',
      callingCode: '372',
      flag: 'flag-ee',
      name: 'Estonia',
      code: 'EE',
      unicode: 'U+1F1EA U+1F1EA',
      emoji: '🇪🇪',
    },
    SZ: {
      currency: 'SZL',
      callingCode: '268',
      flag: 'flag-sz',
      name: 'Eswatini',
      code: 'SZ',
      unicode: 'U+1F1F8 U+1F1FF',
      emoji: '🇸🇿',
    },
    ET: {
      currency: 'ETB',
      callingCode: '251',
      flag: 'flag-et',
      name: 'Ethiopia',
      code: 'ET',
      unicode: 'U+1F1EA U+1F1F9',
      emoji: '🇪🇹',
    },
    FK: {
      currency: 'FKP',
      callingCode: '500',
      flag: 'flag-fk',
      name: 'Falkland Islands',
      code: 'FK',
      unicode: 'U+1F1EB U+1F1F0',
      emoji: '🇫🇰',
    },
    FO: {
      currency: 'DKK',
      callingCode: '298',
      flag: 'flag-fo',
      name: 'Faroe Islands',
      code: 'FO',
      unicode: 'U+1F1EB U+1F1F4',
      emoji: '🇫🇴',
    },
    BQ: {
      name: 'Caribbean Netherlands',
      callingCode: '599',
      currency: 'USD',
      code: 'BQ',
      unicode: 'U+1F1E7 U+1F1F6',
      emoji: '🇧🇶',
    },
    AC: {
      name: 'Ascension Island',
      callingCode: '247',
      currency: 'SHP',
      code: 'AC',
      unicode: 'U+1F1E6 U+1F1E8',
      emoji: '🇦🇨',
    },
    FJ: {
      currency: 'FJD',
      callingCode: '679',
      flag: 'flag-fj',
      name: 'Fiji',
      code: 'FJ',
      unicode: 'U+1F1EB U+1F1EF',
      emoji: '🇫🇯',
    },
    FI: {
      currency: 'EUR',
      callingCode: '358',
      flag: 'flag-fi',
      name: 'Finland',
      code: 'FI',
      unicode: 'U+1F1EB U+1F1EE',
      emoji: '🇫🇮',
    },
    FR: {
      currency: 'EUR',
      callingCode: '33',
      flag: 'flag-fr',
      name: 'France',
      code: 'FR',
      unicode: 'U+1F1EB U+1F1F7',
      emoji: '🇫🇷',
    },
    GF: {
      currency: 'EUR',
      callingCode: '594',
      flag: 'flag-gf',
      name: 'French Guiana',
      code: 'GF',
      unicode: 'U+1F1EC U+1F1EB',
      emoji: '🇬🇫',
    },
    PF: {
      currency: 'XPF',
      callingCode: '689',
      flag: 'flag-pf',
      name: 'French Polynesia',
      code: 'PF',
      unicode: 'U+1F1F5 U+1F1EB',
      emoji: '🇵🇫',
    },
    TF: {
      currency: 'EUR',
      callingCode: '262',
      flag: 'flag-tf',
      name: 'French Southern and Antarctic Lands',
      code: 'TF',
      unicode: 'U+1F1F9 U+1F1EB',
      emoji: '🇹🇫',
    },
    GA: {
      currency: 'XAF',
      callingCode: '241',
      flag: 'flag-ga',
      name: 'Gabon',
      code: 'GA',
      unicode: 'U+1F1EC U+1F1E6',
      emoji: '🇬🇦',
    },
    GM: {
      currency: 'GMD',
      callingCode: '220',
      flag: 'flag-gm',
      name: 'Gambia',
      code: 'GM',
      unicode: 'U+1F1EC U+1F1F2',
      emoji: '🇬🇲',
    },
    GE: {
      currency: 'GEL',
      callingCode: '995',
      flag: 'flag-ge',
      name: 'Georgia',
      code: 'GE',
      unicode: 'U+1F1EC U+1F1EA',
      emoji: '🇬🇪',
    },
    DE: {
      currency: 'EUR',
      callingCode: '49',
      flag: 'flag-de',
      name: 'Germany',
      code: 'DE',
      unicode: 'U+1F1E9 U+1F1EA',
      emoji: '🇩🇪',
    },
    GH: {
      currency: 'GHS',
      callingCode: '233',
      flag: 'flag-gh',
      name: 'Ghana',
      code: 'GH',
      unicode: 'U+1F1EC U+1F1ED',
      emoji: '🇬🇭',
    },
    GI: {
      currency: 'GIP',
      callingCode: '350',
      flag: 'flag-gi',
      name: 'Gibraltar',
      code: 'GI',
      unicode: 'U+1F1EC U+1F1EE',
      emoji: '🇬🇮',
    },
    GR: {
      currency: 'EUR',
      callingCode: '30',
      flag: 'flag-gr',
      name: 'Greece',
      code: 'GR',
      unicode: 'U+1F1EC U+1F1F7',
      emoji: '🇬🇷',
    },
    GL: {
      currency: 'DKK',
      callingCode: '299',
      flag: 'flag-gl',
      name: 'Greenland',
      code: 'GL',
      unicode: 'U+1F1EC U+1F1F1',
      emoji: '🇬🇱',
    },
    GD: {
      currency: 'XCD',
      callingCode: '1473',
      flag: 'flag-gd',
      name: 'Grenada',
      code: 'GD',
      unicode: 'U+1F1EC U+1F1E9',
      emoji: '🇬🇩',
    },
    GP: {
      currency: 'EUR',
      callingCode: '590',
      flag: 'flag-gp',
      name: 'Guadeloupe',
      code: 'GP',
      unicode: 'U+1F1EC U+1F1F5',
      emoji: '🇬🇵',
    },
    GU: {
      currency: 'USD',
      callingCode: '1671',
      flag: 'flag-gu',
      name: 'Guam',
      code: 'GU',
      unicode: 'U+1F1EC U+1F1FA',
      emoji: '🇬🇺',
    },
    GT: {
      currency: 'GTQ',
      callingCode: '502',
      flag: 'flag-gt',
      name: 'Guatemala',
      code: 'GT',
      unicode: 'U+1F1EC U+1F1F9',
      emoji: '🇬🇹',
    },
    GG: {
      currency: 'GBP',
      callingCode: '44',
      flag: 'flag-gg',
      name: 'Guernsey',
      code: 'GG',
      unicode: 'U+1F1EC U+1F1EC',
      emoji: '🇬🇬',
    },
    GN: {
      currency: 'GNF',
      callingCode: '224',
      flag: 'flag-gn',
      name: 'Guinea',
      code: 'GN',
      unicode: 'U+1F1EC U+1F1F3',
      emoji: '🇬🇳',
    },
    GW: {
      currency: 'XOF',
      callingCode: '245',
      flag: 'flag-gw',
      name: 'Guinea-Bissau',
      code: 'GW',
      unicode: 'U+1F1EC U+1F1FC',
      emoji: '🇬🇼',
    },
    GY: {
      currency: 'GYD',
      callingCode: '592',
      flag: 'flag-gy',
      name: 'Guyana',
      code: 'GY',
      unicode: 'U+1F1EC U+1F1FE',
      emoji: '🇬🇾',
    },
    HT: {
      currency: 'HTG',
      callingCode: '509',
      flag: 'flag-ht',
      name: 'Haiti',
      code: 'HT',
      unicode: 'U+1F1ED U+1F1F9',
      emoji: '🇭🇹',
    },
    HM: {
      currency: 'AUD',
      callingCode: '672',
      flag: 'flag-hm',
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
      unicode: 'U+1F1ED U+1F1F2',
      emoji: '🇭🇲',
    },
    HN: {
      currency: 'HNL',
      callingCode: '504',
      flag: 'flag-hn',
      name: 'Honduras',
      code: 'HN',
      unicode: 'U+1F1ED U+1F1F3',
      emoji: '🇭🇳',
    },
    HK: {
      currency: 'HKD',
      callingCode: '852',
      flag: 'flag-hk',
      name: 'Hong Kong',
      code: 'HK',
      unicode: 'U+1F1ED U+1F1F0',
      emoji: '🇭🇰',
    },
    HU: {
      currency: 'HUF',
      callingCode: '36',
      flag: 'flag-hu',
      name: 'Hungary',
      code: 'HU',
      unicode: 'U+1F1ED U+1F1FA',
      emoji: '🇭🇺',
    },
    IS: {
      currency: 'ISK',
      callingCode: '354',
      flag: 'flag-is',
      name: 'Iceland',
      code: 'IS',
      unicode: 'U+1F1EE U+1F1F8',
      emoji: '🇮🇸',
    },
    IN: {
      currency: 'INR',
      callingCode: '91',
      flag: 'flag-in',
      name: 'India',
      code: 'IN',
      unicode: 'U+1F1EE U+1F1F3',
      emoji: '🇮🇳',
    },
    ID: {
      currency: 'IDR',
      callingCode: '62',
      flag: 'flag-id',
      name: 'Indonesia',
      code: 'ID',
      unicode: 'U+1F1EE U+1F1E9',
      emoji: '🇮🇩',
    },
    IR: {
      currency: 'IRR',
      callingCode: '98',
      flag: 'flag-ir',
      name: 'Iran',
      code: 'IR',
      unicode: 'U+1F1EE U+1F1F7',
      emoji: '🇮🇷',
    },
    IQ: {
      currency: 'IQD',
      callingCode: '964',
      flag: 'flag-iq',
      name: 'Iraq',
      code: 'IQ',
      unicode: 'U+1F1EE U+1F1F6',
      emoji: '🇮🇶',
    },
    IE: {
      currency: 'EUR',
      callingCode: '353',
      flag: 'flag-ie',
      name: 'Ireland',
      code: 'IE',
      unicode: 'U+1F1EE U+1F1EA',
      emoji: '🇮🇪',
    },
    IM: {
      currency: 'GBP',
      callingCode: '44',
      flag: 'flag-im',
      name: 'Isle of Man',
      code: 'IM',
      unicode: 'U+1F1EE U+1F1F2',
      emoji: '🇮🇲',
    },
    IL: {
      currency: 'ILS',
      callingCode: '972',
      flag: 'flag-il',
      name: 'Israel',
      code: 'IL',
      unicode: 'U+1F1EE U+1F1F1',
      emoji: '🇮🇱',
    },
    IT: {
      currency: 'EUR',
      callingCode: '39',
      flag: 'flag-it',
      name: 'Italy',
      code: 'IT',
      unicode: 'U+1F1EE U+1F1F9',
      emoji: '🇮🇹',
    },
    CI: {
      currency: 'XOF',
      callingCode: '225',
      flag: 'flag-ci',
      name: 'Ivory Coast',
      code: 'CI',
      unicode: 'U+1F1E8 U+1F1EE',
      emoji: '🇨🇮',
    },
    JM: {
      currency: 'JMD',
      callingCode: '1876',
      flag: 'flag-jm',
      name: 'Jamaica',
      code: 'JM',
      unicode: 'U+1F1EF U+1F1F2',
      emoji: '🇯🇲',
    },
    JP: {
      currency: 'JPY',
      callingCode: '81',
      flag: 'flag-jp',
      name: 'Japan',
      code: 'JP',
      unicode: 'U+1F1EF U+1F1F5',
      emoji: '🇯🇵',
    },
    JE: {
      currency: 'GBP',
      callingCode: '44',
      flag: 'flag-je',
      name: 'Jersey',
      code: 'JE',
      unicode: 'U+1F1EF U+1F1EA',
      emoji: '🇯🇪',
    },
    JO: {
      currency: 'JOD',
      callingCode: '962',
      flag: 'flag-jo',
      name: 'Jordan',
      code: 'JO',
      unicode: 'U+1F1EF U+1F1F4',
      emoji: '🇯🇴',
    },
    KZ: {
      currency: 'KZT',
      callingCode: '76',
      flag: 'flag-kz',
      name: 'Kazakhstan',
      code: 'KZ',
      unicode: 'U+1F1F0 U+1F1FF',
      emoji: '🇰🇿',
    },
    KE: {
      currency: 'KES',
      callingCode: '254',
      flag: 'flag-ke',
      name: 'Kenya',
      code: 'KE',
      unicode: 'U+1F1F0 U+1F1EA',
      emoji: '🇰🇪',
    },
    KI: {
      currency: 'AUD',
      callingCode: '686',
      flag: 'flag-ki',
      name: 'Kiribati',
      code: 'KI',
      unicode: 'U+1F1F0 U+1F1EE',
      emoji: '🇰🇮',
    },
    XK: {
      currency: 'EUR',
      callingCode: '383',
      flag: 'flag-xk',
      name: 'Kosovo',
      code: 'XK',
      unicode: 'U+1F1FD U+1F1F0',
      emoji: '🇽🇰',
    },
    KW: {
      currency: 'KWD',
      callingCode: '965',
      flag: 'flag-kw',
      name: 'Kuwait',
      code: 'KW',
      unicode: 'U+1F1F0 U+1F1FC',
      emoji: '🇰🇼',
    },
    KG: {
      currency: 'KGS',
      callingCode: '996',
      flag: 'flag-kg',
      name: 'Kyrgyzstan',
      code: 'KG',
      unicode: 'U+1F1F0 U+1F1EC',
      emoji: '🇰🇬',
    },
    LA: {
      currency: 'LAK',
      callingCode: '856',
      flag: 'flag-la',
      name: 'Laos',
      code: 'LA',
      unicode: 'U+1F1F1 U+1F1E6',
      emoji: '🇱🇦',
    },
    LV: {
      currency: 'EUR',
      callingCode: '371',
      flag: 'flag-lv',
      name: 'Latvia',
      code: 'LV',
      unicode: 'U+1F1F1 U+1F1FB',
      emoji: '🇱🇻',
    },
    LB: {
      currency: 'LBP',
      callingCode: '961',
      flag: 'flag-lb',
      name: 'Lebanon',
      code: 'LB',
      unicode: 'U+1F1F1 U+1F1E7',
      emoji: '🇱🇧',
    },
    LS: {
      currency: 'LSL',
      callingCode: '266',
      flag: 'flag-ls',
      name: 'Lesotho',
      code: 'LS',
      unicode: 'U+1F1F1 U+1F1F8',
      emoji: '🇱🇸',
    },
    LR: {
      currency: 'LRD',
      callingCode: '231',
      flag: 'flag-lr',
      name: 'Liberia',
      code: 'LR',
      unicode: 'U+1F1F1 U+1F1F7',
      emoji: '🇱🇷',
    },
    LY: {
      currency: 'LYD',
      callingCode: '218',
      flag: 'flag-ly',
      name: 'Libya',
      code: 'LY',
      unicode: 'U+1F1F1 U+1F1FE',
      emoji: '🇱🇾',
    },
    LI: {
      currency: 'CHF',
      callingCode: '423',
      flag: 'flag-li',
      name: 'Liechtenstein',
      code: 'LI',
      unicode: 'U+1F1F1 U+1F1EE',
      emoji: '🇱🇮',
    },
    LT: {
      currency: 'EUR',
      callingCode: '370',
      flag: 'flag-lt',
      name: 'Lithuania',
      code: 'LT',
      unicode: 'U+1F1F1 U+1F1F9',
      emoji: '🇱🇹',
    },
    LU: {
      currency: 'EUR',
      callingCode: '352',
      flag: 'flag-lu',
      name: 'Luxembourg',
      code: 'LU',
      unicode: 'U+1F1F1 U+1F1FA',
      emoji: '🇱🇺',
    },
    MO: {
      currency: 'MOP',
      callingCode: '853',
      flag: 'flag-mo',
      name: 'Macau',
      code: 'MO',
      unicode: 'U+1F1F2 U+1F1F4',
      emoji: '🇲🇴',
    },
    MK: {
      currency: 'MKD',
      callingCode: '389',
      flag: 'flag-mk',
      name: 'Macedonia',
      code: 'MK',
      unicode: 'U+1F1F2 U+1F1F0',
      emoji: '🇲🇰',
    },
    MG: {
      currency: 'MGA',
      callingCode: '261',
      flag: 'flag-mg',
      name: 'Madagascar',
      code: 'MG',
      unicode: 'U+1F1F2 U+1F1EC',
      emoji: '🇲🇬',
    },
    MW: {
      currency: 'MWK',
      callingCode: '265',
      flag: 'flag-mw',
      name: 'Malawi',
      code: 'MW',
      unicode: 'U+1F1F2 U+1F1FC',
      emoji: '🇲🇼',
    },
    MY: {
      currency: 'MYR',
      callingCode: '60',
      flag: 'flag-my',
      name: 'Malaysia',
      code: 'MY',
      unicode: 'U+1F1F2 U+1F1FE',
      emoji: '🇲🇾',
    },
    MV: {
      currency: 'MVR',
      callingCode: '960',
      flag: 'flag-mv',
      name: 'Maldives',
      code: 'MV',
      unicode: 'U+1F1F2 U+1F1FB',
      emoji: '🇲🇻',
    },
    ML: {
      currency: 'XOF',
      callingCode: '223',
      flag: 'flag-ml',
      name: 'Mali',
      code: 'ML',
      unicode: 'U+1F1F2 U+1F1F1',
      emoji: '🇲🇱',
    },
    MT: {
      currency: 'EUR',
      callingCode: '356',
      flag: 'flag-mt',
      name: 'Malta',
      code: 'MT',
      unicode: 'U+1F1F2 U+1F1F9',
      emoji: '🇲🇹',
    },
    MH: {
      currency: 'USD',
      callingCode: '692',
      flag: 'flag-mh',
      name: 'Marshall Islands',
      code: 'MH',
      unicode: 'U+1F1F2 U+1F1ED',
      emoji: '🇲🇭',
    },
    MQ: {
      currency: 'EUR',
      callingCode: '596',
      flag: 'flag-mq',
      name: 'Martinique',
      code: 'MQ',
      unicode: 'U+1F1F2 U+1F1F6',
      emoji: '🇲🇶',
    },
    MR: {
      currency: 'MRO',
      callingCode: '222',
      flag: 'flag-mr',
      name: 'Mauritania',
      code: 'MR',
      unicode: 'U+1F1F2 U+1F1F7',
      emoji: '🇲🇷',
    },
    MU: {
      currency: 'MUR',
      callingCode: '230',
      flag: 'flag-mu',
      name: 'Mauritius',
      code: 'MU',
      unicode: 'U+1F1F2 U+1F1FA',
      emoji: '🇲🇺',
    },
    YT: {
      currency: 'EUR',
      callingCode: '262',
      flag: 'flag-yt',
      name: 'Mayotte',
      code: 'YT',
      unicode: 'U+1F1FE U+1F1F9',
      emoji: '🇾🇹',
    },
    MX: {
      currency: 'MXN',
      callingCode: '52',
      flag: 'flag-mx',
      name: 'Mexico',
      code: 'MX',
      unicode: 'U+1F1F2 U+1F1FD',
      emoji: '🇲🇽',
    },
    FM: {
      currency: 'USD',
      callingCode: '691',
      flag: 'flag-fm',
      name: 'Micronesia',
      code: 'FM',
      unicode: 'U+1F1EB U+1F1F2',
      emoji: '🇫🇲',
    },
    MD: {
      currency: 'MDL',
      callingCode: '373',
      flag: 'flag-md',
      name: 'Moldova',
      code: 'MD',
      unicode: 'U+1F1F2 U+1F1E9',
      emoji: '🇲🇩',
    },
    MC: {
      currency: 'EUR',
      callingCode: '377',
      flag: 'flag-mc',
      name: 'Monaco',
      code: 'MC',
      unicode: 'U+1F1F2 U+1F1E8',
      emoji: '🇲🇨',
    },
    MN: {
      currency: 'MNT',
      callingCode: '976',
      flag: 'flag-mn',
      name: 'Mongolia',
      code: 'MN',
      unicode: 'U+1F1F2 U+1F1F3',
      emoji: '🇲🇳',
    },
    ME: {
      currency: 'EUR',
      callingCode: '382',
      flag: 'flag-me',
      name: 'Montenegro',
      code: 'ME',
      unicode: 'U+1F1F2 U+1F1EA',
      emoji: '🇲🇪',
    },
    MS: {
      currency: 'XCD',
      callingCode: '1664',
      flag: 'flag-ms',
      name: 'Montserrat',
      code: 'MS',
      unicode: 'U+1F1F2 U+1F1F8',
      emoji: '🇲🇸',
    },
    MA: {
      currency: 'MAD',
      callingCode: '212',
      flag: 'flag-ma',
      name: 'Morocco',
      code: 'MA',
      unicode: 'U+1F1F2 U+1F1E6',
      emoji: '🇲🇦',
    },
    MZ: {
      currency: 'MZN',
      callingCode: '258',
      flag: 'flag-mz',
      name: 'Mozambique',
      code: 'MZ',
      unicode: 'U+1F1F2 U+1F1FF',
      emoji: '🇲🇿',
    },
    MM: {
      currency: 'MMK',
      callingCode: '95',
      flag: 'flag-mm',
      name: 'Myanmar',
      code: 'MM',
      unicode: 'U+1F1F2 U+1F1F2',
      emoji: '🇲🇲',
    },
    NA: {
      currency: 'NAD',
      callingCode: '264',
      flag: 'flag-na',
      name: 'Namibia',
      code: 'NA',
      unicode: 'U+1F1F3 U+1F1E6',
      emoji: '🇳🇦',
    },
    NR: {
      currency: 'AUD',
      callingCode: '674',
      flag: 'flag-nr',
      name: 'Nauru',
      code: 'NR',
      unicode: 'U+1F1F3 U+1F1F7',
      emoji: '🇳🇷',
    },
    NP: {
      currency: 'NPR',
      callingCode: '977',
      flag: 'flag-np',
      name: 'Nepal',
      code: 'NP',
      unicode: 'U+1F1F3 U+1F1F5',
      emoji: '🇳🇵',
    },
    NL: {
      currency: 'EUR',
      callingCode: '31',
      flag: 'flag-nl',
      name: 'Netherlands',
      code: 'NL',
      unicode: 'U+1F1F3 U+1F1F1',
      emoji: '🇳🇱',
    },
    NC: {
      currency: 'XPF',
      callingCode: '687',
      flag: 'flag-nc',
      name: 'New Caledonia',
      code: 'NC',
      unicode: 'U+1F1F3 U+1F1E8',
      emoji: '🇳🇨',
    },
    NZ: {
      currency: 'NZD',
      callingCode: '64',
      flag: 'flag-nz',
      name: 'New Zealand',
      code: 'NZ',
      unicode: 'U+1F1F3 U+1F1FF',
      emoji: '🇳🇿',
    },
    NI: {
      currency: 'NIO',
      callingCode: '505',
      flag: 'flag-ni',
      name: 'Nicaragua',
      code: 'NI',
      unicode: 'U+1F1F3 U+1F1EE',
      emoji: '🇳🇮',
    },
    NE: {
      currency: 'XOF',
      callingCode: '227',
      flag: 'flag-ne',
      name: 'Niger',
      code: 'NE',
      unicode: 'U+1F1F3 U+1F1EA',
      emoji: '🇳🇪',
    },
    NG: {
      currency: 'NGN',
      callingCode: '234',
      flag: 'flag-ng',
      name: 'Nigeria',
      code: 'NG',
      unicode: 'U+1F1F3 U+1F1EC',
      emoji: '🇳🇬',
    },
    NU: {
      currency: 'NZD',
      callingCode: '683',
      flag: 'flag-nu',
      name: 'Niue',
      code: 'NU',
      unicode: 'U+1F1F3 U+1F1FA',
      emoji: '🇳🇺',
    },
    NF: {
      currency: 'AUD',
      callingCode: '672',
      flag: 'flag-nf',
      name: 'Norfolk Island',
      code: 'NF',
      unicode: 'U+1F1F3 U+1F1EB',
      emoji: '🇳🇫',
    },
    KP: {
      currency: 'KPW',
      callingCode: '850',
      flag: 'flag-kp',
      name: 'North Korea',
      code: 'KP',
      unicode: 'U+1F1F0 U+1F1F5',
      emoji: '🇰🇵',
    },
    MP: {
      currency: 'USD',
      callingCode: '1670',
      flag: 'flag-mp',
      name: 'Northern Mariana Islands',
      code: 'MP',
      unicode: 'U+1F1F2 U+1F1F5',
      emoji: '🇲🇵',
    },
    NO: {
      currency: 'NOK',
      callingCode: '47',
      flag: 'flag-no',
      name: 'Norway',
      code: 'NO',
      unicode: 'U+1F1F3 U+1F1F4',
      emoji: '🇳🇴',
    },
    OM: {
      currency: 'OMR',
      callingCode: '968',
      flag: 'flag-om',
      name: 'Oman',
      code: 'OM',
      unicode: 'U+1F1F4 U+1F1F2',
      emoji: '🇴🇲',
    },
    PK: {
      currency: 'PKR',
      callingCode: '92',
      flag: 'flag-pk',
      name: 'Pakistan',
      code: 'PK',
      unicode: 'U+1F1F5 U+1F1F0',
      emoji: '🇵🇰',
    },
    PW: {
      currency: 'USD',
      callingCode: '680',
      flag: 'flag-pw',
      name: 'Palau',
      code: 'PW',
      unicode: 'U+1F1F5 U+1F1FC',
      emoji: '🇵🇼',
    },
    PS: {
      currency: 'ILS',
      callingCode: '970',
      flag: 'flag-ps',
      name: 'Palestine',
      code: 'PS',
      unicode: 'U+1F1F5 U+1F1F8',
      emoji: '🇵🇸',
    },
    PA: {
      currency: 'PAB',
      callingCode: '507',
      flag: 'flag-pa',
      name: 'Panama',
      code: 'PA',
      unicode: 'U+1F1F5 U+1F1E6',
      emoji: '🇵🇦',
    },
    PG: {
      currency: 'PGK',
      callingCode: '675',
      flag: 'flag-pg',
      name: 'Papua New Guinea',
      code: 'PG',
      unicode: 'U+1F1F5 U+1F1EC',
      emoji: '🇵🇬',
    },
    PY: {
      currency: 'PYG',
      callingCode: '595',
      flag: 'flag-py',
      name: 'Paraguay',
      code: 'PY',
      unicode: 'U+1F1F5 U+1F1FE',
      emoji: '🇵🇾',
    },
    PE: {
      currency: 'PEN',
      callingCode: '51',
      flag: 'flag-pe',
      name: 'Peru',
      code: 'PE',
      unicode: 'U+1F1F5 U+1F1EA',
      emoji: '🇵🇪',
    },
    PH: {
      currency: 'PHP',
      callingCode: '63',
      flag: 'flag-ph',
      name: 'Philippines',
      code: 'PH',
      unicode: 'U+1F1F5 U+1F1ED',
      emoji: '🇵🇭',
    },
    PN: {
      currency: 'NZD',
      callingCode: '64',
      flag: 'flag-pn',
      name: 'Pitcairn Islands',
      code: 'PN',
      unicode: 'U+1F1F5 U+1F1F3',
      emoji: '🇵🇳',
    },
    PL: {
      currency: 'PLN',
      callingCode: '48',
      flag: 'flag-pl',
      name: 'Poland',
      code: 'PL',
      unicode: 'U+1F1F5 U+1F1F1',
      emoji: '🇵🇱',
    },
    PT: {
      currency: 'EUR',
      callingCode: '351',
      flag: 'flag-pt',
      name: 'Portugal',
      code: 'PT',
      unicode: 'U+1F1F5 U+1F1F9',
      emoji: '🇵🇹',
    },
    PR: {
      currency: 'USD',
      callingCode: '1787',
      flag: 'flag-pr',
      name: 'Puerto Rico',
      code: 'PR',
      unicode: 'U+1F1F5 U+1F1F7',
      emoji: '🇵🇷',
    },
    QA: {
      currency: 'QAR',
      callingCode: '974',
      flag: 'flag-qa',
      name: 'Qatar',
      code: 'QA',
      unicode: 'U+1F1F6 U+1F1E6',
      emoji: '🇶🇦',
    },
    CG: {
      currency: 'XAF',
      callingCode: '242',
      flag: 'flag-cg',
      name: 'Republic of the Congo',
      code: 'CG',
      unicode: 'U+1F1E8 U+1F1EC',
      emoji: '🇨🇬',
    },
    RO: {
      currency: 'RON',
      callingCode: '40',
      flag: 'flag-ro',
      name: 'Romania',
      code: 'RO',
      unicode: 'U+1F1F7 U+1F1F4',
      emoji: '🇷🇴',
    },
    RU: {
      currency: 'RUB',
      callingCode: '7',
      flag: 'flag-ru',
      name: 'Russia',
      code: 'RU',
      unicode: 'U+1F1F7 U+1F1FA',
      emoji: '🇷🇺',
    },
    RW: {
      currency: 'RWF',
      callingCode: '250',
      flag: 'flag-rw',
      name: 'Rwanda',
      code: 'RW',
      unicode: 'U+1F1F7 U+1F1FC',
      emoji: '🇷🇼',
    },
    RE: {
      currency: 'EUR',
      callingCode: '262',
      flag: 'flag-re',
      name: 'Réunion',
      code: 'RE',
      unicode: 'U+1F1F7 U+1F1EA',
      emoji: '🇷🇪',
    },
    BL: {
      currency: 'EUR',
      callingCode: '590',
      flag: 'flag-bl',
      name: 'Saint Barthélemy',
      code: 'BL',
      unicode: 'U+1F1E7 U+1F1F1',
      emoji: '🇧🇱',
    },
    KN: {
      currency: 'XCD',
      callingCode: '1869',
      flag: 'flag-kn',
      name: 'Saint Kitts and Nevis',
      code: 'KN',
      unicode: 'U+1F1F0 U+1F1F3',
      emoji: '🇰🇳',
    },
    LC: {
      currency: 'XCD',
      callingCode: '1758',
      flag: 'flag-lc',
      name: 'Saint Lucia',
      code: 'LC',
      unicode: 'U+1F1F1 U+1F1E8',
      emoji: '🇱🇨',
    },
    MF: {
      currency: 'EUR',
      callingCode: '590',
      flag: 'flag-mf',
      name: 'Saint Martin',
      code: 'MF',
      unicode: 'U+1F1F2 U+1F1EB',
      emoji: '🇲🇫',
    },
    PM: {
      currency: 'EUR',
      callingCode: '508',
      flag: 'flag-pm',
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
      unicode: 'U+1F1F5 U+1F1F2',
      emoji: '🇵🇲',
    },
    VC: {
      currency: 'XCD',
      callingCode: '1784',
      flag: 'flag-vc',
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
      unicode: 'U+1F1FB U+1F1E8',
      emoji: '🇻🇨',
    },
    WS: {
      currency: 'WST',
      callingCode: '685',
      flag: 'flag-ws',
      name: 'Samoa',
      code: 'WS',
      unicode: 'U+1F1FC U+1F1F8',
      emoji: '🇼🇸',
    },
    SM: {
      currency: 'EUR',
      callingCode: '378',
      flag: 'flag-sm',
      name: 'San Marino',
      code: 'SM',
      unicode: 'U+1F1F8 U+1F1F2',
      emoji: '🇸🇲',
    },
    SA: {
      currency: 'SAR',
      callingCode: '966',
      flag: 'flag-sa',
      name: 'Saudi Arabia',
      code: 'SA',
      unicode: 'U+1F1F8 U+1F1E6',
      emoji: '🇸🇦',
    },
    SN: {
      currency: 'XOF',
      callingCode: '221',
      flag: 'flag-sn',
      name: 'Senegal',
      code: 'SN',
      unicode: 'U+1F1F8 U+1F1F3',
      emoji: '🇸🇳',
    },
    RS: {
      currency: 'RSD',
      callingCode: '381',
      flag: 'flag-rs',
      name: 'Serbia',
      code: 'RS',
      unicode: 'U+1F1F7 U+1F1F8',
      emoji: '🇷🇸',
    },
    SC: {
      currency: 'SCR',
      callingCode: '248',
      flag: 'flag-sc',
      name: 'Seychelles',
      code: 'SC',
      unicode: 'U+1F1F8 U+1F1E8',
      emoji: '🇸🇨',
    },
    SL: {
      currency: 'SLL',
      callingCode: '232',
      flag: 'flag-sl',
      name: 'Sierra Leone',
      code: 'SL',
      unicode: 'U+1F1F8 U+1F1F1',
      emoji: '🇸🇱',
    },
    SG: {
      currency: 'SGD',
      callingCode: '65',
      flag: 'flag-sg',
      name: 'Singapore',
      code: 'SG',
      unicode: 'U+1F1F8 U+1F1EC',
      emoji: '🇸🇬',
    },
    SX: {
      currency: 'ANG',
      callingCode: '1721',
      flag: 'flag-sx',
      name: 'Sint Maarten',
      code: 'SX',
      unicode: 'U+1F1F8 U+1F1FD',
      emoji: '🇸🇽',
    },
    SK: {
      currency: 'EUR',
      callingCode: '421',
      flag: 'flag-sk',
      name: 'Slovakia',
      code: 'SK',
      unicode: 'U+1F1F8 U+1F1F0',
      emoji: '🇸🇰',
    },
    SI: {
      currency: 'EUR',
      callingCode: '386',
      flag: 'flag-si',
      name: 'Slovenia',
      code: 'SI',
      unicode: 'U+1F1F8 U+1F1EE',
      emoji: '🇸🇮',
    },
    SB: {
      currency: 'SBD',
      callingCode: '677',
      flag: 'flag-sb',
      name: 'Solomon Islands',
      code: 'SB',
      unicode: 'U+1F1F8 U+1F1E7',
      emoji: '🇸🇧',
    },
    SO: {
      currency: 'SOS',
      callingCode: '252',
      flag: 'flag-so',
      name: 'Somalia',
      code: 'SO',
      unicode: 'U+1F1F8 U+1F1F4',
      emoji: '🇸🇴',
    },
    ZA: {
      currency: 'ZAR',
      callingCode: '27',
      flag: 'flag-za',
      name: 'South Africa',
      code: 'ZA',
      unicode: 'U+1F1FF U+1F1E6',
      emoji: '🇿🇦',
    },
    GS: {
      currency: 'GBP',
      callingCode: '500',
      flag: 'flag-gs',
      name: 'South Georgia',
      code: 'GS',
      unicode: 'U+1F1EC U+1F1F8',
      emoji: '🇬🇸',
    },
    KR: {
      currency: 'KRW',
      callingCode: '82',
      flag: 'flag-kr',
      name: 'South Korea',
      code: 'KR',
      unicode: 'U+1F1F0 U+1F1F7',
      emoji: '🇰🇷',
    },
    SS: {
      currency: 'SSP',
      callingCode: '211',
      flag: 'flag-ss',
      name: 'South Sudan',
      code: 'SS',
      unicode: 'U+1F1F8 U+1F1F8',
      emoji: '🇸🇸',
    },
    ES: {
      currency: 'EUR',
      callingCode: '34',
      flag: 'flag-es',
      name: 'Spain',
      code: 'ES',
      unicode: 'U+1F1EA U+1F1F8',
      emoji: '🇪🇸',
    },
    LK: {
      currency: 'LKR',
      callingCode: '94',
      flag: 'flag-lk',
      name: 'Sri Lanka',
      code: 'LK',
      unicode: 'U+1F1F1 U+1F1F0',
      emoji: '🇱🇰',
    },
    SD: {
      currency: 'SDG',
      callingCode: '249',
      flag: 'flag-sd',
      name: 'Sudan',
      code: 'SD',
      unicode: 'U+1F1F8 U+1F1E9',
      emoji: '🇸🇩',
    },
    SR: {
      currency: 'SRD',
      callingCode: '597',
      flag: 'flag-sr',
      name: 'Suriname',
      code: 'SR',
      unicode: 'U+1F1F8 U+1F1F7',
      emoji: '🇸🇷',
    },
    SJ: {
      currency: 'NOK',
      callingCode: '4779',
      flag: 'flag-sj',
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
      unicode: 'U+1F1F8 U+1F1EF',
      emoji: '🇸🇯',
    },
    SE: {
      currency: 'SEK',
      callingCode: '46',
      flag: 'flag-se',
      name: 'Sweden',
      code: 'SE',
      unicode: 'U+1F1F8 U+1F1EA',
      emoji: '🇸🇪',
    },
    CH: {
      currency: 'CHE',
      callingCode: '41',
      flag: 'flag-ch',
      name: 'Switzerland',
      code: 'CH',
      unicode: 'U+1F1E8 U+1F1ED',
      emoji: '🇨🇭',
    },
    SY: {
      currency: 'SYP',
      callingCode: '963',
      flag: 'flag-sy',
      name: 'Syria',
      code: 'SY',
      unicode: 'U+1F1F8 U+1F1FE',
      emoji: '🇸🇾',
    },
    ST: {
      currency: 'STD',
      callingCode: '239',
      flag: 'flag-st',
      name: 'São Tomé and Príncipe',
      code: 'ST',
      unicode: 'U+1F1F8 U+1F1F9',
      emoji: '🇸🇹',
    },
    TW: {
      currency: 'TWD',
      callingCode: '886',
      flag: 'flag-tw',
      name: 'Taiwan',
      code: 'TW',
      unicode: 'U+1F1F9 U+1F1FC',
      emoji: '🇹🇼',
    },
    TJ: {
      currency: 'TJS',
      callingCode: '992',
      flag: 'flag-tj',
      name: 'Tajikistan',
      code: 'TJ',
      unicode: 'U+1F1F9 U+1F1EF',
      emoji: '🇹🇯',
    },
    TZ: {
      currency: 'TZS',
      callingCode: '255',
      flag: 'flag-tz',
      name: 'Tanzania',
      code: 'TZ',
      unicode: 'U+1F1F9 U+1F1FF',
      emoji: '🇹🇿',
    },
    TH: {
      currency: 'THB',
      callingCode: '66',
      flag: 'flag-th',
      name: 'Thailand',
      code: 'TH',
      unicode: 'U+1F1F9 U+1F1ED',
      emoji: '🇹🇭',
    },
    TL: {
      currency: 'USD',
      callingCode: '670',
      flag: 'flag-tl',
      name: 'Timor-Leste',
      code: 'TL',
      unicode: 'U+1F1F9 U+1F1F1',
      emoji: '🇹🇱',
    },
    TG: {
      currency: 'XOF',
      callingCode: '228',
      flag: 'flag-tg',
      name: 'Togo',
      code: 'TG',
      unicode: 'U+1F1F9 U+1F1EC',
      emoji: '🇹🇬',
    },
    TK: {
      currency: 'NZD',
      callingCode: '690',
      flag: 'flag-tk',
      name: 'Tokelau',
      code: 'TK',
      unicode: 'U+1F1F9 U+1F1F0',
      emoji: '🇹🇰',
    },
    TO: {
      currency: 'TOP',
      callingCode: '676',
      flag: 'flag-to',
      name: 'Tonga',
      code: 'TO',
      unicode: 'U+1F1F9 U+1F1F4',
      emoji: '🇹🇴',
    },
    TT: {
      currency: 'TTD',
      callingCode: '1868',
      flag: 'flag-tt',
      name: 'Trinidad and Tobago',
      code: 'TT',
      unicode: 'U+1F1F9 U+1F1F9',
      emoji: '🇹🇹',
    },
    TN: {
      currency: 'TND',
      callingCode: '216',
      flag: 'flag-tn',
      name: 'Tunisia',
      code: 'TN',
      unicode: 'U+1F1F9 U+1F1F3',
      emoji: '🇹🇳',
    },
    TR: {
      currency: 'TRY',
      callingCode: '90',
      flag: 'flag-tr',
      name: 'Turkey',
      code: 'TR',
      unicode: 'U+1F1F9 U+1F1F7',
      emoji: '🇹🇷',
    },
    TM: {
      currency: 'TMT',
      callingCode: '993',
      flag: 'flag-tm',
      name: 'Turkmenistan',
      code: 'TM',
      unicode: 'U+1F1F9 U+1F1F2',
      emoji: '🇹🇲',
    },
    TC: {
      currency: 'USD',
      callingCode: '1649',
      flag: 'flag-tc',
      name: 'Turks and Caicos Islands',
      code: 'TC',
      unicode: 'U+1F1F9 U+1F1E8',
      emoji: '🇹🇨',
    },
    TV: {
      currency: 'AUD',
      callingCode: '688',
      flag: 'flag-tv',
      name: 'Tuvalu',
      code: 'TV',
      unicode: 'U+1F1F9 U+1F1FB',
      emoji: '🇹🇻',
    },
    UG: {
      currency: 'UGX',
      callingCode: '256',
      flag: 'flag-ug',
      name: 'Uganda',
      code: 'UG',
      unicode: 'U+1F1FA U+1F1EC',
      emoji: '🇺🇬',
    },
    UA: {
      currency: 'UAH',
      callingCode: '380',
      flag: 'flag-ua',
      name: 'Ukraine',
      code: 'UA',
      unicode: 'U+1F1FA U+1F1E6',
      emoji: '🇺🇦',
    },
    AE: {
      currency: 'AED',
      callingCode: '971',
      flag: 'flag-ae',
      name: 'United Arab Emirates',
      code: 'AE',
      unicode: 'U+1F1E6 U+1F1EA',
      emoji: '🇦🇪',
    },
    GB: {
      currency: 'GBP',
      callingCode: '44',
      flag: 'flag-gb',
      name: 'United Kingdom',
      code: 'GB',
      unicode: 'U+1F1EC U+1F1E7',
      emoji: '🇬🇧',
    },
    US: {
      currency: 'USD',
      callingCode: '1',
      flag: 'flag-us',
      name: 'United States',
      code: 'US',
      unicode: 'U+1F1FA U+1F1F8',
      emoji: '🇺🇸',
    },
    UM: {
      currency: 'USD',
      callingCode: '246',
      flag: 'flag-um',
      name: 'United States Minor Outlying Islands',
      code: 'UM',
      unicode: 'U+1F1FA U+1F1F2',
      emoji: '🇺🇲',
    },
    VI: {
      currency: 'USD',
      callingCode: '1340',
      flag: 'flag-vi',
      name: 'United States Virgin Islands',
      code: 'VI',
      unicode: 'U+1F1FB U+1F1EE',
      emoji: '🇻🇮',
    },
    UY: {
      currency: 'UYI',
      callingCode: '598',
      flag: 'flag-uy',
      name: 'Uruguay',
      code: 'UY',
      unicode: 'U+1F1FA U+1F1FE',
      emoji: '🇺🇾',
    },
    UZ: {
      currency: 'UZS',
      callingCode: '998',
      flag: 'flag-uz',
      name: 'Uzbekistan',
      code: 'UZ',
      unicode: 'U+1F1FA U+1F1FF',
      emoji: '🇺🇿',
    },
    VU: {
      currency: 'VUV',
      callingCode: '678',
      flag: 'flag-vu',
      name: 'Vanuatu',
      code: 'VU',
      unicode: 'U+1F1FB U+1F1FA',
      emoji: '🇻🇺',
    },
    VA: {
      currency: 'EUR',
      callingCode: '3906698',
      flag: 'flag-va',
      name: 'Vatican City',
      code: 'VA',
      unicode: 'U+1F1FB U+1F1E6',
      emoji: '🇻🇦',
    },
    VE: {
      currency: 'VEF',
      callingCode: '58',
      flag: 'flag-ve',
      name: 'Venezuela',
      code: 'VE',
      unicode: 'U+1F1FB U+1F1EA',
      emoji: '🇻🇪',
    },
    VN: {
      currency: 'VND',
      callingCode: '84',
      flag: 'flag-vn',
      name: 'Vietnam',
      code: 'VN',
      unicode: 'U+1F1FB U+1F1F3',
      emoji: '🇻🇳',
    },
    WF: {
      currency: 'XPF',
      callingCode: '681',
      flag: 'flag-wf',
      name: 'Wallis and Futuna',
      code: 'WF',
      unicode: 'U+1F1FC U+1F1EB',
      emoji: '🇼🇫',
    },
    EH: {
      currency: 'MAD',
      callingCode: '212',
      flag: 'flag-eh',
      name: 'Western Sahara',
      code: 'EH',
      unicode: 'U+1F1EA U+1F1ED',
      emoji: '🇪🇭',
    },
    YE: {
      currency: 'YER',
      callingCode: '967',
      flag: 'flag-ye',
      name: 'Yemen',
      code: 'YE',
      unicode: 'U+1F1FE U+1F1EA',
      emoji: '🇾🇪',
    },
    ZM: {
      currency: 'ZMW',
      callingCode: '260',
      flag: 'flag-zm',
      name: 'Zambia',
      code: 'ZM',
      unicode: 'U+1F1FF U+1F1F2',
      emoji: '🇿🇲',
    },
    ZW: {
      currency: 'ZWL',
      callingCode: '263',
      flag: 'flag-zw',
      name: 'Zimbabwe',
      code: 'ZW',
      unicode: 'U+1F1FF U+1F1FC',
      emoji: '🇿🇼',
    },
    AX: {
      currency: 'EUR',
      callingCode: '358',
      flag: 'flag-ax',
      name: 'Åland Islands',
      code: 'AX',
      unicode: 'U+1F1E6 U+1F1FD',
      emoji: '🇦🇽',
    },
  },
}
